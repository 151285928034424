<script setup>
import CompPagination from "@/components/CompPagination";
import IconsComp from "@/components/IconsComp";
import CompSearch from "@/components/CompSearch";
import CompDropdown from "@/components/CompDropdown";
import CompBreadCrumbs from "@/components/CompBreadCrumbs";
import CompSelect from "@/components/CompSelect";
import CompModal from "@/components/CompModal";
import CompAvaliation from "@/components/CompAvaliation";
import { CompDataLoad, CompStatus } from '@/components'
import { ref, onMounted, watch } from "vue";
import { useAuthStore, useMonitorStore, useProposalStore, useSpecialtyStore } from "@/stores";
import { useRouter } from "vue-router";

const router = useRouter();
const proposals = ref([]);
const projects = ref([]);
const proposalsCount = ref([])
const currentPage = ref(1);
const oldCurrentPage = ref(1)
const firstPage = ref(1);
const lastPage = ref(1);
const perPage = ref(20);
const paginationOnLoad = ref(false)
const dataLoad = ref("Carregando..");
const selectedProposals = ref([]);
const selectedAction = ref("");
const ArrowIcons = ref([]);
const proposalSelectedModal = ref([])
const classifiedProposalModal = ref([])
const allStatus = ref(['Todos os status', 'Aguardando parecer', 'Aguardando classificação', 'Especialidade rejeitada', 'Selecionar parecerista', 'Triagem pendente', 'Classificada', 'Rejeitada']);
const selectedStatus = ref('Todos os status')
const selectedSpecialty = ref(0)
const specialties = ref([])
const search = ref("")
const user = ref(null)

onMounted(async () => {
    [proposals.value, projects.value, user.value] = await Promise.all([
        useMonitorStore().proposals().then(response => response?.data),
        useMonitorStore().projects().then(response => response?.data),
        useAuthStore().myUser().then(response => response?.data)
    ])

    proposalsCount.value = await useMonitorStore().proposalsAndProjectsCount(useAuthStore().user.id).then(response => response?.data);

    specialties.value = user.value?.instituto?.especialidades ?? []
    specialties.value.push({ id: 0, nome: "Todas especialidades" })
    specialties.value = specialties.value.sort(obj => obj.id)

    proposals.value.length === 0
        ? (dataLoad.value = "Nenhuma proposta encontrada")
        : "";
});

watch(currentPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getProposals();
    }
});

watch(perPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getProposals();
    }
});

async function getProposals() {
    proposals.value = [];
    dataLoad.value = "Carregando";

    paginationOnLoad.value = true

    const searchData = {
        nome: search.value,
        status: selectedStatus?.value == 'Todos os status' ? '' : selectedStatus?.value,
        especialidades: selectedSpecialty.value == 0 ? "" : selectedSpecialty.value,
        currentPage: currentPage.value !== oldCurrentPage.value ? currentPage.value : 1,
        perPage: perPage.value
    }
    proposals.value = await useMonitorStore().searchProposals(searchData);

    currentPage.value = proposals.value.meta.current_page;
    oldCurrentPage.value = currentPage.value
    perPage.value = proposals.value.meta.per_page;
    lastPage.value = proposals.value.meta.last_page;
    proposals.value = proposals.value.data;

    proposals.value.length === 0
        ? (dataLoad.value = "Nenhuma proposta encontrada")
        : "";

    paginationOnLoad.value = false
}

async function proposalSelected(proposal_id) {
    await useProposalStore().update(proposal_id, { status: 'Selecionado' }).then(() => {
        router.push({ name: "painel-monitor-projetos" })
    });
}

async function classifiedProposal(proposal_id) {
    await useProposalStore().update(proposal_id, { status: 'Classificada' }).then(() => {
        router.push({ name: "painel-monitor-proposta", params: { id: proposal_id } })
    });
}
</script>

<template>
    <section>
        <div class="container mx-auto px-6 pt-10">
            <div class="hidden md:flex justify-between flex-col md:flex-row items-center mb-8">
                <div>
                    <CompBreadCrumbs>
                        <template v-slot:default>
                            <li>
                                <router-link :to="{ name: 'painel-monitor' }">
                                    <IconsComp icon="editais-line" class="w-6 h-6" />
                                    Meu painel
                                </router-link>
                            </li>
                        </template>
                    </CompBreadCrumbs>
                </div>
            </div>

            <div class="flex flex-col lg:flex-row gap-6">
                <div class="w-full lg:w-6/12">
                    <div class="text-5xl font-bold font-poppins text-purple-800">
                        {{ user?.instituto?.nome ? user?.instituto?.nome : 'Não possui instituto' }}
                    </div>
                </div>
                <div class="card w-full lg:w-6/12">
                    <div class="text-2xl font-bold font-poppins text-purple-800 mb-2">
                        Propostas
                    </div>
                    <div class="grid gap-6 lg:grid-cols-3 propostas">
                        <div class="big red">
                            <div class="title">
                                <IconsComp icon="proposta-filled" />
                                <span>
                                    {{ proposalsCount.triagemPendente ? parseInt(proposalsCount.triagemPendente) : 0 +
                                        proposalsCount.selecionarParecerista ? parseInt(proposalsCount.selecionarParecerista) :
                                        0 +
                                            proposalsCount.especialidadeRejeitada ? parseInt(proposalsCount.especialidadeRejeitada)
                                            : 0
                                    }}
                                </span>
                            </div>
                            <div class="description">Em processo de triagem</div>
                        </div>

                        <div class="big red">
                            <div class="title">
                                <IconsComp icon="clock-filled" />
                                <span>
                                    {{ proposalsCount.aguardandoClassificacao ? proposalsCount.aguardandoClassificacao : 0
                                    }}
                                </span>
                            </div>
                            <div class="description">Aguardando classificação</div>
                        </div>

                        <div class="big blue">
                            <div class="title">
                                <IconsComp icon="proposta-filled" />
                                <span>
                                    {{ proposalsCount.classificada ? proposalsCount.classificada : 0 }}
                                </span>
                            </div>
                            <div class="description">Classificados (Banco de propostas)</div>
                        </div>

                        <div class="small yellow">
                            <div class="title">
                                <IconsComp icon="timer-filled" />
                                <span>
                                    {{ proposalsCount.aguardandoParecer ? proposalsCount.aguardandoParecer : 0 }}
                                </span>
                            </div>
                            <div class="description">Aguardando parecer</div>
                        </div>

                        <div class="small">
                            <div class="title">
                                <IconsComp icon="reject-filled" />
                                <span>
                                    {{ proposalsCount.rejeitada ? proposalsCount.rejeitada : 0 }}
                                </span>
                            </div>
                            <div class="description">Propostas rejeitadas</div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="text-2xl font-bold font-poppins text-purple-800 mb-2">
                        Projetos
                    </div>
                    <div class="grid grid-cols-1 gap-6 propostas">
                        <div class="big green">
                            <div class="title">
                                <IconsComp icon="clock-filled" />
                                <span>
                                    {{ proposalsCount.selecionado ? proposalsCount.selecionado : 0 }}
                                </span>
                            </div>
                            <div class="description">Classificados</div>
                        </div>

                        <div class="small">
                            <div class="title">
                                <IconsComp icon="reject-filled" />
                                <span>
                                    {{ proposalsCount.descontinuado ? proposalsCount.descontinuado : 0 }}
                                </span>
                            </div>
                            <div class="description">Finalizados</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-10">
                <ul class="menu-tabs">
                    <li class="active">
                        <router-link :to="{ name: 'painel-monitor' }">
                            <span>
                                <IconsComp icon="document" class="w-6 h-6" />
                            </span>
                            Propostas
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'painel-monitor-projetos' }">
                            <span>
                                <IconsComp icon="document" class="w-6 h-6" />
                            </span>
                            Projetos
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <!-- Filtro -->
    <section class="bg-purple-50 pt-8">
        <div v-if="selectedProposals.length === 0" class="container mx-auto px-6 pb-4">
            <div class="text-sm text-gray-400">Filtrar propostas</div>
            <div class="flex justify-between mt-4">
                <div class="flex items-center">
                    <div class="relative mr-2">
                        <CompSelect @change.prevent="getProposals()" class="max-w-sm" optionName="nome"
                            name="selectSpecialties" :options="specialties" v-model="selectedSpecialty"
                            :optionSelected="0" />
                    </div>
                    <div class="relative">
                        <CompSelect @change.prevent="getProposals()" class="max-w-sm" name="selectSpecialties"
                            :options="allStatus" v-model="selectedStatus" optionSelected="Todos os status " />
                    </div>
                </div>
                <div class="w-96 max-w-[100%]">
                    <CompSearch @submit.prevent="getProposals" name="search" v-model="search" />
                </div>
            </div>
        </div>
        <div v-else class="container mx-auto px-6 pb-4">
            <div class="text-sm text-gray-400">Ação em lote</div>
            <div class="flex items-center">
                <div class="mt-4">
                    <CompSelect v-model="selectedAction" name="action" optionDisabled="Selecione a ação" optionName="nome"
                        :options="[
                            { id: 1, nome: 'Opção 1' },
                            { id: 2, nome: 'Opção 2' },
                            { id: 3, nome: 'Opção 3' },
                        ]" />
                </div>
                <button class="btn primary ml-4" :disabled="!selectedAction">
                    Aplicar
                </button>
            </div>
        </div>
    </section>
    <!-- Tabela -->
    <section class="bg-purple-50">
        <div class="container mx-auto px-6 pt-2 pb-6">
            <div class="overflow-x-scroll xl:overflow-hidden rounded-lg border border-white shadow-md">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="flex items-center">
                                    Propostas
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Especialidade
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Inatividade
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>Status</div>
                            </th>
                            <th scope="col">
                                <div>Nota</div>
                            </th>
                            <th scope="col">
                                <div>
                                    Recebida em
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody v-show="proposals.length > 0" v-for="(proposal, i) in proposals" :key="i">
                        <tr>
                            <td>
                                <div class="w-[240px] truncate flex items-center">
                                    {{ proposal.nome_proposta }}
                                </div>
                            </td>
                            <td>
                                <div class="w-32 truncate">{{ proposal.especialidades?.map(obj => obj.nome).join(", ") }}
                                </div>
                            </td>
                            <td>
                                <div>
                                    {{ ($moment().diff(proposal.updated_at, "days")) }}
                                    dias
                                </div>
                            </td>
                            <td>
                                <CompStatus :status="proposal.status"
                                    :link="{ name: 'painel-monitor-proposta', params: { id: proposal?.id } }" />
                            </td>
                            <td>
                                <div>-</div>
                            </td>
                            <td>
                                <div>{{ $moment(proposal.created_at).format('DD/MM/YYYY') }}</div>
                            </td>
                            <td class="relative lg:static">
                                <div class="flex lg:justify-end items-center max-lg:float-right max-lg:mr-8">
                                    <CompDropdown alignment="right" divStyle="absolute" class="mr-8">
                                        <template #toggle>
                                            <IconsComp icon="more" class="w-6 h-6 fill-green-500" />
                                        </template>
                                        <router-link :to="{ name: 'painel-monitor-proposta', params: { id: proposal.id } }">
                                            <li>
                                                <IconsComp icon="eye" /> Ver proposta
                                            </li>
                                        </router-link>
                                        <a v-if="!proposal.status?.includes('Classificada')"
                                            @click.prevent="classifiedProposalModal[i] = !classifiedProposalModal[i]"
                                            class="cursor-pointer">
                                            <li>
                                                <IconsComp icon="download" />Adicionar ao banco
                                            </li>
                                        </a>
                                        <a v-if="proposal.status?.includes('Classificada')"
                                            @click.prevent="proposalSelectedModal[i] = !proposalSelectedModal[i]"
                                            class="cursor-pointer">
                                            <li>
                                                <IconsComp icon="download" />Validar como projeto
                                            </li>
                                        </a>
                                    </CompDropdown>
                                    <div v-if="proposal.avaliacoes?.length > 0" class="ml-auto">
                                        <button @click.prevent="ArrowIcons[i] = !ArrowIcons[i]">
                                            <IconsComp :class="{ 'rotate-180': ArrowIcons[i] }" icon="arrow-down"
                                                class="transition-all duration-300 fill-green-500 w-6 h-6 ml-12 md:ml-2" />
                                        </button>
                                    </div>
                                </div>
                                <CompModal v-model="proposalSelectedModal[i]" :isOpen="proposalSelectedModal[i]"
                                    @close="proposalSelectedModal[i] = !proposalSelectedModal[i]" customClass="validacao">
                                    <div class="text-xl font-poppins font-bold mb-2">
                                        Validar a proposta: {{ proposal?.nome_proposta }}. Como projeto?
                                    </div>
                                    <div class="mb-8">
                                        <p>
                                            Texto de suporte se necessários. Aenean eu ultricies lorem. Aliquam
                                            ac efficitur ante. Etiam nec tempus ante, sed bibendum magna. Donec
                                            sed tortor sed sem malesuada.
                                        </p>
                                    </div>
                                    <div class="flex justify-end">
                                        <button @click.prevent="
                                            proposalSelectedModal[i] = !proposalSelectedModal[i]
                                            " type="button" class="btn secondary">
                                            Cancelar
                                        </button>
                                        <button @click.prevent="
                                            proposalSelectedModal[i] = !proposalSelectedModal[i];
                                        proposalSelected(proposal.id);
                                        " type="button" class="btn primary">
                                            Confirmar
                                        </button>
                                    </div>
                                </CompModal>
                                <CompModal v-model="classifiedProposalModal[i]" :isOpen="classifiedProposalModal[i]"
                                    @close="classifiedProposalModal[i] = !classifiedProposalModal[i]"
                                    customClass="validacao">
                                    <div class="text-xl font-poppins font-bold mb-2">
                                        Adicionar a proposta: {{ proposal?.nome_proposta }}. Ao banco de propostas
                                        classificadas?
                                    </div>
                                    <div class="mb-8">
                                        <p>
                                            Texto de suporte se necessários. Aenean eu ultricies lorem. Aliquam
                                            ac efficitur ante. Etiam nec tempus ante, sed bibendum magna. Donec
                                            sed tortor sed sem malesuada.
                                        </p>
                                    </div>
                                    <div class="flex justify-end">
                                        <button @click.prevent="
                                            classifiedProposalModal[i] = !classifiedProposalModal[i]
                                            " type="button" class="btn secondary">
                                            Cancelar
                                        </button>
                                        <button @click.prevent="
                                            classifiedProposalModal[i] = !classifiedProposalModal[i];
                                        classifiedProposal(proposal.id);
                                        " type="button" class="btn primary">
                                            Confirmar
                                        </button>
                                    </div>
                                </CompModal>
                            </td>
                        </tr>
                        <CompAvaliation :avaliations="proposal.avaliacoes" :isOpen="ArrowIcons[i]" />
                    </tbody>
                    <tbody v-if="proposals.length === 0">
                        <tr>
                            <td colspan="1000">
                                <CompDataLoad :dataLoad="dataLoad" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
    <!--  Paginação -->
    <section class="bg-purple-50 pb-10">
        <div class="container mx-auto px-6">
            <CompPagination :onLoad="paginationOnLoad" v-model:quantity="perPage" v-model:page="currentPage"
                :firstPage="firstPage" :lastPage="lastPage" />
        </div>
    </section>
</template>
