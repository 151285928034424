<script setup>
import { ref } from 'vue'
import IconsComp from '@/components/IconsComp'
import CompCheckbox from '@/components/CompCheckbox'
import CompProgress from '@/components/CompProgress'
import CompModal from '@/components/CompModal'
import CompInput from '@/components/CompInput'
import CompTextArea from '@/components/CompTextArea'
import { Field } from 'vee-validate'

const props = defineProps({
    values: {},
})

const support_types = ref([
    "Contato com investidores",
    "Networking",
    "Acesso a dados",
    "Financiamento",
    "Materiais para prototipagem",
    "Auxílio a propriedade Intelectual",
    "Aprender a desenvolver projetos em saúde",
    "Aprender a desenvolver negócios em saúde",
    "Aprender sobre formas de captação de recurso",
    "Outro",
])

const heard_about = ref([
    "Instagram",
    "Linkedin",
    "E-mail institucional (Webmaster, HC Online, etc)",
    "Comunicação interna (Newsletter)",
    "Cartaz ou totem no complexo HCFMUSP",
    "Equipe Inova HC",
    "Indicação de amigos/colegas de trabalho",
    "Imprensa",
    "Aprender sobre formas de captação de recurso",
    "Outro",
])


const formStep3 = ref({
    team_motivation: props.values.team_motivation,
    support_types: props.values.support_types,
    heard_about: props.values.heard_about,
})

</script>

<template>
    <div class="mb-16">
        <div class="text-3xl font-poppins font-bold text-purple-800 mb-2">
            Motivações e necessidades de projeto
        </div>
        <p class="mb-8">Para podermos alocar melhor os recursos e esforços do InovaHC, precisamos entender um pouco das
            motivações e necessidades de projeto da equipe. Esta sessão nos ajudará a contribuir da melhor forma com seu
            projeto.</p>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Qual a motivação da equipe para participar do In.pulse?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompTextArea name="team_motivation" v-model="formStep3.team_motivation" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-6">
                Quais os tipos de apoio que você precisa para desenvolver seu projeto?
                <span class="ml-auto asterisk"></span>
            </div>

            <CompCheckbox name="support_types" :checkboxes="support_types" v-model="formStep3.support_types"
                :hasOther="true" />
        </div>

        <div class="card">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                Como ficou sabendo do processo seletivo In.pulse?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompCheckbox name="heard_about" :checkboxes="heard_about" v-model="formStep3.heard_about" :hasOther="true" />
        </div>
    </div>
</template>