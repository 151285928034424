<script setup>
import { ref, onMounted, onUnmounted, watch } from "vue";
import IconsComp from "@/components/IconsComp";
import CompCheckbox from "@/components/CompCheckbox";
import CompProgress from "@/components/CompProgress";
import CompModal from "@/components/CompModal";
import CompInput from "@/components/CompInput";
import {
    NewProgramInPulseStep1View,
    NewProgramInPulseStep2View,
    NewProgramInPulseStep3View,
    NewProgramInPulseStep4View,
    NewProgramInPulseStep5View,
    NewProgramInPulseStep6View,
} from "./new_program_inpulse_steps/index";
import { useProposalStore, useAuthStore, useEdictStore, useProgramStore, useDraftProposalStore, useAlertStore, useFormDownloadStore } from "@/stores";
import { Form, useForm } from "vee-validate";
import * as yup from "yup";
import { ptForm } from "yup-locale-pt";
import { useRouter, useRoute } from "vue-router";
import { schema, currentStep } from '@/schemas/inpulseSteps'

import moment from "moment-timezone";
moment.tz.setDefault("America/Sao_Paulo").locale("pt-br");

const route = useRoute();
const router = useRouter();

yup.setLocale(ptForm);

const steps = ref([
    "Introdução",
    "Equipe",
    "Motivações e necessidades",
    "Estágio da solução",
    "Projeto",
]);

const nextStep = () => {
    if (currentStep.value < steps.value.length) {
        currentStep.value++;
        window.scroll({ top: 100, left: 100, behavior: "smooth" });
    } else if (currentStep.value === steps.value.length) {
        currentStep.value++;
    }
};

const prevStep = () => {
    if (currentStep.value > 1) {
        currentStep.value--;
    }
};

const { errors, values, handleSubmit, resetForm } = useForm({
    validationSchema: schema,
    keepValuesOnUnmount: true,
    keepValuesOnMount: true,
    initialValues: JSON.parse(localStorage.getItem("form-inpulse")),
});

const program = ref(null);
const edict = ref(null);
const formDownload = ref(null);

onMounted(async () => {
    formDownload.value = await useFormDownloadStore().all().then(response => response?.data?.find(obj => obj.nome == "In.pulse"));
    program.value = await useProgramStore().find(2).then(response => response.data);
    edict.value = program.value.ultimoEdital;
    if (!edict.value || moment(edict.value.abertura) > moment() || moment(edict.value.encerramento) < moment()) {
        router.push('programas-inpulse');
        return;
    }

    const draftProposal = await useDraftProposalStore().findByEdict(edict.value.id).then(response => response?.data);
    if (!draftProposal && localStorage.getItem("form-inpulse") !== null) {
        localStorage.removeItem("form-inpulse");
    } else {
        resetForm({ values: JSON.parse(localStorage.getItem("form-inpulse")), force: true });
    }

    values["edict_id"] = edict.value.id;
    values["edict"] = "inpulse";

});

onUnmounted(() => {
    localStorage.removeItem("form-inpulse");
});

watch(errors, (newValue, oldValue) => {
    if (newValue != oldValue) {
        const firstErrorFieldName = Object.keys(errors.value)[0];
        const el = document.querySelector(`[name="${firstErrorFieldName}"]`);
        if (el) {
            el.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
            });
        }
    }
});

const saveDraftProposal = async () => {
    await useDraftProposalStore().store(values).then(() => {
        router.push({ name: 'painel-proponente' })
    });
};

const onSubmit = handleSubmit(async (values, { resetForm }) => {
    if (currentStep.value === 5) {
        const alertStore = useAlertStore();
        alertStore.success("Criando proposta...");

        await useProposalStore()
            .store(values)
            .then(async (response) => {
                if (response?.proposta?.id && values['business_model']) {
                    await useProposalStore().storeFile(response.proposta?.id, values);
                }
                if (response?.proposta?.id && values['pitch']) {
                    await useProposalStore().storeFile(response.proposta?.id, values);
                }

                nextStep();
                resetForm();
                localStorage.removeItem("form-inpulse");
                const alertStore = useAlertStore()
                alertStore.success(response?.message)
            });

        return;
    }

    nextStep();
});
</script>
<template>
    <section v-if="currentStep > 0 && currentStep <= steps.length">
        <div class="container mx-auto px-6 pt-12 pb-10 md:w-8/12">
            <div class="mb-16">
                <CompProgress :currentStep="currentStep" :steps="steps" />
            </div>

            <div class="text-5xl font-bold font-poppins text-purple-800 text-center">
                Inscrições Programa In.pulse
            </div>
        </div>
    </section>
    <section v-if="currentStep > 0 && currentStep <= steps.length" class="bg-purple-50 bg-opacity-80">
        <form @submit="onSubmit" keep-values>
            <div class="container mx-auto px-6 py-10 md:w-8/12">
                <NewProgramInPulseStep1View v-if="currentStep === 1" :values="values" :edict="edict">
                    <div class="flex mt-4 mb-16">
                        <button type="submit" class="btn primary max-w-4">
                            Preencher formulário de incrição
                        </button>
                        <a :href="formDownload?.url" target="_blank" class="no-underline">
                            <button type="button" class="btn secondary flex">
                                Baixar formulário
                                <IconsComp icon="download" class="fill-green-500 w-6 h-6 ml-4" />
                            </button>
                        </a>
                    </div>
                </NewProgramInPulseStep1View>

                <NewProgramInPulseStep2View v-if="currentStep === 2" :values="values" />

                <NewProgramInPulseStep3View v-if="currentStep === 3" :values="values" />

                <NewProgramInPulseStep4View v-if="currentStep === 4" :values="values" />

                <NewProgramInPulseStep5View v-if="currentStep === 5" :values="values" />

                <div v-if="currentStep > 1 && currentStep <= steps.length">
                    <div class="flex my-8">
                        <button @click.prevent="prevStep" type="button" class="btn medium secondary">
                            Voltar
                        </button>
                        <button type="submit" v-if="currentStep === steps.length" class="flex btn medium primary">
                            Enviar proposta
                            <IconsComp icon="arrow-left" class="ml-2 fill-white w-6 h-6" />
                        </button>
                        <button type="submit" v-else class="flex btn medium primary">
                            Avançar
                            <IconsComp icon="arrow-full-right" class="ml-2 fill-white w-6 h-6" />
                        </button>
                        <a @click.prevent="saveDraftProposal"
                            class="flex items-center ml-auto no-underline font-bold cursor-pointer">
                            Salvar rascunho
                            <IconsComp icon="save" class="ml-2 fill-green-500 w-6 h-6" />
                        </a>
                    </div>
                    <p class="flex text-red-500">
                        <span class="asterisk mr-2"></span>
                        Campo obrigatório
                    </p>
                </div>
            </div>
        </form>
    </section>

    <section class="h-full">
        <NewProgramInPulseStep6View v-if="currentStep === 6" />
    </section>
</template>
