<script setup>
import { ref } from 'vue'
import IconsComp from '@/components/IconsComp'
import CompCheckbox from '@/components/CompCheckbox'
import CompRadio from '@/components/CompRadio'
import CompProgress from '@/components/CompProgress'
import CompModal from '@/components/CompModal'
import CompInput from '@/components/CompInput'
import CompTextArea from '@/components/CompTextArea'
import { Field } from 'vee-validate'

const props = defineProps({
    values: {},
})

const development_stages = ref([
    "Coleta de dados sobre problema",
    "Ideia de conceito básico da solução",
    "Ideia desenvolvida com requisitos mínimos do projeto",
    "Protótipo de baixa fidelidade sem testes com usuários",
    "Protótipo de alta fidelidade sem testes com usuários",
    "Protótipo de baixa ou alta fidelidade com testes com usuários",
    "Mínimo produto viável com testes com possíveis clientes",
    "Empresa já constituída para comercializar a solução sem vendas",
    "Empresa já constituída para comercializar a solução com primeiras vendas",
    "Outro",
])

const project_inpi = ref([
    "Sim, patente depositada",
    "Sim, outro tipo de propriedade intelectual",
    "Não",
])

const received_promotion = ref([
    "Sim, FAPESP",
    "Sim, FINEP",
    "Sim, EMBRAPII",
    "Sim, capital privado",
    "Sim, fomento internacional",
    "Não",
    "Outro",
])

const formStep4 = ref({
    development_stage: props.values.development_stage,
    project_inpi: props.values.project_inpi,
    intellectual_support: props.values.intellectual_support,
    has_investiment: props.values.has_investiment,
    has_search: props.values.has_search,
    clinic_support: props.values.clinic_support,
    has_regulation: props.values.has_regulation,
    regulation_support: props.values.regulation_support,
    sensitive_data: props.values.sensitive_data,
    sensitive_data_support: props.values.sensitive_data_support,
    received_promotion: props.values.received_promotion,
    commercial_exploitation: props.values.commercial_exploitation,
    execution: props.values.execution,
})

</script>

<template>
    <div class="mb-16">
        <div class="text-3xl font-poppins font-bold text-purple-800 mb-2">
            Estágio da solução
        </div>
        <p>
            Nessa etapa, precisamos entender em que momento a sua startup está em termos de desenvolvimento tecnológico,
            investimento, relação com a ética em pesquisa e regulamentação. Isso é muito importante para entendermos o
            contexto geral e identificar pontos que necessitarão de ajustes, caso seu projeto seja selecionado.
        </p>
        <div class="card my-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-6">
                Estágio de desenvolvimento da solução
                <span class="ml-auto asterisk"></span>
            </div>

            <CompRadio name="development_stage" :radios="development_stages" v-model="formStep4.development_stage"
                :hasOther="true" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                O projeto já possui propriedade intelectual registrada no INPI?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompRadio name="project_inpi" :radios="project_inpi" v-model="formStep4.project_inpi" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                Será necessário apoio para proteção de propriedade intelectual da solução desenvolvida? Se sim, qual?
                <span class="ml-auto asterisk"></span>
            </div>

            <CompTextArea name="intellectual_support" v-model="formStep4.intellectual_support" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                O startup já recebeu algum investimento?
                <span class="ml-auto asterisk"></span>
            </div>
            <p class="text-small mb-4">
                Amigos, investidor anjo, alguma série, etc.
            </p>
            <Field v-slot="{ field, errors }" name="has_investiment">
                <input v-bind="field" type="text" placeholder="Sua resposta...">
                <p v-if="errors.length > 0" class="mt-2 peer-invalid:flex text-red-500 text-sm">{{ errors[0] }}</p>
            </Field>
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                Haverá pesquisa envolvendo seres humanos a operacionalização do projeto?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompRadio name="has_search" :radios="['Sim', 'Não', 'Não tenho certeza']" v-model="formStep4.has_search" />
        </div>


        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                Será necessário apoio para submissão de ensaio clínico ao Comitê de Ética em Pesquisa? Se sim, qual tipo de
                apoio?
                <span class="ml-auto asterisk"></span>
            </div>

            <CompTextArea name="clinic_support" v-model="formStep4.clinic_support" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                Haverá regulamentação aplicável ao uso da solução desenvolvida?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompRadio name="has_regulation" :radios="['Sim', 'Não', 'Não tenho certeza']"
                v-model="formStep4.has_regulation" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                Será necessário apoio para o processo de certificação/regulamentação da solução desenvolvida? Se sim, qual
                tipo de apoio?
                <span class="ml-auto asterisk"></span>
            </div>

            <CompTextArea name="regulation_support" v-model="formStep4.regulation_support" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                Haverá uso de dados pessoais/sensíveis/identificáveis na execução do projeto?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompRadio name="sensitive_data" :radios="['Sim', 'Não']" v-model="formStep4.sensitive_data" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                Será necessário apoio para tratamento dos dados pessoais/sensíveis/identificáveis utilizados pelo projeto?
                Se sim, qual?
                <span class="ml-auto asterisk"></span>
            </div>

            <CompTextArea name="sensitive_data_support" v-model="formStep4.sensitive_data_support" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                O projeto recebeu algum tipo de fomento?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompRadio name="received_promotion" :radios="received_promotion" v-model="formStep4.received_promotion"
                :hasOther="true" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                Existe intenção da solução desenvolvida ser transferida para exploração comercial por terceiros (ou outros
                desenhos societários)? Qual tipo de apoio é necessário caso positivo?
                <span class="ml-auto asterisk"></span>
            </div>

            <CompTextArea name="commercial_exploitation" v-model="formStep4.commercial_exploitation" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                A equipe do projeto dispõe de todos os recursos identificados para sua execução? Se não, precisa de apoio?
                <span class="ml-auto asterisk"></span>
            </div>

            <CompTextArea name="execution" v-model="formStep4.execution" />
        </div>
    </div>
</template>