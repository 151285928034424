<script setup>
import IconsComp from "./IconsComp.vue";
import { ref } from "vue";
import { useField } from "vee-validate";

const props = defineProps({
    modelValue: {
        type: [String, Number],
        default: 5,
    },
    name: {
        type: String,
        required: true,
    },
    min: {
        type: [String, Number],
        default: 1,
    },
    max: {
        type: [String, Number],
        default: 10,
    },
    minName: {
        type: String,
        default: "Min.",
    },
    maxName: {
        type: String,
        default: "Max.",
    },
});

defineEmits(["update:modelValue"]);

const { errorMessage, value } = useField(props.name, props.schema);
</script>
<template>
    <div class="mt-4">
        <div class="mb-6">
            <!-- <input :value="modelValue" type="text" :name="name" :class="errorMessage ? '!border-red-500' : ''" readonly /> -->
            <div v-if="errorMessage" class="my-1 peer-invalid:flex text-red-500 text-sm">
                {{ errorMessage }} Use a barra abaixo para selecionar o valor.
            </div>
        </div>
        <div class="container">
            <div class="numbers mb-4">
                <span>1</span><span>2</span><span>3</span><span>4</span><span>5</span><span>6</span><span>7</span><span>8</span><span>9</span><span>10</span>
            </div>
            <div class="slider">
                <span><!-- half --></span>
                <input @input="$emit('update:modelValue', $event.target.value)" :value="modelValue" type="range"
                    class="custom-range" :min="min" :max="max" step="1" id="range" />
                <span><!-- half --></span>
            </div>
            <div class="flex items-center">
                <div>
                    {{ minName }}
                    <div class="text-sm text-gray-400 text-left mt-1">{{ min }}</div>
                </div>
                <div class="ml-auto">
                    {{ maxName }}
                    <div class="text-sm text-gray-400 text-right mt-1">{{ max }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.numbers,
.slider {
    display: flex;
    justify-content: space-between;
}

.numbers span,
.slider span {
    width: 1;
    flex-grow: 0;
    text-align: center;
}

.slider span {
    flex-grow: 0;
}

.slider input {
    width: 100%;
    /* flex-grow: 0; */
    margin-left: -8px;
    /* account for UI sizing */
    /* margin-right: -1px; */
    /* account for UI sizing */
}
</style>