<script setup>
import { ref } from 'vue'
import IconsComp from '@/components/IconsComp'
import CompCheckbox from '@/components/CompCheckbox'
import CompProgress from '@/components/CompProgress'
import CompModal from '@/components/CompModal'
import CompInput from '@/components/CompInput'
import CompMultiSelect from '@/components/CompMultiSelect'
import CompRadio from '@/components/CompRadio'
import CompTextArea from '@/components/CompTextArea'
import { Field, FieldArray } from 'vee-validate'

const props = defineProps({
    values: {},
})

const project_features = ref([
    "Software",
    "Serviço",
    "Processo",
    "Produto digital",
    "Produto físico",
    "Algorítimo de inteligência artificial",
    "Biotecnologia",
])

const expected_outcome = ref([
    "Indefinido",
    "Licenciar a solução para outra empresa e/ou instituição",
    "Alcançar a primeira venda/contrato da minha startup",
    "Amadurecer o modelo de negócios",
    "Amadurecer a tecnologia da startup",
    "Implementação da solução na gestão pública",
    "Outro",
])

const project_institutions = ref([
    "Sim, instituição privada na qual o representante do projeto é sódio.",
    "Sim, instituição privada na qual a equipe possui vinculo empregatício",
    "Sim, instituição pública na qual a equipe possui vinculo empregatício",
    "Sim, instituição pública sem vinculos empregatícios da equipe",
    "Não",
    "Outro",
])

const formStep5 = ref({
    project_name: props.values.project_name,
    specialty_areas: props.values.specialty_areas,
    project_feature: props.values.project_feature,
    project_description: props.values.project_description,
    project_problems: props.values.project_problems,
    people_affected: props.values.people_affected,
    project_solutions: props.values.project_solutions,
    project_offer: props.values.project_offer,
    project_validation: props.values.project_validation,
    project_expectations: props.values.project_expectations,
    project_scale: props.values.project_scale,
    project_files: props.values.project_files ? props.values.project_files : [{
        link: "",
        description: "",
    }],
    project_institution: props.values.project_institution,
})

const AddAnotherLink = () => {
    formStep5.value.project_files.push({});
}

const removeLink = (link) => {
    if (!formStep5.value.project_files.includes(link)) {
        return;
    }

    formStep5.value.project_files = formStep5.value.project_files.filter(
        selectedLink => selectedLink !== link
    );

    props.values.project_files = props.values.project_files.filter(
        selectedLink => selectedLink !== link
    );
}
</script>

<template>
    <div class="mb-16">
        <div class="text-3xl font-poppins font-bold text-purple-800 mb-2">
            Informações do projeto de desenvolvimento
        </div>
        <p class="mb-8">Precisamos de algumas informações sobre o projeto para podermos avaliá-lo :)</p>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Nome do projeto
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Máximo 280 caracteres
            </p>
            <CompTextArea name="project_name" v-model="formStep5.project_name" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Área de especialidade do projeto
                <span class="ml-auto asterisk"></span>
            </div>
            <CompMultiSelect name="specialty_areas" optionDisabled="Selecione uma ou mais especialidades"
                v-model="formStep5.specialty_areas" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-6">
                Do ponto de vista tecnológico o projeto se caracteriza como um:
                <span class="ml-auto asterisk"></span>
            </div>

            <CompCheckbox name="project_feature" :checkboxes="project_features" v-model="formStep5.project_feature" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Do que se trata o projeto?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Até 1500 caracteres
            </p>
            <CompTextArea name="project_description" v-model="formStep5.project_description" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Qual problema do sistema de saúde seu projeto se propõe a resolver?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Até 1500 caracteres
            </p>
            <CompTextArea name="project_problems" v-model="formStep5.project_problems" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Quem são e qual o número de potenciais pessoas atingidas por esse problema?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Quem são as pessoas atingidas pelo problema relatado? Quais suas funções, dores e necessidades? Qual o
                número de pessoas acometidas pelo problema descrito na pergunta anterior? Quais são as características
                gerais dessas pessoas?
            </p>
            <CompTextArea name="people_affected" v-model="formStep5.people_affected" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Como a sua solução pretende resolver esse problema?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Descreva em no máximo 1500 caracteres qual o funcionamento e objetivo/finalidade da invenção proposta pelo
                projeto, evidenciando de que maneira - dentro do contexto do problema – ela resolverá ou mitigará o
                problema. E.g.: “um hardware que irá medir... a fim de...”, “um novo processo que permitirá... que
                evita...”, “redução de... através de um método de diagnóstico que...”.
            </p>
            <CompTextArea name="project_solutions" v-model="formStep5.project_solutions" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Quais as oportunidades de aplicação da sua solução e por que ela é inovadora?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Descreva em no máximo 1500 caracteres de que forma a solução a ser desenvolvida será inserida no mercado da
                saúde pública, destacando onde pode ser aplicada, seu diferencial frente a concorrentes existentes, produtos
                e/ou serviços concorrentes.
            </p>
            <CompTextArea name="project_offer" v-model="formStep5.project_offer" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                O projeto passou por validação cientifica e apresentou evidências de seu funcionamento (Ensaio Clínico,
                Observacional, entre outros)? Comente os resultados encontrados até o momento.
                <span class="ml-auto asterisk"></span>
            </div>
            <CompTextArea name="project_validation" v-model="formStep5.project_validation" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Como a solução proposta poderia ser escalada ou replicada?
                <span class="ml-auto asterisk"></span>
            </div>
            <p class="pb-2">Levante quais os desafios para escalar mapeados até o presente momento (até 1000 caracteres)</p>
            <p>
                Dica: descreva o potencial da solução ser escalável (i.e., pode ser expandida e ser reproduzida em grandes
                quantidades, considerando o público alvo e os elementos que influenciem a chegada até o mesmo) ou em que
                grau é replicável nos diferentes níveis de saúde pública ou mesmo em outros setores (e.g., agritech,
                foodtech, biotech, etc.), assim como os desafios que a equipe identifica para alcançar o mercado.
            </p>
            <CompTextArea name="project_scale" v-model="formStep5.project_scale" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Insira os links relativos as publicações cientificas, site institucional ou outros links associados ao
                projeto.
                <span class="ml-auto asterisk"></span>
            </div>
            <div id="div-links">
                <div v-for="(project_files, i) in formStep5.project_files" :key="i" class="flex items-center w-full">
                    <div class="my-4 w-full">
                        <Field v-slot="{ field, errors }" :name="'project_files[' + i + '].link'">
                            <div class="flex mb-4 w-full">
                                <div class="bg-gray-200 border-gray-300 border-l border-t border-b rounded-l-lg py-2 pl-3 pr-2"
                                    :class="errors[0] ? '!border-red-500' : ''">
                                    http://
                                </div>

                                <input v-bind="field" type="link" placeholder="site.com"
                                    class="!rounded-l-none !border-l-0 !my-0" :class="errors[0] ? '!border-red-500' : ''" />
                            </div>
                            <p v-if="errors.length > 0" class="mt-2 peer-invalid:flex text-red-500 text-sm">{{ errors[0] }}
                            </p>
                        </Field>
                        <Field v-slot="{ field, errors }" :name="'project_files[' + i + '].description'">
                            <input v-bind="field" type="text" placeholder="Descrição do link"
                                :class="errors[0] ? '!border-red-500' : ''" />
                            <p v-if="errors.length > 0" class="mt-2 peer-invalid:flex text-red-500 text-sm">{{ errors[0] }}
                            </p>
                        </Field>
                    </div>
                    <button @click.prevent="removeLink(project_files)" type="button" class="ml-2">
                        <IconsComp icon="close-line" class="fill-gray-400 w-6 h-6" />
                    </button>
                </div>
            </div>
            <div class="flex mt-4 cursor-pointer">
                <IconsComp icon="add-circle" class="fill-green-500 w-6 h-6 mr-2" />
                <a @click.prevent="AddAnotherLink" class="font-bold cursor-pointer">
                    Adicionar outro link
                </a>
            </div>
        </div>

        <div class="card my-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                O projeto foi desenvolvido dentro de alguma instituição ou por alguma instituição?
                <span class="ml-auto asterisk"></span>
            </div>

            <CompRadio name="project_institution" :radios="project_institutions" v-model="formStep5.project_institution"
                :hasOther="true" />
        </div>

    </div>
</template>