<script setup>
import { CompBreadCrumbs, IconsComp, CompMultiSelect, CompCollapse, CompPagination, CompSearch, CompDropdown, CompModal, CompDataLoad } from "@/components";
import { ref, onMounted, watch } from "vue";
import { useAuthStore, useReviewerStore } from "@/stores";

const user = useAuthStore().user
const currentPage = ref(1);
const oldCurrentPage = ref(1);
const firstPage = ref(1);
const lastPage = ref(1);
const perPage = ref(20);
const paginationOnLoad = ref(false)
const reviewers = ref([]);
const dataLoad = ref("Carregando..");
const search = ref('')
const ArrowIcons = ref([])

onMounted(async () => {
    await getReviewers();
});

watch(currentPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getReviewers();
    }
});

watch(perPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getReviewers();
    }
});

async function getReviewers() {
    reviewers.value = [];
    dataLoad.value = "Carregando";

    paginationOnLoad.value = true
    reviewers.value = await useReviewerStore().search(search.value, "", "", perPage.value, currentPage.value !== oldCurrentPage.value ? currentPage.value : 1)

    currentPage.value = reviewers.value.meta.current_page;
    oldCurrentPage.value = currentPage.value
    perPage.value = reviewers.value.meta.per_page;
    lastPage.value = reviewers.value.meta.last_page;
    reviewers.value = reviewers.value.data;

    reviewers.value.length === 0
        ? (dataLoad.value = "Nenhuma parecerista encontrado")
        : "";

    paginationOnLoad.value = false
}
</script>

<template>
    <div class="container mx-auto px-6 pt-10">
        <div class="flex justify-between flex-col md:flex-row items-center mb-8">
            <div class="hidden md:flex">
                <CompBreadCrumbs>
                    <template v-slot:default>
                        <li>
                            <router-link v-if="user?.tipo === 'super-admin'" :to="{ name: 'painel-inovahc-monitores' }">
                                <IconsComp icon="user" class="w-6 h-6" />
                                Usuários
                            </router-link>
                            <router-link v-else :to="{ name: 'painel-inovahc-pareceristas' }">
                                <IconsComp icon="user" class="w-6 h-6" />
                                Usuários
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'painel-inovahc-pareceristas' }">
                                Pareceristas
                            </router-link>
                        </li>
                    </template>
                </CompBreadCrumbs>
            </div>
            <div class="ml-auto">
                <router-link :to="{ name: 'adicionar-parecerista' }" class="btn small btn-sm primary no-underline">Adicionar
                    novo</router-link>
            </div>
        </div>

        <div class="text-5xl font-bold font-poppins mb-8 text-purple-800">
            Gestão de usuários do sistema
        </div>
        <div>
            <ul class="menu-tabs">
                <li v-if="user?.tipo === 'super-admin'">
                    <router-link :to="{ name: 'painel-inovahc-monitores' }">
                        Monitores
                    </router-link>
                </li>
                <li class="active">
                    <router-link :to="{ name: 'painel-inovahc-pareceristas' }">
                        Pareceristas
                    </router-link>
                </li>
                <li v-if="user?.tipo === 'super-admin'">
                    <router-link :to="{ name: 'painel-inovahc-proponentes' }">
                        Proponentes
                    </router-link>
                </li>
            </ul>
        </div>
    </div>

    <div class="bg-purple-50">
        <div class="container mx-auto px-6 pt-8 mb-8">
            <div class="w-96 ml-auto max-w-full mb-4">
                <CompSearch @submit.prevent="getReviewers" name="search" v-model="search"
                    placeholder="Buscar parecerista" />
            </div>
            <div class="overflow-x-scroll lg:overflow-hidden rounded-lg border border-white shadow-md">
                <table class="table table-auto rounded">
                    <thead class="bg-gray-50">
                        <tr>
                            <th scope="col" style="width: 400px">
                                <div class="profile">
                                    Parecerista
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col" style="width: 300px">
                                <div>
                                    Especialidades
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col" style="width: 220px">
                                <div>
                                    Parecer pendente
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col" style="width: 50px">
                                <span>Status</span>
                            </th>
                            <th scope="col" width="50"></th>
                        </tr>
                    </thead>
                    <tbody v-show="reviewers.length > 0" v-for="(reviewer, i) in reviewers" :key="i"
                        class="bg-white border-t">
                        <tr class="hover:bg-white hover:text-purple-800">
                            <td>
                                <div v-if="reviewer.nome_completo" class="profile w-96">
                                    <div class="profile-img small">
                                        <img v-if="reviewer?.avatar" :src="reviewer?.avatar" alt="" />
                                        <img v-else src="@/assets/img/template/profile2.png" alt="" />
                                    </div>
                                    <div>
                                        {{ reviewer.nome_completo }}

                                        <div class="text-gray-400 text-sm">
                                            {{ reviewer.email }}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div v-if="reviewer.especialidades" class="w-32 truncate">
                                    {{
                                        reviewer.especialidades.map((obj) => obj.nome).join(", ")
                                    }}
                                </div>
                            </td>
                            <td>
                                <div class="w-32 truncate">
                                    {{ reviewer.pareceristaPropostas?.filter(obj =>
                                        obj?.status?.includes('Parecer pendente') &&
                                        $moment(obj?.data_prazo).isAfter($moment(), 'day')).length }}
                                </div>
                            </td>
                            <td>
                                <router-link :to="{ name: 'painel-inovahc-parecerista', params: { id: reviewer.id } }"
                                    class="no-underline">
                                    <button type="button" class="tag" :class="reviewer.inativo ? 'red' : 'blue'">
                                        {{ reviewer.inativo ? 'Inativo' : "Ativo" }}
                                    </button>
                                </router-link>
                            </td>
                            <td class="relative lg:static">
                                <div class="flex justify-end items-center">
                                    <CompDropdown alignment="right" divStyle="absolute" class="mr-8">
                                        <template #toggle>
                                            <IconsComp icon="more" class="w-6 h-6 fill-green-500" />
                                        </template>
                                        <router-link :to="{
                                            name: 'painel-inovahc-parecerista',
                                            params: { id: reviewer.id },
                                        }">
                                            <li class="text-purple-800">
                                                Visualizar parecerista
                                            </li>
                                        </router-link>
                                    </CompDropdown>
                                    <div class="ml-auto">
                                        <button @click.prevent="ArrowIcons[i] = !ArrowIcons[i]">
                                            <IconsComp :class="{ 'rotate-180': ArrowIcons[i] }" icon="arrow-down"
                                                class="transition-all duration-300 fill-green-500 w-6 h-6 ml-12 md:ml-3" />
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="ArrowIcons[i]"
                            class="transition-all duration-500 ease-linear hover:bg-white hover:text-purple-800">
                            <td colspan="1">
                                <div class="grid grid-cols-2 ml-10">
                                    <div>
                                        <div class="text-gray-400 text-sm mb-2">
                                            Telefone de contato
                                        </div>
                                        <p class="text-md text-neutral-500 font-normal">
                                            {{ reviewer.telefone ? reviewer.telefone : "-" }}
                                        </p>
                                    </div>
                                    <div>
                                        <div class="text-gray-400 text-sm mb-2">
                                            Disponibilidade por mês
                                        </div>
                                        <p class="text-md text-neutral-500 font-normal">
                                            {{
                                                reviewer.disponibilidade_para_projetos
                                                ? reviewer.disponibilidade_para_projetos + ' pareceres'
                                                : "-"
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td colspan="2">
                                <div class="flex">
                                    <div class="mr-14">
                                        <div class="text-gray-400 text-sm mb-2">
                                            Pareceres emitidos
                                        </div>
                                        <p class="text-md text-neutral-500 font-normal">
                                            {{ reviewer.pareceristaPropostas?.filter(obj =>
                                                obj?.status?.includes('Parecer enviado')).length }}
                                        </p>
                                    </div>
                                    <div>
                                        <div class="text-gray-400 text-sm mb-2">
                                            Incluído(a) por
                                        </div>
                                        <div>
                                            <div class="profile whitespace-nowrap">
                                                <div class="profile-img small !w-6 !h-6">
                                                    <img v-if="reviewer?.responsavel?.avatar"
                                                        :src="reviewer?.responsavel?.avatar" alt="" />
                                                    <img v-else src="@/assets/img/template/profile2.png" alt="" />
                                                </div>
                                                <div class="text-sm text-neutral-500 whitespace-nowrap">
                                                    {{ reviewer.responsavel?.nome_completo }} - {{
                                                        reviewer.responsavel?.instituto?.nome }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="ArrowIcons[i]"
                            class="transition-all duration-500 ease-linear hover:bg-white hover:text-purple-800">
                            <td colspan="1000">
                                <div class="ml-10">
                                    <div class="text-gray-400 text-sm mb-2">
                                        Experiência profissional
                                    </div>
                                    <p class="text-md text-neutral-500 font-normal">
                                        {{
                                            reviewer.experiencia_profissional
                                            ? reviewer.experiencia_profissional
                                            : "-"
                                        }}
                                    </p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-show="reviewers.length === 0">
                        <tr>
                            <td colspan="1000">
                                <CompDataLoad :dataLoad="dataLoad" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="mt-4 pb-20">
                <div class="container mx-auto px-6">
                    <CompPagination :onLoad="paginationOnLoad" v-model:quantity="perPage" v-model:page="currentPage"
                        :firstPage="firstPage" :lastPage="lastPage" />
                </div>
            </div>
        </div>
    </div>
</template>
