<script setup>
import CompBreadCrumbs from "@/components/CompBreadCrumbs"
import IconsComp from "@/components/IconsComp"
import { useProgramStore, useEdictStore, useAuthStore } from "@/stores"
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import moment from "moment-timezone";

moment.tz.setDefault("America/Sao_Paulo").locale("pt-br");

const baseUrl = `${process.env.VUE_APP_ONLY_URL}`;
const router = useRouter()
const route = useRoute()
const changeButtonColor = ref(true)
const authStore = ref(useAuthStore())
const subscriptionClosed = ref(false)
const classifiedProjects = ref([])
const program = ref({ loading: true });
const edict = ref(null);

onMounted(async () => {
    program.value = await useProgramStore().find(1).then(response => response.data);
    edict.value = program.value.ultimoEdital

    const slidesContainer = document.querySelector(".slides-container");
    const slideWidth = slidesContainer.querySelector(".slide").clientWidth;
    const prevButton = document.querySelector(".prev");
    const nextButton = document.querySelector(".next");

    nextButton.addEventListener("click", () => {
        slidesContainer.scrollLeft += slideWidth;
    });

    prevButton.addEventListener("click", () => {
        slidesContainer.scrollLeft -= slideWidth;
    });

    if (!edict.value) {
        router.back()
        return;
    }

    if (!authStore.value.user) {
        localStorage.setItem('program', JSON.stringify('incube'))
        localStorage.setItem('edict_id', JSON.stringify(edict.value?.id))
    }

    classifiedProjects.value = edict.value.projetos?.filter(obj => obj.status == "Classificada");

    if (moment(edict.value.encerramento) < moment()) {
        subscriptionClosed.value = true
    }
})
</script>

<template>
    <div class="container mx-auto px-6 pt-10 pb-12">
        <div class="hidden md:flex justify-between flex-col md:flex-row items-center mb-8">
            <div>
                <CompBreadCrumbs>
                    <template v-slot:default>
                        <li>
                            <router-link :to="{ name: 'programas' }">
                                <IconsComp icon="editais-line" class="w-6 h-6" />
                                Programas
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'programas-incube' }">
                                In.cube
                            </router-link>
                        </li>
                    </template>
                </CompBreadCrumbs>
            </div>
        </div>
        <div v-if="!program || program?.loading" class="grid grid-cols-1 md:grid-cols-2 gap-4 items-center mt-10">
            <div class="font-bold font-poppins text-purple-800 text-center text-3xl md:text-5xl md:py-8 mx-auto">
            </div>
            <div>
                <div class="font-poppins font-bold text-purple-800 text-3xl md:text-6xl mb-4">
                    Programa In.cube
                </div>
                <p>
                    No In.cube, você tem a oportunidade de contar com a equipe do InovaHC em capacitação e desenvolvimento
                    de ideias de soluções inovadoras que resolvem desafios de saúde.
                </p>
            </div>
        </div>
        <div v-else-if="!subscriptionClosed" class="grid grid-cols-1 md:grid-cols-2 gap-4 items-center mt-10">
            <div class="font-bold font-poppins text-purple-800 text-center text-5xl md:py-8 mx-auto">
                <img src="@/assets/img/site/programa-incube-open.png" alt="Programa aberto" class="w-auto h-auto" />
            </div>
            <div>
                <div class="font-poppins font-bold text-purple-800 text-3xl md:text-6xl mb-4">
                    Programa In.cube
                </div>
                <p>
                    No In.cube, você tem a oportunidade de contar com a equipe do InovaHC em capacitação e desenvolvimento
                    de ideias de soluções inovadoras que resolvem desafios de saúde.
                </p>
                <div class="flex max-sm:flex-col max-sm:items-center max-sm:gap-4">
                    <router-link v-if="edict"
                        :to="authStore.user ? { name: 'adicionar-programa-incube', params: { editalId: edict?.id } } : { name: 'primeiro-acesso-proponente' }"
                        class="btn primary mr-2 no-underline">Inscreva-se</router-link>
                    <a v-if="edict?.arquivo_edital" :href="baseUrl + edict?.arquivo_edital" type="button"
                        class="btn secondary no-underline" target="_blank">Leia o edital</a>
                    <button v-else type="button" class="btn secondary">Leia o edital</button>
                </div>
            </div>
        </div>
        <div v-else class="grid grid-cols-1 md:grid-cols-2 gap-4 items-center mt-10">
            <div class="font-bold font-poppins text-purple-800 text-center text-5xl py-10 mx-auto">
                <img src="@/assets/img/site/programa-close.png" alt="Programa Fechado" class="w-auto h-auto" />

            </div>
            <div>
                <div class="font-poppins font-bold text-purple-800 text-3xl md:text-6xl mb-4">
                    Prazo do edital {{ edict?.nome }} encerrado
                </div>
                <p class="font-bold">
                    Você ainda pode enviar sua proposta para nosso banco de projetos.
                </p>
                <p class="py-4">
                    Neste caso avaliaremos as propostas caso a caso de acordo com as características do projeto e nossos
                    recursos disponíveis.
                </p>

                <div class="flex">
                    <router-link
                        :to="authStore.user ? { name: 'adicionar-programa' } : { name: 'primeiro-acesso-proponente' }"
                        class="btn primary mr-2 no-underline">Submeter
                        projeto</router-link>
                    <a v-if="classifiedProjects?.length > 0" href="#classifieds" role="button"
                        class="btn secondary ml-2 no-underline">Ver classificados
                        {{ edict?.nome }}</a>
                </div>
            </div>
        </div>

        <div class="text-2xl font-poppins font-bold text-purple-800 text-center mt-20">
            O que o programa In.cube oferece
        </div>

        <div class="max-w-screen-lg mx-auto px-4 md:px-8 py-12 transition-all duration-500 ease-linear">

            <div class="relative">
                <div
                    class="slides-container h-72 flex snap-x snap-mandatory overflow-hidden overflow-x-auto space-2 rounded scroll-smooth before:w-[45vw] before:shrink-0 after:w-[45vw] after:shrink-0 md:before:w-0 md:after:w-0">
                    <div
                        class="slide aspect-square m-2 flex-shrink-0 snap-center rounded-2xl overflow-hidden shadow-md w-56">
                        <div class="text-center p-2">
                            <IconsComp icon="add-circle" class="fill-purple-500 w-10 h-10  mx-auto " />
                            <div class="text-lg font-bold text-purple-800 my-4">
                                Mentorias especializadas
                            </div>
                            <p class="text-sm">
                                Os participantes recebem mentorias exclusivas de especialistas em regulamentação em saúde,
                                captação de recursos e modelagem de negócios.
                            </p>
                        </div>
                    </div>
                    <div
                        class="slide aspect-square m-2 flex-shrink-0 snap-center rounded-2xl overflow-hidden shadow-md w-56">
                        <div class="text-center p-2">
                            <IconsComp icon="add-circle" class="fill-purple-500 w-10 h-10  mx-auto " />
                            <div class="text-lg font-bold text-purple-800 my-4">
                                Conteúdo exclusivo
                            </div>
                            <p class="text-sm">
                                Acesso ao EaD do programa com conteúdos de desenvolvimento de projetos de inovação em saúde.
                            </p>
                        </div>
                    </div>
                    <div
                        class="slide aspect-square m-2 flex-shrink-0 snap-center rounded-2xl overflow-hidden shadow-md w-56">
                        <div class="text-center p-2">
                            <IconsComp icon="add-circle" class="fill-purple-500 w-10 h-10  mx-auto " />
                            <div class="text-lg font-bold text-purple-800 my-4">
                                Demoday
                            </div>
                            <p class="text-sm">
                                Ao final do programa, os participantes tem oportunidade de apresentar seus projetos para
                                parceiros e potenciais investidores.
                            </p>
                        </div>
                    </div>
                    <div
                        class="slide aspect-square m-2 flex-shrink-0 snap-center rounded-2xl overflow-hidden shadow-md w-56">
                        <div class="text-center p-2">
                            <IconsComp icon="add-circle" class="fill-purple-500 w-10 h-10  mx-auto " />
                            <div class="text-lg font-bold text-purple-800 my-4">
                                Acompanhamento Individualizado
                            </div>
                            <p class="text-sm">
                                Cada equipe selecionada é acompanhada semanalmente por uma consultora de inovação.
                            </p>
                        </div>
                    </div>
                    <div
                        class="slide aspect-square m-2 flex-shrink-0 snap-center rounded-2xl overflow-hidden shadow-md w-56">
                        <div class="text-center p-2">
                            <IconsComp icon="add-circle" class="fill-purple-500 w-10 h-10  mx-auto " />
                            <div class="text-lg font-bold text-purple-800 my-4">
                                Divulgação
                            </div>
                            <p class="text-sm">
                                Exposição e publicidade dos projetos nas redes do InovaHC.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="absolute top-0 -left-8 md:-left-16 h-full items-center flex">
                    <button @click.prevent="!changeButtonColor ? changeButtonColor = !changeButtonColor : ''" role="button"
                        class="prev p-1 rounded group"
                        :class="changeButtonColor ? 'bg-neutral-100 text-neutral-900' : 'bg-green-500 text-white'"
                        aria-label="prev"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke-width="1.5" stroke="currentColor"
                            class="w-5 h-5 group-active:-translate-x-2 transition-all duration-200 ease-linear">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>

                    </button>
                </div>
                <div class="absolute top-0 -right-8 md:-right-16 h-full items-center flex">
                    <button @click.prevent="changeButtonColor ? changeButtonColor = !changeButtonColor : ''" role="button"
                        class="next p-1 rounded group"
                        :class="changeButtonColor ? 'bg-green-500 text-white' : 'bg-neutral-100 text-neutral-900'"
                        aria-label="next"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke-width="1.5" stroke="currentColor"
                            class="w-5 h-5 group-active:translate-x-2 transition-all duration-200 ease-linear">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>

        <div v-if="!subscriptionClosed" class="text-center mt-4">
            <router-link v-if="edict"
                :to="authStore.user ? { name: 'adicionar-programa-incube', params: { editalId: edict.id } } : { name: 'primeiro-acesso-proponente' }"
                class="btn primary mr-2 no-underline">Inscreva-se</router-link>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 items-center mt-32">
            <div>
                <div class="font-poppins font-bold text-purple-800 text-2xl mb-4">
                    Para quem é o In.cube
                </div>
                <p>
                    Grupos de estudantes, pesquisadores(as) e/ou colaboradores(as) com ao menos um integrante vinculado à
                    FMUSP ou HCFMUSP para desenvolver processos, produtos, serviços ou negócios de saúde a partir de suas
                    pesquisas acadêmicas
                </p>
                <div v-if="!subscriptionClosed && program && !program?.loading"
                    class="flex max-sm:flex-col max-sm:items-center max-sm:gap-4">
                    <router-link v-if="edict"
                        :to="authStore.user ? { name: 'adicionar-programa-incube', params: { editalId: edict.id } } : { name: 'primeiro-acesso-proponente' }"
                        class="btn primary mr-2 no-underline">Inscreva-se</router-link>
                    <a v-if="edict?.arquivo_edital" :href="baseUrl + edict?.arquivo_edital" type="button"
                        class="btn secondary no-underline" target="_blank">Leia o edital</a>
                    <button v-else type="button" class="btn secondary">Leia o edital</button>
                </div>
            </div>
            <div class="font-bold font-poppins text-purple-800 text-center text-5xl md:py-10 mx-auto">
                <img src="@/assets/img/site/programa-incube2.png" alt="In.cube" class="w-auto h-auto" />
            </div>
        </div>
    </div>
    <div v-if="subscriptionClosed && authStore.user && classifiedProjects?.length > 0" id="classifieds" class="bg-gray-50">
        <div class="container mx-auto px-6 py-24">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-10">
                <div>
                    <div class="font-bold font-poppins text-3xl text-purple-800 mb-4">
                        Classificados para edital {{ edict?.nome }}
                    </div>
                    <p class="font-poppins text-lg text-purple-500">Não foi classificado?</p>
                    <p>
                        Confira em seu email as orientações para requisitar interposição, mas corra que o prazo é curto.
                    </p>
                </div>
                <div>
                    <div v-for="classifiedProject in classifiedProjects" :key="classifiedProject"
                        class="pb-2 border-b border-gray-200 mb-4">
                        <div class="text-sm text-gray-400">Projeto</div>
                        <div class="font-bold text-green-600 mb-4">
                            {{ classifiedProject.nome_proposta }}
                        </div>
                        <div class="text-sm text-gray-400">Responsável</div>
                        <p>{{ classifiedProject.proponente.primeiro_nome }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-gray-100">
        <div class="container mx-auto px-6 pt-4 mb-8">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4 items-center ">
                <div class="font-bold font-poppins text-purple-800 text-center text-5xl py-6 md:py-10 mx-auto">
                    <img src="@/assets/img/site/programa-duvida.png" alt="Duvidas?" class="w-auto h-auto" />
                </div>
                <div>
                    <div class="font-poppins font-bold text-purple-800 text-2xl mb-4">
                        Alguma dúvida?
                    </div>
                    <p>
                        Se você tiver alguma dúvida sobre se encaixar nesse perfil, entre em contato conosco pelo e-mail:
                        <b>inovahc@hc.fm.usp.br</b>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<style type="text/css">
html {
    scroll-behavior: smooth;
}

.slides-container {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.slides-container::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}
</style>