<script setup>
import CompBreadCrumbs from "@/components/CompBreadCrumbs";
import IconsComp from "@/components/IconsComp";
import CompDropdown from "@/components/CompDropdown";
import CompProposalData from "@/components/CompProposalData";
import CompDocuments from "@/components/CompDocuments";
import { ref, onMounted, watch } from "vue";
import { useProposerStore, useAuthStore } from "@/stores";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const user = useAuthStore().user;
const project_id = route.params.id;
const project = ref(null);
const documentUpdated = ref(false)

onMounted(async () => {
    project.value = await useProposerStore()
        .getProject(project_id)
        .then((response) => response?.data);

    if (!project.value) {
        router.back();
    }

    project.value.proponente = user
});

watch(documentUpdated, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        project.value = await useProposerStore().getProject(project_id).then(response => response?.data);
    }
});
</script>

<template>
    <section>
        <div class="container mx-auto px-6 pt-10 pb-10">
            <div class="flex justify-between flex-col md:flex-row items-center mb-8">
                <div>
                    <CompBreadCrumbs>
                        <template v-slot:default>
                            <li>
                                <router-link :to="{ name: 'painel-proponente' }">
                                    <IconsComp icon="painel" />
                                    Meu Painel
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'painel-proponente-projetos' }">Projetos
                                </router-link>
                            </li>
                        </template>
                    </CompBreadCrumbs>
                </div>
                <div class="ml-auto flex">
                    <a v-print="'#project-details'" class="cursor-pointer">
                        <IconsComp icon="printer" class="fill-green-500 w-6 h-6" />
                    </a>
                    <div class="ml-4">
                        <CompDropdown alignment="right">
                            <template #toggle>
                                <IconsComp icon="more" class="w-6 h-6 fill-green-500 rotate-90" />
                            </template>
                        </CompDropdown>
                    </div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex text-5xl font-bold font-poppins text-purple-800 mb-4">
                    {{ project?.nome_proposta }}
                    <IconsComp icon="check-filled" class="w-6 h-6 fill-green-500" />
                </div>
                <div class="flex items-center">
                    <span class="text-sm mr-1">Status:</span>
                    <button class="tag" :class="[
                        project?.status.includes('Selecionado') ? 'green' : '',
                        project?.status.includes('Descontinuado') ? 'disabled' : '',
                        project?.status.includes('Rejeitada') ? 'disabled' : '',
                        project?.status.includes('Rejeitada') ? 'disabled' : '',
                        project?.documentos?.filter(obj =>
                            obj.tipo.includes('Plano de trabalho') &&
                            obj.status.includes('Enviado')).length > 0 ? 'blue' : '',
                        project?.status.includes('Relatório final pendente') &&
                            project?.documentos?.filter(obj =>
                                obj.tipo.includes('Relatório final') &&
                                obj.status.includes('Enviado')).length > 0 ? 'blue' : '',
                    ]">{{ project?.status.includes('Plano de trabalho pendente') &&
    project?.documentos?.filter(obj =>
        obj.tipo.includes('Plano de trabalho') &&
        obj.status.includes('Enviado')).length > 0 ? 'Plano de trabalho enviado'
    : project?.status.includes('Relatório final pendente') &&
        project?.documentos?.filter(obj =>
            obj.tipo.includes('Relatório final') &&
            obj.status.includes('Enviado')).length > 0 ? 'Relatório final enviado' :
        !project?.status.includes('pendente')
            ? project?.status : '' }}</button>
                    <div v-for="pendente in project?.documentos?.filter(obj => obj.status === 'Pendente')" :key="pendente">
                        <button class="tag yellow">{{ pendente.tipo }} pendente</button>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="project-details" class="bg-purple-50">
        <div class="container mx-auto px-6 py-16">
            <div class="flex flex-col md:flex-row gap-6">
                <div class="w-full md:w-8/12">
                    <div class="container mx-auto px-6">
                        <div class="flex flex-col md:flex-row gap-6">
                            <div class="flex flex-col justify-start">
                                <div class="text-3xl font-bold font-poppins text-purple-800 mb-4">
                                    Informações sobre o projeto
                                </div>
                                <div class="flex gap-8 mb-8">
                                    <div>
                                        <div class="text-sm text-gray-500">
                                            Programa / data de inscrição
                                        </div>
                                        <div class="font-bold text-purple-800">
                                            {{
                                                project?.edital ? project.edital.nome : "Fluxo contínuo"
                                            }}
                                        </div>
                                        <div class="font-sm text-purple-500">
                                            Enviada em
                                            {{ $moment(project?.created_at).format('DD/MM/YYYY') }}
                                        </div>
                                    </div>
                                    <div>
                                        <div class="text-sm text-gray-500">
                                            Área de especialidade
                                        </div>
                                        <div class="font-bold text-purple-800">
                                            {{
                                                project?.especialidades
                                                    .map((obj) => obj.nome)
                                                    .join(", ")
                                            }}
                                        </div>
                                        <div v-if="proposal?.respostas?.find(
                                                    (obj) =>
                                                        obj.pergunta_id === 'secondary_specialty_areas'
                                                )
                                                ">
                                            <div class="text-sm text-gray-500 mt-1">
                                                Áreas de especialidade secundárias
                                            </div>
                                            <div class="text-purple-500 text-sm">
                                                {{
                                                    proposal?.respostas
                                                        ?.filter(
                                                            (obj) =>
                                                                obj.pergunta_id === "secondary_specialty_areas"
                                                        )
                                                        .map((obj) =>
                                                            specialties
                                                                .map((specialty) => {
                                                                    if (obj.valor.includes(specialty.id)) {
                                                                        return specialty.nome;
                                                                    }
                                                                })
                                                                .join(", ")
                                                        )
                                                        .join(", ")
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- COLLAPSE -->
                                <CompProposalData :proposal="project" :user="user" :collapse="[1, 2, 3]" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Documentos -->
                <div class="w-full md:w-4/12 flex flex-col">
                    <CompDocuments v-model:updated="documentUpdated" :projectId="project_id"
                        :documents="project?.documentos" v-if="project?.documentos" />
                </div>
            </div>
        </div>
    </section>

    <!-- Detalhes do Projeto -->
    <section class="bg-purple-50 bg-opacity-80"></section>
</template>
