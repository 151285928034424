<script setup>
import IconsComp from "./IconsComp.vue";
import { ref } from 'vue'

defineProps({
    quantity: {
        type: [Number, String],
        default: 20,
    },
    page: {
        type: [Number, String],
        default: 1,
    },
    firstPage: {
        type: [Number, String],
        default: 1,
    },
    lastPage: {
        type: [Number, String],
        default: 1,
    },
    onLoad: {
        type: Boolean,
        required: true,
    },
})

const emits = defineEmits(['update:page', 'update:quantity'])

function updateQuantity(quantity) {
    emits('update:quantity', quantity)
}

function updatePage(page) {
    emits('update:page', page)
}
</script>
<template>
    <div class="comp-pagination">
        <div class="content">
            <div class="control">
                <input class="number" :value="quantity" type="text"
                    @input="updateQuantity(Math.min(Math.max(1, Number($event.target.value) || 1), 20))" />
                <div class="arrow">
                    <button @click.stop="quantity < 100 && quantity > 1 ? updateQuantity(Number(quantity) + 10) : quantity < 100 && quantity == 1 ?
                        updateQuantity(10) : ''" class="flex items-center" type="button" :disabled="onLoad">
                        <IconsComp icon="filter-arrow-top" />
                    </button>
                    <button @click.stop="quantity > 1 && quantity > 10 ? updateQuantity(Number(quantity) - 10) : quantity > 1 && quantity <= 10 ?
                        updateQuantity(1) : ''" class="flex items-center" type="button" :disabled="onLoad">
                        <IconsComp icon="filter-arrow-down" />
                    </button>
                </div>
            </div>

            <div class="title ml-1">Quantidade</div>
        </div>

        <div class="content justify-center controller">
            <button @click.stop="firstPage < page ? updatePage(Number(page) - 1) : ''" class="flex items-center"
                type="button" :disabled="page === firstPage || onLoad">
                <IconsComp icon="arrow-right" :class="page === firstPage ? '!bg-gray-300' : ''" />
            </button>
            <div class="page">{{ page }}</div>
            <button @click.stop="lastPage > page ? updatePage(Number(page) + 1) : ''" class="flex items-center"
                type="button" :disabled="page === lastPage || onLoad">
                <IconsComp icon="arrow-left" :class="page === lastPage ? '!bg-gray-300' : ''" />
            </button>
        </div>

        <div class="content justify-end">
            <div class="title mr-1">Página</div>

            <div class="control">
                <input class="number" :value="page" type="text"
                    @input="updatePage(Math.min(Math.max(1, Number($event.target.value) || 1), lastPage))" />
                <div class="arrow">
                    <button @click.stop="firstPage < page ? updatePage(Number(page) - 1) : ''" class="flex items-center"
                        type="button" :disabled="page === firstPage || onLoad">
                        <IconsComp icon="filter-arrow-top" />
                    </button>
                    <button @click.stop="lastPage > page ? updatePage(Number(page) + 1) : ''" class="flex items-center"
                        type="button" :disabled="page === lastPage || onLoad">
                        <IconsComp icon="filter-arrow-down" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
