<script setup>
import CompInput from "@/components/CompInput";
import { ref } from "vue";
import { Form, useField } from "vee-validate";
import * as yup from "yup";
import { ptForm } from "yup-locale-pt";
import { useRouter, useRoute } from "vue-router";
import axios from "axios";
import { useAuthStore } from "@/stores";

yup.setLocale(ptForm);
const route = useRoute();
const router = useRouter();
const token = route.params.token;

const form = ref({
    email: "",
    password: "",
    password_confirmation: "",
    token: token,
})

const submited = ref(false);
const errorMessages = ref([])
async function onSubmit(values, { setErrors }) {
    await useAuthStore().resetPassword(form.value)
        .then(response => response.data?.status ? submited.value = true : submited.value = true).catch(error => {
            error.response?.data?.errors ? errorMessages.value = error.response?.data?.errors : ''
            error.response?.data?.email ? errorMessages.value = { email: [error.response?.data?.email] } : ''
        })
}
</script>

<template>
    <section id="page-home" class="flex flex-1 items-center">
        <div class="container mx-auto p-6 flex flex-col md:flex-row justify-between items-center">
            <div class="text-white w-12/12 md:w-5/12 max-w-[456px] shadow-sm">
                <div class="text-4xl font-bold font-poppins mb-6">
                    Emissão de Pareceres para os programas InovaHC
                </div>
                <div class="mb-4 text-lg">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam.
                </div>
                <div>
                    <a href="#" class="font-bold no-underline font-poppins">Manual dos pareceres</a>
                </div>
            </div>

            <div class="w-12/12 md:w-5/12 py-16">
                <div v-if="!submited">
                    <div class="text-white md:text-purple-800 font-bold font-poppins text-2xl mb-2">
                        Redefinir Senha
                    </div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                    <div v-for="errorMessage in errorMessages" :key="errorMessage" class="text-red-500">
                        {{ errorMessage[0] }}
                    </div>
                    <Form @submit="onSubmit" class="form-simples">
                        <CompInput v-model="form.email" name="email" type="email" label="Seu e-mail"
                            placeholder="Seu e-mail" :schema="yup.string().email().required('Preencha seu e-mail')" />
                        <CompInput v-model="form.password" name="password" label="Nova senha" placeholder="Sua nova senha"
                            type="password" :isPassword="true"
                            :schema="yup.string().min(8).required('Preencha sua nova senha')" />
                        <p class="text-sm">Sua nova senha deve conter ao menos 8 caracteres</p>

                        <CompInput v-model="form.password_confirmation" name="password_confirmation" label="Confirmar senha"
                            placeholder="Confirme sua nova senha" type="password" :isPassword="true"
                            :schema="yup.string().min(8).required('Preencha a confirmação da nova senha')" />

                        <button type="submit" class="btn primary w-full my-8">Atualizar senha</button>
                    </Form>
                </div>
                <div v-else>
                    <p>
                        Senha redefinida com sucesso!
                        Faça login utilizando sua nova senha.
                        <router-link :to="{ name: 'login' }" class="no-underline hover:underline">Fazer Login</router-link>
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>
