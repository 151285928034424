<script setup>
import CompPagination from "@/components/CompPagination";
import IconsComp from "@/components/IconsComp";
import CompSearch from "@/components/CompSearch";
import CompSelect from "@/components/CompSelect";
import CompDropdown from "@/components/CompDropdown";
import CompAvaliation from "@/components/CompAvaliation";
import { CompDataLoad } from '@/components'
import { ref, onMounted, watch } from "vue";
import { useProjectStore, useEdictStore, useSpecialtyStore, useProgramStore } from "@/stores";

const currentPage = ref(1);
const oldCurrentPage = ref(1)
const firstPage = ref(1);
const lastPage = ref(1);
const perPage = ref(20);
const paginationOnLoad = ref(false)
const allStatus = ref(['Todos os status', 'Selecionado', 'Plano de trabalho pendente', 'Relatório final pendente', 'Descontinuado'])
const projects = ref([]);
const projectCount = ref(0);
const dataLoad = ref("Carregando..");
const edicts = ref([]);
const specialties = ref([])
const programs = ref([])
const search = ref('')
const selectedSpecialty = ref(0)
const selectedStatus = ref('Todos os status')
const selectedProgramId = ref(null);
const ArrowIcons = ref([]);

onMounted(async () => {
    await getProjects();

    [edicts.value, specialties.value, programs.value] = await Promise.all([
        useEdictStore().all().then((response) => response.data),
        useSpecialtyStore().allWithoutPagination().then((response) => response.data),
        useProgramStore().all().then((response) => response.data),
    ]);
    projectCount.value = programs.value.map(obj => obj?.ultimoEdital?.projetos?.length).reduce((a, b) => a + b, 0)
    specialties.value.push({ id: 0, nome: "Todas especialidades" })
    specialties.value = specialties.value.sort(obj => obj.id)
});

watch(currentPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getProjects();
    }
});

watch(perPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getProjects();
    }
});

async function getProjects() {
    projects.value = [];
    dataLoad.value = "Carregando";

    paginationOnLoad.value = true
    projects.value = await useProjectStore().search(search.value, selectedStatus.value == 'Todos os status' ? '' : selectedStatus.value, selectedProgramId.value ? selectedProgramId.value : "", selectedSpecialty.value == 0 ? "" : selectedSpecialty.value, "", perPage.value, currentPage.value !== oldCurrentPage.value ? currentPage.value : 1)

    currentPage.value = projects.value.meta.current_page;
    oldCurrentPage.value = currentPage.value
    perPage.value = projects.value.meta.per_page;
    lastPage.value = projects.value.meta.last_page;
    projects.value = projects.value.data;

    projects.value.length === 0
        ? (dataLoad.value = "Nenhuma projeto encontrado")
        : "";

    paginationOnLoad.value = false
}
</script>

<template>
    <section>
        <div class="container mx-auto px-6 pt-10">
            <div class="text-5xl font-bold font-poppins mb-8 text-purple-800">
                Projetos
            </div>
            <div>
                <ul class="menu-tabs">
                    <li>
                        <router-link :to="{ name: 'painel-inovahc-programas' }">
                            <span>
                                <IconsComp icon="painel" class="w-6 h-6" />
                            </span>
                            Propostas
                        </router-link>
                    </li>
                    <li class="active">
                        <router-link :to="{ name: 'painel-inovahc-projetos' }">
                            <span>
                                <IconsComp icon="clipboard-line" class="w-6 h-6" />
                            </span>
                            Projetos
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'painel-inovahc-editais' }">
                            <span>
                                <IconsComp icon="document-2" class="w-6 h-6" />
                            </span>
                            Editais
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <!-- Filtro -->
    <section class="bg-purple-50 pt-8">
        <div class="container mx-auto px-6 pb-4">
            <div class="text-sm text-gray-400">Filtrar projetos</div>
            <ul v-if="programs.length === 0" class="filtro-categoria flex animate-pulse">
                <li v-for="animate in 4" :key="animate" class="bg-gray-400 border-none flex space-x-4 p-4">
                    <div class="animate-pulse flex space-x-4 py-2">
                        <div class="flex-1">
                            <div class="space-y-3">
                                <div class="grid grid-cols-3 gap-4">
                                    <div class="h-2 bg-gray-400 rounded col-span-3"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <ul v-else class="filtro-categoria grid grid-cols-4">
                <li @click.prevent="selectedProgramId = null; getProjects()" class="!pr-1"
                    :class="!selectedProgramId ? 'ativo' : ''">
                    Todas
                    <span class="text-white ml-2 p-1 inline-block w-6 align-baseline rounded-full text-sm text-center"
                        :class="!selectedProgramId ? 'bg-green-800' : 'bg-green-500'">{{ projectCount }}</span>
                </li>
                <li v-for="(program) in programs" :key="program.id"
                    @click.prevent="selectedProgramId = program.id; getProjects(program.id)" class="!pr-1"
                    :class="selectedProgramId == program.id ? 'ativo' : ''">
                    {{ program.nome }}
                    <span class="text-white ml-2 p-1 inline-block w-6 align-baseline rounded-full text-sm text-center"
                        :class="selectedProgramId == program.id ? 'bg-green-800' : 'bg-green-500'">
                        {{
                            program.ultimoEdital?.projetos?.length
                        }}
                    </span>
                </li>
            </ul>
            <div class="grid grid-cols-1 md:flex justify-between mt-2">
                <div class="flex items-center">
                    <div class="relative mr-2">
                        <CompSelect @change.prevent="getProjects()" class="max-w-sm" optionName="nome"
                            name="selectSpecialties" :options="specialties" v-model="selectedSpecialty"
                            :optionSelected="0" />
                    </div>
                    <div class="relative">
                        <CompSelect @change.prevent="getProjects()" class="max-w-sm" name="selectSpecialties"
                            :options="allStatus" v-model="selectedStatus" optionSelected="Todos os status " />
                    </div>
                </div>
                <div class="w-96 max-w-[100%]">
                    <CompSearch @submit.prevent="getProjects" name="search" v-model="search" />
                </div>
            </div>
        </div>
    </section>
    <!-- Tabela -->
    <section class="bg-purple-50">
        <div class="container mx-auto px-6 pb-6">
            <div class="overflow-x-scroll lg:overflow-hidden rounded-lg border border-white shadow-md">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="flex items-center">
                                    Proposta
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Especialidade
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Inativo
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <span>Status</span>
                            </th>
                            <th scope="col">
                                <div>
                                    Selecionado
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody v-show="projects.length > 0" v-for="(tableValue, i) in projects" :key="tableValue">
                        <tr>
                            <td>
                                <div v-if="tableValue.nome_proposta" class="w-[240px] truncate flex items-center">
                                    {{ tableValue.nome_proposta }}
                                </div>
                            </td>
                            <td>
                                <div class="w-32 truncate">
                                    {{
                                        tableValue.especialidades?.map((obj) => obj.nome).join(", ")
                                    }}
                                </div>
                            </td>
                            <td>
                                <div>
                                    {{ ($moment().diff(tableValue.updated_at, "days")) }}
                                    dias
                                </div>
                            </td>
                            <td>
                                <router-link :to="{ name: 'painel-inovahc-projeto', params: { id: tableValue?.id } }"
                                    class="no-underline">
                                    <button type="button" :class="[
                                        tableValue.status?.includes('Selecionado')
                                            ? 'tag green'
                                            : tableValue.status?.includes(
                                                'Plano de trabalho pendente'
                                            ) ||
                                                tableValue.status?.includes('Relatório final pendente')
                                                ? 'tag yellow'
                                                : tableValue.status?.includes('Descontinuado') ||
                                                    tableValue.status?.includes('Encerrado')
                                                    ? 'tag disabled'
                                                    : '',
                                    ]">
                                        {{ tableValue.status }}
                                    </button>
                                </router-link>
                            </td>
                            <td>
                                {{ $moment(tableValue.created_at).format('DD/MM/YYYY') }}
                            </td>
                            <td class="relative lg:static">
                                <div class="flex justify-end items-center">
                                    <CompDropdown alignment="right" divStyle="absolute" class="mr-8">
                                        <template #toggle>
                                            <IconsComp icon="more" class="w-6 h-6 fill-green-500" />
                                        </template>
                                        <router-link :to="{
                                            name: 'painel-inovahc-projeto',
                                            params: { id: tableValue.id },
                                        }" class="cursor-pointer">
                                            <li>
                                                <IconsComp icon="eye" />
                                                Ver proposta
                                            </li>
                                        </router-link>
                                        <a class="cursor-pointer">
                                            <li>
                                                <IconsComp icon="cover" class="w-2 h-2" />
                                                Adicionar ao banco
                                            </li>
                                        </a>
                                        <a class="cursor-pointer">
                                            <li>
                                                <IconsComp icon="success-line" />
                                                Validar como projeto
                                            </li>
                                        </a>
                                    </CompDropdown>
                                    <div v-if="tableValue.avaliacoes?.length > 0" class="ml-auto">
                                        <button @click.prevent="ArrowIcons[i] = !ArrowIcons[i]">
                                            <IconsComp :class="{ 'rotate-180': ArrowIcons[i] }" icon="arrow-down"
                                                class="transition-all duration-300 fill-green-500 w-6 h-6 ml-12 md:ml-2" />
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <CompAvaliation :avaliations="tableValue.avaliacoes" :isOpen="ArrowIcons[i]" />
                    </tbody>
                    <tbody v-if="projects.length === 0">
                        <tr>
                            <td colspan="1000">
                                <CompDataLoad :dataLoad="dataLoad" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
    <!--  Paginação -->
    <section class="bg-purple-50 pb-10">
        <div class="container mx-auto px-6">
            <CompPagination :onLoad="paginationOnLoad" v-model:quantity="perPage" v-model:page="currentPage"
                :firstPage="firstPage" :lastPage="lastPage" />
        </div>
    </section>
</template>
