import { defineStore } from "pinia";

import { requestS } from "@/helpers";
import { router } from "@/router";
import { useAlertStore } from "@/stores";

const baseUrl = `${process.env.VUE_APP_API_URL}`;

export const useSpecialtyStore = defineStore({
  id: "specialty",
  state: () => ({
    specialty: null,
  }),
  actions: {
    async all(perPage = 20, currentPage = 1) {
      try {
        return await requestS
          .get(
            `${baseUrl}/especialidades?per_page=${perPage}&page=${currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async find(specialty_id) {
      try {
        return await requestS
          .get(`${baseUrl}/especialidades/${specialty_id}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return null;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async store(data) {
      try {
        return await requestS
          .post(`${baseUrl}/especialidades`, {
            nome: data.name,
            tipo: data.type,
            instituto_id: data.institute_id,
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async update(specialty_id, data) {
      try {
        return await requestS
          .put(`${baseUrl}/especialidades/${specialty_id}`, {
            nome: data.name,
            tipo: data.type,
            instituto_id: data.institute_id,
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async delete(specialty_id) {
      try {
        return await requestS
          .delete(`${baseUrl}/especialidades/${specialty_id}`)
          .then((response) => {
            return true;
          })
          .catch((error) => {
            return false;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async search(nome = "", perPage = 20, currentPage = 1) {
      try {
        return await requestS
          .get(
            `${baseUrl}/especialidades/search?nome=${nome}&per_page=${perPage}&page=${currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
    async allWithoutPagination() {
      try {
        return await requestS
          .get(`${baseUrl}/especialidades/todas`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
  },
});
