<script setup>
import CompPagination from "@/components/CompPagination";
import IconsComp from "@/components/IconsComp";
import CompSearch from "@/components/CompSearch";
import CompDropdown from "@/components/CompDropdown";
import CompModal from "@/components/CompModal";
import CompRadio from "@/components/CompRadio";
import CompTextArea from "@/components/CompTextArea";
import CompSelect from "@/components/CompSelect";
import { CompAvaliation, CompDataLoad, CompProposalReject, CompStatus } from '@/components'
import { ref, onMounted, watch } from "vue";
import { useProposalStore, useEdictStore, useSpecialtyStore, useProgramStore } from "@/stores";
import { Form, useForm } from "vee-validate";
import * as yup from "yup";
import { ptForm } from "yup-locale-pt";
import { useRouter, useRoute } from "vue-router";

yup.setLocale(ptForm);

const route = useRoute()
const router = useRouter()
const currentPage = ref(1);
const oldCurrentPage = ref(1)
const firstPage = ref(1);
const lastPage = ref(1);
const perPage = ref(20);
const paginationOnLoad = ref(false)
const allStatus = ref(['Todos os status', 'Aguardando parecer', 'Aguardando classificação', 'Especialidade rejeitada', 'Selecionar parecerista', 'Triagem pendente', 'Classificada', 'Rejeitada']);
const specialties = ref([])
const programs = ref([]);
const proposals = ref([]);
const edicts = ref([]);
const selectedProgramId = ref(null);
const dataLoad = ref("Carregando..");
const ArrowIcons = ref([]);
const rejectModalIsOpen = ref([]);
const rejectModalUpdated = ref(false);
const projectSelectModal = ref([]);
const classifiedProposalModal = ref([])
const selectedSpecialty = ref(0)
const selectedStatus = ref('Todos os status')
const selectedEdict = ref(null)
const search = ref('')

onMounted(async () => {
    await getProposals();

    [edicts.value, specialties.value, programs.value] = await Promise.all([
        useEdictStore()
            .all()
            .then((response) => response.data),
        useSpecialtyStore().allWithoutPagination().then(response => response.data),
        useProgramStore().all().then(response => response.data),
    ]);
    specialties.value.push({ id: 0, nome: "Todas especialidades" })
    specialties.value = specialties.value.sort(obj => obj.id)
});

watch(currentPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getProposals();
    }
});

watch(perPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getProposals();
    }
});

watch(rejectModalUpdated, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getProposals();
    }
});

async function getProposals() {
    proposals.value = [];
    dataLoad.value = "Carregando";

    paginationOnLoad.value = true

    const data = {
        nome: search.value,
        status: selectedStatus.value == 'Todos os status' ? '' : selectedStatus.value,
        programa: selectedProgramId.value ? selectedProgramId.value : "",
        especialidade: selectedSpecialty.value == 0 ? "" : selectedSpecialty.value,
        edital: selectedEdict.value ? selectedEdict.value : "",
        perPage: perPage.value,
        currentPage: currentPage.value !== oldCurrentPage.value ? currentPage.value : 1
    }

    proposals.value = await useProposalStore().search(data.nome, data.status, data.programa, data.especialidade, data.edital, data.perPage, data.currentPage)

    currentPage.value = proposals.value?.meta?.current_page;
    oldCurrentPage.value = currentPage.value
    perPage.value = proposals.value?.meta?.per_page;
    lastPage.value = proposals.value?.meta?.last_page;

    proposals.value = proposals.value.data;
    proposals.value.length === 0
        ? (dataLoad.value = "Nenhuma proposta encontrada")
        : "";

    paginationOnLoad.value = false
}

async function proposalSelected(proposal_id) {
    await useProposalStore().update(proposal_id, { status: "Selecionado" }).then(async () => {
        router.push({ name: "painel-inovahc-projetos" })
    });
}

async function classifiedProposal(proposal_id) {
    await useProposalStore().update(proposal_id, { status: 'Classificada' }).then(async () => {
        await getProposals();
    });
}
</script>

<template>
    <section>
        <div class="container mx-auto px-6 pt-10">
            <div class="text-5xl font-bold font-poppins mb-8 text-purple-800">
                Propostas
            </div>
            <div>
                <ul class="menu-tabs">
                    <li class="active">
                        <router-link :to="{ name: 'painel-inovahc-programas' }">
                            <span>
                                <IconsComp icon="painel" class="w-6 h-6" />
                            </span>
                            Propostas
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'painel-inovahc-projetos' }">
                            <span>
                                <IconsComp icon="clipboard-line" class="w-6 h-6" />
                            </span>
                            Projetos
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'painel-inovahc-editais' }">
                            <span>
                                <IconsComp icon="document-2" class="w-6 h-6" />
                            </span>
                            Editais
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <!-- Component Lista Status Card -->
    <section class="bg-purple-50">
        <div class="container mx-auto px-6 py-10">
            <!-- Cards -->
            <div v-if="edicts.length == 0" class="grid gap-12 grid-cols-1 md:grid-cols-3">
                <div class="card" v-for="(edict, i) in 3" :key="i">
                    <div class="animate-pulse flex space-x-4 p-4">
                        <div class="flex-1 space-y-6 py-1">
                            <div class="space-y-3">
                                <div class="grid grid-cols-3 gap-4">
                                    <div class="h-2 bg-gray-400 rounded col-span-2"></div>
                                    <div class="h-2 bg-gray-400 rounded col-span-1"></div>
                                    <div class="h-2 bg-gray-400 rounded col-span-1"></div>
                                    <div class="h-2 bg-gray-400 rounded col-span-2"></div>
                                </div>
                                <div class="h-2 bg-gray-400 rounded"></div>
                                <div class="grid grid-cols-3 gap-4">
                                    <div class="h-2 bg-gray-400 rounded col-span-2"></div>
                                    <div class="h-2 bg-gray-400 rounded col-span-1"></div>
                                    <div class="h-2 bg-gray-400 rounded col-span-1"></div>
                                    <div class="h-2 bg-gray-400 rounded col-span-2"></div>
                                    <div class="h-2 bg-gray-400 rounded col-span-1"></div>
                                    <div class="h-2 bg-gray-400 rounded col-span-1"></div>
                                    <div class="h-2 bg-gray-400 rounded col-span-1"></div>
                                </div>
                                <div class="h-2 bg-gray-400 rounded"></div>
                                <div class="h-2 bg-gray-400 rounded"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="grid gap-12 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <div class="card"
                    v-for="(edict, i) in  programs.filter(obj => obj.ultimoEdital).map(obj => obj.ultimoEdital) " :key="i">
                    <div>
                        <div class="font-poppins font-bold text-purple-800 text-2xl break-all">
                            {{ edict?.nome }}
                        </div>
                        <div class="text-sm text-gray-400">Inscrições</div>
                        <div class="text-sm text-gray-600">
                            {{ $moment(edict?.abertura).format('DD/MM/YYYY') }} à
                            {{ $moment(edict?.encerramento).format('DD/MM/YYYY') }}
                        </div>
                        <div class="text-sm text-gray-600">
                            {{ edict?.propostas?.length }} propostas recebidas
                        </div>
                    </div>

                    <div class="flex flex-col gap-2 py-4">
                        <div v-if="edict?.propostas?.filter(
                            (obj) =>
                                obj.status.includes('Triagem pendente') ||
                                obj.status.includes('Selecionar parecerista') ||
                                obj.status.includes('Aguardando classificação') ||
                                obj.status.includes('Especialidade rejeitada')
                        ).length > 0
                            " class="status red">
                            <div class="head">
                                <IconsComp icon="clipboard" />
                                <span>
                                    {{
                                        edict?.propostas?.filter(
                                            (obj) =>
                                                obj.status.includes('Triagem pendente') ||
                                                obj.status.includes('Selecionar parecerista') ||
                                                obj.status.includes('Aguardando classificação') ||
                                                obj.status.includes('Especialidade rejeitada')
                                        ).length
                                    }}</span>
                            </div>
                            <span>Em triagem </span>
                        </div>

                        <div v-if="edict?.propostas?.filter((obj) =>
                                    obj.status.includes('Aguardando parecer')
                                ).length > 0 || $moment() > $moment(edict?.encerramento)
                                " class="status yellow">
                            <div class="head">
                                <IconsComp icon="timer-filled" />
                                <span>{{
                                    edict?.propostas?.filter((obj) =>
                                        obj.status.includes("Aguardando parecer")
                                    ).length
                                }}</span>
                            </div>
                            <span>Aguardando parecer </span>
                        </div>
                        <div v-if="$moment() > $moment(edict?.encerramento) && edict?.propostas?.filter((obj) =>
                                    obj.status.includes('Classificada')
                                )?.length > 0" class="status blue">
                            <div class="head">
                                <IconsComp icon="cover" />
                                <span>{{
                                    edict?.propostas?.filter((obj) =>
                                        obj.status.includes("Classificada")
                                    ).length
                                }}
                                </span>
                            </div>
                            <span>Classificadas/Não selecionados</span>
                        </div>

                        <div v-else-if="$moment() <= $moment(edict?.encerramento) && edict?.propostas?.filter((obj) => obj?.status?.includes('Classificada')
                                )?.length > 0
                                " class="status blue">
                            <div class="head">
                                <IconsComp icon="cover" />
                                <span>{{
                                    edict?.propostas?.filter((obj) =>
                                        obj.status.includes("Classificada")
                                    ).length
                                }}</span>
                            </div>
                            <span>Classificadas </span>
                        </div>

                        <div v-if="edict?.propostas?.filter((obj) =>
                                    obj.status.includes('Rejeitada')
                                ).length > 0
                                " class="status">
                            <div class="head">
                                <IconsComp icon="close-line" />
                                <span>{{
                                    edict?.propostas?.filter((obj) =>
                                        obj.status.includes("Rejeitada")
                                    ).length
                                }}</span>
                            </div>
                            <span>Rejeitadas </span>
                        </div>
                    </div>
                    <div v-if="$moment(edict?.encerramento) > $moment()"
                        class="flex justify-center items-center sticky top-[100vh] mt-4">
                        <router-link v-if="programs[i]?.id" :to="{
                            name: 'painel-inovahc-programa',
                            params: { id: programs[i]?.id },
                        }
                            " class="btn primary !p-1 no-underline">
                            <IconsComp icon="plus" class="stroke-white" />
                        </router-link>
                    </div>
                    <div v-else class="flex justify-center items-center sticky top-[100vh] mt-4">
                        <router-link v-if="programs[i]?.id" :to="{
                            name: 'painel-inovahc-programa',
                            params: { id: programs[i]?.id },
                        }
                            " class="btn primary no-underline w-full text-center">
                            Selecionar classificados
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Filtro -->
    <section class="bg-purple-50">
        <div class="container mx-auto px-6 pb-4">
            <div class="text-sm text-gray-400">Filtrar propostas</div>
            <ul v-if="programs.length === 0" class="filtro-categoria flex animate-pulse">
                <li v-for=" animate  in  4 " :key="animate" class="bg-gray-400 border-none flex space-x-4 p-4">
                    <div class="animate-pulse flex space-x-4 py-2">
                        <div class="flex-1">
                            <div class="space-y-3">
                                <div class="grid grid-cols-3 gap-4">
                                    <div class="h-2 bg-gray-400 rounded col-span-3"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <ul v-else class="filtro-categoria grid grid-cols-4">
                <li @click.prevent="selectedEdict = null; getProposals()" :class="!selectedEdict ? 'ativo' : ''">
                    Todas
                </li>
                <li v-for="( program ) in  programs " :key="program.id"
                    @click.prevent="selectedEdict = program.ultimoEdital?.id; currentPage = 1; getProposals()"
                    :class="selectedEdict && selectedEdict == program.ultimoEdital?.id ? 'ativo' : ''">
                    {{ program.nome }}
                </li>
            </ul>
            <div class="grid grid-cols-1 md:flex justify-between">
                <div class="flex items-center">
                    <div class="relative mr-2">
                        <CompSelect @change.prevent="getProposals()" class="max-w-sm" optionName="nome"
                            name="selectSpecialties" :options="specialties" v-model="selectedSpecialty"
                            :optionSelected="0" />
                    </div>
                    <div class="relative">
                        <CompSelect @change.prevent="getProposals()" class="max-w-sm" name="selectSpecialties"
                            :options="allStatus" v-model="selectedStatus" optionSelected="Todos os status " />
                    </div>
                </div>
                <div class="w-96 max-w-[100%]">
                    <CompSearch @submit.prevent="getProposals" name="search" v-model="search" />
                </div>
            </div>
        </div>
    </section>
    <!-- Tabela -->
    <section class="bg-purple-50">
        <div class="container mx-auto px-6 pb-6">
            <div class="overflow-x-scroll lg:overflow-hidden rounded-lg border border-white shadow-md">
                <!-- <CompTable :tableValues="tableValues" /> -->
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="flex items-center">
                                    Proposta
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Especialidade
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Inativo
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <span>Status</span>
                            </th>
                            <th scope="col">
                                <div>
                                    Recebido
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody v-show="proposals.length > 0" v-for="( tableValue, i ) in  proposals " :key="i">
                        <tr>
                            <td>
                                <div v-if="tableValue.nome_proposta" class="w-[240px] truncate flex items-center">
                                    {{ tableValue.nome_proposta }}
                                </div>
                            </td>
                            <td>
                                <div class="w-32 truncate">
                                    {{
                                        tableValue.especialidades?.map((obj) => obj.nome).join(", ")
                                    }}
                                </div>
                            </td>
                            <td>
                                <div>
                                    {{ ($moment().diff(tableValue.updated_at, "days")) }}
                                    dias
                                </div>
                            </td>
                            <td>
                                <CompStatus :status="tableValue?.status"
                                    :link="{ name: 'painel-inovahc-proposta', params: { id: tableValue?.id } }" />
                                <CompModal v-if="tableValue.status.includes('Classificada')" v-model="projectSelectModal[i]"
                                    :isOpen="projectSelectModal[i]" @close="projectSelectModal[i] = !projectSelectModal[i]"
                                    customClass="validacao">
                                    <div class="text-xl font-poppins font-bold mb-2">
                                        Selecionar a proposta como projeto?
                                    </div>
                                    <div class="mb-8">
                                        <p>
                                            Texto de suporte se necessários. Aenean eu ultricies
                                            lorem. Aliquam ac efficitur ante. Etiam nec tempus ante,
                                            sed bibendum magna. Donec sed tortor sed sem malesuada.
                                        </p>
                                    </div>
                                    <div class="flex justify-end">
                                        <button @click.prevent="
                                            projectSelectModal[i] = !projectSelectModal[i]
                                            " type="button" class="btn secondary">
                                            Cancelar
                                        </button>
                                        <button @click.prevent="
                                            projectSelectModal[i] = !projectSelectModal[i];
                                        proposalSelected(tableValue.id);
                                        " type="button" class="btn primary">
                                            Confirmar
                                        </button>
                                    </div>
                                </CompModal>

                                <CompModal v-model="classifiedProposalModal[i]" :isOpen="classifiedProposalModal[i]"
                                    @close="classifiedProposalModal[i] = !classifiedProposalModal[i]"
                                    customClass="validacao">
                                    <div class="text-xl font-poppins font-bold mb-2">
                                        Classificar a proposta: <b>{{ tableValue.nome_proposta }}</b>?
                                    </div>
                                    <div class="mb-8">
                                        <p>
                                            Texto de suporte se necessários. Aenean eu ultricies
                                            lorem. Aliquam ac efficitur ante. Etiam nec tempus ante,
                                            sed bibendum magna. Donec sed tortor sed sem malesuada.
                                        </p>
                                    </div>
                                    <div class="flex justify-end">
                                        <button @click.prevent="
                                            classifiedProposalModal[i] = !classifiedProposalModal[i]
                                            " type="button" class="btn secondary">
                                            Cancelar
                                        </button>
                                        <button @click.prevent="
                                            classifiedProposalModal[i] = !classifiedProposalModal[i];
                                        ; classifiedProposal(tableValue.id);
                                        " type="button" class="btn primary">
                                            Confirmar
                                        </button>
                                    </div>
                                </CompModal>
                            </td>
                            <td>
                                <div>
                                    {{ $moment(tableValue?.created_at).format("DD/MM/YYYY") }}
                                </div>
                            </td>
                            <td class="relative lg:static">
                                <div class="flex justify-end items-center">
                                    <CompDropdown alignment="right" divStyle="absolute" class="mr-8">
                                        <template #toggle>
                                            <IconsComp icon="more" class="w-6 h-6 fill-green-500" />
                                        </template>
                                        <router-link :to="{
                                            name: 'painel-inovahc-proposta',
                                            params: { id: tableValue.id },
                                        }
                                            " class="cursor-pointer">
                                            <li>
                                                <IconsComp icon="eye" />
                                                Ver proposta
                                            </li>
                                        </router-link>
                                        <a v-if="tableValue.status.includes('Aguardando classificação')"
                                            @click.prevent="classifiedProposalModal[i] = !classifiedProposalModal[i]"
                                            class="cursor-pointer no-underline">
                                            <li>
                                                <IconsComp icon="download" />
                                                Classificar proposta
                                            </li>
                                        </a>
                                        <a v-if="tableValue.status?.includes('Classificada')" @click.prevent="
                                            projectSelectModal[i] = !projectSelectModal[i]
                                            " class="cursor-pointer">
                                            <li>
                                                <IconsComp icon="download" />Selecionar como
                                                projeto
                                            </li>
                                        </a>
                                        <a v-if="!tableValue.status.includes('Rejeitada')" @click.prevent="
                                            rejectModalIsOpen[i] = !rejectModalIsOpen[i]
                                            " class="cursor-pointer">
                                            <li>
                                                <IconsComp icon="download" />Rejeitar proposta
                                            </li>
                                        </a>
                                    </CompDropdown>
                                    <div v-if="tableValue.avaliacoes?.length > 0" class="ml-auto">
                                        <button @click.prevent="ArrowIcons[i] = !ArrowIcons[i]">
                                            <IconsComp :class="{ 'rotate-180': ArrowIcons[i] }" icon="arrow-down"
                                                class="transition-all duration-300 fill-green-500 w-6 h-6 ml-12 md:ml-2" />
                                        </button>
                                    </div>
                                </div>
                                <CompProposalReject v-model:isOpen="rejectModalIsOpen[i]" :proposalId="tableValue.id"
                                    v-model:updated="rejectModalUpdated" />
                            </td>
                        </tr>
                        <CompAvaliation :avaliations="tableValue.avaliacoes" :isOpen="ArrowIcons[i]" />
                    </tbody>
                    <tbody v-if="proposals?.length === 0">
                        <tr>
                            <td colspan="1000">
                                <CompDataLoad :dataLoad="dataLoad" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
    <!--  Paginação -->
    <section class="bg-purple-50 pb-10">
        <div class="container mx-auto px-6">
            <CompPagination :onLoad="paginationOnLoad" v-model:quantity="perPage" v-model:page="currentPage"
                :firstPage="firstPage" :lastPage="lastPage" />
        </div>
    </section>
</template>
