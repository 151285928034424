<template>
    <div class="comp-modal" v-if="isOpen">
        <div class="bgmodal" @click.prevent="closeModal"></div>

        <div :class="['modal', customClass]" ref="modal">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        customClass: {
            type: [String, Array],
            default: "",
        },
    },
    methods: {
        closeModal() {
            this.$emit("close");
        },
    },
};
</script>
