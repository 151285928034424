<script setup>
import CompBreadCrumbs from "@/components/CompBreadCrumbs";
import IconsComp from "@/components/IconsComp";
import CompPagination from "@/components/CompPagination";
import CompDropdown from "@/components/CompDropdown";
import CompSearch from "@/components/CompSearch";
import { CompDataLoad } from '@/components'
import { ref, onMounted, watch } from "vue";
import { useProposerStore } from "@/stores";

const currentPage = ref(1);
const oldCurrentPage = ref(1);
const firstPage = ref(1);
const lastPage = ref(1);
const perPage = ref(20);
const paginationOnLoad = ref(false)
const proposers = ref([]);
const dataLoad = ref("Carregando..");
const search = ref('')

onMounted(async () => {
    await getProposers();
});

watch(currentPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getProposers();
    }
});

watch(perPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getProposers();
    }
});

async function getProposers() {
    proposers.value = [];
    dataLoad.value = "Carregando";

    paginationOnLoad.value = true
    proposers.value = await useProposerStore().search(search.value, perPage.value, currentPage.value !== oldCurrentPage.value ? currentPage.value : 1)

    currentPage.value = proposers.value.meta.current_page;
    oldCurrentPage.value = currentPage.value
    perPage.value = proposers.value.meta.per_page;
    lastPage.value = proposers.value.meta.last_page;
    proposers.value = proposers.value.data;

    proposers.value.length === 0
        ? (dataLoad.value = "Nenhuma proponente encontrado")
        : "";

    paginationOnLoad.value = false
}
</script>

<template>
    <div class="container mx-auto px-6 pt-10">
        <div class="hidden md:flex justify-between flex-col md:flex-row items-center mb-8">
            <div>
                <CompBreadCrumbs>
                    <template v-slot:default>
                        <li>
                            <router-link :to="{ name: 'painel-inovahc-monitores' }">
                                <IconsComp icon="user" class="w-6 h-6" />
                                Usuários
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'painel-inovahc-proponentes' }">
                                Proponentes
                            </router-link>
                        </li>
                    </template>
                </CompBreadCrumbs>
            </div>
        </div>

        <div class="text-5xl font-bold font-poppins mb-8 text-purple-800">
            Gestão de usuários do sistema
        </div>
        <div>
            <ul class="menu-tabs">
                <li>
                    <router-link :to="{ name: 'painel-inovahc-monitores' }">
                        Monitores
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'painel-inovahc-pareceristas' }">
                        Pareceristas
                    </router-link>
                </li>
                <li class="active">
                    <router-link :to="{ name: 'painel-inovahc-proponentes' }">
                        Proponentes
                    </router-link>
                </li>
            </ul>
        </div>
    </div>

    <div class="bg-purple-50">
        <div class="container mx-auto px-6 pt-8 mb-8">
            <div class="w-96 ml-auto max-w-full mb-4">
                <CompSearch @submit.prevent="getProposers" name="search" v-model="search" placeholder="Buscar proponente" />
            </div>
            <div class="overflow-x-scroll lg:overflow-hidden rounded-lg border border-white shadow-md">
                <table class="table table-auto rounded">
                    <thead class="bg-gray-50">
                        <tr>
                            <th scope="col">
                                <div class="profile">
                                    Proponente
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Telefone
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Propostas
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Projetos
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <span>Status</span>
                            </th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody v-show="proposers.length > 0" v-for="(proposer, i) in proposers" :key="i"
                        class="bg-white border-t">
                        <tr class="hover:bg-white hover:text-purple-800">
                            <td>
                                <div v-if="proposer.nome_completo" class="profile w-96">
                                    <div class="profile-img small">
                                        <img v-if="proposer?.avatar" :src="proposer?.avatar" alt="" />
                                        <img v-else src="@/assets/img/template/profile2.png" alt="" />
                                    </div>
                                    <div>
                                        {{ proposer.nome_completo }}

                                        <div class="text-gray-400 text-sm">
                                            {{ proposer.email }}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div v-if="proposer.telefone" class="truncate w-32">
                                    {{ proposer.telefone }}
                                </div>
                            </td>
                            <td>
                                <div v-if="proposer.proponentePropostas" class="truncate w-32">
                                    {{ proposer.proponentePropostas?.length }}
                                </div>
                            </td>
                            <td>
                                <div v-if="proposer.proponenteProjetos" class="truncate w-32">
                                    {{ proposer.proponenteProjetos?.length }}
                                </div>
                            </td>
                            <td>
                                <router-link :to="{ name: 'painel-inovahc-proponente', params: { id: proposer.id } }"
                                    class="no-underline">
                                    <button type="button" class="tag" :class="proposer.inativo ? 'red' : 'blue'">
                                        {{ proposer.inativo ? 'Inativo' : "Ativo" }}
                                    </button>

                                </router-link>
                            </td>
                            <td class="relative lg:static">
                                <div class="flex items-center md:float-right mr-8">
                                    <CompDropdown alignment="right" divStyle="absolute">
                                        <template #toggle>
                                            <IconsComp icon="more" class="w-6 h-6 fill-green-500" />
                                        </template>
                                        <router-link :to="{
                                            name: 'painel-inovahc-proponente',
                                            params: { id: proposer.id },
                                        }">
                                            <li class="text-purple-800">
                                                Visualizar proponente
                                            </li>
                                        </router-link>
                                    </CompDropdown>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-show="proposers.length === 0">
                        <tr>
                            <td colspan="1000">
                                <CompDataLoad :dataLoad="dataLoad" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="mt-4 pb-20">
                <div class="container mx-auto px-6">
                    <CompPagination :onLoad="paginationOnLoad" v-model:quantity="perPage" v-model:page="currentPage"
                        :firstPage="firstPage" :lastPage="lastPage" />
                </div>
            </div>
        </div>
    </div>
</template>
