<script setup>
import CompPagination from "@/components/CompPagination";
import IconsComp from "@/components/IconsComp";
import CompSearch from "@/components/CompSearch";
import CompDropdown from "@/components/CompDropdown";
import CompBreadCrumbs from "@/components/CompBreadCrumbs";
import CompModal from "@/components/CompModal";
import { CompDataLoad, CompSelect } from '@/components'
import { ref, onMounted, watch } from "vue";
import { useInstituteStore, useSpecialtyStore } from "@/stores";


const institutes = ref([]);
const proposalsAndProjectsCount = ref([]);
const specialties = ref([]);
const dataLoad = ref("Carregando..");
const currentPage = ref(1);
const oldCurrentPage = ref(1);
const firstPage = ref(1);
const lastPage = ref(1);
const perPage = ref(20);
const paginationOnLoad = ref(false)
const deleteModalIsOpen = ref([]);
const selectedSpecialty = ref(0)
const search = ref('')

onMounted(async () => {
    await getInstitutes();

    [proposalsAndProjectsCount.value, specialties.value] = await Promise.all([
        useInstituteStore().proposalsAndProjectsCount().then((response) => response.data),
        useSpecialtyStore().allWithoutPagination().then((response) => response.data),
    ])
    specialties.value.push({ id: 0, nome: "Todas especialidades" })
    specialties.value = specialties.value.sort((a, b) => a.id - b.id)
});

watch(currentPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getInstitutes();
    }
});

watch(perPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getInstitutes();
    }
});

async function getInstitutes() {
    dataLoad.value = "Carregando";
    institutes.value = [];

    paginationOnLoad.value = true
    institutes.value = await useInstituteStore().search(search.value, selectedSpecialty.value == 0 ? "" : selectedSpecialty.value, perPage.value, currentPage.value !== oldCurrentPage.value ? currentPage.value : 1);

    currentPage.value = institutes.value.meta.current_page;
    oldCurrentPage.value = currentPage.value
    perPage.value = institutes.value.meta.per_page;
    perPage.value = institutes.value.meta.per_page;
    lastPage.value = institutes.value.meta.last_page;
    institutes.value = institutes.value.data;

    institutes.value.length === 0
        ? (dataLoad.value = "Nenhum instituto encontrado")
        : "";
    paginationOnLoad.value = false
}

async function onDelete(id) {
    await useInstituteStore().delete(id).then(async () => {
        await getInstitutes();
    });
}
</script>

<template>
    <section>
        <div class="container mx-auto px-6 pt-10">
            <div class="flex justify-between flex-col md:flex-row items-center mb-8">
                <div class="hidden md:flex">
                    <CompBreadCrumbs>
                        <template v-slot:default>
                            <li>
                                <router-link :to="{ name: 'painel-inovahc-institutos' }">
                                    <IconsComp icon="institutes" class="w-6 h-6" />
                                    Institutos
                                </router-link>
                            </li>
                        </template>
                    </CompBreadCrumbs>
                </div>
                <div class="ml-auto">
                    <router-link :to="{ name: 'adicionar-instituto' }" class="btn primary small no-underline">
                        Novo instituto
                    </router-link>
                </div>
            </div>

            <div class="flex flex-col gap-6">
                <div class="w-full">
                    <div class="text-5xl font-bold font-poppins text-purple-800">
                        Institutos
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Filtro -->
    <section class="bg-purple-50 mt-8">
        <div class="container mx-auto px-6 pt-10">
            <div class="text-sm text-gray-400">Filtrar propostas</div>
            <div class="grid grid-cols-1 md:flex justify-between">
                <div class="flex items-center">
                    <div class="relative mr-2 mt-2">
                        <CompSelect @change.prevent="getInstitutes" class="max-w-sm" optionName="nome"
                            name="specialtiesSelect" :options="specialties" v-model="selectedSpecialty"
                            :optionSelected="0" />
                    </div>
                </div>
                <div class="w-96 max-w-full">
                    <CompSearch @submit.prevent="getInstitutes" name="search" v-model="search"
                        placeholder="Buscar instituto" />
                </div>
            </div>
        </div>
    </section>
    <!-- Tabela -->
    <section class="bg-purple-50">
        <div class="container mx-auto px-6 pt-2 pb-6">
            <div class="overflow-x-scroll md:overflow-hidden rounded-lg border border-white shadow-md">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="flex items-center">
                                    Instituto
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Responsável
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Avaliações pendentes
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Propostas classificadas
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Projetos ativos
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody v-show="institutes.length > 0" v-for="(tableValue, i) in institutes" :key="tableValue">
                        <tr>
                            <td>
                                <router-link :to="{ name: 'painel-inovahc-instituto', params: { id: tableValue.id } }">
                                    <div v-if="tableValue.nome" class="w-[240px] truncate flex items-center">
                                        {{ tableValue.nome }}
                                    </div>
                                </router-link>
                            </td>
                            <td>
                                <div class="profile mb-4">
                                    <div class="profile-img small">
                                        <img v-if="tableValue?.monitor?.avatar" :src="tableValue?.monitor?.avatar" alt="" />
                                        <img v-else src="@/assets/img/template/profile2.png" alt="" />
                                    </div>
                                    <div class="grid grid-cols-1 gap-x-2">
                                        <div class="nome">
                                            {{ tableValue.monitor?.nome_completo }}
                                        </div>
                                        <div class="text-sm text-grey-500">
                                            {{ tableValue.monitor?.email }}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    {{ tableValue.especialidades?.map(obj => obj?.propostas).find(obj =>
                                        obj)?.filter(obj =>
                                            obj?.status?.includes('Triagem pendente') ||
                                            obj?.status?.includes('Selecionar parecerista') ||
                                            obj?.status?.includes('Aguardando parecer') ||
                                            obj?.status?.includes('Aguardando classificação')
                                        ).length ?? 0
                                    }}
                                </div>
                            </td>
                            <td>
                                <div>
                                    {{ tableValue.especialidades?.map(obj => obj?.propostas).find(obj =>
                                        obj)?.filter(obj => obj?.status?.includes('Classificada')).length ?? 0
                                    }}
                                </div>
                            </td>
                            <td>
                                <div>
                                    {{ tableValue.especialidades?.map(obj => obj?.projetos).find(obj =>
                                        obj)?.filter(obj => $moment().diff(obj?.updated_at, 'days') < 15).length ?? 0 }} de {{
        tableValue.especialidades?.map(obj => obj?.projetos).find(obj =>
            obj)?.filter(obj => obj).length ?? 0
    }}
                                </div>
                            </td>
                            <td class="relative md:static">
                                <div class="flex items-center md:float-right mr-8">
                                    <CompDropdown alignment="right" divStyle="absolute">
                                        <template #toggle>
                                            <IconsComp icon="more" class="w-6 h-6 fill-green-500" />
                                        </template>
                                        <router-link :to="{
                                                    name: 'painel-inovahc-instituto',
                                                    params: { id: tableValue.id },
                                                }">
                                            <li class="text-purple-800">
                                                Visualizar instituto
                                            </li>
                                        </router-link>
                                        <router-link :to="{
                                            name: 'editar-instituto',
                                            params: { id: tableValue.id },
                                        }">
                                            <li class="text-purple-800">
                                                Editar instituto
                                            </li>
                                        </router-link>
                                        <a @click.prevent="
                                            deleteModalIsOpen[i] = !deleteModalIsOpen[i]
                                            " class="cursor-pointer">
                                            <li class="text-purple-800">
                                                Deletar instituto
                                            </li>
                                        </a>
                                    </CompDropdown>
                                </div>
                                <CompModal v-model="deleteModalIsOpen[i]" :isOpen="deleteModalIsOpen[i]"
                                    @close="deleteModalIsOpen[i] = !deleteModalIsOpen[i]" customClass="validacao">
                                    <div class="text-xl font-poppins font-bold mb-2">
                                        Confirmar ação
                                    </div>
                                    <div class="mb-8">
                                        <p>
                                            Tem certeza que deseja deletar o
                                            <b>{{ tableValue.nome }}</b>?
                                        </p>
                                        <p>
                                            Clique em “Ok” para deletar ou em “Cancelar” para
                                            permanecer na página.
                                        </p>
                                    </div>
                                    <div class="flex justify-end">
                                        <button @click.prevent="
                                            deleteModalIsOpen[i] = !deleteModalIsOpen[i]
                                            " type="button" class="btn secondary">
                                            Cancelar
                                        </button>
                                        <button @click="
                                            onDelete(tableValue.id);
                                        deleteModalIsOpen[i] = !deleteModalIsOpen[i];
                                        " type="button" class="btn primary">
                                            Ok
                                        </button>
                                    </div>
                                </CompModal>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-show="institutes.length === 0">
                        <tr>
                            <td colspan="1000">
                                <CompDataLoad :dataLoad="dataLoad" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
    <!--  Paginação -->
    <section class="bg-purple-50 pb-20 mb-0">
        <div class="container mx-auto px-6">
            <CompPagination :onLoad="paginationOnLoad" v-model:quantity="perPage" v-model:page="currentPage"
                :firstPage="firstPage" :lastPage="lastPage" />
        </div>
    </section>
</template>
