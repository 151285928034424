<script setup>
import CompInput from "@/components/CompInput";
import IconsComp from "@/components/IconsComp";
import CompSelect from "@/components/CompSelect";
import CompFile from "@/components/CompFile";
import CompModal from "@/components/CompModal";
import { ref, onMounted, onBeforeMount, onBeforeUnmount } from "vue";
import { useEdictStore, useProgramStore } from "@/stores";
import { Form, useField, Field } from "vee-validate";
import * as yup from "yup";
import { ptForm } from "yup-locale-pt";
import { useRouter, useRoute } from "vue-router";
import moment from "moment-timezone";
import { CompToogle } from "@/components";

moment.tz.setDefault("America/Sao_Paulo").locale("pt-br");
yup.setLocale(ptForm);

const route = useRoute();
const router = useRouter();
const programs = ref([]);
const cancelModalIsOpen = ref(false);
const addModalIsOpen = ref(false);
const loading = ref(false);
const edict_id = route.params.id;
const form = ref({
    program_id: null,
    name: "",
    opening: "",
    closure: "",
    edict_file: { name: "edital-in-pule-2023.pdf" },
    work_plan: { name: "modelo-plano-de-trabalho.doc" },
    final_project_report: { name: "modelo-relatorio.doc" },
    reviewer_certificate: { name: "certificado.jpg" },
    archived: null
});

onMounted(async () => {
    loading.value = true;
    const edict = await useEdictStore().find(edict_id).then(response => response?.data);

    if (!edict) {
        router.back();
    }

    programs.value = await useProgramStore().all().then(response => response?.data);

    form.value.program_id = edict.programa?.id;
    form.value.name = edict.nome;
    form.value.opening = moment(edict.abertura).format('YYYY-MM-DD');
    form.value.closure = moment(edict.encerramento).format('YYYY-MM-DD');
    form.value.edict_file = edict.arquivo_edital;
    form.value.work_plan = edict.plano_trabalho;
    form.value.final_project_report = edict.relatorio_final_projeto;
    form.value.reviewer_certificate = edict.certificado_parecerista;
    form.value.archived = edict.arquivado;
    loading.value = false;
});

async function onSubmit(values, { resetForm }) {
    await useEdictStore().update(edict_id, form.value).then((response) => {
        if (response) {
            resetForm();
            router.push({ name: "painel-inovahc-editais" });
        }
    });
}
</script>

<template>
    <a @click="$router.back()" class="cursor-pointer">
        <button class="hidden md:grid absolute top-28 right-24">
            <span class="flex font-bold text-green-500">
                Fechar
                <IconsComp icon="close-line" class="w-8 h-8 fill-green-500 ml-2 -mt-1" />
            </span>
        </button>
    </a>
    <div v-if="!loading" class="grid grid-cols-1 gap-2">
        <div class="mx-auto w-full max-w-3xl py-12 px-6">
            <div class="text-purple-800 font-bold font-poppins text-2xl mb-4">
                Editar edital
            </div>
            <div>
                <p>
                    Selecione o programa correspondente ao novo edital que será publicado
                    e crie um nome seguindo o modelo:
                    <b>"nome do programa"_"ano"/"ordem"</b> ex.: in.cube_2023/1.
                </p>
                <p>
                    Você vai precisar também anexar os documentos relacionados ao novo
                    edital, são eles: PDF do edital, modelo do documento de plano de
                    trabalho e relatório final e por fim o template do certificado que
                    será emitido aos pareceristas.
                </p>
            </div>
            <Form @submit="onSubmit" class="form-simples">
                <div class="my-6">
                    <CompToogle v-model="form.archived" texto="Edital Arquivado" />
                    <p>
                        Se o edital for arquivado, ele será exibido apenas para super admins.
                    </p>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 my-6">
                    <div>
                        <CompSelect v-model="form.program_id" name="program_id" label="Programa"
                            optionDisabled="Selecionar o programa" optionName="nome" :options="programs"
                            :schema="yup.number().required()" />
                    </div>
                    <div>
                        <CompInput v-model="form.name" label="Nome do edital" type="text" placeholder="ex. in.cube_2023/1"
                            name="name" :schema="yup.string().max(255).required()" />
                    </div>
                </div>
                <p class="mt-6">
                    Cuidado ao editar a data para não sobrepor com outros editais do mesmo programa.
                </p>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                    <div>
                        <CompInput v-model="form.opening" label="Abertura das inscrições" type="date" name="opening"
                            :schema="yup.date('Insira uma data válida').required()" />
                    </div>
                    <div>
                        <CompInput v-model="form.closure" label="Encerramento das inscrições" type="date" name="closure"
                            :schema="yup.date('Insira uma data válida')
                                .required().test(
                                    'A data de encerramento é posterior a de abertura',
                                    'A data de encerramento deve ser posterior a de abertura',
                                    (value) => {
                                        if (!value) return true;
                                        return $moment(form.opening) < $moment(value) ? true : false;
                                    }
                                )" />
                    </div>
                </div>

                <div class="text-purple-800 mb-2">Arquivo do edital</div>
                <p class="text-sm">
                    Instruções sobre o arquivo que deve ser submetido lorem ipsum dolor
                </p>
                <CompFile name="edict_file" id="edict_file" v-model="form.edict_file" :schema="yup.mixed().notRequired()"
                    documentTypes="pdf, doc, docx" />

                <div class="font-bold text-purple-800 text-lg mb-2 mt-8">
                    Modelos de documentos
                </div>
                <p class="pb-4">
                    Enviar link dos arquivos metus risus in nam felis. Vitae a est dictum
                    adipiscing. Platea in dui platea diam diam.
                </p>
                <div class="my-6">
                    <div class="text-purple-800 mb-2">Plano de trabalho</div>
                    <p class="text-sm">
                        Instruções sobre o arquivo que deve ser submetido lorem ipsum dolor
                    </p>
                    <CompFile name="work_plan" id="work_plan" v-model="form.work_plan" :schema="yup.mixed().notRequired()"
                        documentTypes="pdf, doc, docx" />
                </div>

                <div class="mb-6">
                    <div class="text-purple-800 mb-2">Relatório final do projeto</div>
                    <p class="text-sm">
                        Instruções sobre o arquivo que deve ser submetido lorem ipsum dolor
                    </p>
                    <CompFile name="final_project_report" id="final_project_report" v-model="form.final_project_report"
                        :schema="yup.mixed().notRequired()" documentTypes="pdf, doc, docx" />
                </div>
                <div class="mb-6">
                    <div class="text-purple-800 mb-2">Certificado parecerista</div>
                    <p class="text-sm">
                        Instruções sobre o arquivo que deve ser submetido lorem ipsum dolor
                    </p>
                    <CompFile name="reviewer_certificate" id="reviewer_certificate" v-model="form.reviewer_certificate"
                        :schema="yup.mixed().notRequired()" documentTypes="pdf, doc, docx" />
                </div>

                <div class="grid grid-cols-2 gap-6">
                    <button @click.prevent="cancelModalIsOpen = true" type="button" class="btn secondary w-full my-8">
                        Cancelar
                    </button>
                    <button @click.prevent="addModalIsOpen = true" type="button" class="btn primary w-full my-8">
                        Salvar alterações
                    </button>
                </div>

                <CompModal v-model="cancelModalIsOpen" :isOpen="cancelModalIsOpen"
                    @close="cancelModalIsOpen = !cancelModalIsOpen" customClass="validacao">
                    <div class="text-xl font-poppins font-bold mb-2">Confirmar ação</div>
                    <div class="mb-8">
                        <p>
                            Se você fechar essa janela irá perder todas as edições feitas até
                            agora.
                        </p>
                        <p>
                            Clique em “Ok” para sair ou em “Cancelar” para permanecer na
                            página.
                        </p>
                    </div>
                    <div class="flex justify-end">
                        <button @click.prevent="cancelModalIsOpen = !cancelModalIsOpen" type="button" class="btn secondary">
                            Cancelar
                        </button>
                        <button @click.prevent="$router.back()" type="button" class="btn primary">
                            Ok
                        </button>
                    </div>
                </CompModal>

                <CompModal v-model="addModalIsOpen" :isOpen="addModalIsOpen" @close="addModalIsOpen = !addModalIsOpen"
                    customClass="validacao">
                    <div class="text-xl font-poppins font-bold mb-2">
                        Atualizar edital
                    </div>
                    <div class="mb-8">
                        Você tem certeza que deseja atualizar as informações do edital
                        <b>{{ form.name }}</b>?
                    </div>
                    <div class="flex justify-end">
                        <button @click.prevent="addModalIsOpen = !addModalIsOpen" type="button" class="btn secondary">
                            Cancelar
                        </button>
                        <button type="button" @click.prevent="
                            $refs.submitButton.click();
                        addModalIsOpen = !addModalIsOpen;
                        " class="btn primary">
                            Salvar alterações
                        </button>
                    </div>
                </CompModal>
                <button ref="submitButton" type="submit" class="hidden"></button>
            </Form>
        </div>
    </div>
    <div v-else class="flex items-center justify-center align-middle h-full">
        <div class="text-center inline-block align-middle my-10 text-xl font-bold text-purple-800">
            Carregando..
        </div>
    </div>
</template>
