<template>
  <div class="comp-progress">
    <div
      v-for="(step, index) in steps"
      :key="index"
      :class="[
        'step',
        { 'step-active': index + 1 === currentStep },
        { 'step-past': index + 1 < currentStep },
      ]"
    >
      <div class="content">
        <div class="number">
          <span>{{ index + 1 }}</span>
        </div>
        <div v-if="!withoutNames" class="name">{{ step }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentStep: {
      type: Number,
      default: 1,
    },
    steps: {
      type: Array,
      required: true,
    },
    withoutNames: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
