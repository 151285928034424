<script setup>
import CompInput from "@/components/CompInput";
import IconsComp from "@/components/IconsComp";
import CompSelect from "@/components/CompSelect";
import CompFile from "@/components/CompFile";
import CompModal from "@/components/CompModal";
import { ref, onMounted } from "vue";
import { useAlertStore, useEdictStore, useFormDownloadStore, useProgramStore } from "@/stores";
import { Form, useField, Field } from "vee-validate";
import * as yup from "yup";
import { ptForm } from "yup-locale-pt";
import { useRouter } from "vue-router";

yup.setLocale(ptForm);

const formsDownload = ref([]);
const addModalIsOpen = ref(false);
const form = ref({
    name: "",
    document: null,
});

onMounted(async () => {
    await getForms();
});

async function getForms() {
    formsDownload.value = await useFormDownloadStore().all().then(response => response?.data);
}

async function onSubmit() {
    const hasForm = formsDownload.value.find(formDownload => formDownload.nome == form.value.name);

    if (hasForm) {
        await useFormDownloadStore().update(hasForm.id, form.value).then(async (response) => {
            if (!response || response?.error || response?.errors) {
                return;
            }
            await getForms();
        })
    } else {
        await useFormDownloadStore().store(form.value).then(async (response) => {
            if (!response || response?.error || response?.errors) {
                return;
            }
            await getForms();
        });
    }
    addModalIsOpen.value = false;
    form.value = {};
}
</script>

<template>
    <a @click="$router.back()" class="cursor-pointer">
        <button class="hidden md:grid absolute top-28 right-24">
            <span class="flex font-bold text-green-500">
                Fechar
                <IconsComp icon="close-line" class="w-8 h-8 fill-green-500 ml-2 -mt-1" />
            </span>
        </button>
    </a>
    <div class="grid grid-cols-1 gap-2">
        <div class="mx-auto w-full max-w-3xl py-12 px-6">
            <div class="text-purple-800 font-bold font-poppins text-2xl mb-4">
                Formulários para download
            </div>
            <div>
                <p>
                    Envie o formulário para download de acordo com o programa selecionado.
                </p>
                <p>
                    Para editar o formulário, basta baixa-lo, edita-lo, e envia-lo novamente.
                </p>
                <div class="grid grid-cols-1 md:grid-cols-3 gap-2">
                    <a v-for="formDownload in formsDownload" :key="formDownload.id" :href="formDownload?.url"
                        target="_blank">
                        <button class="btn secondary mt-4">
                            Baixe o formulário {{ formDownload?.nome }}
                        </button>
                    </a>
                </div>
            </div>
            <Form @submit="addModalIsOpen = !addModalIsOpen" class="form-simples">
                <div class="my-6">
                    <div>
                        <CompSelect v-model="form.name" name="name" label="Programa" optionDisabled="Selecionar o programa"
                            :options="['In.cube', 'In.pulse', 'In.pacte', 'Fluxo continuo']"
                            :schema="yup.string().required()" />
                    </div>
                </div>

                <div class="my-6">
                    <div class="text-purple-800 mb-2">Formulário</div>
                    <p class="text-sm">
                        Faça o upload do formulário em .pdf
                    </p>
                    <CompFile name="document" id="document" v-model="form.document" :schema="yup.mixed().required()" />
                </div>

                <CompModal v-model="addModalIsOpen" :isOpen="addModalIsOpen" @close="addModalIsOpen = !addModalIsOpen"
                    customClass="validacao px-6">
                    <div class="text-xl font-poppins font-bold mb-2">Publicar formulário</div>
                    <div class="mb-8">
                        Você tem certeza que deseja atualizar o formulário <b>{{ form.name }}</b>?
                    </div>
                    <div class="flex justify-end">
                        <button @click.prevent="addModalIsOpen = !addModalIsOpen" type="button" class="btn secondary">
                            Cancelar
                        </button>
                        <button @click.prevent="
                            onSubmit()
                            " type="button" class="btn primary">
                            Publicar formulário
                        </button>
                    </div>
                </CompModal>
                <button type="submit" class="btn primary w-full">Atualizar</button>
            </Form>
        </div>
    </div>
</template>
