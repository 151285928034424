<script setup>
import { ref } from "vue";
import IconsComp from "@/components/IconsComp";
import CompFile from "@/components/CompFile";

const props = defineProps({
    values: {},
});

const form = ref({
    pitch: props.values.pitch,
});
</script>

<template>
    <div class="mb-16">
        <div class="text-3xl font-poppins font-bold text-purple-800 mb-2">
            Pitch Deck
        </div>
        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Arquivo do pitch
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Compartilhe seu pitch deck. Você pode usar seu modelo próprio ou seguir
                <a href="https://inovahc-back.tapronto.com.br/faker-docs/modelo.pdf" target="_blank">nosso template</a>.
            </p>
            <CompFile name="pitch" id="pitch" v-model="form.pitch" documentTypes="pdf" />
        </div>
    </div>
</template>
