<script setup>
import CompPagination from "@/components/CompPagination";
import IconsComp from "@/components/IconsComp";
import CompSearch from "@/components/CompSearch";
import CompBreadCrumbs from "@/components/CompBreadCrumbs";
import CompSelect from "@/components/CompSelect";
import CompDropdown from "@/components/CompDropdown";
import CompModal from "@/components/CompModal";
import CompRadio from "@/components/CompRadio";
import CompAvaliation from "@/components/CompAvaliation";
import { CompDataLoad, CompProposalReject, CompStatus } from '@/components'
import { ref, onMounted, watch } from "vue";
import { useEdictStore, useProposalStore, useSpecialtyStore, useProgramStore, useProjectStore } from "@/stores";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const program_id = route.params.id
const program = ref()
const currentPage = ref(1);
const oldCurrentPage = ref(1)
const firstPage = ref(1);
const lastPage = ref(1);
const perPage = ref(20);
const paginationOnLoad = ref(false)
const allStatus = ref(['Todos os status', 'Aguardando parecer', 'Aguardando classificação', 'Especialidade rejeitada', 'Selecionar parecerista', 'Triagem pendente', 'Classificada', 'Rejeitada', 'Selecionado', 'Plano de trabalho pendente', 'Relatório final pendente', 'Descontinuado'])
const proposals = ref([]);
const projects = ref([]);
const proposalsAndProjectsCount = ref([])
const dataLoad = ref("Carregando..");
const edicts = ref([]);
const specialties = ref([])
const programs = ref([])
const search = ref('')
const edict = ref(null)
const selectedEdict = ref(null)
const selectedSpecialty = ref(0)
const selectedStatus = ref('Todos os status')
const selectedProgramId = ref(null);
const ArrowIcons = ref([]);
const rejectModalIsOpen = ref([]);
const rejectModalUpdated = ref(false);
const edictChange = ref(true)

onMounted(async () => {
    program.value = await useProgramStore().find(program_id).then(response => response?.data)

    if (!program.value) {
        router.back()
        return
    }

    [edicts.value, specialties.value, programs.value] = await Promise.all([
        useEdictStore().search("", program_id).then(response => response.data),
        useSpecialtyStore().all().then((response) => response.data),
        useProgramStore().all().then((response) => response.data),
    ]);
    edict.value = edicts.value.length > 0 ? edicts.value[0] : '';
    selectedEdict.value = edicts.value.length > 0 ? edicts.value[0].id : '';
    specialties.value.push({ id: 0, nome: "Todas especialidades" })
    specialties.value = specialties.value.sort(obj => obj.id)

    await getProposals();
});

watch(currentPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getProposals();
    }
});

watch(perPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getProposals();
    }
});

watch(rejectModalUpdated, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getProposals();
    }
});

async function getProposals() {
    proposals.value = [];
    dataLoad.value = "Carregando";
    paginationOnLoad.value = true

    await getProjects();

    proposals.value = await useProposalStore().search(search.value, selectedStatus.value == 'Todos os status' ? '' : selectedStatus.value, selectedProgramId.value ? selectedProgramId.value : "", selectedSpecialty.value == 0 ? "" : selectedSpecialty.value, selectedEdict.value ? selectedEdict.value : "", perPage.value, currentPage.value !== oldCurrentPage.value ? currentPage.value : 1)

    currentPage.value = proposals.value.meta.current_page;
    oldCurrentPage.value = currentPage.value
    perPage.value = proposals.value.meta.per_page;
    lastPage.value = proposals.value.meta.last_page;
    proposals.value = proposals.value.data.concat(projects.value);

    proposals.value.length === 0
        ? (dataLoad.value = "Nenhuma proposta ou projeto encontrado")
        : "";

    paginationOnLoad.value = false

    edict.value = await useEdictStore().find(selectedEdict.value).then(response => response?.data)

    if (edictChange) {
        proposalsAndProjectsCount.value = proposals.value;
        edictChange.value = false
    }
}

async function getProjects() {
    projects.value = await useProjectStore().search(search.value, selectedStatus.value == 'Todos os status' ? '' : selectedStatus.value, selectedProgramId.value ? selectedProgramId.value : "", selectedSpecialty.value == 0 ? "" : selectedSpecialty.value, selectedEdict.value ? selectedEdict.value : "", perPage.value, currentPage.value !== oldCurrentPage.value ? currentPage.value : 1).then(response => response?.data)
}

function proposalsCount() {
    proposalsAndProjectsCount.value = proposals.value
}
</script>

<template>
    <div class="container mx-auto px-6 pt-10 pb-12">
        <div class="hidden md:flex justify-between flex-col md:flex-row items-center mb-8">
            <div>
                <CompBreadCrumbs>
                    <template v-slot:default>
                        <li>
                            <router-link :to="{ name: 'painel-inovahc-programas' }">
                                <IconsComp icon="painel" class="w-6 h-6" />
                                Programas
                            </router-link>
                        </li>
                        <li>
                            <router-link v-if="program" :to="{
                                name: 'painel-inovahc-programa',
                                params: { id: program.id },
                            }">
                                {{ program.nome }}
                            </router-link>
                        </li>
                    </template>
                </CompBreadCrumbs>
            </div>
        </div>

        <div class="flex flex-col md:flex-row gap-6">
            <div class="w-full md:w-6/12">
                <div class="text-3xl lg:text-5xl font-bold font-poppins mb-8 text-purple-800 break-all">
                    Propostas {{ edict?.nome }}
                </div>
                <div class="w-full md:w-6/12">
                    <p class="text-sm text-gray-400">Edital</p>
                    <CompSelect @input.prevent="getProposals(); edictChange = true;" optionName="nome" name="edital"
                        v-model="selectedEdict" :optionSelected="edict?.id" :options="edicts" :required="true" />
                </div>
            </div>
            <div class="card w-full md:w-6/12" v-if="edict">
                <div class="grid gap-6 md:grid-cols-3 propostas">
                    <div class="big red">
                        <div class="title">
                            <IconsComp icon="proposta-filled" />
                            <span>
                                {{
                                    proposalsAndProjectsCount?.filter(
                                        (obj) =>
                                            obj?.status == "Triagem pendente" ||
                                            obj?.status == "Selecionar parecerista" ||
                                            obj?.status == "Especialidade rejeitada" ||
                                            obj?.status == "Aguardando classificação"
                                    )?.length
                                }}
                            </span>
                        </div>
                        <div class="description">Em triagem</div>
                    </div>

                    <div class="big blue">
                        <div class="title">
                            <IconsComp icon="clock-filled" />
                            <span>
                                {{
                                    proposalsAndProjectsCount?.filter((obj) => obj.status == "Classificada")
                                        .length
                                }}
                            </span>
                        </div>
                        <div class="description">Classificados</div>
                    </div>

                    <div class="big green">
                        <div class="title">
                            <IconsComp icon="proposta-filled" />
                            <span>
                                {{
                                    proposalsAndProjectsCount?.filter((obj) => obj.status == "Selecionado").length
                                }}
                            </span>
                        </div>
                        <div class="description">Projetos selecionados</div>
                    </div>

                    <div class="small yellow">
                        <div class="title">
                            <IconsComp icon="timer-filled" />
                            <span>
                                {{
                                    proposalsAndProjectsCount?.filter((obj) => obj.status == "Aguardando parecer").length
                                }}
                            </span>
                        </div>
                        <div class="description">Aguardando parecer</div>
                    </div>

                    <div class="small">
                        <div class="title">
                            <IconsComp icon="reject-filled" />
                            <span>
                                {{
                                    proposalsAndProjectsCount?.filter((obj) => obj.status == "Rejeitada" || obj.status ==
                                        "Descontinuado").length
                                }}
                            </span>
                        </div>
                        <div class="description">Propostas rejeitadas</div>
                    </div>

                    <div class="small green">
                        <div class="title">
                            <IconsComp icon="success-filled" />
                            <span>
                                {{ proposalsAndProjectsCount?.filter((obj) => !obj?.status?.includes('Rejeitada') &&
                                    !obj.status?.includes("Descontinuado"))
                                    .length }}
                            </span>
                        </div>
                        <div class="description">Propostas aprovadas</div>
                    </div>
                </div>
            </div>
            <div class="card w-full md:w-6/12" v-else>
                <div class="animate-pulse flex space-x-4 p-4">
                    <div class="flex-1 space-y-6 py-1">
                        <div class="space-y-3">
                            <div class="grid grid-cols-3 gap-4">
                                <div class="h-2 bg-gray-400 rounded col-span-2"></div>
                                <div class="h-2 bg-gray-400 rounded col-span-1"></div>
                                <div class="h-2 bg-gray-400 rounded col-span-1"></div>
                                <div class="h-2 bg-gray-400 rounded col-span-2"></div>
                            </div>
                            <div class="h-2 bg-gray-400 rounded"></div>
                            <div class="grid grid-cols-3 gap-4">
                                <div class="h-2 bg-gray-400 rounded col-span-2"></div>
                                <div class="h-2 bg-gray-400 rounded col-span-1"></div>
                                <div class="h-2 bg-gray-400 rounded col-span-1"></div>
                                <div class="h-2 bg-gray-400 rounded col-span-2"></div>
                                <div class="h-2 bg-gray-400 rounded col-span-1"></div>
                                <div class="h-2 bg-gray-400 rounded col-span-1"></div>
                                <div class="h-2 bg-gray-400 rounded col-span-1"></div>
                            </div>
                            <div class="h-2 bg-gray-400 rounded"></div>
                            <div class="h-2 bg-gray-400 rounded"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section class="bg-purple-50">
        <div class="container mx-auto px-6 pt-8">
            <div class="text-sm text-gray-400">Filtrar propostas</div>
            <div class="grid grid-cols-1 md:flex justify-between">
                <div class="flex items-center">
                    <div class="relative mr-2">
                        <CompSelect @change.prevent="getProposals(null)" class="max-w-sm" optionName="nome"
                            name="selectSpecialties" :options="specialties" v-model="selectedSpecialty"
                            :optionSelected="0" />
                    </div>
                    <div class="relative">
                        <CompSelect @change.prevent="getProposals(null)" class="max-w-sm" name="selectSpecialties"
                            :options="allStatus" v-model="selectedStatus" optionSelected="Todos os status " />
                    </div>
                </div>
                <div class="w-96 max-w-full">
                    <CompSearch @submit.prevent="getProposals" name="search" v-model="search" />
                </div>
            </div>
        </div>
    </section>
    <!-- Tabela -->
    <section class="bg-purple-50">
        <div class="container mx-auto px-6 pt-4 pb-12">
            <div class="overflow-x-scroll xl:overflow-hidden rounded-lg border border-white shadow-md">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="flex items-center">
                                    Proposta
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Especialidade
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Inativo
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <span>Status</span>
                            </th>
                            <th scope="col">
                                <div>
                                    Avaliação
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Recebida em
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody v-show="proposals.length > 0" v-for="(tableValue, i) in proposals" :key="i">
                        <tr>
                            <td>
                                <div v-if="tableValue.nome_proposta" class="w-[240px] truncate flex items-center">
                                    {{ tableValue.nome_proposta }}
                                </div>
                            </td>
                            <td>
                                <div class="w-32 truncate">
                                    {{
                                        tableValue.especialidades?.map((obj) => obj.nome).join(", ")
                                    }}
                                </div>
                            </td>
                            <td>
                                <div>
                                    {{ ($moment().diff(tableValue.updated_at, "days")) }}
                                    dias
                                </div>
                            </td>
                            <td>
                                <CompStatus :status="tableValue.status"
                                    :link="{ name: 'painel-inovahc-proposta', params: { id: tableValue?.id } }" />
                            </td>
                            <td>{{ tableValue.rating }}</td>
                            <td>
                                {{ $moment(tableValue.created_at).format('DD/MM/YYYY') }}
                            </td>
                            <td class="relative xl:static">
                                <div class="flex justify-end items-center">
                                    <CompDropdown alignment="right" divStyle="absolute" class="mr-8">
                                        <template #toggle>
                                            <IconsComp icon="more" class="w-6 h-6 fill-green-500" />
                                        </template>
                                        <router-link
                                            v-if="tableValue.status.includes('Selecionado') || tableValue.status.includes('Plano de trabalho pendente') || tableValue.status.includes('Relatório final pendente') || tableValue.status.includes('Descontinuado')"
                                            :to="{
                                                name: 'painel-inovahc-projeto',
                                                params: { id: tableValue.id },
                                            }" class="cursor-pointer">
                                            <li>
                                                <IconsComp icon="eye" />
                                                Ver projeto
                                            </li>
                                        </router-link>
                                        <router-link v-else :to="{
                                            name: 'painel-inovahc-proposta',
                                            params: { id: tableValue.id },
                                        }" class="cursor-pointer">
                                            <li>
                                                <IconsComp icon="eye" />
                                                Ver proposta
                                            </li>
                                        </router-link>
                                        <router-link v-if="tableValue.status.includes('Aguardando classificação')" :to="{
                                            name: 'painel-inovahc-proposta',
                                            params: { id: tableValue.id },
                                        }" class="cursor-pointer">
                                            <li>
                                                <IconsComp icon="download" />
                                                Classificar proposta
                                            </li>
                                        </router-link>
                                        <a v-if="tableValue.status?.includes('Classificada')" class="cursor-pointer">
                                            <li>
                                                <IconsComp icon="download" />Selecionar como
                                                projeto
                                            </li>
                                        </a>
                                        <a v-if="!tableValue.status.includes('Rejeitada') || tableValue.status.includes('Descontinuado')"
                                            @click.prevent="rejectModalIsOpen[i] = !rejectModalIsOpen[i]"
                                            class="cursor-pointer">
                                            <li>
                                                <IconsComp icon="download" />Rejeitar proposta
                                            </li>
                                        </a>
                                    </CompDropdown>
                                    <div v-if="tableValue.avaliacoes?.length > 0" class="ml-auto">
                                        <button @click.prevent="ArrowIcons[i] = !ArrowIcons[i]">
                                            <IconsComp :class="{ 'rotate-180': ArrowIcons[i] }" icon="arrow-down"
                                                class="transition-all duration-300 fill-green-500 w-6 h-6 ml-12 md:ml-4" />
                                        </button>
                                    </div>
                                </div>
                                <CompProposalReject v-model:isOpen="rejectModalIsOpen[i]" :proposalId="tableValue.id"
                                    v-model:updated="rejectModalUpdated" />
                            </td>
                        </tr>
                        <CompAvaliation :avaliations="tableValue.avaliacoes" :isOpen="ArrowIcons[i]" />
                    </tbody>
                    <tbody v-show="proposals.length === 0">
                        <tr>
                            <td colspan="1000">
                                <CompDataLoad :dataLoad="dataLoad" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
    <!--  Paginação -->
    <section class="bg-purple-50">
        <div class="container mx-auto px-6">
            <CompPagination :onLoad="paginationOnLoad" v-model:quantity="perPage" v-model:page="currentPage"
                :firstPage="firstPage" :lastPage="lastPage" />
        </div>
    </section>
</template>
