<script setup>
import { CompPagination, IconsComp, CompSearch, CompTable, CompDropdown, CompBreadCrumbs, CompModal, CompDataLoad } from "@/components";
import { ref, onMounted, watch } from "vue";
import { useEdictStore, useProgramStore } from "@/stores";

const edicts = ref([]);
const programs = ref([]);
const selectedProgram = ref('');
const dataLoad = ref("Carregando..");
const search = ref('')
const currentPage = ref(1);
const oldCurrentPage = ref(1)
const firstPage = ref(1);
const lastPage = ref(1);
const perPage = ref(20);
const paginationOnLoad = ref(false)
const deleteModalIsOpen = ref([]);
const filingModalIsOpen = ref([]);

watch(currentPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getEdicts();
    }
});

watch(perPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getEdicts();
    }
});

onMounted(async () => {
    await getEdicts();
    programs.value = await useProgramStore().all().then(response => response.data);
});

async function getEdicts() {
    dataLoad.value = "Carregando";
    edicts.value = [];

    paginationOnLoad.value = true
    edicts.value = await useEdictStore().search(search.value, selectedProgram.value, perPage.value, currentPage.value !== oldCurrentPage.value ? currentPage.value : 1)

    currentPage.value = edicts.value.meta.current_page;
    oldCurrentPage.value = currentPage.value
    perPage.value = edicts.value.meta.per_page;
    edicts.value = edicts.value.data;

    edicts.value.length === 0
        ? (dataLoad.value = "Nenhum edital encontrado")
        : "";

    paginationOnLoad.value = false
}

async function onDelete(id) {
    await useEdictStore().delete(id).then(async () => {
        await getEdicts();
    });
}

async function onFiling(id) {
    await useEdictStore().filing(id).then(async () => {
        await getEdicts();
    });
}
</script>

<template>
    <section>
        <div class="container mx-auto px-6 pt-10">
            <div class="text-5xl font-bold font-poppins mb-8 text-purple-800">
                Programas
            </div>
            <div>
                <ul class="menu-tabs">
                    <li>
                        <router-link :to="{ name: 'painel-inovahc-programas' }">
                            <span>
                                <IconsComp icon="painel" class="w-6 h-6" />
                            </span>
                            Propostas
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'painel-inovahc-projetos' }">
                            <span>
                                <IconsComp icon="clipboard-line" class="w-6 h-6" />
                            </span>
                            Projetos
                        </router-link>
                    </li>
                    <li class="active">
                        <router-link :to="{ name: 'painel-inovahc-editais' }">
                            <span>
                                <IconsComp icon="document-2" class="w-6 h-6" />
                            </span>
                            Editais
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <!-- Filtro -->
    <section class="bg-purple-50 pt-8">
        <div class="container mx-auto px-6">
            <div class="text-sm text-gray-400">Filtrar editais</div>
            <div class="grid grid-cols-1 md:flex">
                <ul v-if="programs.length === 0" class="filtro-categoria flex animate-pulse">
                    <li v-for="animate in 5" :key="animate" class="bg-gray-400 border-none flex space-x-4 p-4">
                        <div class="animate-pulse flex space-x-4 py-2">
                            <div class="flex-1">
                                <div class="space-y-3">
                                    <div class="grid grid-cols-3 gap-4">
                                        <div class="h-2 bg-gray-400 rounded col-span-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul v-else class="filtro-categoria grid grid-cols-4">
                    <li @click.prevent="selectedProgram = ''; getEdicts()" :class="selectedProgram == '' ? 'ativo' : ''">
                        Todas
                    </li>
                    <li v-for="(program, i) in programs" :key="i" @click.prevent="selectedProgram = program.id; getEdicts()"
                        :class="selectedProgram == program.id ? 'ativo' : ''">
                        {{ program.nome }}
                    </li>
                    <li @click.prevent="selectedProgram = 'fluxo-continuo'; getEdicts('fluxo-continuo')"
                        :class="selectedProgram == 'fluxo-continuo' ? 'ativo' : ''">
                        contínuo
                    </li>
                </ul>
                <div class="ml-auto">
                    <router-link :to="{ name: 'adicionar-edital' }" class="btn primary no-underline">Novo
                        edital</router-link>
                </div>
            </div>
        </div>
    </section>
    <!-- Tabela -->
    <section class="bg-purple-50">
        <div class="container mx-auto px-6 pt-2 pb-6">
            <div class="overflow-x-scroll md:overflow-hidden rounded-lg border border-white shadow-md">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="flex items-center">
                                    Publicação
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Edital
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Prazo de inscrição
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <span>Status</span>
                            </th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody v-show="edicts.length > 0" v-for="(tableValue, i) in edicts" :key="tableValue">
                        <tr>
                            <td>
                                <div class="truncate flex items-center">
                                    {{ tableValue.created_at ? $moment(tableValue.created_at).format('DD/MM/YYYY') : '-' }}
                                </div>
                            </td>
                            <td>
                                <div class="w-32 truncate font-bold">
                                    {{ tableValue.nome }}
                                </div>
                            </td>
                            <td v-if="!tableValue.nome?.includes('fluxo contínuo')">
                                {{ $moment(tableValue.abertura).format('DD/MM/YYYY') }}
                                à
                                {{ $moment(tableValue.encerramento).format('DD/MM/YYYY') }}
                            </td>
                            <td v-else>
                                indeterminado
                            </td>
                            <td>
                                <button v-if="!tableValue.arquivado" type="button" :class="[
                                    tableValue.status === 'Publicado' ? 'tag gray' : '',
                                    tableValue.status === 'Inscrições abertas' ? 'tag blue' : '',
                                    tableValue.status === 'Finalizando seleção' ? 'tag green' : '',
                                    tableValue.status === 'Encerrado' ? 'tag disabled' : '',
                                ]">
                                    {{ tableValue.status }}
                                </button>
                                <button v-else type="button" class="tag disabled">
                                    Arquivado
                                </button>
                            </td>
                            <td class="relative md:static">
                                <div class="flex items-center md:float-right mr-8">
                                    <CompDropdown alignment="right" divStyle="absolute">
                                        <template #toggle>
                                            <IconsComp icon="more" class="w-6 h-6 fill-green-500" />
                                        </template>
                                        <router-link :to="{
                                            name: 'editar-edital',
                                            params: { id: tableValue.id },
                                        }">
                                            <li class="text-purple-800">
                                                Editar edital
                                            </li>
                                        </router-link>
                                        <a @click.prevent="
                                            filingModalIsOpen[i] = !filingModalIsOpen[i]
                                            " class="cursor-pointer">
                                            <li class="text-purple-800">
                                                Arquivar edital
                                            </li>
                                        </a>
                                        <a @click.prevent="
                                            deleteModalIsOpen[i] = !deleteModalIsOpen[i]
                                            " class="cursor-pointer">
                                            <li class="text-purple-800">
                                                Deletar edital
                                            </li>
                                        </a>
                                    </CompDropdown>
                                </div>
                                <CompModal v-model="filingModalIsOpen[i]" :isOpen="filingModalIsOpen[i]"
                                    @close="filingModalIsOpen[i] = !filingModalIsOpen[i]" customClass="validacao">
                                    <div class="text-xl font-poppins font-bold mb-2">
                                        Confirmar ação
                                    </div>
                                    <div class="mb-8">
                                        <p>
                                            Tem certeza que deseja arquivar o
                                            <b>{{ tableValue.nome }}</b>?
                                        </p>
                                        <p>
                                            Clique em “Ok” para arquivar ou em “Cancelar” para
                                            permanecer na página.
                                        </p>
                                    </div>
                                    <div class="flex justify-end">
                                        <button @click.prevent="
                                            filingModalIsOpen[i] = !filingModalIsOpen[i]
                                            " type="button" class="btn secondary">
                                            Cancelar
                                        </button>
                                        <button @click="
                                            onFiling(tableValue.id);
                                        filingModalIsOpen[i] = !filingModalIsOpen[i];
                                        " type="button" class="btn primary">
                                            Ok
                                        </button>
                                    </div>
                                </CompModal>
                                <CompModal v-model="deleteModalIsOpen[i]" :isOpen="deleteModalIsOpen[i]"
                                    @close="deleteModalIsOpen[i] = !deleteModalIsOpen[i]" customClass="validacao">
                                    <div class="text-xl font-poppins font-bold mb-2">
                                        Confirmar ação
                                    </div>
                                    <div class="mb-8">
                                        <p>
                                            Tem certeza que deseja deletar o
                                            <b>{{ tableValue.nome }}</b>?
                                        </p>
                                        <p>
                                            Clique em “Ok” para deletar ou em “Cancelar” para
                                            permanecer na página.
                                        </p>
                                    </div>
                                    <div class="flex justify-end">
                                        <button @click.prevent="
                                            deleteModalIsOpen[i] = !deleteModalIsOpen[i]
                                            " type="button" class="btn secondary">
                                            Cancelar
                                        </button>
                                        <button @click="
                                            onDelete(tableValue.id);
                                        deleteModalIsOpen[i] = !deleteModalIsOpen[i];
                                        " type="button" class="btn primary">
                                            Ok
                                        </button>
                                    </div>
                                </CompModal>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-show="edicts.length === 0">
                        <tr>
                            <td colspan="1000">
                                <CompDataLoad :dataLoad="dataLoad" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
    <!--  Paginação -->
    <section class="bg-purple-50 pb-20 mb-0">
        <div class="container mx-auto px-6">
            <CompPagination :onLoad="paginationOnLoad" v-model:quantity="perPage" v-model:page="currentPage"
                :firstPage="firstPage" :lastPage="lastPage" />
        </div>
    </section>
</template>
