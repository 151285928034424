<script setup>
import CompPagination from "@/components/CompPagination";
import IconsComp from "@/components/IconsComp";
import CompBreadCrumbs from "@/components/CompBreadCrumbs";
import CompModal from "@/components/CompModal";
import { ref, onMounted, watch } from "vue";
import { useProposerStore, useAuthStore, useDraftProposalStore } from "@/stores";
import { CompAvaliation, CompStatus } from "@/components";
import { useRouter } from "vue-router";

const router = useRouter();
const currentPage = ref(1);
const firstPage = ref(1);
const lastPage = ref(1);
const perPage = ref(20);
const paginationOnLoad = ref(false)
const dataLoad = ref("Carregando..");
const discardModalIsOpen = ref([])
const authStore = ref(useAuthStore());
const proposalDataLoad = ref(false);
const proposals = ref([]);
const draftProposals = ref([]);
const isProposals = ref(true);
const showOpnion = ref([]);

onMounted(async () => {
    await getProposals();
    draftProposals.value = await useDraftProposalStore().allWithoutPagination().then(response => response?.data)
});

watch(currentPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getProposals();
    }
});

watch(perPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getProposals();
    }
});

async function getProposals() {
    proposals.value = [];
    dataLoad.value = "Carregando";
    proposalDataLoad.value = false

    paginationOnLoad.value = true
    proposals.value = await useProposerStore().getProposals(perPage.value, currentPage.value)

    currentPage.value = proposals.value.meta.current_page;
    perPage.value = proposals.value.meta.per_page;
    lastPage.value = proposals.value.meta.last_page;
    proposals.value = proposals.value.data;

    proposals.value.length === 0
        ? (dataLoad.value = "Nenhuma proposta encontrada")
        : "";

    paginationOnLoad.value = false
    proposalDataLoad.value = true
}

const editDraftProposal = (programName, draftProposal) => {
    localStorage.setItem("form-" + programName, JSON.stringify(draftProposal.perguntas_e_respostas));
    router.push({ name: 'adicionar-programa-' + programName, params: { editalId: draftProposal.edital?.id } });
}

const edictDraftProposalContinuos = (draftProposal) => {
    localStorage.setItem("form-continuos-flow", JSON.stringify(draftProposal.perguntas_e_respostas));
    router.push({ name: 'adicionar-programa' });
}

const discardProposal = async (id) => {
    await useDraftProposalStore().delete(id);
    router.go(0)
}
</script>

<template>
    <section>
        <div class="container mx-auto px-6 pt-10">
            <div class="hidden md:flex justify-between flex-col md:flex-row items-center mb-8">
                <div>
                    <CompBreadCrumbs>
                        <template v-slot:default>
                            <li>
                                <router-link :to="{ name: 'painel-proponente' }">
                                    <IconsComp icon="painel" class="w-6 h-6" />
                                    Meu painel
                                </router-link>
                            </li>
                        </template>
                    </CompBreadCrumbs>
                </div>
            </div>

            <div class="mb-8">
                <div class="text-5xl font-bold font-poppins text-purple-800">
                    Olá {{ authStore.user?.nome_completo }}
                </div>
            </div>
            <div class="mt-10">
                <ul class="menu-tabs">
                    <li :class="isProposals ? 'active' : ''">
                        <router-link :to="{ name: 'painel-proponente' }" class="cursor-pointer">
                            <span>
                                <IconsComp icon="proposta-line" class="w-6 h-6" />
                            </span>
                            Propostas
                        </router-link>
                    </li>
                    <li :class="!isProposals ? 'active' : ''">
                        <router-link :to="{ name: 'painel-proponente-projetos' }" class="cursor-pointer">
                            <span>
                                <IconsComp icon="projetos-line" class="w-6 h-6" />
                            </span>
                            Projetos
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <!-- Propostas -->
    <section>
        <!-- Tabela -->
        <section class="bg-purple-50 py-8">
            <div v-if="proposals.length === 0" class="container mx-auto my-32 px-6">
                <div class="w-8/12 mx-auto">
                    <p v-if="!proposalDataLoad" class="text-center text-2xl">
                        Carregando..
                    </p>
                    <p v-else class="text-center text-2xl">
                        Você não tem nenhuma proposta associada ao seu cadastro. Acesse
                        nossa página de programas e consulte os editais abertos.
                    </p>
                    <div v-if="proposalDataLoad" class="mt-8 text-center mx-auto px-20">
                        <router-link :to="{ name: 'programas' }" class="no-underline">
                            <button class="btn primary" type="button">Ver programas</button>
                        </router-link>
                    </div>
                </div>
            </div>
            <div v-else class="container mx-auto px-6">
                <div v-for="(tableValue, i) in draftProposals" :key="tableValue"
                    class="card border-l-8 my-8 border-purple-800">
                    <div class="grid grid-cols-4 gap-4">
                        <div class="col-span-3">
                            <div class="font-poppins font-bold text-xl text-purple-800">
                                {{ tableValue.nome_proposta ?? "Proposta sem nome" }}
                            </div>
                            <div class="flex items-center">
                                <div class="text-sm text-gray-400 mr-2">Status</div>
                                <div class="tag gray">
                                    {{ tableValue.status }}
                                </div>
                            </div>
                            <div class="flex items-center">
                                <div class="text-sm text-gray-400 mr-2">Programa</div>
                                <div>
                                    {{
                                        tableValue.edital
                                        ? tableValue.edital?.nome
                                        : "fluxo contínuo"
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="ml-auto w-full">
                            <div v-if="tableValue.edital && tableValue.edital?.programa" class="w-full">
                                <button
                                    @click.prevent="editDraftProposal(tableValue.edital?.programa?.nome.replace('.', '').toLowerCase(), tableValue)"
                                    class="btn primary w-full max-md:small" type="button">
                                    Editar proposta
                                </button>
                            </div>
                            <div v-else class="w-full">
                                <button @click.prevent="edictDraftProposalContinuos(tableValue)"
                                    class="btn primary w-full max-md:small" type="button">
                                    Editar proposta
                                </button>
                            </div>
                            <div class="mt-2">
                                <button @click.prevent="discardModalIsOpen[i] = !discardModalIsOpen[i]"
                                    class="btn secondary w-full">Descartar proposta</button>
                            </div>
                            <CompModal v-model="discardModalIsOpen[i]" :isOpen="discardModalIsOpen[i]"
                                @close="discardModalIsOpen[i] = !discardModalIsOpen[i]" customClass="validacao">
                                <div class="text-xl font-poppins font-bold mb-2">
                                    Descartar proposta
                                </div>
                                <div class="mb-8">
                                    <div class="mb-8">
                                        Você tem certeza que deseja descartar essa
                                        proposta? Essa ação não pode ser desfeita.
                                    </div>
                                </div>
                                <div class="flex justify-end">
                                    <button @click.prevent="
                                        discardModalIsOpen[i] = !discardModalIsOpen[i]
                                        " type="button" class="btn secondary">
                                        Cancelar
                                    </button>
                                    <button
                                        @click.prevent="discardProposal(tableValue.id); discardModalIsOpen[i] = !discardModalIsOpen[i]"
                                        type="button" class="btn primary">
                                        Descartar
                                    </button>
                                </div>
                            </CompModal>
                        </div>
                    </div>
                </div>


                <div v-for="(tableValue, i) in proposals" :key="tableValue" class="card border-l-8 my-8" :class="[
                    tableValue.status === 'Aguardando parecer' ||
                        tableValue.status === 'Selecionar parecerista' ||
                        tableValue.status === 'Triagem pendente' ||
                        tableValue.status === 'Especialidade rejeitada' ||
                        tableValue.status === 'Aguardando classificação'
                        ? 'border-blue-500' :
                        tableValue.status === 'Rejeitada' ? 'border-red-500' : tableValue.status === 'Classificada' ? 'border-green-500' : 'border-purple-800'

                ]">
                    <div class="grid grid-cols-2 lg:grid-cols-4 gap-4">
                        <div class="lg:col-span-3">
                            <div class="font-poppins font-bold lg:text-xl" :class="[
                                tableValue.status === 'Aguardando parecer' ||
                                    tableValue.status === 'Selecionar parecerista' ||
                                    tableValue.status === 'Triagem pendente' ||
                                    tableValue.status === 'Especialidade rejeitada' ||
                                    tableValue.status === 'Aguardando classificação'
                                    ? 'text-blue-500'
                                    :
                                    tableValue.status === 'Rejeitada' ? 'text-red-500' : tableValue.status === 'Classificada' ? 'text-green-500' : 'text-purple-800'
                            ]">
                                {{ tableValue.nome_proposta }}
                            </div>
                            <div class="flex items-center">
                                <div class="text-sm text-gray-400 mr-2 max-md:small">Status</div>
                                <router-link :to="{ name: 'painel-proponente-proposta', params: { id: tableValue.id } }"
                                    class="no-underline">
                                    <button class="max-md:small max-sm:!px-1"
                                        :class="tableValue.status.includes('Triagem pendente') ||
                                            tableValue.status.includes('Aguardando parecer') ||
                                            tableValue.status.includes('Selecionar parecerista') ||
                                            tableValue.status.includes('Especialidade rejeitada') || tableValue.status.includes('Aguardando classificação') ? 'tag blue' :
                                            tableValue.status.includes('Rejeitada') ? 'tag red' : tableValue.status.includes('Classificada') ? 'tag green' : 'tag purple'">
                                        {{
                                            tableValue.status.includes('Triagem pendente') ||
                                            tableValue.status.includes('Aguardando parecer') ||
                                            tableValue.status.includes('Selecionar parecerista') ||
                                            tableValue.status.includes('Especialidade rejeitada') ||
                                            tableValue.status.includes('Aguardando classificação') ? 'Em avaliação'
                                            : tableValue.status.includes('Rejeitada') ? 'Rejeitada'
                                                : tableValue.status.includes('Classificada') ? 'Classificada' : 'Pendente'
                                        }}
                                    </button>
                                </router-link>
                            </div>
                            <div class="flex items-center">
                                <div class="text-sm text-gray-400 mr-2">Programa</div>
                                <div>
                                    {{
                                        tableValue.edital
                                        ? tableValue.edital?.nome
                                        : "fluxo contínuo"
                                    }}
                                </div>
                            </div>
                            <div v-if="tableValue.status === 'Aguardando parecer' && tableValue.avaliacoes?.length > 0 || tableValue.status === 'Aguardando classificação' && tableValue.avaliacoes?.length > 0 || tableValue.status === 'Classificada' && tableValue.avaliacoes?.length > 0"
                                class="flex items-center mt-4">
                                <IconsComp icon="editais-line" class="fill-green-500 w-6 h-6 mr-2" />
                                <a @click.prevent="showOpnion[i] = !showOpnion[i]" class="cursor-pointer no-underline">{{
                                    showOpnion[i] ? "Ocultar parecer" : "Ver parecer" }}</a>
                            </div>
                        </div>
                        <div class="ml-auto lg:w-full">
                            <div class="">
                                <router-link :to="{
                                    name: 'painel-proponente-proposta',
                                    params: { id: tableValue.id },
                                }" class=" no-underline">
                                    <button class="btn primary max-md:small max-sm:!px-1 lg:w-full" type="button">
                                        {{
                                            tableValue.status === "Aguardando parecer" ||
                                            tableValue.status === "Selecionar parecerista" ||
                                            tableValue.status === "Aguardando classificação" ||
                                            tableValue.status === "Classificada"
                                            ? "Visualizar proposta"
                                            : ""
                                        }}
                                        {{
                                            tableValue.status === "Rejeitada"
                                            ? "Revisar proposta"
                                            : ""
                                        }}
                                        {{
                                            tableValue.status === "Triagem pendente"
                                            ? "Visualizar proposta"
                                            : ""
                                        }}
                                    </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div v-if="showOpnion[i]"
                        class="mt-4 overflow-x-scroll lg:overflow-hidden rounded-lg border border-white shadow-md">
                        <table class="table min-w-[800px]">
                            <tbody>
                                <CompAvaliation :avaliations="tableValue.avaliacoes" :isOpen="showOpnion[i]" />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!--  Paginação -->
            <div v-if="proposals.length > 0" class="pb-10">
                <div class="container mx-auto px-6">
                    <CompPagination :onLoad="paginationOnLoad" v-model:quantity="perPage" v-model:page="currentPage"
                        :firstPage="firstPage" :lastPage="lastPage" />
                </div>
            </div>
        </section>
    </section>

    <!-- Projetos -->
</template>
