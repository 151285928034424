<script setup>
import CompCheckbox from "@/components/CompCheckbox";
import CompSelect from "@/components/CompSelect";
import CompMultiSelect from "@/components/CompMultiSelect";
import CompTextArea from "@/components/CompTextArea";
import { ref, onMounted } from "vue";
import { useSpecialtyStore } from "@/stores";

const props = defineProps({
    values: {},
});

const specialties = ref([]);

onMounted(async () => {
    specialties.value = await useSpecialtyStore().allWithoutPagination().then(response => response?.data);
});

const project_features = ref([
    "Software",
    "Serviço",
    "Processo",
    "Produto digital",
    "Produto físico",
    "Algorítimo de inteligência artificial",
    "Biotecnologia",
]);

const expected_outcome = ref([
    "Indefinido",
    "Licenciar a solução para outra empresa e/ou instituição",
    "Alcançar a primeira venda/contrato da minha startup",
    "Amadurecer o modelo de negócios",
    "Amadurecer a tecnologia da startup",
    "Implementação da solução na gestão pública",
    "Outro",
]);

const form = ref({
    project_name: props.values.project_name,
    specialty_areas: props.values.specialty_areas,
    secondary_specialty_areas: props.values.secondary_specialty_areas,
    project_feature: props.values.project_feature,
    project_description: props.values.project_description,
    project_problems: props.values.project_problems,
    project_solutions: props.values.project_solutions,
    people_affected: props.values.people_affected,
    project_offer: props.values.project_offer,
    project_scale: props.values.project_scale,
});
</script>

<template>
    <div class="mb-16">
        <div class="text-3xl font-poppins font-bold text-purple-800 mb-2">
            Informações do projeto de desenvolvimento
        </div>
        <p class="mb-8">
            Precisamos de algumas informações sobre o projeto para podermos avaliá-lo
            :)
        </p>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Nome do projeto
                <span class="ml-auto asterisk"></span>
            </div>
            <p>Máximo 280 caracteres</p>
            <CompTextArea name="project_name" v-model="form.project_name" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Área principal de especialidade em saúde do projeto
                <span class="ml-auto asterisk"></span>
            </div>

            <CompSelect name="specialty_areas" :options="specialties" optionDisabled="Selecione uma especialidade"
                optionName="nome" v-model="form.specialty_areas" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Selecione as áreas secundárias de especialidade em saúde do projeto
                <span class="ml-auto asterisk"></span>
            </div>

            <CompMultiSelect name="secondary_specialty_areas" optionDisabled="Selecione uma ou mais especialidades"
                v-model="form.secondary_specialty_areas" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-6">
                Do ponto de vista tecnológico o projeto se caracteriza como um:
                <span class="ml-auto asterisk"></span>
            </div>

            <CompCheckbox name="project_feature" :checkboxes="project_features" v-model="form.project_feature" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Descreva seu projeto
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Descreva em até 1500 caracteres do que se trata seu projeto, no que ele
                consiste (e.g., dispositivo, software, algoritmo de IA, formulação
                química, etc), qual sua natureza (e.g., novo método, procedimento,
                abordagem, tecnologia, etc), qual sua finalidade, assim como outras
                eventuais informações que o caracterizem.
            </p>
            <CompTextArea name="project_description" v-model="form.project_description" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Qual problema seu projeto resolve?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Dica: o problema pode ser entendido como o evento alvo que seu projeto
                deseja resolver. Pode ser uma doença, uma consequência (física, social,
                emocional) dela, um processo ou método atual ineficiente, etc; em resumo
                - uma necessidade ainda não atendida que um indivíduo ou organização
                apresente. Além disso, descreva também a sua relevância dentro do
                contexto da saúde pública.
            </p>
            <CompTextArea name="project_problems" v-model="form.project_problems" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Como o projeto pretende resolver esse problema?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Dica: descreva qual o funcionamento e objetivo/finalidade da invenção
                proposta pelo projeto, evidenciando de que maneira - dentro do contexto
                do problema – ela resolverá ou mitigará o problema. E.g.: “um hardware
                que irá medir... a fim de...”, “um novo processo que permitirá... que
                evita...”, “redução de... através de um método de diagnóstico que...”.
            </p>
            <CompTextArea name="project_solutions" v-model="form.project_solutions" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Quem são e qual o número de potenciais pessoas atingidas por esse
                problema?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Quem são as pessoas atingidas pelo problema relatado? Quais suas
                funções, dores e necessidades? Qual o número de pessoas acometidas pelo
                problema descrito na pergunta anterior? Quais são as características
                gerais dessas pessoas?
            </p>
            <CompTextArea name="people_affected" v-model="form.people_affected" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Quais são as oportunidades de aplicação do projeto e por que ele é
                inovador?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Descreva em no máximo 1500 caracteres de que forma a solução a ser
                desenvolvida será inserida no mercado da saúde pública, destacando onde
                pode ser aplicada, seu diferencial frente a concorrentes existentes,
                produtos e/ou serviços concorrentes.
            </p>
            <CompTextArea name="project_offer" v-model="form.project_offer" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Como a solução proposta poderia ser escalada ou replicada para
                diferentes áreas da saúde pública ou para além desta? Levante quais são
                os desafios para alcançar o mercado.
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Dica: descreva o potencial da solução ser escalável (i.e., pode ser
                expandida e ser reproduzida em grandes quantidades, considerando o
                público alvo e os elementos que influenciem a chegada até o mesmo) ou em
                que grau é replicável nos diferentes níveis de saúde pública ou mesmo em
                outros setores (e.g., agritech, foodtech, biotech, etc.), assim como os
                desafios que a equipe identifica para alcançar o mercado. (até 1000
                caracteres)
            </p>
            <CompTextArea name="project_scale" v-model="form.project_scale" />
        </div>
    </div>
</template>
