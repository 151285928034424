<script setup>
import { ref, onMounted, onUnmounted, watch } from "vue";
import IconsComp from "@/components/IconsComp";
import CompProgress from "@/components/CompProgress";
import {
    ContinuosFlowStep1View,
    ContinuosFlowStep2View,
    ContinuosFlowStep3View,
    ContinuosFlowStep4View,
    ContinuosFlowStep5View,
    ContinuosFlowStep6View,
} from "./continuos_flow_steps/index";
import { useProposalStore, useAuthStore, useDraftProposalStore, useAlertStore, useFormDownloadStore } from "@/stores";
import { Form, useForm } from "vee-validate";
import * as yup from "yup";
import { ptForm } from "yup-locale-pt";
import { schema, currentStep } from '@/schemas/continuosflowSteps'
import { useRouter, useRoute } from "vue-router";
import axios from "axios";

const route = useRoute();
const router = useRouter();
const formDownload = ref(null);

yup.setLocale(ptForm);

const steps = ref([
    "Introdução",
    "Equipe",
    "Estágio da solução",
    "Projeto",
    "Pitch",
]);

const nextStep = () => {
    if (currentStep.value < steps.value.length) {
        currentStep.value++;
        window.scroll({ top: 0, behavior: "smooth" });
    } else if (currentStep.value === steps.value.length) {
        currentStep.value++;
    }
};

const prevStep = () => {
    if (currentStep.value > 1) {
        currentStep.value--;
    }
};

const { errors, values, handleSubmit, resetForm } = useForm({
    validationSchema: schema,
    keepValuesOnUnmount: true,
    keepValuesOnMount: true,
});

onMounted(async () => {
    formDownload.value = await useFormDownloadStore().all().then(response => response?.data?.find(obj => obj.nome == "Fluxo continuo"));
    const draftProposal = await useDraftProposalStore().findByEdict().then(response => response?.data);
    if (!draftProposal && localStorage.getItem("form-continuos-flow") !== null) {
        localStorage.removeItem("form-continuos-flow");
    } else {
        resetForm({ values: JSON.parse(localStorage.getItem("form-continuos-flow")), force: true });
    }

    values["edict"] = "continuos-flow";
});

onUnmounted(() => {
    localStorage.removeItem("form-continuos-flow");
});
const saveDraftProposal = async () => {
    await useDraftProposalStore().store(values).then(() => {
        router.push({ name: 'painel-proponente' })
    });
};

watch(errors, (newValue, oldValue) => {
    if (newValue != oldValue) {
        const firstErrorFieldName = Object.keys(errors.value)[0];
        const el = document.querySelector(`[name="${firstErrorFieldName}"]`);
        if (el) {
            el.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
            });
        }
    }
});

const onSubmit = handleSubmit(async (values, { resetForm }) => {

    if (currentStep.value == 5) {
        const alertStore = useAlertStore();
        alertStore.success("Criando proposta...");

        await useProposalStore().store(values).then(async (response) => {
            if (response?.proposta?.id && values['business_model']) {
                await useProposalStore().storeFile(response.proposta?.id, values);
            }
            if (response?.proposta?.id && values['pitch']) {
                await useProposalStore().storeFile(response.proposta?.id, values);
            }

            nextStep();
            resetForm();
            localStorage.removeItem("form-continuos-flow");
            const alertStore = useAlertStore()
            alertStore.success(response?.message)
        });

        return;
    }

    nextStep();
});
</script>
<template>
    <section v-if="currentStep > 0 && currentStep <= steps.length">
        <div class="container mx-auto px-6 pt-12 pb-10 md:w-8/12">
            <div class="mb-16">
                <CompProgress :currentStep="currentStep" :steps="steps" />
            </div>

            <div class="text-5xl font-bold font-poppins text-purple-800 text-center">
                Avaliação sob demanda de propostas de projetos
            </div>
        </div>
    </section>
    <section v-if="currentStep > 0 && currentStep <= steps.length" class="bg-purple-50 bg-opacity-80">
        <form @submit="onSubmit" keep-values>
            <div class="container mx-auto px-6 py-10 md:w-8/12">
                <ContinuosFlowStep1View v-if="currentStep === 1" :values="values">
                    <div class="flex mt-4 mb-16">
                        <button type="submit" class="btn primary max-w-4">
                            Preencher formulário de incrição
                        </button>
                        <a :href="formDownload?.url" target="_blank" class="no-underline cursor-pointer">
                            <button type="button" class="btn secondary flex">
                                Baixar formulário
                                <IconsComp icon="download" class="fill-green-500 w-6 h-6 ml-4" />
                            </button>
                        </a>
                    </div>
                </ContinuosFlowStep1View>

                <ContinuosFlowStep2View v-if="currentStep === 2" :values="values" />

                <ContinuosFlowStep3View v-if="currentStep === 3" :values="values" />

                <ContinuosFlowStep4View v-if="currentStep === 4" :values="values" />

                <ContinuosFlowStep5View v-if="currentStep === 5" :values="values" />

                <div v-if="currentStep > 1 && currentStep <= steps.length">
                    <div class="flex my-8">
                        <button @click.prevent="prevStep" type="button" class="btn medium secondary">
                            Voltar
                        </button>
                        <button type="submit" v-if="currentStep === steps.length" class="flex btn medium primary">
                            Enviar proposta
                            <IconsComp icon="arrow-left" class="ml-2 fill-white w-6 h-6" />
                        </button>
                        <button type="submit" v-else class="flex btn medium primary">
                            Avançar
                            <IconsComp icon="arrow-full-right" class="ml-2 fill-white w-6 h-6" />
                        </button>
                        <a @click.prevent="saveDraftProposal"
                            class="flex items-center ml-auto no-underline font-bold cursor-pointer">
                            Salvar rascunho
                            <IconsComp icon="save" class="ml-2 fill-green-500 w-6 h-6" />
                        </a>
                    </div>
                    <p class="flex text-red-500">
                        <span class="asterisk mr-2"></span>
                        Campo obrigatório
                    </p>
                </div>
            </div>
        </form>
    </section>

    <section class="h-full">
        <ContinuosFlowStep6View v-if="currentStep === 6" />
    </section>
</template>
