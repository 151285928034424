<script setup>
import CompFile from "@/components/CompFile";
import CompTextArea from "@/components/CompTextArea";
import CompModal from "@/components/CompModal";
import CompDropdown from "@/components/CompDropdown";
import CompToogle from "@/components/CompToogle";
import IconsComp from "@/components/IconsComp";
import { onMounted, ref, watch } from "vue";
import { useAuthStore, useProjectStore } from "@/stores";
import { useRouter } from "vue-router";
import { useForm, Field, Form } from "vee-validate";
import * as yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { CompSelect } from ".";

yup.setLocale(ptForm);

const router = useRouter();
const documentModal = ref(false);
const workPlanUpdateModal = ref(false);
const workPlanUpdateConfirm = ref(false);
const workPlandeleteConfirm = ref(false);
const finalReportUpdateModal = ref(false);
const finalReportUpdateConfirm = ref(false)
const finalReportDeleteConfirm = ref(false)
const documentUpdateModal = ref([]);
const documentUpdateConfirm = ref([]);
const deleteDocumentConfirm = ref([])
const work_plan = ref(false);
const final_report = ref(false);
const requested_work_plan = ref(false);
const requested_final_report = ref(false);
const user = useAuthStore().user;
const form = ref({
    document_type: null,
    document: null,
    description: '',
});
const formUpdate = ref({
    document_type: null,
    document: null,
    description: '',
});

const props = defineProps({
    projectId: {
        type: Number,
        required: true,
    },
    documents: {
        type: Array,
        required: true,
        default: [],
    },
    edict: {
        ttype: [Object, String],
        required: true,
    },
    updated: {
        type: Boolean,
        default: false,
    }
})

const emits = defineEmits(['update:updated']);

const schema = yup.object({
    document_type: yup.string().required(),
    document: yup.mixed().required(),
    description: yup.string().nullable(),
});

const { errors, values, handleSubmit, resetForm } = useForm({
    validationSchema: schema,
    keepValuesOnUnmount: true,
    keepValuesOnMount: true,
});

if (props.documents.find((obj) => obj.tipo.includes("Plano de trabalho"))) {
    work_plan.value = true;
    requested_work_plan.value = true
}

if (props.documents.find((obj) => obj.tipo.includes("Relatório final"))) {
    final_report.value = true;
    requested_final_report.value = true
}

async function addDocument() {
    await useProjectStore().sendDocument(props.projectId, form.value).then(async () => {
        emits('update:updated', !props.updated)
        documentModal.value = false
    })
}

async function updateDocument(documentId) {
    await useProjectStore().updateDocument(props.projectId, documentId, values).then(async () => {
        emits('update:updated', !props.updated)
        form.document_type = null
        formUpdate.document_type = null
        documentUpdateConfirm.value = []
        workPlanUpdateConfirm.value = false
        finalReportUpdateConfirm.value = false
    })
}

async function deleteDocument(documentId) {
    await useProjectStore().deleteDocument(props.projectId, documentId).then(async (response) => {
        if (response.data.tipo == "Plano de trabalho" && work_plan.value) {
            emits('update:updated', !props.updated)
            work_plan.value = false;
            requested_work_plan.value = false
        } else if (response.data.tipo == "Relatório final" && final_report.value) {
            emits('update:updated', !props.updated)
            final_report.value = false;
            requested_final_report.value = false
        }
    })
}

async function documentRequest(document) {
    if (document == "Plano de trabalho" && !work_plan.value) {
        await useProjectStore().documentRequest(props.projectId, "Plano de trabalho").then(response => {
            emits('update:updated', !props.updated)
            work_plan.value = true;
            requested_work_plan.value = true
        });
    } else if (document == "Relatório final" && !final_report.value) {
        await useProjectStore().documentRequest(props.projectId, "Relatório final").then(response => {
            emits('update:updated', !props.updated)
            final_report.value = true;
            requested_final_report.value = true
        });
    }
}
</script>
<template>
    <div>
        <div>
            <div class="card">
                <div class="text-2xl font-poppins font-bold text-purple-800 mb-6">
                    Documentos do projeto
                </div>
                <div v-if="user.tipo?.includes('monitor') || user.tipo?.includes('super-admin')" class="mb-6">
                    <div class="mb-4">
                        <CompToogle @click.prevent="!requested_work_plan ? documentRequest('Plano de trabalho') : ''"
                            :blocked="requested_work_plan" v-model="work_plan" texto="Solicitar plano de trabalho" />
                    </div>
                    <div class="mb-4">
                        <CompToogle @click.prevent="!requested_final_report ? documentRequest('Relatório final') : ''"
                            :blocked="requested_final_report" v-model="final_report" texto="Solicitar relatório final" />
                    </div>
                </div>
                <div v-else-if="user.tipo?.includes('proponente') && documents.filter(obj => obj).length === 0"
                    class="mb-6">
                    O monitor do projeto ainda não autorizou a submissão de
                    documentos.
                </div>
                <button v-if="documents.length > 0 || user.tipo?.includes('monitor') || user.tipo?.includes('super-admin')"
                    @click.prevent="documentModal = !documentModal" type="button" class="btn primary w-full">
                    Adicionar documento
                </button>
                <div v-if="documents?.find(obj => obj.tipo == 'Relatório final')?.arquivo">
                    <div class="text-purple-500 text-xl my-4">Relatório final</div>
                    <div class="flex">
                        <div>
                            <div class="text-sm font-bold text-purple-800">
                                {{ documents?.find(obj => obj.tipo == "Relatório final")?.nome_do_arquivo
                                }}
                            </div>
                            <div class="text-sm text-gray-400">
                                Atualizado em {{ $moment(documents?.find(obj =>
                                    obj.tipo == 'Relatório final')?.enviado_em).format('DD/MM/YYYY') }}
                            </div>
                        </div>
                        <div class="ml-auto">
                            <CompDropdown alignment="right">
                                <template #toggle>
                                    <IconsComp icon="more" class="w-6 h-6 fill-green-500 rotate-90" />
                                </template>
                                <a @click.prevent="finalReportUpdateModal = !finalReportUpdateModal" class="cursor-pointer">
                                    <li>
                                        <IconsComp icon="download" />Editar
                                    </li>
                                </a>
                                <a v-if="documents?.find(obj => obj.tipo == 'Relatório final').arquivo"
                                    class="cursor-pointer" target="_blank"
                                    :href="documents?.find(obj => obj.tipo == 'Relatório final').arquivo">
                                    <li>
                                        <IconsComp icon="download" />Baixar
                                    </li>
                                </a>
                                <a @click.prevent="finalReportDeleteConfirm = !finalReportDeleteConfirm"
                                    class="cursor-pointer">
                                    <li>
                                        <IconsComp icon="download" />Excluir
                                    </li>
                                </a>
                            </CompDropdown>
                        </div>
                        <CompModal v-model="finalReportDeleteConfirm" :isOpen="finalReportDeleteConfirm"
                            @close="finalReportDeleteConfirm = !finalReportDeleteConfirm" customClass="validacao">
                            <div class="text-xl font-poppins font-bold mb-2">
                                Excluir {{ documents?.find(obj => obj.tipo == "Relatório final")?.tipo }}
                            </div>
                            <div class="mb-8">
                                Você tem certeza que deseja excluir o arquivo
                                {{ documents?.find(obj => obj.tipo == "Relatório final")?.nome_do_arquivo
                                }} ?
                            </div>
                            <div class="flex justify-end">
                                <button @click.prevent="finalReportDeleteConfirm = !finalReportDeleteConfirm" type="button"
                                    class="btn secondary">
                                    Cancelar
                                </button>
                                <button type="button" @click.prevent="
                                    deleteDocument(documents?.find(obj => obj.tipo == 'Relatório final')?.id);
                                finalReportDeleteConfirm = !finalReportDeleteConfirm;
                                " class="btn primary">
                                    Excluir
                                </button>
                            </div>
                        </CompModal>
                    </div>
                    <div class="text-sm text-gray-500 mt-2">
                        {{ documents?.find(obj => obj.tipo == "Relatório final")?.descricao }}
                    </div>
                </div>
                <div v-if="documents?.find(obj => obj.tipo == 'Plano de trabalho')?.arquivo">
                    <div class="text-purple-500 text-xl my-4">Plano de trabalho</div>
                    <div class="flex">
                        <div>
                            <div class="text-sm font-bold text-purple-800">
                                {{ documents?.find(obj =>
                                    obj.tipo == "Plano de trabalho")?.nome_do_arquivo }}
                            </div>
                            <div class="text-sm text-gray-400">
                                Atualizado em {{ $moment(documents?.find(obj =>
                                    obj.tipo == "Plano de trabalho")?.enviado_em).format('DD/MM/YYYY') }}
                            </div>
                        </div>
                        <div class="ml-auto">
                            <CompDropdown alignment="right">
                                <template #toggle>
                                    <IconsComp icon="more" class="w-6 h-6 fill-green-500 rotate-90" />
                                </template>
                                <a @click.prevent="workPlanUpdateModal = !workPlanUpdateModal" class="cursor-pointer">
                                    <li>
                                        <IconsComp icon="download" />Editar
                                    </li>
                                </a>
                                <a v-if="documents?.find(obj => obj.tipo == 'Plano de trabalho').arquivo"
                                    class="cursor-pointer" target="_blank"
                                    :href="documents?.find(obj => obj.tipo == 'Plano de trabalho').arquivo">
                                    <li>
                                        <IconsComp icon="download" />Baixar
                                    </li>
                                </a>
                                <a @click.prevent="workPlandeleteConfirm = !workPlandeleteConfirm" class="cursor-pointer">
                                    <li>
                                        <IconsComp icon="download" />Excluir
                                    </li>
                                </a>
                            </CompDropdown>
                        </div>
                        <CompModal v-model="workPlandeleteConfirm" :isOpen="workPlandeleteConfirm"
                            @close="workPlandeleteConfirm = !workPlandeleteConfirm" customClass="validacao">
                            <div class="text-xl font-poppins font-bold mb-2">
                                Excluir {{ documents?.find(obj => obj.tipo == "Plano de trabalho")?.tipo
                                }}
                            </div>
                            <div class="mb-8">
                                Você tem certeza que deseja excluir o arquivo
                                {{ documents?.find(obj =>
                                    obj.tipo == "Plano de trabalho")?.nome_do_arquivo }} ?
                            </div>
                            <div class="flex justify-end">
                                <button @click.prevent="workPlandeleteConfirm = !workPlandeleteConfirm" type="button"
                                    class="btn secondary">
                                    Cancelar
                                </button>
                                <button type="button" @click.prevent="
                                    deleteDocument(documents?.find(obj => obj.tipo == 'Plano de trabalho').id);
                                workPlandeleteConfirm = !workPlandeleteConfirm;
                                " class="btn primary">
                                    Excluir
                                </button>
                            </div>
                        </CompModal>
                    </div>
                    <div class="text-sm text-gray-500 mt-2">
                        {{ documents?.find(obj => obj.tipo == "Plano de trabalho")?.descricao }}
                    </div>
                </div>
                <div v-if="documents?.find(obj => obj.tipo == 'Anexos')?.arquivo">
                    <div class="text-purple-500 text-xl my-4">Anexos</div>
                    <div v-for="(anexo, i) in                      documents?.filter(obj => obj.tipo == 'Anexos')                     "
                        :key="anexo" class="mb-4">
                        <div class="flex">
                            <div>
                                <div class="text-sm font-bold text-purple-800">
                                    {{ anexo.nome_do_arquivo }}
                                </div>
                                <div class="text-sm text-gray-400">
                                    Atualizado em {{ $moment(anexo.enviado_em).format('DD/MM/YYYY') }}
                                </div>
                            </div>
                            <div class="ml-auto">
                                <CompDropdown alignment="right">
                                    <template #toggle>
                                        <IconsComp icon="more" class="w-6 h-6 fill-green-500 rotate-90" />
                                    </template>
                                    <a @click.prevent="documentUpdateModal[i] = !documentUpdateModal[i]"
                                        class="cursor-pointer">
                                        <li>
                                            <IconsComp icon="download" />Editar
                                        </li>
                                    </a>
                                    <a v-if="anexo.arquivo" class="cursor-pointer" target="_blank" :href="anexo.arquivo">
                                        <li>
                                            <IconsComp icon="download" />Baixar
                                        </li>
                                    </a>
                                    <a @click.prevent="deleteDocumentConfirm[i] = !deleteDocumentConfirm[i]"
                                        class="cursor-pointer">
                                        <li>
                                            <IconsComp icon="download" />Excluir
                                        </li>
                                    </a>
                                </CompDropdown>
                            </div>
                            <CompModal v-model="deleteDocumentConfirm[i]" :isOpen="deleteDocumentConfirm[i]"
                                @close="deleteDocumentConfirm[i] = !deleteDocumentConfirm[i]" customClass="validacao">
                                <div class="text-xl font-poppins font-bold mb-2">
                                    Excluir {{ anexo?.tipo }}
                                </div>
                                <div class="mb-8">
                                    Você tem certeza que deseja excluir o arquivo
                                    {{ anexo?.nome_do_arquivo }} ?
                                </div>
                                <div class="flex justify-end">
                                    <button @click.prevent="deleteDocumentConfirm[i] = !deleteDocumentConfirm[i]"
                                        type="button" class="btn secondary">
                                        Cancelar
                                    </button>
                                    <button type="button" @click.prevent="
                                        deleteDocument(anexo.id);
                                    deleteDocumentConfirm[i] = !deleteDocumentConfirm[i];
                                    " class="btn primary">
                                        Excluir
                                    </button>
                                </div>
                            </CompModal>
                        </div>
                        <div class="text-sm text-gray-500 mt-2">
                            {{ anexo.descricao }}
                        </div>
                        <form @submit.prevent="updateDocument(anexo.id)">
                            <CompModal :isOpen="documentUpdateModal[i]"
                                @close="documentUpdateModal[i] = !documentUpdateModal[i]" customClass="triagem">
                                <button @click="documentUpdateModal[i] = !documentUpdateModal[i]"
                                    class="absolute right-5 top-5">
                                    <IconsComp icon="close-filled" class="w-6 h-6 fill-green-500" />
                                </button>

                                <div class="text-3xl text-purple-800 font-bold font-poppins mb-2">
                                    Enviar arquivos
                                </div>
                                <p>
                                    Nesta etapa você poderá submeter os principais documentos e arquivos relativos ao seu
                                    projeto tais como plano de trabalho, contratos e outros.
                                </p>

                                <div class="mb-8">
                                    <div class="font-bold text-purple-800 mt-2 mb-1">Tipo de arquivo</div>
                                    <Field :rules="yup.string().required()" name="document_type" v-slot="{ field, errors }">
                                        <div class="relative">
                                            <select v-bind="field" v-model="formUpdate.document_type"
                                                :class="errors[0] ? '!border-red-500' : ''"
                                                @change.prevent="formUpdate.document = null">
                                                <option value="Anexos" selected>Anexos</option>
                                            </select>
                                            <label></label>
                                        </div>
                                        <div class="my-1 peer-invalid:flex text-red-500 text-sm">
                                            {{ errors[0] }}
                                        </div>
                                    </Field>
                                    <div class="my-2">
                                        <CompFile :schema="yup.mixed().required()" name="document" id="document"
                                            v-model="formUpdate.document" />
                                    </div>

                                    <div class="font-bold text-purple-800 mt-4 mb-2">Descrição(opcional)</div>
                                    <CompTextArea :schema="yup.string().nullable()" name="description"
                                        v-model="anexo.descricao" />
                                </div>

                                <div class="flex justify-end gap-4">
                                    <button @click.prevent="documentUpdateModal[i] = !documentUpdateModal[i];" type="button"
                                        class="btn secondary w-full">
                                        Cancelar
                                    </button>
                                    <button @click.prevent="
                                        documentUpdateModal[i] = !documentUpdateModal[i];
                                    documentUpdateConfirm[i] = !documentUpdateConfirm[i]
                                        " type="button" class="btn primary w-full">
                                        Enviar
                                    </button>
                                </div>
                            </CompModal>

                            <CompModal v-model="documentUpdateConfirm[i]" :isOpen="documentUpdateConfirm[i]"
                                @close="documentUpdateConfirm[i] = !documentUpdateConfirm[i]" customClass="validacao">
                                <div class="text-xl font-poppins font-bold mb-2">
                                    Substituir {{ anexo?.tipo }}
                                </div>
                                <div class="mb-8">
                                    Você tem certeza que deseja substituir o arquivo
                                    {{ anexo?.nome_do_arquivo }} ?
                                </div>
                                <div class="flex justify-end">
                                    <button @click.prevent="documentUpdateConfirm[i] = !documentUpdateConfirm[i]"
                                        type="button" class="btn secondary">
                                        Cancelar
                                    </button>
                                    <button type="submit" class="btn primary">
                                        Substituir
                                    </button>
                                </div>
                            </CompModal>
                        </form>
                    </div>
                </div>
                <hr class="my-6" />
                <div class="font-poppins text-xl font-light text-purple-800 mb-2">
                    Downloads úteis
                </div>
                <ul class="lista-dowloads">
                    <a :href="edict?.plano_trabalho ? edict?.plano_trabalho : 'https://inovahc-back.tapronto.com.br/faker-docs/modelo.pdf'"
                        target="_blank">
                        <li>
                            Modelo plano de trabalho
                            <span>
                                <IconsComp icon="download" class="ml-2" />
                            </span>
                        </li>
                    </a>
                    <a :href="edict?.relatorio_final_projeto ? edict?.relatorio_final_projeto : 'https://inovahc-back.tapronto.com.br/faker-docs/modelo.pdf'"
                        target="_blank">
                        <li>
                            Modelo relatório final<span>
                                <IconsComp icon="download" class="ml-2" />
                            </span></li>
                    </a>
                </ul>
            </div>
        </div>


        <CompModal :isOpen="documentModal" @close="documentModal = !documentModal" customClass="triagem">
            <Form @submit="addDocument">
                <button @click="documentModal = !documentModal" class="absolute right-5 top-5">
                    <IconsComp icon="close-filled" class="w-6 h-6 fill-green-500" />
                </button>

                <div class="text-3xl text-purple-800 font-bold font-poppins mb-2">
                    Enviar arquivos
                </div>
                <p>
                    Nesta etapa você poderá submeter os principais documentos e arquivos relativos ao seu projeto tais como
                    plano de trabalho, contratos e outros.
                </p>

                <div class="mb-8">
                    <div class="font-bold text-purple-800 mt-2 mb-1">Tipo de arquivo</div>
                    <CompSelect :schema="yup.string().required()"
                        :options="documents?.find(obj => obj.status.includes('Pendente') && obj.tipo.includes('Plano de trabalho')) ? ['Plano de trabalho', 'Anexos'] : documents?.find(obj => obj.status.includes('Pendente') && obj.tipo.includes('Relatório final')) ? ['Relatório final', 'Anexos'] : ['Anexos']"
                        name="document_type" v-model="form.document_type" />
                    <div class="my-2">
                        <CompFile :schema="yup.mixed().required()" name="document" id="document" v-model="form.document"
                            :documentTypes="form.document_type == 'Plano de trabalho' || form.document_type == 'Relatório final' ? '.doc,.docx,.pdf' : ''" />
                    </div>

                    <div class="font-bold text-purple-800 mt-4 mb-2">Descrição(opcional)</div>
                    <CompTextArea :schema="yup.string().nullable()" name="description" v-model="form.description" />
                </div>

                <div class="flex justify-end gap-4">
                    <button @click.prevent="documentModal = !documentModal;" type="button" class="btn secondary w-full">
                        Cancelar
                    </button>
                    <button type="submit" class="btn primary w-full">
                        Enviar
                    </button>
                </div>
            </Form>
        </CompModal>
        <CompModal :isOpen="workPlanUpdateModal" @close="workPlanUpdateModal = !workPlanUpdateModal" customClass="triagem">
            <Form @submit="workPlanUpdateModal = !workPlanUpdateModal;
            workPlanUpdateConfirm = !workPlanUpdateConfirm">
                <button @click="workPlanUpdateModal = !workPlanUpdateModal" class="absolute right-5 top-5">
                    <IconsComp icon="close-filled" class="w-6 h-6 fill-green-500" />
                </button>

                <div class="text-3xl text-purple-800 font-bold font-poppins mb-2">
                    Enviar arquivos
                </div>
                <p>
                    Nesta etapa você poderá submeter os principais documentos e arquivos relativos ao seu projeto tais como
                    plano de trabalho, contratos e outros.
                </p>

                <div class="mb-8">
                    <div class="font-bold text-purple-800 mt-2 mb-1">Tipo de arquivo</div>
                    <CompSelect :schema="yup.string().required()" :options="['Plano de trabalho']" name="document_type"
                        v-model="formUpdate.document_type" @change.prevent="formUpdate.document = null" />
                    <div class="my-2">
                        <CompFile :schema="yup.mixed().required()" name="document" id="document"
                            v-model="formUpdate.document" :documentTypes="'.doc,.docx,.pdf'" />
                    </div>

                    <div class="font-bold text-purple-800 mt-4 mb-2">Descrição(opcional)</div>
                    <CompTextArea :schema="yup.string().nullable()" name="description"
                        v-model="documents.find(obj => obj?.tipo?.includes('Plano de trabalho')).descricao" />
                </div>

                <div class="flex justify-end gap-4">
                    <button @click.prevent="workPlanUpdateModal = !workPlanUpdateModal;" type="button"
                        class="btn secondary w-full">
                        Cancelar
                    </button>
                    <button type="submit" class="btn primary w-full">
                        Enviar
                    </button>
                </div>
            </Form>
        </CompModal>

        <CompModal v-model="workPlanUpdateConfirm" :isOpen="workPlanUpdateConfirm"
            @close="workPlanUpdateConfirm = !workPlanUpdateConfirm" customClass="validacao">
            <div class="text-xl font-poppins font-bold mb-2">
                Substituir {{ documents?.find(obj => obj?.tipo?.includes('Plano de trabalho'))?.tipo }}
            </div>
            <div class="mb-8">
                Você tem certeza que deseja substituir o arquivo
                {{ documents?.find(obj => obj.tipo.includes('Plano de trabalho')).nome_do_arquivo }} ?
            </div>
            <div class="flex justify-end">
                <button @click.prevent="workPlanUpdateConfirm = !workPlanUpdateConfirm" type="button" class="btn secondary">
                    Cancelar
                </button>
                <button type="button" @click.prevent="updateDocument" class="btn primary">
                    Substituir
                </button>
            </div>
        </CompModal>


        <CompModal :isOpen="finalReportUpdateModal" @close="finalReportUpdateModal = !finalReportUpdateModal"
            customClass="triagem">
            <Form @submit="finalReportUpdateModal = !finalReportUpdateModal;
            finalReportUpdateConfirm = !finalReportUpdateConfirm">
                <button @click="finalReportUpdateModal = !finalReportUpdateModal" class="absolute right-5 top-5">
                    <IconsComp icon="close-filled" class="w-6 h-6 fill-green-500" />
                </button>

                <div class="text-3xl text-purple-800 font-bold font-poppins mb-2">
                    Enviar arquivos
                </div>
                <p>
                    Nesta etapa você poderá submeter os principais documentos e arquivos relativos ao seu projeto tais como
                    plano de trabalho, contratos e outros.
                </p>

                <div class="mb-8">
                    <div class="font-bold text-purple-800 mt-2 mb-1">Tipo de arquivo</div>
                    <CompSelect :schema="yup.string().required()" :options="['Relatório final']" name="document_type"
                        v-model="formUpdate.document_type" @change.prevent="formUpdate.document = null" />
                    <div class="my-2">
                        <CompFile :schema="yup.mixed().required()" name="document" id="document"
                            v-model="formUpdate.document" :documentTypes="'.doc,.docx,.pdf'" />
                    </div>

                    <div class="font-bold text-purple-800 mt-4 mb-2">Descrição(opcional)</div>
                    <CompTextArea :schema="yup.string().nullable()" name="description"
                        v-model="documents.find(obj => obj?.tipo?.includes('Relatório final')).descricao" />
                </div>

                <div class="flex justify-end gap-4">
                    <button @click.prevent="finalReportUpdateModal = !finalReportUpdateModal;" type="button"
                        class="btn secondary w-full">
                        Cancelar
                    </button>
                    <button type="submit" class="btn primary w-full">
                        Enviar
                    </button>
                </div>
            </form>
        </CompModal>

        <CompModal v-model="finalReportUpdateConfirm" :isOpen="finalReportUpdateConfirm"
            @close="finalReportUpdateConfirm = !finalReportUpdateConfirm" customClass="validacao">
            <div class="text-xl font-poppins font-bold mb-2">
                Substituir {{ documents?.find(obj => obj.tipo.includes('Relatório final'))?.tipo }}
            </div>
            <div class="mb-8">
                Você tem certeza que deseja substituir o arquivo
                {{ documents?.find(obj => obj.tipo.includes('Relatório final')).nome_do_arquivo }} ?
            </div>
            <div class="flex justify-end">
                <button @click.prevent="finalReportUpdateConfirm = !finalReportUpdateConfirm" type="button"
                    class="btn secondary">
                    Cancelar
                </button>
                <button type="button" @click.prevent="updateDocument" class="btn primary">
                    Substituir
                </button>
            </div>
        </CompModal>
    </div>
</template>

