import { createRouter, createWebHistory } from "vue-router";

// Stores
import { useAuthStore } from "@/stores";

// Views
import {
  UI,
  LoginView,
  ProgramsView,
  ProgramsIncubeView,
  ProgramsInpulseView,
  ProgramsInpacteView,
  InstitutePanelView,
  InstitutePanelProjectView,
  ReviewerPanelView,
  MyProfileView,
  ForgotPasswordView,
  ResetPasswordView,
  NewSpecialtyView,
  NewMonitorView,
  NewInstituteView,
  NewEdictView,
  EditEdictView,
  EditInstituteView,
  EditSpecialtyView,
  EditMonitorView,
  EditReviewerView,
  ContinuosFlowView,
  NewProgramInCubeView,
  NewProgramInPulseView,
  NewProgramInPacteView,
  NewReviewerView,
  ProposerPanelView,
  ProposerProposalView,
  ProposerProjectView,
  ProposerProjectsView,
  ProposerFirstAccessView,
  ReviewerFirstAccessView,
  ReviewerProposalView,
  ReviewerSelectionView,
  RegistredReviewersView,
  MonitorProposalView,
  MonitorProposalsView,
  MonitorProjectView,
  MonitorProjectsView,
  MonitorFirstAccessView,
} from "@/views";

import {
  InovahcProgramView,
  InovahcProgramsView,
  InovahcProjectsView,
  InovahcProjectView,
  InovahcEdictsView,
  InovahcInstituteProjectsView,
  InovahcInstituteView,
  InovahcInstitutesView,
  InovahcSpecialtiesView,
  InovahcMonitorView,
  InovahcMonitorsView,
  InovahcMonitorProjectsView,
  InovahcReviewerView,
  InovahcReviewersView,
  InovahcProposerView,
  InovahcProposerProjectsView,
  InovahcProposersView,
  InovahcProposalView,
  InovahcProposalReviewerView,
  InovahcFormsDownloadView,
} from "@/views/inovahc_panel";

export const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }; // always scroll to top
  },
  linkActiveClass: "active",
  routes: [
    { path: "/", component: ProgramsView, name: "home" },
    
    { path: "/ui", component: UI, name: "ui" },
    
    { path: "/programas", component: ProgramsView, name: "programas" },
    { path: "/programas-incube", component: ProgramsIncubeView, name: "programas-incube" },
    { path: "/programas-inpulse", component: ProgramsInpulseView, name: "programas-inpulse" },
    { path: "/programas-inpacte", component: ProgramsInpacteView, name: "programas-inpacte" },
    
    { path: "/login", component: LoginView, name: "login", meta: { withoutFooter: true }},
    { path: "/esqueci-minha-senha", component: ForgotPasswordView, name: "esqueci-minha-senha", meta: { withoutFooter: true }},
    { path: "/nova-senha/:token", component: ResetPasswordView, name: "nova-senha", params: { token: "token" }, meta: { withoutFooter: true }},
    { path: "/meu-perfil", component: MyProfileView, name: "meu-perfil" },

    { path: "/primeiro-acesso-proponente", component: ProposerFirstAccessView, name: "primeiro-acesso-proponente", meta: { withoutFooter: true }},
    { path: "/primeiro-acesso-parecerista/:token", component: ReviewerFirstAccessView, name: "primeiro-acesso-parecerista", params: { token: "token" }, meta: { withoutFooter: true }},
    { path: "/primeiro-acesso-monitor/:token", component: MonitorFirstAccessView, name: "primeiro-acesso-monitor", params: { token: "token" }, meta: { withoutFooter: true }},

    //
    { path: "/painel-inovahc/programa/:id", component: InovahcProgramView, name: "painel-inovahc-programa", params: { id: true }},
    { path: "/painel-inovahc/programas", component: InovahcProgramsView, name: "painel-inovahc-programas"},
    { path: "/painel-inovahc/projetos", component: InovahcProjectsView, name: "painel-inovahc-projetos"},
    { path: "/painel-inovahc/projeto/:id", component: InovahcProjectView, name: "painel-inovahc-projeto", params: { id: true }},
    { path: "/painel-inovahc/editais", component: InovahcEdictsView, name: "painel-inovahc-editais"},
    { path: "/painel-inovahc/instituto/:id", component: InovahcInstituteView, name: "painel-inovahc-instituto", params: { id: true }},
    { path: "/painel-inovahc/instituto/:id/projetos", component: InovahcInstituteProjectsView, name: "painel-inovahc-instituto-projetos", params: { id: true }},
    { path: "/painel-inovahc/institutos", component: InovahcInstitutesView, name: "painel-inovahc-institutos"},
    { path: "/painel-inovahc/especialidades", component: InovahcSpecialtiesView, name: "painel-inovahc-especialidades"},
    { path: "/painel-inovahc/monitor/:id", component: InovahcMonitorView, name: "painel-inovahc-monitor", params: { id: true }},
    { path: "/painel-inovahc/monitor/:id/projetos", component: InovahcMonitorProjectsView, name: "painel-inovahc-monitor-projetos", params: { id: true }},
    { path: "/painel-inovahc/monitores", component: InovahcMonitorsView, name: "painel-inovahc-monitores"},
    { path: "/painel-inovahc/parecerista/:id", component: InovahcReviewerView, name: "painel-inovahc-parecerista", params: { id: true }},
    { path: "/painel-inovahc/pareceristas", component: InovahcReviewersView, name: "painel-inovahc-pareceristas"},
    { path: "/painel-inovahc/proponente/:id", component: InovahcProposerView, name: "painel-inovahc-proponente", params: { id: true }},
    { path: "/painel-inovahc/proponente/:id/projetos", component: InovahcProposerProjectsView, name: "painel-inovahc-proponente-projetos", params: { id: true }},
    { path: "/painel-inovahc/proponentes", component: InovahcProposersView, name: "painel-inovahc-proponentes"},
    { path: "/painel-inovahc/proposta/:id", component: InovahcProposalView, name: "painel-inovahc-proposta", params: { id: true }},
    { path: "/painel-inovahc/proposta/:id/selecionar-parecerista", component: InovahcProposalReviewerView, name: "painel-inovahc-proposta-parecerista", params: { id: true }},
    { path: "/painel-inovahc/formularios-para-download", component: InovahcFormsDownloadView, name: "painel-inovahc-formularios-para-download" },

    { path: "/adicionar-especialidade", component: NewSpecialtyView, name: "adicionar-especialidade"},
    { path: "/editar-especialidade/:id", component: EditSpecialtyView, name: "editar-especialidade"},
    { path: "/adicionar-monitor", component: NewMonitorView, name: "adicionar-monitor"},
    { path: "/editar-monitor/:id", component: EditMonitorView, name: "editar-monitor"},
    { path: "/adicionar-instituto", component: NewInstituteView, name: "adicionar-instituto"},
    { path: "/editar-instituto/:id", component: EditInstituteView, name: "editar-instituto"},
    { path: "/adicionar-edital", component: NewEdictView, name: "adicionar-edital"},
    { path: "/editar-edital/:id", component: EditEdictView, name: "editar-edital"},


    { path: "/painel-proponente", component: ProposerPanelView, name: "painel-proponente"},
    { path: "/painel-proponente/proposta/:id", component: ProposerProposalView, name: "painel-proponente-proposta", params: { id: true }},
    { path: "/painel-proponente/projeto/:id", component: ProposerProjectView, name: "painel-proponente-projeto", params: { id: true }},
    { path: "/painel-proponente/projetos", component: ProposerProjectsView, name: "painel-proponente-projetos", params: { id: true }},
    { path: "/criar-proposta", component: ContinuosFlowView, name: "adicionar-programa"},
    { path: "/criar-proposta-incube", component: NewProgramInCubeView, name: "adicionar-programa-incube"},
    { path: "/criar-proposta-inpulse", component: NewProgramInPulseView, name: "adicionar-programa-inpulse"},
    { path: "/criar-proposta-inpacte", component: NewProgramInPacteView, name: "adicionar-programa-inpacte"},
    
    { path: "/painel-parecerista", component: ReviewerPanelView, name: "painel-parecerista"},
    { path: "/painel-parecerista/proposta/:id", component: ReviewerProposalView, name: "painel-parecerista-proposta", params: { id: true }},
    
    { path: "/painel-monitor", component: MonitorProposalsView, name: "painel-monitor"},
    { path: "/painel-monitor/proposta/:id", component: MonitorProposalView, name: "painel-monitor-proposta", params: { id: true }},
    { path: "/painel-monitor/projetos", component: MonitorProjectsView, name: "painel-monitor-projetos"},
    { path: "/painel-monitor/projeto/:id", component: MonitorProjectView, name: "painel-monitor-projeto", params: { id: true }},
    { path: "/adicionar-parecerista", component: NewReviewerView, name: "adicionar-parecerista"},
    { path: "/editar-parecerista/:id", component: EditReviewerView, name: "editar-parecerista"},
    { path: "/selecionar-pareceristas/proposta/:id", component: ReviewerSelectionView, name: "selecionar-pareceristas-proposta", params: { id: true }},
        
    { path: "/:pathMatch(.*)*", redirect: "/" },
  ],
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  if (authStore.user?.tipo === "super-admin" && to.name === "home") {
    next({ name: "painel-inovahc-programas" });
    return;
  } else if (authStore.user?.tipo === "parecerista" && to.name === "home") {
    next({ name: "painel-parecerista" });
    return;
  } else if (authStore.user?.tipo === "proponente" && to.name === "home") {
    next({ name: "painel-proponente" });
    return;
  } else if (authStore.user?.tipo === "monitor" && to.name === "home") {
    next({ name: "painel-monitor" });
    return;
  }

  const publicPages = [
    "home",
    "login",
    "esqueci-minha-senha",
    "nova-senha",
    "programas",
    "programas-incube",
    "programas-inpulse",
    "programas-inpacte",
    "primeiro-acesso-proponente",
    "primeiro-acesso-parecerista",
    "primeiro-acesso-monitor",
    "ui"
  ];
  const authRequired = !publicPages.includes(to.name);

  if (authRequired && !authStore.user) {
    authStore.returnUrl = to.fullPath;
    next({ name: "login" });
    return;
  }
  //   if (to.name == "nova-senha" && !authStore.token) {
  //     next({ name: "login" });
  //     return;
  //   }

  if (authStore.user && to.name === "login") {
    next({ name: "home" });
    return;
  }

  next();
});
