<script setup>
import { ref } from "vue";
import IconsComp from "@/components/IconsComp";
import CompCheckbox from "@/components/CompCheckbox";
import CompRadio from "@/components/CompRadio";
import CompProgress from "@/components/CompProgress";
import CompModal from "@/components/CompModal";
import CompInput from "@/components/CompInput";
import { Field } from "vee-validate";

const props = defineProps({
    values: {},
});

const project_models = ref([
    "B2B (business to business / empresa para empresa)",
    "B2C (business to client / empresa para consumidor final)",
    "B2G (business to goverment / empresa para governo)",
    "Ainda não formulado",
    "Outro",
]);

const has_financies = ref([
    "Sim, via órgão de fomento de inovação",
    "Sim, via parceiro de empresa privada",
    "Sim, tenho capacidade de financiar o próprio projeto",
    "Não, porém o projeto está inscrito em fontes de investimento público ou privado",
    "Não, porém a equipe busca outras fontes de investimento público ou privado",
    "Não",
    "Outro",
]);

const development_stages = ref([
    "Coleta de dados sobre problema",
    "Ideia de conceito básico da solução",
    "Ideia desenvolvida com requisitos mínimos do projeto",
    "Protótipo de baixa fidelidade sem testes com usuários",
    "Protótipo de alta fidelidade sem testes com usuários",
    "Protótipo de baixa ou alta fidelidade com testes com usuários",
    "Mínimo produto viável com testes com possíveis clientes",
    "Empresa já constituída para comercializar a solução sem vendas",
    "Empresa já constituída para comercializar a solução com primeiras vendas",
    "Outro",
]);

const project_inpi = ref([
    "Sim, patente depositada",
    "Sim, outro tipo de propriedade intelectual",
    "Não",
]);

const approved_project = ref([
    "Sim, submetido e aprovado",
    "Sim, submetido aguardando aprovação ou reanálise",
    "Não",
]);

const helps = ref([
    "Mentoria especializada",
    "Testes em Ambiente Real",
    "Co-desenvolvimento de produto/serviço",
    "Testes laboratoriais",
    "Infraestrutura para prototipagem",
    "Outro",
]);

const form = ref({
    project_model: props.values.project_model,
    partners: props.values.partners,
    has_financy: props.values.has_financy,
    development_stage: props.values.development_stage,
    project_inpi: props.values.project_inpi,
    project_publication: props.values.project_publication,
    intellectual_support: props.values.intellectual_support,
    presented_orally: props.values.presented_orally,
    has_search: props.values.has_search,
    approved_project: props.values.approved_project,
    clinic_support: props.values.clinic_support,
    has_regulation: props.values.has_regulation,
    regulation_support: props.values.regulation_support,
    sensitive_data: props.values.sensitive_data,
    sensitive_data_support: props.values.sensitive_data_support,
    help: props.values.help,
});
</script>

<template>
    <div class="mb-16">
        <div class="text-3xl font-poppins font-bold text-purple-800 mb-2">
            Estágio da solução
        </div>
        <div class="card my-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-6">
                Qual o modelo de negócio do projeto?
                <span class="ml-auto asterisk"></span>
            </div>

            <CompRadio name="project_model" :radios="project_models" v-model="form.project_model" :hasOther="true" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                Indique seus principais parceiros atuais e/ou prospectivos, públicos e
                privados (se houver).
                <span class="ml-auto asterisk"></span>
            </div>
            <Field v-slot="{ field, errors }" name="partners">
                <textarea id="partners" v-bind="field" rows="6" cols="50" placeholder="Sua resposta..."
                    :class="errors.length > 0 ? '!border-red-500' : ''" />
                <p v-if="errors.length > 0" class="mt-2 peer-invalid:flex text-red-500 text-sm">
                    {{ errors[0] }}
                </p>
            </Field>
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-6">
                Há atualmente financiamento do projeto?
                <span class="ml-auto asterisk"></span>
            </div>

            <CompRadio name="has_financy" :radios="has_financies" v-model="form.has_financy" :hasOther="true" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-6">
                Estágio de desenvolvimento da solução
                <span class="ml-auto asterisk"></span>
            </div>

            <CompRadio name="development_stage" :radios="development_stages" v-model="form.development_stage"
                :hasOther="true" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                O projeto já possui propriedade intelectual registrada no INPI?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompRadio name="project_inpi" :radios="project_inpi" v-model="form.project_inpi" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                O projeto possui alguma publicação? Se sim, por favor inserir DOI ou
                link
                <span class="ml-auto asterisk"></span>
            </div>
            <Field v-slot="{ field, errors }" name="project_publication">
                <input v-bind="field" type="text" placeholder="Sua resposta..." />
                <p v-if="errors.length > 0" class="mt-2 peer-invalid:flex text-red-500 text-sm">
                    {{ errors[0] }}
                </p>
            </Field>
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                Será necessário apoio para proteção de propriedade intelectual da
                solução desenvolvida? Se sim, qual?
                <span class="ml-auto asterisk"></span>
            </div>
            <Field v-slot="{ field, errors }" name="intellectual_support">
                <textarea id="intellectual_support" v-bind="field" rows="6" cols="50" placeholder="Sua resposta..."
                    :class="errors.length > 0 ? '!border-red-500' : ''" />
                <p v-if="errors.length > 0" class="mt-2 peer-invalid:flex text-red-500 text-sm">
                    {{ errors[0] }}
                </p>
            </Field>
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                O projeto foi apresentado oralmente em sessão pública (congresso,
                simpósios, outros)?
                <span class="ml-auto asterisk"></span>
            </div>
            <Field v-slot="{ field, errors }" name="presented_orally">
                <input v-bind="field" type="text" placeholder="Sua resposta..." />
                <p v-if="errors.length > 0" class="mt-2 peer-invalid:flex text-red-500 text-sm">
                    {{ errors[0] }}
                </p>
            </Field>
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                Haverá pesquisa envolvendo seres humanos a operacionalização do projeto?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompRadio name="has_search" :radios="['Sim', 'Não', 'Não tenho certeza']" v-model="form.has_search" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                O projeto atual foi submetido e aprovado pelo Comitê de Ética em
                Pesquisa?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompRadio name="approved_project" :radios="approved_project" v-model="form.approved_project" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                Será necessário apoio para submissão de ensaio clínico ao Comitê de
                Ética em Pesquisa? Se sim, qual tipo de apoio?
                <span class="ml-auto asterisk"></span>
            </div>
            <Field v-slot="{ field, errors }" name="clinic_support">
                <input v-bind="field" type="text" placeholder="Sua resposta..." />
                <p v-if="errors.length > 0" class="mt-2 peer-invalid:flex text-red-500 text-sm">
                    {{ errors[0] }}
                </p>
            </Field>
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                Haverá regulamentação aplicável ao uso da solução desenvolvida?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompRadio name="has_regulation" :radios="['Sim', 'Não', 'Não tenho certeza']" v-model="form.has_regulation" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                Será necessário apoio para o processo de certificação/regulamentação da
                solução desenvolvida? Se sim, qual tipo de apoio?
                <span class="ml-auto asterisk"></span>
            </div>
            <Field v-slot="{ field, errors }" name="regulation_support">
                <textarea id="regulation_support" v-bind="field" rows="6" cols="50" placeholder="Sua resposta..."
                    :class="errors.length > 0 ? '!border-red-500' : ''" />
                <p v-if="errors.length > 0" class="mt-2 peer-invalid:flex text-red-500 text-sm">
                    {{ errors[0] }}
                </p>
            </Field>
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                Haverá uso de dados pessoais/sensíveis/identificáveis na execução do
                projeto?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompRadio name="sensitive_data" :radios="['Sim', 'Não']" v-model="form.sensitive_data" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                Será necessário apoio para tratamento dos dados
                pessoais/sensíveis/identificáveis utilizados pelo projeto? Se sim, qual?
                <span class="ml-auto asterisk"></span>
            </div>
            <Field v-slot="{ field, errors }" name="sensitive_data_support">
                <textarea id="sensitive_data_support" v-bind="field" rows="6" cols="50" placeholder="Sua resposta..."
                    :class="errors.length > 0 ? '!border-red-500' : ''" />
                <p v-if="errors.length > 0" class="mt-2 peer-invalid:flex text-red-500 text-sm">
                    {{ errors[0] }}
                </p>
            </Field>
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-6">
                Como o InovaHC pode te ajudar?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>Selecione os principais apoios que você pretende ter com o HCFMUSP</p>

            <CompCheckbox name="help" :checkboxes="helps" v-model="form.help" :hasOther="true" />
        </div>
    </div>
</template>
