<script setup>
import IconsComp from "./IconsComp.vue";

defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
    },
});

const emits = defineEmits(['update:modelValue'])

function onSubmit(values) {
    emits('update:modelValue', values.target.search.value)
}

function onEmpty(value) {
    if (value.target.value == '') {
        emits('update:modelValue', value.target.value)
    }
}

</script>
<template>
    <form @submit.prevent="onSubmit" ref="form">
        <div class="comp-search">
            <input @input.prevent="onEmpty" type="search" name="search" :value="modelValue"
                :placeholder="placeholder ? placeholder : 'Buscar proposta'" />
            <button type="submit">
                <IconsComp icon="search" />
            </button>
        </div>
    </form>
</template>

<script>
export default {
    name: "CompSearch",
};
</script>
