import { defineStore } from "pinia";

import { requestS } from "@/helpers";
import { router } from "@/router";
import { useAlertStore } from "@/stores";

const baseUrl = `${process.env.VUE_APP_API_URL}`;

export const useProjectStore = defineStore({
  id: "project",
  state: () => ({
    project: null,
  }),
  actions: {
    async all(perPage = 20, currentPage = 1) {
      try {
        try {
          return await requestS
            .get(`${baseUrl}/projetos?per_page=${perPage}&page=${currentPage}`)
            .then((response) => {
              return response;
            })
            .catch((error) => {
              return [];
            });
        } catch (error) {
          const alertStore = useAlertStore();
          alertStore.error(error);
        }
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async find(project_id) {
      try {
        return await requestS
          .get(`${baseUrl}/projetos/${project_id}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return null;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async store(data) {
      try {
        return await requestS
          .post(`${baseUrl}/projetos`, {
            nome: data.name,
            descricao: data.description,
            id_usuario: data.user_id,
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async update(project_id, data) {
      try {
        return await requestS
          .put(`${baseUrl}/projetos/${project_id}`, {
            nome: data.name,
            descricao: data.description,
            id_usuario: data.user_id,
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async delete(project_id) {
      try {
        return await requestS
          .delete(`${baseUrl}/projetos/${project_id}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return false;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
    async search(
      nome = "",
      status = "",
      programa = "",
      especialidades = "",
      edital = "",
      perPage = 20,
      currentPage = 1
    ) {
      try {
        return await requestS
          .get(
            `${baseUrl}/projetos/search?nome=${nome}&status=${status}&programa=${programa}&especialidades=${especialidades}&edital=${edital}&per_page=${perPage}&page=${currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async sendDocument(project_id, data) {
      try {
        const formData = new FormData();
        formData.append("tipo", data.document_type);
        formData.append("arquivo", data.document);
        data.description ? formData.append("descricao", data.description) : "";
        return await requestS
          .upload(`${baseUrl}/projetos/${project_id}/documentos`, formData)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async updateDocument(project_id, document_id, data) {
      try {
        const formData = new FormData();
        formData.append("tipo", data.document_type);
        formData.append("arquivo", data.document);
        data.description ? formData.append("descricao", data.description) : "";
        return await requestS
          .upload(
            `${baseUrl}/projetos/${project_id}/atualizar-documentos/${document_id}`,
            formData
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async deleteDocument(project_id, document_id) {
      try {
        return await requestS
          .delete(
            `${baseUrl}/projetos/${project_id}/deletar-documentos/${document_id}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async documentRequest(project_id, document_type) {
      try {
        return await requestS
          .put(`${baseUrl}/projetos/${project_id}/solicitar-documentos`, {
            tipo: document_type,
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
  },
});
