<script setup>
import CompCollapse from "@/components/CompCollapse";
import IconsComp from "@/components/IconsComp";
import { useAuthStore, useSpecialtyStore } from "@/stores";
import { ref, onMounted } from "vue";

const authStore = useAuthStore();
const specialties = ref([]);

onMounted(async () => {
    specialties.value = await useSpecialtyStore().all();
});

defineProps({
    proposal: {
        type: [Array, Object],
        required: true,
        default: [],
    },
    user: {
        type: [Array, Object],
        required: false,
    },
    collapse: {
        type: Array,
        default: [],
    },
});
</script>

<template>
    <!-- COLLAPSE -->
    <CompCollapse :collapseOpen="collapse.includes(1)" titulo="Equipe">
        <template v-slot:default>
            <div v-if="authStore.user.tipo !== 'parecerista'" class="highlight1">Representante da Equipe</div>
            <div v-if="authStore.user.tipo !== 'parecerista'" class="profile mb-4">
                <div class="profile-img">
                    <img :src="proposal?.proponente?.avatar ? proposal?.proponente?.avatar : ''" alt="" />
                </div>
                <div class="dados">
                    <div class="nome !text-purple-600">
                        {{ proposal?.proponente?.nome_completo }}
                    </div>
                    <div class="flex gap-4">
                        <div class="data">
                            <IconsComp icon="email" />{{ proposal?.proponente?.email }}
                        </div>
                        <div class="data">
                            <IconsComp icon="call" />{{ proposal?.proponente?.telefone }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="highlight1">Qual sua formação e experiência?</div>
            <p>
                {{
                    proposal?.perguntas_e_respostas?.formation_experience

                }}
            </p>

            <div class="highlight1">Autodeclaração racial</div>
            <p>{{ proposal?.perguntas_e_respostas?.racial_self_declaration }}</p>

            

            <div v-if="proposal?.integrantes?.length > 0" class="highlight1">Outros integrantes</div>
            <div v-for="(integrante, i) in proposal?.integrantes" :key="i">
                <div class="highlight2 !text-purple-600">{{ integrante.nome }}</div>
                <p>
                    {{ integrante.experiencia }}
                </p>
            </div>

            <div class="highlight1">Características da equipe</div>
            <div v-if="proposal?.perguntas_e_respostas?.fmusp_connection
                ">
                <div class="text-lg text-purple-500">
                    Sua equipe já contém ao menos um(a) membro(a) vinculado(a) ao HCFMUSP
                    ou FMUSP?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.fmusp_connection
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.team_composition
                    ">
                <div class="text-lg text-purple-500">
                    Selecione a alternativa que melhor representa a composição de sua
                    equipe atualmente:
                </div>
                <ul class="list-disc list-inside mb-2">
                    <li v-for="item in proposal?.perguntas_e_respostas?.team_composition?.filter((obj) => obj !== 'Outro')"
                        :key="item">
                        {{ item }}
                    </li>
                </ul>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.team_members_formation
                ">
                <div class="text-lg text-purple-500">
                    Quais as áreas de formação dos membros da equipe?
                </div>
                <ul class="list-disc list-inside mb-2">
                    <li v-for="item in proposal?.perguntas_e_respostas?.team_members_formation?.filter((obj) => obj !== 'Outro')"
                        :key="item">
                        {{ item }}
                    </li>
                </ul>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.project_secrecy
                ">
                <div class="text-lg text-purple-500">
                    Guardarei sigilo das informações trocadas durante todo o projeto:
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.project_secrecy
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.team">
                <div class="text-lg text-purple-500">
                    A equipe é composta por quantas pessoas?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.team
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.fmusp_connection_type
                    ">
                <div class="text-lg text-purple-500">
                    Qual seu vínculo com o Hospital das Clínicas da FMUSP ou Universidade
                    de São Paulo (USP)?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.fmusp_connection_type
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.fmusp_institute
                    ">
                <div class="text-lg text-purple-500">
                    Você pertence a qual instituto?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.fmusp_institute
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.fmusp_contact
                    ">
                <div class="text-lg text-purple-500">
                    Quem é seu contato do HCFMUSP?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.fmusp_contact
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.fmusp_institute_work
                    ">
                <div class="text-lg text-purple-500">
                    Qual o instituto em que esta pessoa trabalha?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.fmusp_institute_work
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.fmusp_institute_from_not_anymore
                    ">
                <div class="text-lg text-purple-500">
                    Seu projeto tem relação com alguma startup residente do Distrito
                    InovaHC ou Distrito for Startups?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.fmusp_institute_from_not_anymore

                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.fmusp_institute_from_no
                    ">
                <div class="text-lg text-purple-500">
                    Seu projeto tem relação com alguma startup residente do Distrito
                    InovaHC ou Distrito for Startups?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.fmusp_institute_from_no
                    }}
                </p>
            </div>
        </template>
    </CompCollapse>

    <CompCollapse v-if="authStore.user.tipo !== 'parecerista'" :collapseOpen="collapse.includes(2)"
        titulo="Motivações e necessidades de projeto">
        <template v-slot:default>
            <div v-if="proposal?.perguntas_e_respostas?.team_motivation
                ">
                <div class="text-lg text-purple-500 mb-2">
                    Qual a motivação da equipe para participar do
                    <b>{{ proposal?.edital?.nome }}</b>?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.team_motivation
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.team_motivation
                    ">
                <div class="text-lg text-purple-500 mb-2">
                    Quais os tipos de apoio que você precisa para desenvolver seu projeto?
                </div>
                <ul class="list-disc list-inside mb-2">
                    <li>
                        {{ proposal?.perguntas_e_respostas
                            ?.team_motivation }}
                    </li>
                </ul>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.heard_about
                ">
                <div class="text-lg text-purple-500 mb-2">
                    Como ficou sabendo do processo seletivo
                    <b>{{ proposal?.edital?.nome }}</b>?
                </div>
                <ul class="list-disc list-inside mb-2">
                    <li v-if="Array.isArray(proposal?.perguntas_e_respostas?.heard_about)"
                        v-for="heard_about in proposal?.perguntas_e_respostas?.heard_about?.filter((obj) => obj !== 'Outro')"
                        :key="heard_about">
                        {{ heard_about }}
                    </li>
                    <li v-else>
                        {{ proposal?.perguntas_e_respostas?.heard_about }}
                    </li>
                </ul>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.support_types
                ">
                <div class="text-lg text-purple-500 mb-2">
                    Quais os tipos de apoio que você precisa?
                </div>
                <ul class="list-disc list-inside mb-2">
                    <li v-if="Array.isArray(proposal?.perguntas_e_respostas?.support_types)"
                        v-for="support_types in proposal?.perguntas_e_respostas?.support_types?.filter((obj) => obj !== 'Outro')"
                        :key="support_types">
                        {{ support_types }}
                    </li>
                    <li v-else>
                        {{ proposal?.perguntas_e_respostas?.support_types }}
                    </li>
                </ul>
            </div>
        </template>
    </CompCollapse>

    <CompCollapse titulo="Estágio da solução" :collapseOpen="collapse.includes(3)">
        <template v-slot:default>
            <div v-if="proposal?.perguntas_e_respostas?.development_stage
                ">
                <div class="text-lg text-purple-500">
                    Estágio de desenvolvimento da solução
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.development_stage
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.project_inpi">
                <div class="text-lg text-purple-500">
                    O projeto já possui propriedade intelectual registrada no INPI?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.project_inpi
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.intellectual_support
                    ">
                <div class="text-lg text-purple-500">
                    Será necessário apoio para proteção de propriedade intelectual da
                    solução desenvolvida? Se sim, qual?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.intellectual_support
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.has_investiment
                    ">
                <div class="text-lg text-purple-500">
                    O startup já recebeu algum investimento?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.has_investiment
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.has_search">
                <div class="text-lg text-purple-500">
                    Haverá pesquisa envolvendo seres humanos a operacionalização do
                    projeto?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.has_search
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.clinic_support
                    ">
                <div class="text-lg text-purple-500">
                    Será necessário apoio para submissão de ensaio clínico ao Comitê de
                    Ética em Pesquisa? Se sim, qual tipo de apoio?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.clinic_support
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.has_regulation
                    ">
                <div class="text-lg text-purple-500">
                    Haverá regulamentação aplicável ao uso da solução desenvolvida?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.has_regulation
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.regulation_support
                    ">
                <div v-if="proposal?.edital?.nome.includes('pacte')" class="text-lg text-purple-500">
                    Será necessário apoio para o processo de certificação/regulamentação
                    em saúde (ANVISA, CONITEC, etc) da solução desenvolvida? Se sim, qual
                    tipo de apoio?
                </div>
                <div v-else class="text-lg text-purple-500">
                    Será necessário apoio para o processo de certificação/regulamentação
                    da solução desenvolvida? Se sim, qual tipo de apoio?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.regulation_support
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.sensitive_data
                    ">
                <div class="text-lg text-purple-500">
                    Haverá uso de dados pessoais/sensíveis/identificáveis na execução do
                    projeto?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.sensitive_data
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.sensitive_data_support
                    ">
                <div class="text-lg text-purple-500">
                    Será necessário apoio para tratamento dos dados
                    pessoais/sensíveis/identificáveis utilizados pelo projeto? Se sim,
                    qual?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.sensitive_data_support
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.received_promotion
                    ">
                <div class="text-lg text-purple-500">
                    O projeto recebeu algum tipo de fomento?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.received_promotion
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.commercial_exploitation
                    ">
                <div class="text-lg text-purple-500">
                    Existe intenção da solução desenvolvida ser transferida para
                    exploração comercial por terceiros (ou outros desenhos societários)?
                    Qual tipo de apoio é necessário caso positivo?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.commercial_exploitation
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.execution">
                <div class="text-lg text-purple-500">
                    A equipe do projeto dispõe de todos os recursos identificados para sua
                    execução? Se não, precisa de apoio?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.execution
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.project_publication
                    ">
                <div class="text-lg text-purple-500">
                    O projeto possui alguma publicação? Se sim, por favor inserir DOI ou
                    link
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.project_publication
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.presented_orally
                    ">
                <div class="text-lg text-purple-500">
                    O projeto foi apresentado oralmente em sessão pública (congresso,
                    simpósios, outros)?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.presented_orally
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.expected_outcome
                    ">
                <div class="text-lg text-purple-500">
                    A longo prazo, qual o desfecho que a equipe gostaria?
                </div>
                <ul class="list-disc list-inside mb-2">
                    <li v-for="item in proposal?.perguntas_e_respostas?.expected_outcome?.filter((obj) => obj !== 'Outro')"
                        :key="item">
                        {{ item }}
                    </li>
                </ul>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.project_model
                ">
                <div class="text-lg text-purple-500">
                    Qual o modelo de negócio do projeto?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.project_model
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.partners">
                <div class="text-lg text-purple-500">
                    Indique seus principais parceiros atuais e/ou prospectivos, públicos e
                    privados (se houver).
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.partners
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.has_financy">
                <div class="text-lg text-purple-500">
                    Há atualmente financiamento do projeto?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.has_financy
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.approved_project
                    ">
                <div class="text-lg text-purple-500">
                    O projeto atual foi submetido e aprovado pelo Comitê de Ética em
                    Pesquisa?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.approved_project
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.help">
                <div class="text-lg text-purple-500">
                    Como o InovaHC pode te ajudar?
                </div>
                <ul class="list-disc list-inside mb-2">
                    <li v-for="item in proposal?.perguntas_e_respostas?.help?.filter((obj) => obj !== 'Outro')" :key="item">
                        {{ item }}
                    </li>
                </ul>
            </div>
        </template>
    </CompCollapse>

    <CompCollapse titulo="Projeto" :collapseOpen="collapse.includes(4)">
        <template v-slot:default>
            <div v-if="proposal?.nome_proposta">
                <div class="text-lg text-purple-500">Nome do projeto ou da ideia</div>
                <p>
                    {{ proposal?.nome_proposta }}
                </p>
            </div>
            <div v-if="proposal?.especialidades?.length > 0">
                <div class="text-lg text-purple-500">
                    Qual área de especialidade em saúde mais se encaixa com sua ideia?
                </div>
                <p>
                    {{ proposal?.especialidades.map((obj) => obj.nome).join(", ") }}
                </p>
            </div>
            <!-- <div v-if="proposal?.perguntas_e_respostas?.secondary_specialty_areas
                ">
                <div class="text-lg text-purple-500">
                    Áreas secundárias de especialidade em saúde do projeto
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.secondary_specialty_areas
                    }}
                </p>
            </div> -->
            <div v-if="proposal?.perguntas_e_respostas?.project_feature
                ">
                <div class="text-lg text-purple-500">
                    Do ponto de vista tecnológico o projeto se caracteriza como um:
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.project_feature[0]
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.project_description
                    ">
                <div class="text-lg text-purple-500">Descreva seu projeto</div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.project_description
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.project_problems
                    ">
                <div class="text-lg text-purple-500">
                    Qual problema o projeto pretende resolver?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.project_problems
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.people_affected
                    ">
                <div class="text-lg text-purple-500">
                    Quem são e qual o número de potenciais pessoas atingidas por esse
                    problema?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.people_affected
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.project_solutions
                    ">
                <div class="text-lg text-purple-500">
                    Como o projeto pretende resolver esse problema?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.project_solutions
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.project_offer
                    ">
                <div class="text-lg text-purple-500">
                    Quais são as oportunidades de aplicação do projeto e por que ele é
                    inovador?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.project_offer
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.project_validation
                    ">
                <div class="text-lg text-purple-500">
                    O projeto passou por validação cientifica e apresentou evidências de
                    seu funcionamento (Ensaio Clínico, Observacional, entre outros)?
                    Comente os resultados encontrados até o momento.
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.project_validation
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.project_institution
                    ">
                <div class="text-lg text-purple-500">
                    O projeto foi desenvolvido dentro de alguma instituição ou por alguma
                    instituição?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.project_institution
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.project_expectations
                    ">
                <div class="text-lg text-purple-500">
                    Qual os resultados esperados com a aplicação deste projeto?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.project_expectations
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.project_scale
                    ">
                <div class="text-lg text-purple-500">
                    Como a solução proposta poderia ser escalada ou replicada? Levante
                    quais são os desafios para alcançar o mercado.
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.project_scale
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.has_firm">
                <div class="text-lg text-purple-500">
                    A equipe já possui empresa firmada?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.has_firm
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.firm_support">
                <div class="text-lg text-purple-500">
                    Existe necessidade de apoio para criação da empresa?
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.firm_support
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.expected_outcome
                    ">
                <div class="text-lg text-purple-500">
                    A longo prazo, qual o desfecho que a equipe gostaria?
                </div>
                <ul class="list-disc list-inside mb-2">
                    <li v-for="item in proposal?.perguntas_e_respostas?.expected_outcome?.filter((obj) => obj !== 'Outro')"
                        :key="item">
                        {{ item }}
                    </li>
                </ul>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.key_words">
                <div class="text-lg text-purple-500">
                    Palavras chave que melhor descrevem sua startup
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.key_words
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.business_model
                    ">
                <div class="text-lg text-purple-500">
                    Suba o anexo do seu Canvas do Modelo de Negócios
                </div>
                <p>
                    {{
                        proposal?.perguntas_e_respostas
                            ?.business_model
                    }}
                </p>
            </div>
            <div v-if="proposal?.perguntas_e_respostas?.project_files
                    ">
                <div class="text-lg text-purple-500">Anexos</div>
                <div v-for="file in JSON.parse(proposal?.perguntas_e_respostas?.project_files)" :key="file" class="mb-2">
                    <div>
                        {{
                            file.link
                        }}
                    </div>
                    <div>
                        {{
                            file.description
                        }}
                    </div>
                </div>
            </div>
        </template>
    </CompCollapse>

    <CompCollapse v-if="proposal?.modelo_de_negocio
        " titulo="Modelo de negócio" :collapseOpen="collapse.includes(5)">
        <template v-slot:default>
            <div>
                <a :href="proposal?.modelo_de_negocio" target="_blank">
                    Link do modelo de negócio
                </a>
            </div>
        </template>
    </CompCollapse>

    <CompCollapse v-if="proposal?.pitch
        " titulo="Pitch" :collapseOpen="collapse.includes(6)">
        <template v-slot:default>
            <div>
                <a :href="proposal?.pitch" target="_blank">
                    Link do Pitch
                </a>
            </div>
        </template>
    </CompCollapse>
</template>
