import { defineStore } from "pinia";

import { requestS } from "@/helpers";
import { router } from "@/router";
import { useAlertStore } from "@/stores";

const baseUrl = `${process.env.VUE_APP_API_URL}`;

export const useProgramStore = defineStore({
  id: "program",
  state: () => ({
    program: null,
  }),
  actions: {
    async all() {
      try {
        return await requestS
          .get(`${baseUrl}/programas`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async find(program_id) {
      try {
        return await requestS
          .get(`${baseUrl}/programas/${program_id}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return null;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async store(data) {
      try {
        return await requestS
          .post(`${baseUrl}/programas`, {
            nome: data.name,
            descricao: data.description,
            id_usuario: data.user_id,
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async update(program_id, data) {
      try {
        return await requestS
          .put(`${baseUrl}/programas/${program_id}`, {
            nome: data.name,
            descricao: data.description,
            id_usuario: data.user_id,
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async delete(program_id) {
      try {
        return await requestS
          .delete(`${baseUrl}/programas/${program_id}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return false;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async edictsOpen() {
      try {
        return await requestS
          .get(`${baseUrl}/programas/editais-abertos`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
    async countProposalsAndProjects() {
      try {
        return await requestS
          .get(`${baseUrl}/programas/quantidade-propostas-e-projetos`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
  },
});
