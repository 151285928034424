<script setup>
import CompBreadCrumbs from "@/components/CompBreadCrumbs"
import IconsComp from "@/components/IconsComp"
import { useProgramStore, useAuthStore } from '@/stores'
import { ref, onMounted } from 'vue'

const user = useAuthStore().user
const programs = ref([])
onMounted(async () => {
    programs.value = await useProgramStore().all().then(response => response.data)
})

function programToReturn() {
    !user ? localStorage.setItem('program', JSON.stringify('continuos-flow')) : ''
}

</script>

<template>
    <div class="bg-purple-50">
        <div class="container mx-auto px-6 pt-10 pb-64">
            <div class="hidden md:flex justify-between flex-col md:flex-row items-center mb-8">
                <div>
                    <CompBreadCrumbs>
                        <template v-slot:default>
                            <li>
                                <router-link :to="{ name: 'programas' }">
                                    <IconsComp icon="editais-line" class="w-6 h-6" />
                                    Programas
                                </router-link>
                            </li>
                        </template>
                    </CompBreadCrumbs>
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
                <div class="text-3xl md:text-5xl font-poppins mb-4 md:max-w-lg">
                    <div class="font-bold text-purple-800">
                        Transforme sua inovação em realidade
                    </div>
                    <div class="text-purple-500">
                        Inscreva seu projeto em um de nossos programas.
                    </div>
                </div>
                <div class="text-purple-800">
                    <p class="pb-8">
                        O InovaHC conta com 3 programas de apoio à inovação: In.cube, In.pulse e In.pacte. Esses programas
                        funcionam com editais com chamadas periódicas de acordo com a disponibilidade de recursos e são
                        sempre divulgados no site do InovaHC.
                    </p>
                    <p class="font-bold pb-8">
                        Cada programa atende uma fase especifica do desenvolvimento tecnológico de projetos de inovação:
                    </p>
                    <p>
                        <b>1.</b> Projetos mais iniciais da ideia aos primeiros protótipos se enquadram no <b>In.cube</b>;
                    </p>
                    <p>
                        <b>2.</b> Projetos com protótipos de baixa fidelidade até o piloto são melhor atendidos pelo
                        <b>in.pulse</b> e;
                    </p>
                    <p>
                        <b>3.</b> Projetos em fase de tração e escala no <b>in.pacte</b>.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="back-programas">
        <div class="container mx-auto px-6 -mt-52 md:pb-10 content-programas ">
            <div class="grid grid-cols-1 lg:grid-cols-3 gap-8 z-20">
                <div v-for='program in programs' :key="program.id" class="card z-20">
                    <div class="text-purple-800 text-2xl font-bold font-poppins pb-2">
                        <span class="text-gray-300">{{ program.id }}.</span> {{ program.nome }}
                    </div>
                    <p class="pb-8 text-purple-800">
                        {{ program.descricao }}
                    </p>
                    <div v-if="program.ultimoEdital"
                        class="flex items-center mt-auto justify-between sticky top-[100vh] max-lg:justify-center max-lg:flex-col max-lg:flex-row max-lg:items-center max-lg:gap-4">

                        <router-link :to="{ name: 'programas-' + program.nome.replace('.', '').toLowerCase() }"
                            class="max-lg:w-full">
                            <button type="button" class="btn primary lg:mr-auto max-lg:w-full">Ver
                                mais
                            </button>
                        </router-link>
                        <router-link
                            v-if="$moment(program.ultimoEdital.abertura) <= $moment() && $moment(program.ultimoEdital.encerramento) >= $moment()"
                            :to="user ? { name: 'adicionar-programa-' + program.nome.replace('.', '').toLowerCase() } : { name: 'primeiro-acesso-proponente' }"
                            class="font-bold no-underline lg:ml-auto">
                            Inscreva-se
                        </router-link>
                        <router-link :to="{ name: 'programas-' + program.nome.replace('.', '').toLowerCase() }" v-else
                            class="tag red no-underline lg:ml-auto cursor-pointer">
                            Inscrições encerradas
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="mt-32 mb-16 md:mb-32 grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
                <div class="font-bold font-poppins text-blue-800 text-center text-5xl z-20">
                    <img src="@/assets/img/site/programas.png" alt="Programas" class="w-auto h-auto" />

                </div>
                <div>
                    <div class="font-poppins text-blue-800 text-3xl lg:text-5xl">
                        Meu projeto não se encaixa em nenhum edital aberto.
                    </div>
                    <div class="font-bold font-poppins text-blue-800 text-3xl lg:text-5xl mb-8">E agora?</div>
                    <p>
                        Caso seu projeto não enquadre em nenhum edital aberto do InovaHC você ainda pode submeter seu
                        projeto no nosso fluxo contínuo de entrada de projetos. Neste caso avaliaremos os projetos caso a
                        caso de acordo com as características do projeto e nossos recursos disponíveis.
                    </p>
                    <router-link @click.prevent="programToReturn"
                        :to="user ? { name: 'adicionar-programa' } : { name: 'primeiro-acesso-proponente' }">
                        <button type="button" class="btn primary">Submeter projeto</button>
                    </router-link>
                    <p class="pt-8 flex justify-start font-bold">
                        Atenção: O InovaHC não realiza compra de produtos ou serviços de tecnologia, trabalhamos com
                        codesenvolvimento tecnológico de soluções para saúde que atendam a critérios de diferencial de
                        inovação, impacto social, viabilidade técnica, aderência aos desafios do complexo e
                        sustentabilidade.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>