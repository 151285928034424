<script setup>
import IconsComp from "./IconsComp.vue";
</script>
<template>
  <ul class="comp-breadcrumbs">
    <slot> </slot>
  </ul>
</template>
<script>
export default {
  name: "CompBreadCrumbs",

  components: {
    IconsComp,
  },
};
</script>
