import { defineStore } from "pinia";

import { requestS } from "@/helpers";
import { router } from "@/router";
import { useAlertStore } from "@/stores";

const baseUrl = `${process.env.VUE_APP_API_URL}`;

export const useMonitorStore = defineStore({
  id: "monitor",
  state: () => ({
    monitor: null,
  }),
  actions: {
    async all(perPage = 20, currentPage = 1) {
      try {
        return await requestS
          .get(`${baseUrl}/monitores?per_page=${perPage}&page=${currentPage}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return { data: [] };
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async find(monitor_id) {
      try {
        return await requestS
          .get(`${baseUrl}/monitores/${monitor_id}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return { data: null };
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async store(data) {
      try {
        return await requestS
          .post(`${baseUrl}/monitores`, {
            nome_completo: data.name,
            telefone: data.phone,
            email: data.email,
            instituto_id: data.institute_id,
          })
          .then((response) => {
            return response;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async update(monitor_id, data) {
      try {
        return await requestS
          .put(`${baseUrl}/monitores/${monitor_id}`, {
            nome_completo: data.name,
            telefone: data.phone,
            email: data.email,
            instituto_id: data.institute_id,
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async delete(monitor_id) {
      try {
        return await requestS
          .delete(`${baseUrl}/monitores/${monitor_id}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return false;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async firstAccess(token, data) {
      try {
        return await requestS
          .post(`${baseUrl}/monitores/primeiro-acesso`, {
            token: token,
            old_password: data.old_password,
            password: data.password,
            password_confirmation: data.password_confirmation,
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async search(nome = "", perPage = 20, currentPage = 1) {
      try {
        return await requestS
          .get(
            `${baseUrl}/monitores/search?nome=${nome}&per_page=${perPage}&page=${currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return { data: [] };
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async monitorProposals(monitor_id, perPage = 20, currentPage = 1) {
      try {
        return await requestS
          .get(
            `${baseUrl}/monitores/${monitor_id}/propostas?per_page=${perPage}&page=${currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return { data: [] };
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async proposals(perPage = 20, currentPage = 1) {
      try {
        return await requestS
          .get(
            `${baseUrl}/monitores/autenticado/propostas?per_page=${perPage}&page=${currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return { data: [] };
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
    async proposal(id) {
      try {
        return await requestS
          .get(`${baseUrl}/monitores/autenticado/propostas/${id}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return { data: null };
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
    async projects(perPage = 20, currentPage = 1) {
      try {
        return await requestS
          .get(
            `${baseUrl}/monitores/autenticado/projetos?per_page=${perPage}&page=${currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return { data: [] };
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async project(id) {
      try {
        return await requestS
          .get(`${baseUrl}/monitores/autenticado/projetos/${id}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return { data: null };
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async searchProposals(data) {
      try {
        return await requestS
          .get(
            `${baseUrl}/monitores/autenticado/search-propostas?nome=${data?.nome}&status=${data?.status}&monitor=${data?.monitor}&especialidades=${data?.especialidades}&per_page=${data?.perPage}&page=${data?.currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return { data: [] };
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
    async searchProjects(data) {
      try {
        return await requestS
          .get(
            `${baseUrl}/monitores/autenticado/search-projetos?nome=${data?.nome}&status=${data?.status}&monitor=${data?.monitor}&especialidades=${data?.especialidades}&per_page=${data?.perPage}&page=${data?.currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return { data: [] };
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async allWithoutPagination() {
      try {
        return await requestS
          .get(`${baseUrl}/monitores/todos`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return { data: [] };
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async proposalsAndProjectsCount(monitor_id) {
      try {
        return await requestS
          .get(`${baseUrl}/monitores/${monitor_id}/propostas-e-projetos-count`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return { data: null };
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
  },
});
