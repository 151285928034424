<script setup>
import CompPagination from "@/components/CompPagination";
import IconsComp from "@/components/IconsComp";
import CompSearch from "@/components/CompSearch";
import CompSelect from "@/components/CompSelect";
import CompDropdown from "@/components/CompDropdown";
import CompBreadCrumbs from "@/components/CompBreadCrumbs";
import CompAvaliation from "@/components/CompAvaliation";
import CompModal from "@/components/CompModal";
import { CompDataLoad, CompStatus } from '@/components'
import { ref, onMounted, watch } from "vue";
import { useInstituteStore, useSpecialtyStore } from "@/stores";
import { useRoute, useRouter } from "vue-router";

const router = useRouter()
const route = useRoute()
const institute_id = route.params.id;
const institute = ref(null);
const dataLoad = ref("Carregando..");
const proposalsAndProjectsCount = ref([])
const currentPage = ref(1);
const oldCurrentPage = ref(1)
const firstPage = ref(1);
const lastPage = ref(1);
const perPage = ref(20);
const paginationOnLoad = ref(false)
const selectedProjects = ref([]);
const ArrowIcons = ref([]);
const proposalSelectedModal = ref([])
const classifiedProposalModal = ref([])
const allStatus = ref(['Todos os status', 'Selecionado', 'Plano de trabalho pendente', 'Relatório final pendente', 'Descontinuado'])
const selectedStatus = ref('Todos os status')
const selectedSpecialty = ref(0)
const specialties = ref([])
const search = ref("")
const projects = ref([])

onMounted(async () => {
    institute.value = await useInstituteStore()
        .find(institute_id)
        .then((response) => response?.data);

    if (!institute.value) {
        router.back()
        return;
    }

    await getProjects();

    proposalsAndProjectsCount.value = projects.value.concat(institute.value.especialidades?.map(obj => obj?.propostas))

    specialties.value = institute.value.especialidades;
    specialties.value.push({ id: 0, nome: "Todas especialidades" })
    specialties.value = specialties.value.sort(obj => obj.id)
});

watch(currentPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getProjects();
    }
});

watch(perPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getProjects();
    }
});

async function getProjects() {
    projects.value = [];
    dataLoad.value = "Carregando";

    paginationOnLoad.value = true

    const searchData = {
        nome: search.value,
        status: selectedStatus?.value == 'Todos os status' ? '' : selectedStatus?.value,
        especialidades: selectedSpecialty.value == 0 ? "" : selectedSpecialty.value,
        perPage: perPage.value,
        currentPage: currentPage.value !== oldCurrentPage.value ? currentPage.value : 1
    }
    projects.value = await useInstituteStore().searchProjects(institute_id, searchData);

    currentPage.value = projects.value.meta.current_page;
    oldCurrentPage.value = currentPage.value
    perPage.value = projects.value.meta.per_page;
    lastPage.value = projects.value.meta.last_page;
    projects.value = projects.value.data;

    projects.value.length === 0
        ? (dataLoad.value = "Nenhuma proposta encontrada")
        : "";

    paginationOnLoad.value = false
}

async function proposalSelected(proposal_id) {
    // await useProposalStore().update(proposal_id, { status: 'Selecionado' }).then(() => {
    //     router.push({ name: "painel-inovahc-projetos" })
    // });
}

async function classifiedProposal(proposal_id) {
    // await useProposalStore().update(proposal_id, { status: 'Classificada' }).then(() => {
    //     router.push({ name: "painel-inovahc-proposta", params: { id: proposal_id } })
    // });
}
</script>

<template>
    <section>
        <div class="container mx-auto px-6 pt-10">
            <div class="flex justify-between flex-col md:flex-row items-center mb-8">
                <div class="hidden md:flex">
                    <CompBreadCrumbs>
                        <template v-slot:default>
                            <li>
                                <router-link :to="{ name: 'painel-inovahc-institutos' }">
                                    <IconsComp icon="institutes" class="w-6 h-6" />
                                    Institutos
                                </router-link>
                            </li>
                        </template>
                    </CompBreadCrumbs>
                </div>
                <div class="ml-auto flex items-center">
                    <router-link :to="{ name: 'editar-instituto' }" class="btn primary no-underline">
                        Editar
                    </router-link>
                </div>
            </div>

            <div class="flex flex-col md:items-center md:flex-row gap-6">
                <div class="w-full md:w-6/12">
                    <div class="text-5xl font-bold font-poppins text-purple-800">
                        {{ institute?.nome }}
                    </div>
                    <div class="profile mt-8">
                        <div class="profile-img">
                            <img v-if="institute?.monitor?.avatar" :src="institute?.monitor?.avatar" alt="" />
                            <img v-else src="@/assets/img/template/profile2.png" alt="" />
                        </div>
                        <div class="grid grid-cols-1 gap-0">
                            <div class="text-lg font-bold font-poppins text-purple-800">
                                {{ institute?.monitor?.nome_completo }}
                            </div>
                            <div class="flex-wrap">{{ institute?.monitor?.email }}</div>
                        </div>
                    </div>
                </div>
                <div class="card w-full md:w-6/12">
                    <div class="text-2xl font-bold font-poppins text-purple-800 mb-2">
                        Propostas
                    </div>
                    <div class="grid gap-6 md:grid-cols-3 propostas">
                        <div class="big red">
                            <div class="title">
                                <IconsComp icon="proposta-filled" />
                                <span> {{ proposalsAndProjectsCount?.filter(
                                    (obj) =>
                                        obj?.status?.includes("Triagem pendente") ||
                                        obj?.status?.includes("Selecionar parecerista")
                                ).length }} </span>
                            </div>
                            <div class="description">Em processo de triagem</div>
                        </div>

                        <div class="big red">
                            <div class="title">
                                <IconsComp icon="clock-filled" />
                                <span> {{ proposalsAndProjectsCount?.filter(
                                    (obj) =>
                                        obj?.status?.includes("Aguardando classificação")
                                ).length }} </span>
                            </div>
                            <div class="description">Aguardando classificação</div>
                        </div>

                        <div class="big blue">
                            <div class="title">
                                <IconsComp icon="proposta-filled" />
                                <span> {{ proposalsAndProjectsCount?.filter(
                                    (obj) =>
                                        obj?.status?.includes("Classificada")
                                ).length }} </span>
                            </div>
                            <div class="description">Classificados (Banco de propostas)</div>
                        </div>

                        <div class="small yellow">
                            <div class="title">
                                <IconsComp icon="timer-filled" />
                                <span> {{ proposalsAndProjectsCount?.filter(
                                    (obj) =>
                                        obj?.status?.includes("Aguardando parecer")
                                ).length }} </span>
                            </div>
                            <div class="description">Aguardando parecer</div>
                        </div>

                        <div class="small">
                            <div class="title">
                                <IconsComp icon="reject-filled" />
                                <span> {{ proposalsAndProjectsCount?.filter(
                                    (obj) =>
                                        obj?.status?.includes("Rejeitada")
                                ).length }} </span>
                            </div>
                            <div class="description">Propostas rejeitadas</div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="text-2xl font-bold font-poppins text-purple-800 mb-2">
                        Projetos
                    </div>
                    <div class="grid grid-cols-1 gap-6 propostas">
                        <div class="big green">
                            <div class="title">
                                <IconsComp icon="clock-filled" />
                                <span> {{ proposalsAndProjectsCount?.filter(
                                    (obj) =>
                                        obj?.status?.includes("Selecionado")
                                ).length }} </span>
                            </div>
                            <div class="description">Classificados</div>
                        </div>

                        <div class="small">
                            <div class="title">
                                <IconsComp icon="reject-filled" />
                                <span> {{ proposalsAndProjectsCount?.filter(
                                    (obj) =>
                                        obj?.status?.includes("Descontinuado")
                                ).length }} </span>
                            </div>
                            <div class="description">Finalizados</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-10">
                <ul class="menu-tabs">
                    <li>
                        <router-link :to="{ name: 'painel-inovahc-instituto' }">
                            <span>
                                <IconsComp icon="painel" class="w-6 h-6" />
                            </span>
                            Propostas
                        </router-link>
                    </li>
                    <li class="active">
                        <router-link :to="{ name: 'painel-inovahc-instituto' }">
                            <span>
                                <IconsComp icon="clipboard-line" class="w-6 h-6" />
                            </span>
                            Projetos
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <!-- Filtro -->
    <section class="bg-purple-50 pt-8">
        <div v-if="selectedProjects.length === 0" class="container mx-auto px-6 pb-4">
            <div class="text-sm text-gray-400">Filtrar propostas</div>
            <div class="grid grid-cols-1 md:flex justify-between mt-4">
                <div class="flex items-center">
                    <div class="relative mr-2">
                        <CompSelect @change.prevent="getProjects()" class="max-w-sm" optionName="nome"
                            name="selectSpecialties" :options="specialties" v-model="selectedSpecialty"
                            :optionSelected="0" />
                    </div>
                    <div class="relative">
                        <CompSelect @change.prevent="getProjects()" class="max-w-sm" name="selectSpecialties"
                            :options="allStatus" v-model="selectedStatus" optionSelected="Todos os status " />
                    </div>
                </div>
                <div class="w-96 max-w-[100%]">
                    <CompSearch @submit.prevent="getProjects" name="search" v-model="search" />
                </div>
            </div>
        </div>
        <div v-else class="container mx-auto px-6 pb-4">
            <div class="text-sm text-gray-400">Ação em lote</div>
            <div class="flex items-center">
                <div class="mt-4">
                    <CompSelect v-model="selectedAction" name="action" optionDisabled="Selecione a ação" optionName="nome"
                        :options="[
                            { id: 1, nome: 'Opção 1' },
                            { id: 2, nome: 'Opção 2' },
                            { id: 3, nome: 'Opção 3' },
                        ]" />
                </div>
                <button class="btn primary ml-4" :disabled="!selectedAction">
                    Aplicar
                </button>
            </div>
        </div>
    </section>
    <!-- Tabela -->
    <section class="bg-purple-50">
        <div class="container mx-auto px-6 pt-2 pb-6">
            <div class="overflow-x-scroll md:overflow-hidden rounded-lg border border-white shadow-md">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="flex items-center">
                                    Propostas
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Especialidade
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Inativo
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>Status</div>
                            </th>
                            <th scope="col">
                                <div>Avaliação</div>
                            </th>
                            <th scope="col">
                                <div>
                                    Recebida em
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody v-show="projects.length > 0" v-for="(project, i) in projects" :key="project">
                        <tr>
                            <td>
                                <div class="w-[240px] truncate flex items-center">
                                    {{ project.nome_proposta }}
                                </div>
                            </td>
                            <td>
                                <div class="w-32 truncate">
                                    {{ project?.especialidades?.map(obj => obj.nome).join(", ") }}
                                </div>
                            </td>
                            <td>
                                <div>
                                    {{ ($moment().diff(project?.updated_at, "days")) }}
                                    dias
                                </div>
                            </td>
                            <td>
                                <CompStatus :status="project.status" />
                            </td>
                            <td>
                                <div>-</div>
                            </td>
                            <td>
                                <div>{{ $moment(project.created_at).format('DD/MM/YYYY') }}</div>
                            </td>
                            <td>
                                <div class="flex items-center">
                                    <CompDropdown alignment="right" divStyle="absolute">
                                        <template #toggle>
                                            <IconsComp icon="more" class="w-6 h-6 fill-green-500" />
                                        </template>
                                        <!-- <router-link :to="{ name: 'painel-inovahc-projeto', params: { id: project.id } }">
                                            <li>
                                                <IconsComp icon="download" /> Ver projeto
                                            </li>
                                        </router-link> -->
                                        <a v-if="!project.status?.includes('Classificada') && project.status?.includes('Aguardando classificação')"
                                            @click.prevent="classifiedProposalModal[i] = !classifiedProposalModal[i]"
                                            class="cursor-pointer">
                                            <li>
                                                <IconsComp icon="download" />Adicionar ao banco
                                            </li>
                                        </a>
                                        <a v-if="project.status?.includes('Classificada')"
                                            @click.prevent="proposalSelectedModal[i] = !proposalSelectedModal[i]"
                                            class="cursor-pointer">
                                            <li>
                                                <IconsComp icon="download" />Validar como projeto
                                            </li>
                                        </a>
                                    </CompDropdown>
                                    <div v-if="project.avaliacoes?.length > 0" class="ml-auto">
                                        <button @click.prevent="ArrowIcons[i] = !ArrowIcons[i]" class="ml-1">
                                            <IconsComp :class="{ 'rotate-180': ArrowIcons[i] }" icon="arrow-down"
                                                class="transition-all duration-300 fill-green-500 w-6 h-6" />
                                        </button>
                                    </div>
                                </div>
                                <CompModal v-model="proposalSelectedModal[i]" :isOpen="proposalSelectedModal[i]"
                                    @close="proposalSelectedModal[i] = !proposalSelectedModal[i]" customClass="validacao">
                                    <div class="text-xl font-poppins font-bold mb-2">
                                        Validar a proposta: {{ project?.nome_proposta }}. Como projeto?
                                    </div>
                                    <div class="mb-8">
                                        <p>
                                            Texto de suporte se necessários. Aenean eu ultricies lorem. Aliquam
                                            ac efficitur ante. Etiam nec tempus ante, sed bibendum magna. Donec
                                            sed tortor sed sem malesuada.
                                        </p>
                                    </div>
                                    <div class="flex justify-end">
                                        <button @click.prevent="
                                            proposalSelectedModal[i] = !proposalSelectedModal[i]
                                            " type="button" class="btn secondary">
                                            Cancelar
                                        </button>
                                        <button @click.prevent="
                                            proposalSelectedModal[i] = !proposalSelectedModal[i];
                                        proposalSelected(project.id);
                                        " type="button" class="btn primary">
                                            Confirmar
                                        </button>
                                    </div>
                                </CompModal>
                                <CompModal v-model="classifiedProposalModal[i]" :isOpen="classifiedProposalModal[i]"
                                    @close="classifiedProposalModal[i] = !classifiedProposalModal[i]"
                                    customClass="validacao">
                                    <div class="text-xl font-poppins font-bold mb-2">
                                        Adicionar a proposta: {{ project?.nome_proposta }}. Ao banco de propostas
                                        classificadas?
                                    </div>
                                    <div class="mb-8">
                                        <p>
                                            Texto de suporte se necessários. Aenean eu ultricies lorem. Aliquam
                                            ac efficitur ante. Etiam nec tempus ante, sed bibendum magna. Donec
                                            sed tortor sed sem malesuada.
                                        </p>
                                    </div>
                                    <div class="flex justify-end">
                                        <button @click.prevent="
                                            classifiedProposalModal[i] = !classifiedProposalModal[i]
                                            " type="button" class="btn secondary">
                                            Cancelar
                                        </button>
                                        <button @click.prevent="
                                            classifiedProposalModal[i] = !classifiedProposalModal[i];
                                        classifiedProposal(project.id);
                                        " type="button" class="btn primary">
                                            Confirmar
                                        </button>
                                    </div>
                                </CompModal>
                            </td>
                        </tr>
                        <CompAvaliation :avaliations="project.avaliacoes" :isOpen="ArrowIcons[i]" />
                    </tbody>
                    <tbody v-if="projects?.length === 0">
                        <tr>
                            <td colspan="1000">
                                <CompDataLoad :dataLoad="dataLoad" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
    <!--  Paginação -->
    <section class="bg-purple-50 pb-10">
        <div class="container mx-auto px-6">
            <CompPagination :onLoad="paginationOnLoad" v-model:quantity="perPage" v-model:page="currentPage"
                :firstPage="firstPage" :lastPage="lastPage" />
        </div>
    </section>
</template>
