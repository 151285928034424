<script setup>
import IconsComp from "@/components/IconsComp";
import { ref } from "vue";
import { useField } from "vee-validate";

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
    },
    isPassword: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
    },
    modelValue: {
        type: [String, Number],
    },
    required: {
        type: Boolean,
        default: false,
    },
    schema: {
        required: false,
    },
});

const typePassword = ref("password");

defineEmits(["update:modelValue"]);

const { errorMessage, value } = useField(props.name, props.schema);
</script>
<template>
    <div>
        <label v-if="label" class="flex text-purple-800 mt-2 mb-1">
            {{ label }} <span v-if="required" class="asterisk"></span>
        </label>
        <slot></slot>
        <div :class="isPassword ? 'comp-search' : ''">
            <input v-bind="$attrs" :name="name" :type="isPassword ? typePassword : type"
                :placeholder="placeholder ? placeholder : ''" :value="modelValue"
                @input.prevent="$emit('update:modelValue', $event.target.value)"
                :class="errorMessage ? '!border-red-500' : ''" />
            <div class="relative top-[-3px]">
                <button @click.prevent="
                    typePassword.includes('password')
                        ? (typePassword = 'text')
                        : (typePassword = 'password')
                    " v-if="isPassword" type="button" tabindex="-1">
                    <IconsComp :icon="typePassword.includes('password') ? 'eye-off' : 'eye'" />
                </button>
            </div>
        </div>
        <div class="my-1 peer-invalid:flex text-red-500 text-sm">
            {{ errorMessage }}
        </div>
    </div>
</template>
