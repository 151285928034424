<script setup>
import CompBreadCrumbs from "@/components/CompBreadCrumbs";
import IconsComp from "@/components/IconsComp";
import CompTextArea from "@/components/CompTextArea";
import CompProposalData from "@/components/CompProposalData";
import CompProgress from "@/components/CompProgress";
import CompDropdown from "@/components/CompDropdown";
import CompModal from "@/components/CompModal";
import CompRadio from "@/components/CompRadio";
import CompRange from "@/components/CompRange";
import CompInput from "@/components/CompInput";
import { ref, onMounted } from "vue";
import { useAlertStore, useReviewerStore } from "@/stores";
import { Form, useForm } from "vee-validate";
import * as yup from "yup";
import { ptForm } from "yup-locale-pt";
import { useRoute, useRouter } from "vue-router";
import vClickout from "vue3-clickout";

yup.setLocale(ptForm);

const route = useRoute();
const router = useRouter();
const proposal = ref(null);
const proposal_id = route.params.id;
const reviewerProposal = ref(null);
const reviewRate = ref(0);
const submitConfirmation = ref(false);
const steps = ref([]);
const currentStep = ref(1);
const howRating = ref([]);
const rejectModalIsOpen = ref(false);
const form = ref({
    has_conflict: "",
    has_conflict_answer: "",
    innovation: 6,
    viability: 6,
    solution_potential: 6,
    scale_potential: 6,
    compatibility: 6,
    justify: "",
});
const rejectForm = ref({
    reject_reason: "",
})

onMounted(async () => {
    await getReviewerProposal();
});

async function getReviewerProposal() {
    reviewerProposal.value = await useReviewerStore().myProposal(proposal_id).then(response => response?.data);
    proposal.value = reviewerProposal.value?.proposta
    if (!proposal.value || typeof proposal.value === 'undefined') {
        router.push({ name: "painel-parecerista" });
        return;
    }

    proposal.value.edital?.programa?.nome?.includes('In.')
        ? (steps.value = [1, 2, 3, 4, 5, 6, 7])
        : (steps.value = [1, 2, 3, 4, 5, 6, 7, 8, 9]);

    let answersInNumbers = null;

    if (reviewerProposal.value?.avaliacao) {
        answersInNumbers = Object.entries(reviewerProposal.value?.avaliacao)
            .filter((obj) => !isNaN(obj[1]))
            .map((obj) => obj[1])
            .map(Number);
    }

    if (answersInNumbers && !answersInNumbers.some(isNaN)) {
        reviewRate.value = (
            answersInNumbers?.reduce((a, b) => {
                return a + b;
            }) / answersInNumbers?.length
        ).toFixed(1);
    }
}
const nextStep = () => {
    if (currentStep.value < steps.value.length) {
        currentStep.value++;
    } else if (currentStep.value === steps.value.length) {
        currentStep.value++;
    }
};

const prevStep = () => {
    if (currentStep.value > 1) {
        currentStep.value--;
    }
};

const schema = yup.object({
    // STEP 1
    has_conflict: yup.lazy(() => {
        switch (currentStep.value) {
            case 1:
                return yup.string().max(255).required();
            default:
                return yup.mixed();
        }
    }),
    has_conflict_answer: yup.lazy(() => {
        switch (currentStep.value) {
            case 1:
                return yup.string().when("has_conflict", {
                    is: "Outro:",
                    then: () => yup.string().max(255).required(),
                });
            default:
                return yup.mixed();
        }
    }),
    // STEP 2
    innovation: yup.lazy(() => {
        switch (currentStep.value) {
            case 2:
                return yup.number().min(1).max(10).required();
            default:
                return yup.mixed();
        }
    }),
    // STEP 3
    viability: yup.lazy(() => {
        switch (currentStep.value) {
            case 3:
                return yup.number().min(1).max(10).required();
            default:
                return yup.mixed();
        }
    }),
    // STEP 4
    solution_potential: yup.lazy(() => {
        switch (currentStep.value) {
            case 4:
                return yup.number().min(1).max(10).required();
            default:
                return yup.mixed();
        }
    }),
    // STEP 5
    scale_potential: yup.lazy(() => {
        switch (currentStep.value) {
            case 5:
                return yup.number().min(1).max(10).required();
            default:
                return yup.mixed();
        }
    }),
    // STEP 6
    compatibility: yup.lazy(() => {
        switch (currentStep.value) {
            case 6:
                return yup.number().min(1).max(10).required();
            default:
                return yup.mixed();
        }
    }),
    // STEP 7
    justify: yup.lazy(() => {
        switch (currentStep.value) {
            case 7:
                return yup.string().max(1500).required();
            default:
                return yup.mixed();
        }
    }),
    // STEP 8
    challenges: yup.lazy(() => {
        switch (currentStep.value) {
            case 8:
                return yup.string().max(1500).required();
            default:
                return yup.mixed();
        }
    }),
    // STEP 9
    recomendations: yup.lazy(() => {
        switch (currentStep.value) {
            case 9:
                return yup.string().max(1500).required();
            default:
                return yup.mixed();
        }
    }),
});

const { errors, values, handleSubmit } = useForm({
    validationSchema: schema,
    keepValuesOnUnmount: true,
    keepValuesOnMount: true,
});

const onSubmit = handleSubmit(async (values, { resetForm }) => {
    if (currentStep.value === steps.value.length) {
        await useReviewerStore()
            .acceptProposalReview(proposal_id, values)
            .then(async () => {
                submitConfirmation.value = !submitConfirmation.value;
            });
        resetForm();
        return
    }
    nextStep();
});

async function rejectProposalReview() {
    await useReviewerStore().refuseProposalReview(proposal_id, rejectForm.value).then((res) => {
        rejectModalIsOpen.value = !rejectModalIsOpen.value;
        router.push({ name: "painel-parecerista" });
    });
}

function howRatingClose() {
    howRating.value[currentStep.value] = !howRating.value[currentStep.value];
}
</script>

<template>
    <section>
        <div class="container mx-auto px-6 pt-10 pb-10">
            <div class="flex justify-between flex-col md:flex-row items-center mb-8">
                <div>
                    <CompBreadCrumbs>
                        <template v-slot:default>
                            <li>
                                <router-link :to="{ name: 'painel-parecerista' }">
                                    <IconsComp icon="painel" />
                                    Meu Painel
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'painel-parecerista' }">Propostas
                                </router-link>
                            </li>
                        </template>
                    </CompBreadCrumbs>
                </div>
                <div v-if="reviewerProposal?.status.includes('Parecer enviado')" class="flex items-center">
                    <a @click.prevent="getReviewerProposal" type="button"
                        class="btn primary flex items-center cursor-pointer no-underline">
                        Ver certificado
                        <IconsComp icon="tick-square" class="fill-white w-6 h-6 ml-2" />
                    </a>
                    <a class="cursor-pointer" v-print="'#project-details'">
                        <IconsComp icon="printer" class="fill-green-500 w-6 h-6 ml-2" />
                    </a>
                </div>
                <div v-else class="ml-auto flex">
                    <a class="cursor-pointer" v-print="'#project-details'">
                        <IconsComp icon="printer" class="fill-green-500 w-6 h-6" />
                    </a>
                    <div class="ml-4">
                        <CompDropdown alignment="right">
                            <template #toggle>
                                <IconsComp icon="more" class="w-6 h-6 fill-green-500 rotate-90" />
                            </template>
                            <a class="cursor-pointer" v-print="'#project-details'">
                                <li>
                                    <IconsComp icon="download" />Imprimir proposta
                                </li>
                            </a>
                            <a v-if="reviewerProposal?.status?.includes('Parecer pendente') && $moment(reviewerProposal?.data_prazo).isAfter($moment(), 'day')"
                                @click.prevent="rejectModalIsOpen = !rejectModalIsOpen" class="cursor-pointer">
                                <li>
                                    <IconsComp icon="download" />Recusar avaliação
                                </li>
                            </a>
                        </CompDropdown>
                    </div>
                </div>
            </div>
            <div class="w-full">
                <div class="text-5xl font-bold font-poppins text-purple-800 mb-4">
                    {{ proposal?.nome_proposta }}
                </div>
                <div class="flex items-center">
                    <span class="text-sm mr-1">Status:</span>
                    <button :class="reviewerProposal?.status.includes('Prazo vencido') || reviewerProposal?.status.includes('Parecer pendente') && $moment(reviewerProposal?.data_prazo).isBefore($moment(), 'day')
                        ? 'tag red'
                        : reviewerProposal?.status.includes('Recusado')
                            ? 'tag disabled'
                            : reviewerProposal?.status.includes('Parecer pendente')
                                ? 'tag yellow'
                                : reviewerProposal?.status.includes('Parecer enviado') ||
                                    reviewerProposal?.status.includes('Parecer enviado')
                                    ? 'tag green'
                                    : ''
                        ">
                        {{ reviewerProposal?.status?.includes('Parecer pendente') &&
                            $moment(reviewerProposal?.data_prazo).isBefore($moment(), 'day') ? 'Prazo vencido' :
                            reviewerProposal?.status }}
                    </button>
                </div>
            </div>
        </div>
    </section>
    <Form @submit="rejectProposalReview">
        <CompModal :isOpen="rejectModalIsOpen" @close="rejectModalIsOpen = !rejectModalIsOpen" customClass="triagem">
            <button @click="rejectModalIsOpen = !rejectModalIsOpen" class="absolute right-5 top-5">
                <IconsComp icon="close" class="w-4 h-4" />
            </button>

            <div class="text-3xl text-purple-800 font-bold font-poppins mb-8">
                Recusar avaliação
            </div>
            <div class="mb-6">
                <div class="text-xl text-purple-800 font-bold font-poppins mb-4 relative flex">
                    Qual o motivo da rejeição da avaliação?
                </div>

                <CompTextArea v-model="rejectForm.reject_reason" id="reject_reason" name="reject_reason"
                    placeholder="Motivos..." :schema="yup.string().max(1500).required()" />
            </div>

            <div class="flex justify-end gap-4">
                <button @click.prevent="rejectModalIsOpen = !rejectModalIsOpen" type="button" class="btn secondary w-full">
                    Cancelar
                </button>
                <button type="submit" class="btn primary w-full">
                    Recusar avaliação
                </button>
            </div>
        </CompModal>
    </Form>

    <section id="project-details" class="bg-purple-50">
        <div class="container mx-auto px-6 py-16">
            <div class="flex flex-col-reverse md:flex-row md:gap-6">
                <div class="w-full md:w-6/12">
                    <div class="container mx-auto px-6 py-10">
                        <div class="flex flex-col md:flex-row gap-6">
                            <div class="flex flex-col justify-start">
                                <div class="text-3xl font-bold font-poppins text-purple-800 mb-4">
                                    Informações sobre o projeto
                                </div>
                                <div class="flex gap-8 mb-8">
                                    <div>
                                        <div class="text-sm text-gray-500">
                                            Programa / data de inscrição
                                        </div>
                                        <div class="font-bold text-purple-800">
                                            {{ proposal?.edital?.nome }}
                                        </div>
                                        <div class="font-sm text-purple-500">
                                            Enviada em
                                            {{ $moment(proposal?.created_at).format('DD/MM/YYYY') }}
                                        </div>
                                    </div>
                                    <div>
                                        <div class="text-sm text-gray-500">
                                            Área de especialidade
                                        </div>
                                        <div class="font-bold text-purple-800">
                                            {{
                                                proposal?.especialidades
                                                    .map((obj) => obj.nome)
                                                    .join(", ")
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <!-- COLLAPSE -->
                                <CompProposalData :proposal="proposal" :collapse="[1, 2, 3]" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- sidebar -->
                <section v-if="reviewerProposal?.status.includes('Parecer pendente') && $moment(reviewerProposal?.data_prazo).isAfter($moment(), 'day')
                    " class="w-full md:w-6/12 mb-6">
                    <form @submit.prevent="onSubmit" keep-values>
                        <div class="card">
                            <div class="font-bold font-poppins text-purple-800 text-xl">
                                Avaliação técnica
                            </div>
                            <div>
                                <CompProgress :currentStep="currentStep" :steps="steps" :withoutNames="true" />
                            </div>
                            <div class="text-sm text-gray-400">
                                Etapa {{ currentStep }} de {{ steps.length }}
                            </div>
                            <div v-if="currentStep === 1" id="step1">
                                <div class="text-lg font-bold text-purple-800 mt-2">
                                    Apresenta conflito de interesses para a avaliação deste
                                    projeto?
                                </div>
                                <!-- STEP 1 -->
                                <div class="mt-2">
                                    <CompRadio name="has_conflict" v-model="form.has_conflict" :radios="[
                                        'Não',
                                        'Sim. Conheço o projeto a ser avaliado.',
                                        'Sim. Conheço o(a) autor(a) do projeto a ser avaliado.',
                                        'Outro:',
                                    ]" />
                                    <div v-if="form.has_conflict.includes('Outro:')">
                                        <CompInput type="text" name="has_conflict_answer"
                                            v-model="form.has_conflict_answer" />
                                    </div>
                                </div>
                                <p v-if="form.has_conflict.includes(
                                    'Sim. Conheço o projeto a ser avaliado.'
                                ) || form.has_conflict.includes(
                                    'Sim. Conheço o(a) autor(a) do projeto a ser avaliado.'
                                )
                                    " class="text-red-500 text-sm mt-4">
                                    Apesar do conflito de interesse você pode seguir com o parecer
                                    ou, se preferir, pode recusar a avaliação.
                                </p>
                            </div>
                            <div v-if="currentStep === 2" id="step2">
                                <div class="flex items-center">
                                    <div class="text-lg font-bold text-purple-500 mt-2">
                                        Diferencial de Inovação
                                    </div>
                                    <a @click.prevent="
                                        howRating[currentStep] = !howRating[currentStep]
                                        "
                                        class="cursor-pointer no-underline ml-auto hover:opacity-100 opacity-100">Como
                                        avaliar?
                                    </a>
                                    <div v-if="howRating[currentStep]" @close="
                                        howRating[currentStep] = !howRating[currentStep]
                                        " v-clickout="howRatingClose" class="relative text-left">
                                        <div
                                            class="absolute bg-green-900 text-white z-10 p-4 rounded right-[120px] -top-[200px] w-[500px]">
                                            <button @click.prevent="howRating[currentStep] = !howRating[currentStep]"
                                                class="absolute right-5 top-5 z-20" type="button">
                                                <IconsComp icon="close" class="fill-white w-4 h-4" />
                                            </button>
                                            <div class="font-bold text-lg mb-2">Como Avaliar</div>
                                            <p>
                                                Avaliar informações sobre o diferencial frente a
                                                concorrentes existentes, produtos e/ou serviços
                                                substitutos e a sua atuação no mercado atual.
                                                Avaliação da abrangência da inovação proposta em
                                                relação a seuineditismo. Para que uma invenção se
                                                torne uma inovação, utilizamos a definição
                                                estabelecida no Manual de Oslo onde uma inovação é um
                                                produto ou processo novo ou aprimorado que
                                                significativamente se diferencie do produto ou
                                                processo predecessor, e que, para este edital, tem
                                                possibilidade de se tornar disponível a longo prazo
                                                para usuários em potencial (do produto) ou passou a
                                                ser utilizado (processo).
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <CompRange v-model="form.innovation" name="innovation" />
                                </div>
                            </div>
                            <div v-if="currentStep === 3" id="step3">
                                <div class="flex items-center">
                                    <div class="text-lg font-bold text-purple-500 mt-2">
                                        Viabilidade técnica e qualificação da equipe
                                    </div>
                                    <a @click.prevent="
                                        howRating[currentStep] = !howRating[currentStep]
                                        "
                                        class="cursor-pointer no-underline ml-auto hover:opacity-100 opacity-100">Como
                                        avaliar?
                                    </a>
                                    <div v-if="howRating[currentStep]" @close="
                                        howRating[currentStep] = !howRating[currentStep]
                                        " v-clickout="howRatingClose" class="relative text-left">
                                        <div
                                            class="absolute bg-green-900 text-white z-10 p-4 rounded right-[120px] -top-[200px] w-[500px]">
                                            <button @click.prevent="howRating[currentStep] = !howRating[currentStep]"
                                                class="absolute right-5 top-5 z-20" type="button">
                                                <IconsComp icon="close" class="fill-white w-4 h-4" />
                                            </button>
                                            <div class="font-bold text-lg mb-2">Como Avaliar</div>
                                            <p>
                                                Considera: (i) se a equipe proponente possui
                                                experiência na área de forma que evidenciem a
                                                capacidade técnica de desenvolvimento do projeto.
                                                Estas experiências podem ser clínicas, científicas,
                                                profissionais,entre outras e deverão ser analisadas
                                                pensando na capacidade de execução da equipe frente ao
                                                projeto proposto; (ii) se a solução proposta tem
                                                viabilidade técnica considerando os limites da
                                                tecnologia proposta pelo inscrito — tais como
                                                softwares, equipamentos médicos, fármacos e outras
                                                tecnologias.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <CompRange v-model="form.viability" name="viability" minName="Inviável"
                                        maxName="Totalmente viável" />
                                </div>
                            </div>
                            <div v-if="currentStep === 4" id="step4">
                                <div class="flex items-center">
                                    <div class="text-lg font-bold text-purple-500 mt-2">
                                        Potencial de impacto social da solução
                                    </div>
                                    <a @click.prevent="
                                        howRating[currentStep] = !howRating[currentStep]
                                        "
                                        class="cursor-pointer no-underline ml-auto hover:opacity-100 opacity-100">Como
                                        avaliar?
                                    </a>
                                    <div v-if="howRating[currentStep]" @close="
                                        howRating[currentStep] = !howRating[currentStep]
                                        " v-clickout="howRatingClose" class="relative text-left">
                                        <div
                                            class="absolute bg-green-900 text-white z-10 p-4 rounded right-[120px] -top-[200px] w-[500px]">
                                            <button @click.prevent="howRating[currentStep] = !howRating[currentStep]"
                                                class="absolute right-5 top-5 z-20" type="button">
                                                <IconsComp icon="close" class="fill-white w-4 h-4" />
                                            </button>
                                            <div class="font-bold text-lg mb-2">Como Avaliar</div>
                                            <p>
                                                A solução foi desenhada para gerar impacto positivo na
                                                sociedade, com aumento de acesso às estratégias de
                                                saúde e o proponente quantifica e mapeia os perfis
                                                sociais atingidos, demonstrando conhecimento do
                                                contexto abordado. Por exemplo: redução de custos para
                                                a rede SUS, aumento de acesso, redução de tempo do
                                                paciente no sistema, excelência no atendimento, entre
                                                outros.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <CompRange v-model="form.solution_potential" name="solution_potential"
                                        minName="Nenhum impacto" maxName="Alto Impacto" />
                                </div>
                            </div>
                            <div v-if="currentStep === 5" id="step5">
                                <div class="flex items-center">
                                    <div class="text-lg font-bold text-purple-500 mt-2">
                                        Potencial de escala e sustentabilidade da solução
                                    </div>
                                    <a @click.prevent="
                                        howRating[currentStep] = !howRating[currentStep]
                                        "
                                        class="cursor-pointer no-underline ml-auto hover:opacity-100 opacity-100">Como
                                        avaliar?
                                    </a>
                                    <div v-if="howRating[currentStep]" @close="
                                        howRating[currentStep] = !howRating[currentStep]
                                        " v-clickout="howRatingClose" class="relative text-left">
                                        <div
                                            class="absolute bg-green-900 text-white z-10 p-4 rounded right-[120px] -top-[200px] w-[500px]">
                                            <button @click.prevent="howRating[currentStep] = !howRating[currentStep]"
                                                class="absolute right-5 top-5 z-20" type="button">
                                                <IconsComp icon="close" class="fill-white w-4 h-4" />
                                            </button>
                                            <div class="font-bold text-lg mb-2">Como Avaliar</div>
                                            <p>
                                                Analisa se a solução proposta pode ser convertida em
                                                um modelo de negócio escalável ou em que grau é
                                                replicável nos níveis de saúde (primário, secundário,
                                                terciário), constando como diferencial aquelas que
                                                extrapolam a área da saúde.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <CompRange v-model="form.scale_potential" name="scale_potential"
                                        minName="Insustentável e não escalável"
                                        maxName="Totalmente sustentável e escalável" />
                                </div>
                            </div>
                            <div v-if="currentStep === 6" id="step6">
                                <div v-if="proposal.edital?.programa?.nome?.includes('In.')">
                                    <div class="flex items-center">
                                        <div class="text-lg font-bold text-purple-500 mt-2">
                                            Compatibilidade da solução com a proposta do programa
                                        </div>
                                        <a @click.prevent="
                                            howRating[currentStep] = !howRating[currentStep]
                                            "
                                            class="cursor-pointer no-underline ml-auto hover:opacity-100 opacity-100">Como
                                            avaliar?
                                        </a>
                                        <div v-if="howRating[currentStep]" @close="
                                            howRating[currentStep] = !howRating[currentStep]
                                            " v-clickout="howRatingClose" class="relative text-left">
                                            <div
                                                class="absolute bg-green-900 text-white z-10 p-4 rounded right-[120px] -top-[200px] w-[500px]">
                                                <button @click.prevent="howRating[currentStep] = !howRating[currentStep]"
                                                    class="absolute right-5 top-5 z-20" type="button">
                                                    <IconsComp icon="close" class="fill-white w-4 h-4" />
                                                </button>
                                                <div class="font-bold text-lg mb-2">Como Avaliar</div>
                                                <p>
                                                    Considere o tema do edital vigente e o nível de
                                                    maturidade tecnológica da solução. Programa In.cube:
                                                    projetos da ideia conceitual até o protótipo de
                                                    baixa fidelidade (TRL de 1 a 5); Programa In.pulse:
                                                    estágio dos projetos com protótipo de baixa
                                                    fidelidade até primeiras vendas além de atender ao
                                                    edital publicado (TRL de 5 a 7).
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <CompRange v-model="form.compatibility" name="compatibility"
                                            minName="Totalmente incompatível" maxName="Totalmente compatível" />
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="flex items-center">
                                        <div class="text-lg font-bold text-purple-500 mt-2">
                                            Compatibilidade da solução com o centro de inovação
                                            especialidade Como avaliar?
                                        </div>
                                        <a @click.prevent="
                                            howRating[currentStep] = !howRating[currentStep]
                                            "
                                            class="cursor-pointer no-underline ml-auto hover:opacity-100 opacity-100">Como
                                            avaliar?
                                        </a>
                                        <div v-if="howRating[currentStep]" @close="
                                            howRating[currentStep] = !howRating[currentStep]
                                            " v-clickout="howRatingClose" class="relative text-left">
                                            <div
                                                class="absolute bg-green-900 text-white z-10 p-4 rounded right-[120px] -top-[200px] w-[500px]">
                                                <button @click.prevent="howRating[currentStep] = !howRating[currentStep]"
                                                    class="absolute right-5 top-5 z-20" type="button">
                                                    <IconsComp icon="close" class="fill-white w-4 h-4" />
                                                </button>
                                                <div class="font-bold text-lg mb-2">Como Avaliar</div>
                                                <p>
                                                    Considere o tema do edital vigente e o nível de
                                                    maturidade tecnológica da solução. Programa In.cube:
                                                    projetos da ideia conceitual até o protótipo de
                                                    baixa fidelidade (TRL de 1 a 5); Programa In.pulse:
                                                    estágio dos projetos com protótipo de baixa
                                                    fidelidade até primeiras vendas além de atender ao
                                                    edital publicado (TRL de 5 a 7).
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <CompRange v-model="form.compatibility" name="compatibility"
                                            minName="Totalmente incompatível" maxName="Totalmente compatível" />
                                    </div>
                                </div>
                            </div>
                            <div v-if="currentStep === 7" id="step7">
                                <div class="">
                                    <div class="text-lg font-bold text-purple-500 my-2">
                                        Justifique as notas atribuídas e, caso deseje, aponte pontos
                                        de melhoria
                                    </div>
                                    <div>
                                        <CompTextArea name="justify" v-model="form.justify" />
                                    </div>
                                </div>
                            </div>
                            <div v-if="currentStep === 8" id="step8">
                                <div class="">
                                    <div class="text-lg font-bold text-purple-500 my-2">
                                        Quais os desafios para viabilizar esta solução?
                                    </div>
                                    <div>
                                        <CompTextArea name="challenges" v-model="form.challenges" />
                                    </div>
                                </div>
                            </div>
                            <div v-if="currentStep === 9" id="step9">
                                <div class="">
                                    <div class="text-lg font-bold text-purple-500 my-2">
                                        Quais as recomendações e sugestões para viabilizar esta
                                        solução?
                                    </div>
                                    <div>
                                        <CompTextArea name="recomendations" v-model="form.recomendations" />
                                    </div>
                                </div>
                            </div>
                            <div class="flex items-center mt-4">
                                <button v-if="currentStep == 1" @click.prevent="rejectModalIsOpen = !rejectModalIsOpen"
                                    type="button" class="btn secondary w-full">
                                    Recusar Avaliação
                                </button>
                                <button v-else @click.prevent="prevStep" type="button" class="btn secondary w-full">
                                    Voltar
                                </button>
                                <button v-if="currentStep !== steps.length" type="submit" class="btn primary w-full"
                                    :disabled="Object.keys(errors).length > 0">
                                    Avançar
                                </button>
                                <button v-else type="submit" class="btn primary w-full">
                                    Submeter parecer
                                </button>
                            </div>
                        </div>
                    </form>
                    <CompModal v-model="submitConfirmation" :isOpen="submitConfirmation" @close="
                        submitConfirmation = !submitConfirmation; getReviewerProposal()
                        " customClass="validacao">
                        <div class="text-xl font-poppins font-bold mb-2">
                            Parecer enviado com sucesso!
                        </div>
                        <div class="mb-8">
                            <p>
                                Texto de suporte se necessários. Aenean eu ultricies lorem.
                                Aliquam ac efficitur ante. Etiam nec tempus ante, sed bibendum
                                magna. Donec sed tortor sed sem malesuada.
                            </p>
                        </div>
                        <div class="flex justify-end">
                            <button @click.prevent="
                                submitConfirmation = !submitConfirmation; getReviewerProposal()
                                " type="button" class="btn secondary">
                                Fechar
                            </button>
                            <a @click.prevent="
                                submitConfirmation = !submitConfirmation; getReviewerProposal()
                                " class="cursor-pointer no-underline">
                                <button type="button" class="btn primary flex items-center">
                                    Ver certificado
                                    <IconsComp icon="tick-square" class="fill-white w-6 h-6 ml-2" />
                                </button>
                            </a>
                        </div>
                    </CompModal>
                </section>
                <section v-else-if="reviewerProposal?.status.includes('Parecer enviado')"
                    class="w-full md:w-6/12 mb-6 py-20">
                    <div class="card">
                        <div class="flex items-center">
                            <div class="text-purple-800 font-bold font-poppins text-xl">
                                Resultado da avaliação
                            </div>
                            <div class="text-purple-500 font-bold font-poppins text-xl ml-auto">
                                {{ reviewRate }} pts
                            </div>
                        </div>
                        <div class="mt-10 grid grid-cols-3 gap-4 items-end pb-8 border-b border-purple-500">
                            <div>
                                <div class="text-sm mb-2">Diferencial de Inovação</div>
                                <div class="text-purple-500">
                                    {{
                                        reviewerProposal?.avaliacao?.innovation
                                    }}
                                    <span class="text-sm">pts</span>
                                </div>
                            </div>
                            <div>
                                <div class="text-sm mb-2">
                                    Viabilidade técnica e qualificação da equipe
                                </div>
                                <div class="text-purple-500">
                                    {{
                                        reviewerProposal?.avaliacao?.innovation
                                    }}
                                    <span class="text-sm">pts</span>
                                </div>
                            </div>
                            <div>
                                <div class="text-sm mb-2">
                                    Potencial de impacto social da solução
                                </div>
                                <div class="text-purple-500">
                                    {{
                                        reviewerProposal?.avaliacao?.solution_potential
                                    }}
                                    <span class="text-sm">pts</span>
                                </div>
                            </div>
                            <div>
                                <div class="text-sm mb-2">
                                    Potencial de escala e sustentabilidade da solução
                                </div>
                                <div class="text-purple-500">
                                    {{
                                        reviewerProposal?.avaliacao?.scale_potential
                                    }}
                                    <span class="text-sm">pts</span>
                                </div>
                            </div>
                            <div>
                                <div class="text-sm mb-2">
                                    Compatibilidade da solução com a proposta do programa
                                </div>
                                <div class="text-purple-500">
                                    {{
                                        reviewerProposal?.avaliacao?.compatibility
                                    }}
                                    <span class="text-sm">pts</span>
                                </div>
                            </div>
                        </div>
                        <div class="mt-8">
                            <div class="text-purple-500 text-lg mb-2">
                                Justificativa da nota
                            </div>
                            <div>
                                {{
                                    reviewerProposal?.avaliacao?.justify
                                }}
                            </div>
                            <div v-if="reviewerProposal?.avaliacao?.challenges
                                    ">
                                <div class="text-purple-500 text-lg mt-4 mb-2">
                                    Quais os desafios para viabilizar esta solução?
                                </div>
                                <div>
                                    {{
                                        reviewerProposal?.avaliacao?.challenges
                                    }}
                                </div>
                            </div>
                            <div v-if="reviewerProposal?.avaliacao?.recomendations
                                    ">
                                <div class="text-purple-500 text-lg mt-4 mb-2">
                                    Quais as recomendações e sugestões para viabilizar esta
                                    solução?
                                </div>
                                <div class="border-b border-purple-500 pb-4">
                                    {{
                                        reviewerProposal?.avaliacao?.recomendations
                                    }}
                                </div>
                            </div>
                            <div class="text-purple-500 text-lg mt-4 mb-2">
                                Há conflito de interesses para a avaliação deste projeto?
                            </div>
                            <div>
                                {{
                                    reviewerProposal?.avaliacao?.has_conflict
                                }}
                                <p class="text-sm">
                                    {{
                                        reviewerProposal?.avaliacao?.has_conflict_answer
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </section>
</template>
