<script setup>
import { useField, Field } from "vee-validate";
import IconsComp from "@/components/IconsComp";
import { ref } from "vue";
import * as yup from "yup";

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    id: {
        type: String,
        required: true,
    },
    modelValue: {
        type: Object,
    },
    documentTypes: {
        type: String,
    },
    required: {
        type: Boolean,
        default: false,
    },
    schema: {
        required: false,
    },
});

const emits = defineEmits(["update:modelValue"]);
const formatError = ref(null);

function change(event) {
    if (
        [
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/pdf",
        ].includes(event.target.files[0]["type"]) && props.documentTypes
    ) {
        emits("update:modelValue", event.target.files[0]);
        formatError.value = null;
    } else if (!props.documentTypes) {
        emits("update:modelValue", event.target.files[0]);
        formatError.value = null;
    }
    else {
        formatError.value =
            "Extensão do arquivo incompatível. São aceitos apenas documentos com extensão PDF, DOC ou DOCX.";
    }
}
function drop(event) {
    if (
        [
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/pdf",
        ].includes(event.dataTransfer?.files[0]["type"]) && props.documentTypes
    ) {
        emits("update:modelValue", event.dataTransfer.files[0]);
        formatError.value = null;
    } else if (!props.documentTypes) {
        emits("update:modelValue", event.dataTransfer.files[0]);
        formatError.value = null;
    }
    else {
        formatError.value =
            "Extensão do arquivo incompatível. São aceitos apenas documentos com extensão PDF, DOC ou DOCX.";
    }
}
const { errorMessage, value } = useField(props.name, props.schema);
</script>
<template>
    <div v-if="!modelValue" @dragover.prevent @drop.prevent
        class="bg-green-50 w-full my-1 py-6 border-dotted border-2 border-green-500 rounded-md" :class="modelValue
            ? '!bg-green-500'
            : formatError || errorMessage
                ? '!bg-red-50 !border-red-500'
                : ''
            ">
        <div @drop="drop">
            <IconsComp icon="upload" class="fill-green-500 w-10 h-10 mx-auto mb-4" />
            <p class="text-center text-sm">
                Arraste e solte o arquivo que você quer enviar ou clique para
            </p>
            <div class="text-center mt-4">
                <label :for="id">
                    <a class="btn secondary cursor-pointer no-underline">
                        Selecionar arquivo
                    </a>
                    <input :name="name" v-bind="$attrs" :id="id" @change.prevent="change" type="file" class="hidden"
                        :accept="documentTypes" />
                </label>
                <input type="hidden" :value="value" />
                <div class="mt-4 peer-invalid:flex text-red-500 text-sm">
                    {{ errorMessage }}
                </div>
            </div>
        </div>
    </div>
    <div v-else @dragover.prevent @drop.prevent>
        <div class="bg-green-500 rounded-md py-14" @drop="drop">
            <p class="text-center text-white">
                {{ modelValue.name ? modelValue.name : modelValue }}
            </p>
            <a @click="$emit('update:modelValue', null)" class="bg-white cursor-pointer">
                <IconsComp icon="trash" class="text-center mx-auto fill-green-500 bg-white rounded-full p-2 w-12 h-12" />
            </a>
        </div>
    </div>
    <p v-if="formatError" class="flex items-center mt-4 peer-invalid:flex text-red-500">
        <IconsComp icon="close" class="fill-red-500 w-4 h-4 mr-2" />
        {{ formatError }}
    </p>
</template>
