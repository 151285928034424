<script setup>
import CompBreadCrumbs from "@/components/CompBreadCrumbs";
import IconsComp from "@/components/IconsComp";
import CompDropdown from "@/components/CompDropdown";
import CompModal from "@/components/CompModal";
import CompRadio from "@/components/CompRadio";
import { ref, onMounted } from "vue";
import { useMonitorStore, useProposalStore } from "@/stores";
import { useRoute, useRouter } from "vue-router";
import { CompDataLoad, CompProposalData, CompTextArea } from "@/components";
import { Form, useForm } from "vee-validate";
import * as yup from "yup";
import { ptForm } from "yup-locale-pt";

yup.setLocale(ptForm);

const route = useRoute();
const router = useRouter();
const rejectModalIsOpen = ref(false);
const confirmRejectModalIsOpen = ref(false);
const addModalIsOpen = ref(false);
const proposalSelectedModal = ref(false)
const classifiedProposalModal = ref(false)
const proposal_id = route.params.id;
const proposal = ref(null);
const form = ref({
    motivation: [],
    comments: "",
});

onMounted(async () => {
    proposal.value = await useMonitorStore().proposal(proposal_id).then(response => response?.data);
    if (!proposal.value) {
        router.back();
        return;
    }

    if (!proposal.value) {
        router.back();
        return;
    } else if (
        proposal.value.status == "Selecionar parecerista" ||
        proposal.value.status == "Aguardando parecer"
    ) {
        router.push({
            name: "selecionar-pareceristas-proposta",
            params: { id: proposal_id },
        });
        return;
    }
});

async function updateProposal(status) {
    const data = {};
    data.status = status;

    if (form.value.motivation.length > 0 && form.value.comments) {
        data.motivation = form.value.motivation;
        data.comments = form.value.comments;
    }

    await useProposalStore().update(proposal_id, data);
    status == 'Rejeitada' ? router.go(0) : ''
}

async function proposalSelected() {
    await useProposalStore().update(proposal_id, { status: 'Selecionado' }).then(async () => {
        router.push({ name: "painel-inovahc-projetos" })
    });
}

async function classifiedProposal() {
    await useProposalStore().update(proposal_id, { status: 'Classificada' }).then(async () => {
        router.go(0)
    });
}
</script>

<template>
    <section>
        <div class="container mx-auto px-6 pt-10 pb-10">
            <div class="hidden md:flex justify-between flex-col md:flex-row items-center mb-8">
                <div>
                    <CompBreadCrumbs>
                        <template v-slot:default>
                            <li>
                                <router-link :to="{ name: 'painel-monitor' }">
                                    <IconsComp icon="painel" />
                                    Meu Painel
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'painel-monitor' }">Propostas
                                </router-link>
                            </li>
                        </template>
                    </CompBreadCrumbs>
                </div>
                <div class="ml-auto flex">
                    <a v-print="'#project-details'" class="cursor-pointer">
                        <IconsComp icon="printer" class="fill-green-500 w-6 h-6" />
                    </a>
                    <div class="ml-4">
                        <CompDropdown alignment="right">
                            <template #toggle>
                                <IconsComp icon="more" class="w-6 h-6 fill-green-500 rotate-90" />
                            </template>
                            <a v-if="proposal?.status == 'Triagem pendente'"
                                @click.prevent="addModalIsOpen = true; updateProposal('Selecionar parecerista');"
                                class="cursor-pointer">
                                <li>
                                    <IconsComp icon="download" />Aprovar
                                    proposta
                                </li>
                            </a>
                            <a v-if="!proposal.status.includes('Rejeitada')" @click.prevent="
                                rejectModalIsOpen = !rejectModalIsOpen
                                " class="cursor-pointer">
                                <li>
                                    <IconsComp icon="download" />Rejeitar proposta
                                </li>
                            </a>
                            <a v-if="!proposal.status?.includes('Classificada')"
                                @click.prevent="classifiedProposalModal = !classifiedProposalModal" class="cursor-pointer">
                                <li>
                                    <IconsComp icon="download" />Adicionar ao banco
                                </li>
                            </a>
                        </CompDropdown>
                    </div>
                </div>
            </div>
            <div class="w-full">
                <div class="text-5xl font-bold font-poppins text-purple-800 mb-4">
                    {{ proposal?.nome_proposta }}
                </div>
                <div class="flex items-center">
                    <span class="text-sm mr-1">Status:</span>
                    <button :class="proposal?.status.includes('Triagem pendente') ||
                        proposal?.status.includes('Selecionar parecerista') ||
                        proposal?.status.includes('Aguardando classificação')
                        ? 'tag red'
                        : proposal?.status.includes('Rejeitada')
                            ? 'tag disabled'
                            : proposal?.status.includes('Aguardando parecer') || proposal?.status.includes('Plano de trabalho') || proposal?.status.includes('Relatório final')
                                ? 'tag yellow'
                                : proposal?.status.includes('Classificada')
                                    ? 'tag blue'
                                    : ''
                        ">{{ proposal?.status }}</button>
                </div>
            </div>
        </div>
    </section>

    <!-- Detalhes do Projeto -->
    <section id="project-details" class="bg-purple-50 bg-opacity-80">
        <div class="container mx-auto px-6 py-10">
            <div class="flex flex-col md:flex-row gap-6">
                <div class="w-full md:w-8/12 flex flex-col justify-start">
                    <div class="text-3xl font-bold font-poppins text-purple-800 mb-4">
                        Informações sobre o projeto
                    </div>
                    <div class="flex gap-8 mb-8">
                        <div>
                            <div class="text-sm text-gray-500">
                                Programa / data de inscrição
                            </div>
                            <div class="font-bold text-purple-800">Fluxo contínuo</div>
                            <div class="font-sm text-purple-500">
                                Enviada em
                                {{ $moment(proposal?.created_at).format('DD/MM/YYYY') }}
                            </div>
                        </div>
                        <div>
                            <div class="text-sm text-gray-500">Área de especialidade</div>
                            <div class="font-bold text-purple-800">
                                {{ proposal?.especialidades?.map((e) => e.nome)?.join(", ") }}
                            </div>
                        </div>
                    </div>
                    <!-- COLLAPSE -->
                    <CompProposalData :proposal="proposal" :collapse="[1, 2, 3]" />
                </div>

                <div v-if="proposal?.status == 'Triagem pendente'" class="w-full md:w-4/12 flex flex-col">
                    <div class="ml-auto">
                        <button @click.prevent="addModalIsOpen = true; updateProposal('Selecionar parecerista');"
                            class="btn primary large flex items-center">
                            Aprovar proposta
                            <IconsComp icon="success-line" class="w-6 h-6 fill-white ml-2" />
                        </button>
                    </div>
                    <div class="mt-4 ml-auto">
                        <button @click.prevent="rejectModalIsOpen = true" class="btn secondary large flex items-center">
                            Rejeitar proposta
                            <IconsComp icon="close-line" class="w-6 h-6 fill-green-500 ml-2" />
                        </button>
                    </div>
                </div>
            </div>

            <Form @submit="updateProposal('Rejeitada')">
                <CompModal :isOpen="rejectModalIsOpen" @close="rejectModalIsOpen = !rejectModalIsOpen"
                    customClass="triagem">
                    <button @click="rejectModalIsOpen = !rejectModalIsOpen" class="absolute right-5 top-5">
                        <IconsComp icon="close" class="w-4 h-4" />
                    </button>

                    <div class="text-3xl text-purple-800 font-bold font-poppins mb-8">
                        Rejeitar proposta
                    </div>

                    <div class="mb-8">
                        <div class="text-xl text-purple-800 font-bold font-poppins mb-2">
                            Qual o motivo da rejeição da proposta?
                        </div>

                        <CompRadio v-model="form.motivation" name="motivation" :radios="[
                            'Especialidade em saúde não condiz com a proposta (enviar de volta para InovaHC)',
                            'A proposta enviada é uma proposta de venda',
                            'Formulário preenchido com baixa qualidade ou em desacordo com o edital',
                            'Outros',
                        ]" :schema="yup.string().max(255).required()" />
                    </div>
                    <div class="mb-6">
                        <div class="text-xl text-purple-800 font-bold font-poppins mb-2 relative flex">
                            Comentários sobre a avaliação
                        </div>

                        <CompTextArea v-model="form.comments" id="comments" name="comments" placeholder="Comentários..."
                            :schema="yup.string().max(1500).required()" />
                    </div>

                    <div class="flex justify-end gap-4">
                        <button @click.prevent="rejectModalIsOpen = !rejectModalIsOpen" type="button"
                            class="btn secondary w-full">
                            Cancelar
                        </button>
                        <button @click.prevent="$refs.rejectSubmitButton.click()" type="button" class="btn primary w-full">
                            Rejeitar proposta
                        </button>
                    </div>
                </CompModal>
                <button ref="rejectSubmitButton" type="submit" class="hidden"></button>
            </Form>

            <CompModal v-model="confirmRejectModalIsOpen" :isOpen="confirmRejectModalIsOpen"
                @close="confirmRejectModalIsOpen = !confirmRejectModalIsOpen" customClass="validacao">
                <div class="text-xl font-poppins font-bold mb-2">
                    Você acaba de rejeitar uma proposta
                </div>
                <div class="mb-8">
                    <p>
                        Texto de suporte se necessários. Aenean eu ultricies lorem. Aliquam
                        ac efficitur ante. Etiam nec tempus ante, sed bibendum magna. Donec
                        sed tortor sed sem malesuada.
                    </p>
                </div>
                <div class="flex justify-end">
                    <button @click.prevent="
                        confirmRejectModalIsOpen = !confirmRejectModalIsOpen
                        " type="button" class="btn secondary">
                        Cancelar
                    </button>
                    <button @click.prevent="
                        confirmRejectModalIsOpen = !confirmRejectModalIsOpen;
                    updateProposal('Rejeitada');
                    " type="button" class="btn primary">
                        Confirmar
                    </button>
                </div>
            </CompModal>
            <button ref="rejectSubmitButton" type="submit" class="hidden"></button>

            <CompModal v-model="addModalIsOpen" :isOpen="addModalIsOpen" @close="addModalIsOpen = !addModalIsOpen"
                customClass="validacao">
                <div class="text-xl font-poppins font-bold mb-2">
                    Proposta aprovada com sucesso!
                </div>
                <div class="mb-8">
                    Texto de suporte se necessários. Aenean eu ultricies lorem. Aliquam ac
                    efficitur ante. Etiam nec tempus ante, sed bibendum magna. Donec sed
                    tortor sed sem malesuada.
                </div>
                <div class="flex justify-end">
                    <button @click.prevent="addModalIsOpen = !addModalIsOpen" type="button" class="btn secondary">
                        Cancelar
                    </button>
                    <router-link :to="{ name: 'selecionar-pareceristas-proposta', params: { id: proposal_id } }"
                        class="btn primary no-underline">
                        Selecionar pareceristas
                    </router-link>
                </div>
            </CompModal>
            <button ref="submitButton" type="submit" class="hidden"></button>

            <CompModal v-model="proposalSelectedModal" :isOpen="proposalSelectedModal"
                @close="proposalSelectedModal = !proposalSelectedModal" customClass="validacao">
                <div class="text-xl font-poppins font-bold mb-2">
                    Validar a proposta: {{ proposal?.nome_proposta }}. Como projeto?
                </div>
                <div class="mb-8">
                    <p>
                        Texto de suporte se necessários. Aenean eu ultricies lorem. Aliquam
                        ac efficitur ante. Etiam nec tempus ante, sed bibendum magna. Donec
                        sed tortor sed sem malesuada.
                    </p>
                </div>
                <div class="flex justify-end">
                    <button @click.prevent="
                        proposalSelectedModal = !proposalSelectedModal
                        " type="button" class="btn secondary">
                        Cancelar
                    </button>
                    <button @click.prevent="
                        proposalSelectedModal = !proposalSelectedModal;
                    proposalSelected(proposal.id);
                    " type="button" class="btn primary">
                        Confirmar
                    </button>
                </div>
            </CompModal>
            <CompModal v-model="classifiedProposalModal" :isOpen="classifiedProposalModal"
                @close="classifiedProposalModal = !classifiedProposalModal" customClass="validacao">
                <div class="text-xl font-poppins font-bold mb-2">
                    Adicionar a proposta: {{ proposal?.nome_proposta }}. Ao banco de propostas
                    classificadas?
                </div>
                <div class="mb-8">
                    <p>
                        Texto de suporte se necessários. Aenean eu ultricies lorem. Aliquam
                        ac efficitur ante. Etiam nec tempus ante, sed bibendum magna. Donec
                        sed tortor sed sem malesuada.
                    </p>
                </div>
                <div class="flex justify-end">
                    <button @click.prevent="
                        classifiedProposalModal = !classifiedProposalModal
                        " type="button" class="btn secondary">
                        Cancelar
                    </button>
                    <button @click.prevent="
                        classifiedProposalModal = !classifiedProposalModal;
                    classifiedProposal(proposal.id);
                    " type="button" class="btn primary">
                        Confirmar
                    </button>
                </div>
            </CompModal>
        </div>
    </section>
</template>
