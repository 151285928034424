<script setup>
import CompInput from "@/components/CompInput";
import IconsComp from "@/components/IconsComp";
import CompModal from "@/components/CompModal";
import CompMultiSelect from "@/components/CompMultiSelect";
import CompTextArea from "@/components/CompTextArea";
import { ref, onMounted } from "vue";
import { useReviewerStore, useSpecialtyStore, useAuthStore } from "@/stores";
import * as yup from "yup";
import { Form, Field } from "vee-validate";
import { ptForm } from "yup-locale-pt";
import { useRouter, useRoute } from "vue-router";
import { vMaska } from 'maska';

yup.setLocale(ptForm);

const route = useRoute();
const router = useRouter();
const authStore = ref(useAuthStore());
const reviewer_id = route.params.id;
const reviewer = ref(null);
const cancelModalIsOpen = ref(false);
const addModalIsOpen = ref(false);
const loading = ref(false);
const form = ref({
    treatment: "",
    name: "",
    email: "",
    phone: "",
    specialties: [],
    professional_experience: "",
    how_many_projects: 0,
});

onMounted(async () => {
    loading.value = true;
    reviewer.value = await useReviewerStore().find(reviewer_id).then(response => response?.data);

    if (!reviewer.value) {
        router.back();
    }

    form.value.treatment = reviewer.value.tratamento;
    form.value.name = reviewer.value.nome_completo;
    form.value.email = reviewer.value.email;
    form.value.phone = reviewer.value.telefone;
    form.value.specialties = reviewer.value.especialidades.map(obj => obj?.id);
    form.value.professional_experience = reviewer.value.experiencia_profissional;
    form.value.how_many_projects = reviewer.value.disponibilidade_para_projetos;
    loading.value = false;
});

async function onSubmit(values, { resetForm }) {
    await useReviewerStore().update(reviewer_id, values).then(() => {
        resetForm();
        router.push({ name: "painel-inovahc-pareceristas" });
    });
}
</script>

<template>
    <button @click.prevent="$router.back()" class="hidden md:grid absolute top-28 right-24">
        <span class="flex font-bold text-green-500">
            Fechar
            <IconsComp icon="close-line" class="w-8 h-8 fill-green-500 ml-2" />
        </span>
    </button>
    <div v-if="!loading" class="grid grid-cols-1 gap-2">
        <div class="mx-auto w-full max-w-3xl py-12 px-6">
            <div class="text-purple-800 font-poppins text-2xl mb-4">
                <b>Editar:</b> {{ reviewer?.nome_completo }}
            </div>
            <div v-if="reviewer?.responsavel">
                <div class="text-sm text-gray-400">Criado por</div>
                <div class="text-purple-500">
                    {{ reviewer?.responsavel?.nome_completo }}
                </div>
            </div>

            <Form @submit="onSubmit" class="form-simples mt-8">
                <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mb-2">
                    <div>
                        <CompInput v-model="form.treatment" label="Tratamento" type="text" name="treatment"
                            placeholder="Prof. Dr." :schema="yup.string()" />
                    </div>

                    <div class="md:col-span-2">
                        <CompInput v-model="form.name" label="Nome completo do avaliador" type="text" name="evaluator_name"
                            placeholder="Nome" :required="true" :schema="yup.string().required()" />
                    </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
                    <div>
                        <CompInput v-model="form.email" label="E-mail" type="email" name="email" placeholder="E-mail"
                            :required="true" :schema="yup.string().required()" />
                    </div>

                    <div>
                        <CompInput v-model="form.phone" label="Telefone" type="text" name="phone" placeholder="Telefone"
                            v-maska data-maska="(##) #####-####"
                            :schema="yup.string()
                                .matches(phoneRegExp, 'Apenas números').min(15, 'O telefone deve ser completo').required()" />
                    </div>
                </div>
                <div class="mb-4">
                    <CompMultiSelect name="specialties" placeholder="Selecione a área" label="Área de especialidade"
                        :required="true" v-model="form.specialties" :schema="yup.array().min(1).required()" />
                </div>

                <CompTextArea label="Experiência profissional" name="professional_experience" placeholder="Digite aqui"
                    :required="true" v-model="form.professional_experience" :schema="yup.string().max(340).required()">
                    <p class="text-sm">Breve resumo em até 340 caracteres</p>
                </CompTextArea>

                <div>
                    <CompInput v-model="form.how_many_projects"
                        label="Disponibilidade de avaliar quantos projetos em um mês?" type="number"
                        placeholder="Digite o número de projetos" name="how_many_projects" :required="true"
                        :schema="yup.number().required()">
                        <p class="text-sm">
                            Prevemos a necessidade de cerca de 30 à 60 minutos por projeto
                        </p>
                    </CompInput>
                </div>

                <div class="grid grid-cols-2 gap-6">
                    <button @click.prevent="cancelModalIsOpen = true" type="button" class="btn secondary w-full my-8">
                        Cancelar
                    </button>
                    <button @click.prevent="addModalIsOpen = true" type="button" class="btn primary w-full my-8">
                        Salvar alterações
                    </button>
                </div>

                <CompModal v-model="cancelModalIsOpen" :isOpen="cancelModalIsOpen"
                    @close="cancelModalIsOpen = !cancelModalIsOpen" customClass="validacao">
                    <div class="text-xl font-poppins font-bold mb-2">Confirmar ação</div>
                    <div class="mb-8">
                        <p>
                            Se você fechar essa janela irá perder todas as edições feitas até
                            agora.
                        </p>
                        <p>
                            Clique em “Ok” para sair ou em “Cancelar” para permanecer na
                            página.
                        </p>
                    </div>
                    <div class="flex justify-end">
                        <button @click.prevent="cancelModalIsOpen = !cancelModalIsOpen" type="button" class="btn secondary">
                            Cancelar
                        </button>
                        <button @click.prevent="$router.back()" type="button" class="btn primary">
                            Ok
                        </button>
                    </div>
                </CompModal>

                <CompModal v-model="addModalIsOpen" :isOpen="addModalIsOpen" @close="addModalIsOpen = !addModalIsOpen"
                    customClass="validacao">
                    <div class="text-xl font-poppins font-bold mb-2">
                        Atualizar perfil
                    </div>
                    <div class="mb-8">
                        Você tem certeza que deseja atualizar essas informações?
                    </div>
                    <div class="flex justify-end">
                        <button @click.prevent="addModalIsOpen = !addModalIsOpen" type="button" class="btn secondary">
                            Cancelar
                        </button>
                        <button type="button" @click.prevent="
                            $refs.submitButton.click();
                        addModalIsOpen = !addModalIsOpen;
                        " class="btn primary">
                            Sim
                        </button>
                    </div>
                </CompModal>
                <button ref="submitButton" type="submit" class="hidden"></button>
            </Form>
        </div>
    </div>
    <div v-else class="flex items-center justify-center align-middle h-full">
        <div class="text-center inline-block align-middle my-10 text-xl font-bold text-purple-800">
            Carregando..
        </div>
    </div>
</template>
