<script setup>
import CompBreadCrumbs from "@/components/CompBreadCrumbs";
import IconsComp from "@/components/IconsComp";
import CompToogle from "@/components/CompToogle";
import CompInput from "@/components/CompInput";
import CompRadio from "@/components/CompRadio";
import CompModal from "@/components/CompModal";
import CompCheckbox from "@/components/CompCheckbox";
import { onMounted, ref } from "vue";
import { useAuthStore, useUserStore, useAlertStore } from "@/stores";
import { Form, useForm } from "vee-validate";
import * as yup from "yup";
import { ptForm } from "yup-locale-pt";
import { useRouter } from 'vue-router'
import { vMaska } from 'maska';

yup.setLocale(ptForm);

const router = useRouter();
const user = useAuthStore().user;
const receivedInvitations = ref(false);
const receivedNotifications = ref(false);
const showImage = ref(null)
const deleteAccountSteps = ref([1, 2, 3]);
const deleteAccountCurrentStep = ref(1);
const passwordModal = ref(false);
const deleteAccountModal = ref(false);
const imageDeleteModal = ref(false)
const avatar = ref(null)
const passwordUpdateFormErrorMessage = ref(null)
const passwordUpdateFormSuccessMessage = ref(null)
const passwordUpdateFormErrors = ref([])
const form = ref({
    email: user.email,
    first_name: user.primeiro_nome,
    treatment: user.tratamento,
    phone: user.telefone,
    full_name: user.nome_completo,
    specialties: [],
});
const passwordUpdateForm = ref({
    password: "",
    new_password: "",
    new_password_confirmation: "",
});
const deleteAccountForm = ref({
    motivation: "",
    email_confirm: "",
    check: [],
});

const alertStore = useAlertStore();

onMounted(async () => {
    receivedNotifications.value = user.notificacoes_por_email;
    receivedInvitations.value = user.receber_convites
})

const schema = yup.object({
    // STEP 1
    motivation: yup.lazy(() => {
        switch (deleteAccountCurrentStep.value) {
            case 1:
                return yup.string().required();
            default:
                return yup.mixed();
        }
    }),
    email_confirm: yup.lazy(() => {
        switch (deleteAccountCurrentStep.value) {
            case 2:
                return yup.string().email()
                    .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Insira um e-mail válido')
                    .required();
            default:
                return yup.mixed();
        }
    }),
    check: yup.lazy(() => {
        switch (deleteAccountCurrentStep.value) {
            case 3:
                return yup.array().min(1).required();
            default:
                return yup.mixed();
        }
    }),
});

const nextStep = () => {
    if (deleteAccountCurrentStep.value < deleteAccountSteps.value.length) {
        deleteAccountCurrentStep.value++;
    }
};
const { errors, values, handleSubmit } = useForm({
    validationSchema: schema,
    keepValuesOnUnmount: true,
    keepValuesOnMount: true,
});

async function userUpdate() {

    const data = {};
    data.notificacoes_por_email = receivedNotifications.value ? 1 : 0;
    data.receber_convites = receivedInvitations.value ? 1 : 0;
    form.value.first_name ? data.primeiro_nome = form.value.first_name : data.primeiro_nome = "";
    form.value.phone ? data.telefone = form.value.phone : data.telefone = "";
    form.value.full_name ? data.nome_completo = form.value.full_name : data.nome_completo = "";

    if (avatar.value) {
        data.avatar = avatar.value;
    }

    await useUserStore().update(data).then(async () => {
        await useAuthStore().myUser().then((response) => {
            if (response) router.go(0);
        })
    });
}

const deleteAccountOnSubmit = handleSubmit(async (values, { resetForm }) => {
    if (deleteAccountCurrentStep.value === 3) {
        await useAuthStore().delete(values).then((response) => {
            if (response) {
                nextStep();
                router.go(0)
            }
        })
        return;
    }
    nextStep();
});

function showImageFunction(img) {
    if (File.prototype.isPrototypeOf(img)) {
        let reader = new FileReader();
        reader.onload = function (e) {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function () {
                if (Math.max(image.width, image.height) > 2048) {
                    alertStore.error("A imagem deve ter até 2000px");
                } else {
                    showImage.value = e.target.result;
                }
            };
        };
        reader.readAsDataURL(img);
    }
}

async function changePassword() {
    passwordUpdateFormErrorMessage.value = null;
    passwordUpdateFormSuccessMessage.value = null;

    if (passwordUpdateForm.value.new_password != passwordUpdateForm.value.new_password_confirmation) {
        passwordUpdateFormErrorMessage.value = "As senhas precisam ser iguais.";
        return;
    } else if (passwordUpdateForm.value.password == "" || passwordUpdateForm.value.new_password == "" || passwordUpdateForm.value.new_password_confirmation == "") {
        passwordUpdateFormErrorMessage.value = "Preencha todos os campos.";
        return
    } else if (passwordUpdateForm.value.password.length < 8 || passwordUpdateForm.value.new_password.length < 8 || passwordUpdateForm.value.new_password_confirmation.length < 8) {
        passwordUpdateFormErrorMessage.value = "As senhas devem conter ao menos 8 caracteres.";
        return
    }

    await useAuthStore().changePassword(passwordUpdateForm.value).then(response => {
        if (response?.message && !response?.errors) {
            passwordUpdateFormSuccessMessage.value = response?.message
            setTimeout(() => {
                passwordModal.value = false;
            }, 3000)
        } else if (response?.error) {
            passwordUpdateFormErrorMessage.value = response?.error
        } else if (response?.errors) {
            passwordUpdateFormErrors.value = response?.errors
        }
    })
}

async function imageDelete() {
    await useUserStore().imageDelete().then(async () => {
        await useAuthStore().myUser().then(() => {
            imageDeleteModal.value = false;
            router.go(0)
        })
    })
}
</script>

<template>
    <section>
        <div class="container mx-auto px-6 pt-10 pb-10">
            <div class="mb-8">
                <CompBreadCrumbs>
                    <template v-slot:default>
                        <li>
                            <router-link :to="{ name: 'home' }">
                                <IconsComp icon="painel" />
                                Meu Painel
                            </router-link>
                        </li>
                        <li>Meu Perfil</li>
                    </template>
                </CompBreadCrumbs>
            </div>

            <div class="text-5xl font-bold font-poppins text-purple-800 mb-4">
                {{ user?.nome_completo }}
            </div>
        </div>
    </section>
    <section class="bg-purple-50 bg-opacity-80">
        <div class="container mx-auto px-6 py-10">
            <Form @submit="userUpdate" enctype="multipart/form-data">
                <div class="w-full md:w-8/12">
                    <div class="profile mb-10">
                        <div class="profile-img big overflow-hidden">
                            <img v-if="user?.avatar || showImage"
                                :src="user?.avatar && !showImage ? user?.avatar : showImage ? showImage : ''" alt="" />
                            <img v-else src="@/assets/img/template/profile2.png" alt="" />
                        </div>
                        <label for="avatar" class="btn primary">Substituir imagem</label>
                        <input
                            @change.prevent="avatar = $event.target.files[0] ? $event.target.files[0] : ''; showImageFunction($event.target.files[0])"
                            type="file" class="hidden" id="avatar" accept="image/png, image/jpeg, image/jpg">
                        <button @click.prevent="imageDeleteModal = !imageDeleteModal" class="btn secondary">Deletar
                            imagem</button>
                        <CompModal v-model="imageDeleteModal" :isOpen="imageDeleteModal"
                            @close="imageDeleteModal = !imageDeleteModal" customClass="validacao">
                            <div class="text-xl font-poppins font-bold mb-2">Você tem certeza que quer deletar esta imagem?
                            </div>
                            <div class="mb-8">

                            </div>
                            <div class="flex justify-end">
                                <button @click.prevent="imageDeleteModal = !imageDeleteModal" type="button"
                                    class="btn secondary">
                                    Cancelar
                                </button>
                                <button @click.prevent="imageDelete
                                    " type="button" class="btn primary">
                                    Confirmar
                                </button>
                            </div>
                        </CompModal>
                    </div>
                    <div class="text-red-500 my-1" v-if="avatar">
                        Lembre-se de clicar no botão: "Salvar alterações" para guardar as alterações.
                    </div>
                    <div class="mb-12">
                        <div class="text-xl font-bold font-poppins text-purple-800 mb-6">
                            Notificações
                        </div>
                        <div v-if="user.tipo == 'parecerista'" class="mb-6">
                            <CompToogle v-model="receivedInvitations" texto="Disponível para receber convite de parecer" />
                        </div>
                        <div class="mb-6">
                            <CompToogle v-model="receivedNotifications" texto="Receber notificações por email" />
                        </div>
                    </div>
                    <div class="mb-12">
                        <div class="text-xl font-bold font-poppins text-purple-800 mb-6">
                            Dados pessoais
                        </div>
                        <div class="flex gap-6 mb-6">
                            <div class="w-1/2">
                                <CompInput label="Email de cadastro" type="email" name="email" v-model="form.email"
                                    class="!text-gray-400" disabled />
                            </div>
                            <div class="w-1/2">
                                <CompInput label="Telefone" type="text" name="phone" v-model="form.phone" v-maska
                                    data-maska="(##) #####-####"
                                    :schema="yup.string()
                                        .matches(phoneRegExp, 'Apenas números').min(15, 'O telefone deve ser completo').notRequired()" />
                            </div>
                        </div>
                        <div class="flex gap-6 mb-6">
                            <div class="w-1/3">
                                <CompInput label="Primeiro nome" type="text" name="first_name" v-model="form.first_name" />
                            </div>
                            <div class="w-2/3">
                                <CompInput label="Nome completo" type="text" name="full_name" v-model="form.full_name" />
                            </div>
                        </div>
                        <div v-if="user.tipo == 'parecerista'" class="flex gap-6 mb-6">
                            <div class="w-full">
                                <CompInput label="Área de especialidade" type="text" placeholder="Cirurgia"
                                    name="specialties" v-model="form.specialties" disabled />
                            </div>
                        </div>
                    </div>
                    <div class="mb-12">
                        <div class="text-xl font-bold font-poppins text-purple-800 mb-6">
                            Segurança e privacidade
                        </div>
                        <div class="flex justify-between gap-6 mb-6 items-center">
                            <div class="">
                                <div class="font-bold text-purple-800 text-lg">Alterar senha</div>
                                <div>Definir nova senha de acesso à sua conta</div>
                            </div>
                            <div class="flex justify-end">
                                <button @click.prevent="passwordModal = !passwordModal" type="button" class="btn secondary">
                                    Alterar senha
                                </button>
                            </div>
                        </div>
                        <div class="flex justify-between gap-6 mb-6 mt-10 items-center">
                            <div class="">
                                <div class="font-bold text-purple-800 text-lg">Excluir conta</div>
                                <div>Solicite a exclusão definitiva de seus dados cadastrais</div>
                            </div>
                            <div class="flex justify-end">
                                <button @click.prevent="deleteAccountModal = !deleteAccountModal" type="button"
                                    class="btn secondary">
                                    Solicitar
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button type="submit" class="btn primary flex">
                            Salvar alterações
                            <IconsComp icon="save" class="fill-white w-6 h-6 ml-2" />
                        </button>
                    </div>
                </div>
            </Form>

            <CompModal v-model="passwordModal" :isOpen="passwordModal" @close="passwordModal = !passwordModal"
                customClass="triagem">
                <button @click="passwordModal = !passwordModal" class="absolute right-5 top-5">
                    <IconsComp icon="close-line" class="w-8 h-8 fill-green-500" />
                </button>
                <div class="w-96">
                    <Form @submit="changePassword" class="w-full">
                        <div class="text-xl font-bold font-poppins text-purple-800 mb-6 relative flex">
                            Trocar senha
                        </div>
                        <div v-for=" error  in  passwordUpdateFormErrors " :key="error" class="text-red-500 my-1">
                            {{ error[0] }}
                        </div>
                        <div class="text-red-500 my-1">
                            {{ passwordUpdateFormErrorMessage }}
                        </div>
                        <div class="text-green-500 my-1">
                            {{ passwordUpdateFormSuccessMessage }}
                        </div>
                        <CompInput v-model="passwordUpdateForm.password" name="password" label="Senha atual" type="password"
                            :isPassword="true" :schema="yup.string().min(8).required('Preencha sua senha')" />
                        <CompInput v-model="passwordUpdateForm.new_password" name="new_password" label="Nova senha"
                            type="password" :isPassword="true"
                            :schema="yup.string().min(8).required('Preencha sua nova senha')" />
                        <p class="text-sm">Sua nova senha deve conter ao menos 8 caracteres</p>

                        <CompInput v-model="passwordUpdateForm.new_password_confirmation" name="new_password_confirmation"
                            label="Confirmar senha" type="password" :isPassword="true"
                            :schema="yup.string().min(8).required('Preencha a confirmação da nova senha')" />

                        <div class="flex justify-end gap-2 mt-6">
                            <button type="button" @click="passwordModal = false" class="btn secondary w-full p-2">
                                Cancelar
                            </button>
                            <button type="submit" class="btn primary w-full p-2">
                                Salvar
                            </button>
                        </div>
                    </Form>
                </div>
            </CompModal>

            <CompModal v-model="deleteAccountModal" :isOpen="deleteAccountModal" @close="deleteAccountModal = false"
                customClass="triagem">
                <button @click="deleteAccountModal = false" class="absolute right-5 top-5">
                    <IconsComp icon="close-line" class="w-8 h-8 fill-green-500" />
                </button>
                <div class="w-96">
                    <form @submit.prevent="deleteAccountOnSubmit" class="w-full">
                        <div class="text-xl font-bold font-poppins text-purple-800 mb-6">
                            Excluir conta
                        </div>
                        <div v-if="deleteAccountCurrentStep === 1" id="step-1">
                            <div class="text-purple-800 font-bold mb-2">
                                Por que você quer excluir sua conta?
                            </div>
                            <div>
                                <CompRadio v-model="deleteAccountForm.motivation" name="motivation"
                                    :radios="['Motivo 1', 'Motivo 2', 'Outro']" />
                            </div>
                        </div>
                        <div v-if="deleteAccountCurrentStep === 2" id="step-2">
                            <div class="text-purple-800 font-bold mb-4">
                                Para seguir com a solicitação, precisamos confirmar
                                algumas informações.
                            </div>
                            <div>
                                <CompInput type="email" placeholder="Seu e-mail" v-model="deleteAccountForm.email_confirm"
                                    name="email_confirm" />
                            </div>
                        </div>
                        <div v-if="deleteAccountCurrentStep === 3" id="step-3">
                            <div class="text-purple-800 font-bold mb-6">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Phasellus ornare sem in sagittis fringilla. Vestibulum
                                lobortis eleifend libero sit amet ornare. Integer
                                vestibulum scelerisque massa. Proin vitae lobortis massa,
                                at eleifend mi. Interdum et malesuada fames ac ante ipsum
                                primis in faucibus. Donec nec feugiat ante.
                            </div>
                            <div>
                                <CompCheckbox v-model="deleteAccountForm.check" name="check" :checkboxes="[
                                    'Estou ciente das condições de exclusão da minha conta.',
                                ]" />
                            </div>
                        </div>
                        <div class="flex justify-end gap-2 mt-8">
                            <button type="button" @click="
                                deleteAccountModal = false;
                            deleteAccountCurrentStep = 1;
                            " class="btn secondary w-full p-2">
                                Cancelar
                            </button>
                            <button type="submit" class="btn primary w-full p-2">
                                {{
                                    deleteAccountCurrentStep === 3
                                    ? "Excluir conta"
                                    : "Próximo"
                                }}
                            </button>
                        </div>
                    </form>
                </div>
            </CompModal>
        </div>
    </section>
</template>
