<script setup>
import CompInput from "@/components/CompInput";
import IconsComp from "@/components/IconsComp";
import CompModal from "@/components/CompModal";
import CompMultiSelect from "@/components/CompMultiSelect";
import CompTextArea from "@/components/CompTextArea";
import { ref, onMounted } from "vue";
import { useAlertStore, useReviewerStore, useSpecialtyStore } from "@/stores";
import * as yup from "yup";
import { Form, Field } from "vee-validate";
import { ptForm } from "yup-locale-pt";
import { useRouter } from "vue-router";
import { vMaska } from 'maska';

yup.setLocale(ptForm);

const router = useRouter();
const reviewerStore = ref(useReviewerStore());
const reviewers = ref([]);
const specialties = ref([]);
const cancelModalIsOpen = ref(false);
const addModalIsOpen = ref(false);
const form = ref({
    treatment: "",
    name: "",
    email: "",
    phone: "",
    specialties: [],
    professional_experience: "",
    how_many_projects: null,
});

onMounted(async () => {
    [specialties.value] = await Promise.all([
        useSpecialtyStore().allWithoutPagination().then(response => response?.data),
    ]);
});

async function onSubmit() {
    await reviewerStore.value.store(form.value).then(response => {
        if (response?.message) {
            const alertStore = useAlertStore();
            alertStore.error(response?.message, router.push({ name: "painel-inovahc-pareceristas" }));
        }
    });
} 
</script>

<template>
    <button @click.prevent="$router.back()" class="hidden md:grid absolute top-28 right-24">
        <span class="flex font-bold text-green-500">
            Fechar
            <IconsComp icon="close-line" class="w-8 h-8 fill-green-500 ml-2" />
        </span>
    </button>
    <div class="grid grid-cols-1 gap-2">
        <div class="mx-auto w-full max-w-3xl py-12 px-6">
            <div class="text-purple-800 font-bold font-poppins text-2xl mb-4">
                Adicionar novo parecerista
            </div>
            <p class="pb-4">
                Aqui você pode cadastrar novos pareceristas para realizarem a avaliação dos projetos de inovação.
            </p>

            <Form @submit="addModalIsOpen = true" class="form-simples">
                <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mb-2">
                    <div>
                        <CompInput v-model="form.treatment" label="Tratamento" type="text" name="treatment"
                            placeholder="Prof. Dr." :schema="yup.string()" />
                    </div>

                    <div class="md:col-span-2">
                        <CompInput v-model="form.name" label="Nome completo do avaliador" type="text" name="name"
                            placeholder="Nome" :required="true" :schema="yup.string().required()" />
                    </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
                    <div>
                        <CompInput v-model="form.email" label="E-mail" type="email" name="email" placeholder="E-mail"
                            :required="true" :schema="yup.string().required()" />
                    </div>

                    <div>
                        <CompInput label="Telefone" type="text" name="phone" v-model="form.phone" v-maska
                            data-maska="(##) #####-####" :schema="yup.string()
                                .test(
                                    'phone',
                                    'O telefone deve ser completo, insira apenas números.',
                                    phone => !phone || new RegExp(
                                        `^(${phoneRegExp})$`,
                                    ) && phone.length === 15
                                )" />
                    </div>
                </div>
                <div class="mb-4">
                    <CompMultiSelect name="specialties" placeholder="Selecione a área" label="Área de especialidade"
                        :options="specialties" :required="true" v-model="form.specialties"
                        :schema="yup.array().min(1).required()" />
                </div>

                <CompTextArea label="Experiência profissional" name="professional_experience" placeholder="Digite aqui"
                    :required="true" v-model="form.professional_experience" :schema="yup.string().max(340).required()">
                    <p class="text-sm">Breve resumo em até 340 caracteres</p>
                </CompTextArea>

                <div>
                    <CompInput v-model="form.how_many_projects"
                        label="Disponibilidade de avaliar quantos projetos em um mês?" type="number"
                        placeholder="Digite o número de projetos" name="how_many_projects" :required="true"
                        :schema="yup.number().min(1).required()">
                        <p class="text-sm">
                            Prevemos a necessidade de cerca de 30 à 60 minutos por projeto
                        </p>
                    </CompInput>
                </div>

                <div class="grid grid-cols-2 gap-6">
                    <button @click.prevent="cancelModalIsOpen = true" type="button" class="btn secondary w-full my-8">
                        Cancelar
                    </button>
                    <button type="submit" class="btn primary w-full my-8">
                        Adicionar e enviar convite
                    </button>
                </div>

                <CompModal v-model="cancelModalIsOpen" :isOpen="cancelModalIsOpen"
                    @close="cancelModalIsOpen = !cancelModalIsOpen" customClass="validacao">
                    <div class="text-xl font-poppins font-bold mb-2">Confirmar ação</div>
                    <div class="mb-8">
                        <p>
                            Se você fechar essa janela irá perder todas as edições feitas até
                            agora.
                        </p>
                        <p>
                            Clique em “Ok” para sair ou em “Cancelar” para permanecer na
                            página.
                        </p>
                    </div>
                    <div class="flex justify-end">
                        <button @click.prevent="cancelModalIsOpen = !cancelModalIsOpen" type="button" class="btn secondary">
                            Cancelar
                        </button>
                        <button @click.prevent="$router.back()" type="button" class="btn primary">
                            Ok
                        </button>
                    </div>
                </CompModal>

                <CompModal v-model="addModalIsOpen" :isOpen="addModalIsOpen" @close="addModalIsOpen = !addModalIsOpen"
                    customClass="validacao">
                    <div class="text-xl font-poppins font-bold mb-2">
                        Adicionar novo parecerista
                    </div>
                    <div class="mb-8">
                        Você tem certeza que deseja adicionar
                        <b>{{ form.name }}</b> a lista de pareceristas?
                        <p class="mt-4">Um convite será enviado para o email cadastrado.</p>
                    </div>
                    <div class="flex justify-end">
                        <button @click.prevent="addModalIsOpen = !addModalIsOpen" type="button" class="btn secondary">
                            Cancelar
                        </button>
                        <button type="button" @click.prevent="
                            onSubmit();
                        addModalIsOpen = !addModalIsOpen;
                        " class="btn primary">
                            Sim
                        </button>
                    </div>
                </CompModal>
            </Form>
        </div>
    </div>
</template>
