<script setup>
import CompInput from "@/components/CompInput";
import { ref } from "vue";
import { Form, useField } from "vee-validate";
import * as yup from "yup";
import { ptForm } from "yup-locale-pt";
import { useRouter, useRoute } from "vue-router";
import { useAuthStore } from "@/stores";

yup.setLocale(ptForm);

const form = ref({
    email: "",
})
const submited = ref(false);
async function onSubmit(values, { setErrors }) {
    await useAuthStore().forgotPassword(values.email)
        .then((response) =>{
                submited.value = response
            }
        )
}
</script>

<template>
    <section id="page-home" class="flex flex-1 items-center">
        <div class="container mx-auto p-6 flex flex-col md:flex-row justify-between items-center">
            <div class="text-white w-12/12 md:w-5/12 shadow-sm">
                <div class="text-4xl font-bold font-poppins mb-6">
                    Bem-vindo de volta à plataforma de projetos de inovação do InovaHC!
                </div>
            </div>

            <div class="w-12/12 md:w-5/12 py-16">
                <div class="text-white md:text-purple-800 font-bold font-poppins text-2xl mb-2">
                    Esqueceu sua senha?
                </div>
                <div v-if="!submited">
                    <Form @submit="onSubmit" class="form-simples">
                        <CompInput v-model="form.email" name="email" type="email" label="E-mail" placeholder="Seu e-mail"
                            :schema="yup.string().email().required('Preencha seu e-mail')" />
                        <button type="submit" class="btn primary w-full my-8">Enviar</button>
                    </Form>
                    <div class="block md:flex pt-8 border-t border-purple-100">
                        <div class="md:mr-2"></div>
                        <router-link :to="{ name: 'login' }" class="no-underline hover:underline">Fazer login</router-link>
                    </div>
                </div>
                <div v-else>
                    <p v-html="submited?.message"></p>
                    <router-link :to="{ name: 'login' }" class="no-underline hover:underline">Fazer login</router-link>
                </div>
            </div>
        </div>
    </section>
</template>
