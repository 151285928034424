<script setup>
import CompInput from "@/components/CompInput";
import IconsComp from "@/components/IconsComp";
import CompSelect from "@/components/CompSelect";
import CompModal from "@/components/CompModal";
import { ref, onMounted } from "vue";
import {
    useInstituteStore,
    useAuthStore,
    useSpecialtyStore,
    useMonitorStore,
} from "@/stores";
import { Form, useField } from "vee-validate";
import * as yup from "yup";
import { ptForm } from "yup-locale-pt";
import { useRouter, useRoute } from "vue-router";

yup.setLocale(ptForm);

const route = useRoute();
const router = useRouter();
const institute_id = route.params.id;
const specialties = ref([]);
const monitors = ref([]);
const cancelModalIsOpen = ref(false);
const addModalIsOpen = ref(false);
const loading = ref(false);
const form = ref({
    name: "",
    projects_max: 0,
    specialty: null,
    monitor_id: null,
});

onMounted(async () => {
    loading.value = true;
    const institute = await useInstituteStore().find(institute_id).then(response => response?.data);

    if (!institute) {
        router.back();
    }

    [specialties.value, monitors.value] = await Promise.all([
        useSpecialtyStore().allWithoutPagination().then(response => response?.data),
        useMonitorStore().allWithoutPagination().then(response => response?.data),
    ])

    specialties.value = specialties.value.filter(obj => {
        if (!obj.instituto && obj.tipo.includes("Especialidade em saúde")) {
            return obj
        }
    })
    specialties.value.push(institute.especialidades?.find(obj => obj));

    form.value.name = institute?.nome;
    form.value.projects_max = institute?.numero_maximo_projetos;
    form.value.specialty = institute?.especialidades?.find(obj => obj)?.id;
    form.value.monitor_id = institute?.monitor?.id;
    loading.value = false;
});

async function onSubmit() {
    await useInstituteStore().update(institute_id, form.value).then(() => {
        // resetForm();
        router.push({
            name: "painel-inovahc-instituto",
            params: { id: institute_id },
        });
    });
}

Array.prototype.isNull = function () {
    return this.join().replace(/,/g, '').length === 0;
};
</script>

<template>
    <a @click="$router.back()" class="cursor-pointer">
        <button class="hidden md:grid absolute top-28 right-24">
            <span class="flex font-bold text-green-500">
                Fechar
                <IconsComp icon="close-line" class="w-8 h-8 fill-green-500 ml-2 -mt-1" />
            </span>
        </button>
    </a>
    <div v-if="!loading" class="grid grid-cols-1 gap-2">
        <div class="mx-auto w-full max-w-3xl py-12 px-6">
            <div class="text-purple-800 font-bold font-poppins text-2xl mb-4">
                Editar instituto
            </div>

            <Form @submit="addModalIsOpen = !addModalIsOpen" class="form-simples">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 my-6">
                    <div>
                        <CompInput v-model="form.name" label="Nome do instituto" type="text" name="name" :required="true"
                            :schema="yup.string().required()" />
                    </div>
                    <div>
                        <CompInput v-model="form.projects_max" label="Máximo de projetos possíveis" type="number"
                            name="projects_max" min="0" :required="true" :schema="yup.number().required()" />
                    </div>
                </div>
                <CompSelect name="specialty" label="Área de especialidade em saúde"
                    optionDisabled="Selecionar especialidade" v-model="form.specialty" optionName="nome"
                    :options="specialties?.length > 0 && !specialties.isNull() ? specialties : []" :required="true"
                    :schema="yup.number().required()" />
                <CompSelect name="monitor_id" label="Monitor responsável" v-model="form.monitor_id"
                    optionDisabled="Selecionar monitor" optionName="nome_completo"
                    :options="monitors.length > 0 ? monitors : []" :required="true" :schema="yup.number().required()" />
                <div class="grid grid-cols-2 gap-6">
                    <button @click.prevent="cancelModalIsOpen = !cancelModalIsOpen" type="button"
                        class="btn secondary w-full my-8">
                        Cancelar
                    </button>
                    <button type="submit" class="btn primary w-full my-8">
                        Salvar alterações
                    </button>
                </div>

                <CompModal v-model="cancelModalIsOpen" :isOpen="cancelModalIsOpen"
                    @close="cancelModalIsOpen = !cancelModalIsOpen" customClass="validacao">
                    <div class="text-xl font-poppins font-bold mb-2">Confirmar ação</div>
                    <div class="mb-8">
                        <p>
                            Se você fechar essa janela irá perder todas as edições feitas até
                            agora.
                        </p>
                        <p>
                            Clique em “Ok” para sair ou em “Cancelar” para permanecer na
                            página.
                        </p>
                    </div>
                    <div class="flex justify-end">
                        <button @click.prevent="cancelModalIsOpen = !cancelModalIsOpen" type="button" class="btn secondary">
                            Cancelar
                        </button>
                        <button @click.prevent="$router.back()" type="button" class="btn primary">
                            Ok
                        </button>
                    </div>
                </CompModal>

                <CompModal v-model="addModalIsOpen" :isOpen="addModalIsOpen" @close="addModalIsOpen = !addModalIsOpen"
                    customClass="validacao">
                    <div class="text-xl font-poppins font-bold mb-2">
                        Editar instituto
                    </div>
                    <div class="mb-8">
                        Você tem certeza que deseja alterar a instituição {{ institute?.nome }}?
                    </div>
                    <div class="flex justify-end">
                        <button @click.prevent="addModalIsOpen = !addModalIsOpen" type="button" class="btn secondary">
                            Cancelar
                        </button>
                        <button type="submit" @click.prevent="onSubmit()
                            " class="btn primary">
                            Sim
                        </button>
                    </div>
                </CompModal>
            </Form>
        </div>
    </div>
    <div v-else class="flex items-center justify-center align-middle h-full">
        <div class="text-center inline-block align-middle my-10 text-xl font-bold text-purple-800">
            Carregando..
        </div>
    </div>
</template>
