<script setup>
import { ref } from 'vue'
import { useAlertStore, useReviewerStore } from '@/stores'
import CompInput from '@/components/CompInput'
import IconsComp from '@/components/IconsComp'
import { Form, useField, Field } from 'vee-validate';
import * as yup from 'yup'
import { ptForm } from 'yup-locale-pt';
import { useRouter, useRoute } from 'vue-router'

const route = useRoute();
const router = useRouter();
const token = route.params.token;

yup.setLocale(ptForm);
const form = ref({
    old_password: '',
    password: '',
    password_confirmation: '',
})
const errorMessages = ref([])

async function onSubmit() {
    await useReviewerStore().firstAccess(token, form.value).then(response => {
        if (response?.error) {
            return;
        }
        const alertStore = useAlertStore();
        alertStore.success('Senha redefinida com sucesso!', router.push({ name: 'login' }));
    });
}
</script>

<template>
    <section id="page-home" class="flex-1">
        <div class="container mx-auto p-6 flex flex-col md:flex-row justify-between items-center h-full">
            <div class="text-white w-12/12 md:w-5/12 max-w-[456px] shadow-sm">
                <div class="text-4xl font-bold font-poppins mb-4">
                    Emissão de Pareceres para os programas InovaHC
                </div>
                <p>
                    Sed vehicula enim ut nulla mollis eleifend. Donec ornare magna sit amet erat euismod, sit amet viverra
                    neque efficitur.
                </p>
                <a class="flex items-center no-underline cursor-pointer mt-2">
                    <IconsComp icon="editais-line" class="w-8 h-8 fill-green-500 pr-2" />
                    Manual do parecerista
                </a>
            </div>

            <div class="w-12/12 md:w-5/12 py-16">
                <div class="text-white md:text-purple-800 font-bold font-poppins text-2xl mb-2">
                    Redefinir senha
                </div>
                <p class="pb-4">
                    Ultrices vel et erat mi tincidunt. Eu diam ac ipsum elementum tincidunt. Sollicitudin sit ornare nullam
                    mi.
                </p>
                <div v-for="errorMessage in errorMessages" :key="errorMessage" class="text-red-500">
                    {{ errorMessage[0] }}
                </div>
                <Form @submit="onSubmit" class="form-simples">
                    <CompInput v-model="form.old_password" label="Senha atual" type="password" name="old_password"
                        :isPassword="true" :schema="yup.string().min(8).required()" />

                    <CompInput v-model="form.password" label="Nova senha" type="password" name="password" :isPassword="true"
                        :schema="yup.string().min(8).required()" />

                    <CompInput v-model="form.password_confirmation" label="Confirmar senha" type="password"
                        name="password_confirmation" :isPassword="true" :schema="yup.string().min(8).required()" />

                    <button type="submit" class="btn primary w-full my-8">Cadastrar senha</button>
                </Form>
            </div>
        </div>
    </section>
</template>

