<template>
    <div>
        <Form @submit="proposalReject">
            <CompModal :isOpen="isOpen" @close="isOpen = !isOpen" customClass="triagem">
                <button @click="isOpen = !isOpen" class="absolute right-5 top-5">
                    <IconsComp icon="close-line" class="w-6 h-6" />
                </button>

                <div class="text-3xl text-purple-800 font-bold font-poppins mb-8">
                    Rejeitar proposta
                </div>

                <div class="mb-8">
                    <div class="text-xl text-purple-800 font-bold font-poppins mb-2">
                        Qual o motivo da rejeição da proposta?
                    </div>
                    <CompRadio v-model="form.motivation" name="motivation" :radios="[
                        'A proposta enviada é uma proposta de venda',
                        'Formulário preenchido com baixa qualidade ou em desacordo com o edital',
                    ]
                        " :schema="yup.string().max(255).required()" />
                </div>
                <div class="mb-6">
                    <div class="text-xl text-purple-800 font-bold font-poppins mb-2 relative flex">
                        Comentários sobre a avaliação
                    </div>

                    <CompTextArea v-model="form.comments" id="comments" name="comments" placeholder="Comentários..."
                        :schema="yup.string().max(1500).required()" />
                </div>

                <div class="flex justify-end gap-4">
                    <button @click.prevent="
                        isOpen = !isOpen
                        " type="button" class="btn secondary w-full">
                        Cancelar
                    </button>
                    <button @click.prevent="$refs.submitButton.click()" type="button" class="btn primary w-full">
                        Rejeitar proposta
                    </button>
                </div>
            </CompModal>
            <button ref="submitButton" type="submit" class="hidden"></button>
        </Form>
    </div>
</template>

<script setup>
import { useProposalStore } from '@/stores';
import { Form, useForm } from "vee-validate";
import * as yup from "yup";
import { ptForm } from "yup-locale-pt";
import { useRouter, useRoute } from "vue-router";
import { CompRadio, CompTextArea, CompModal, IconsComp } from '.';
import { ref } from 'vue';

yup.setLocale(ptForm);

const form = ref({
    motivation: "",
    comments: "",
});

const props = defineProps({
    isOpen: {
        type: Boolean,
        required: true,
        default: false,
    },
    proposalId: {
        type: Number,
        required: true,
    },
    updated: {
        type: Boolean,
        default: false,
    }
})

const emits = defineEmits(['update:updated', 'update:isOpen'])

async function proposalReject() {
    const data = {};
    data.status = 'Rejeitada';
    data.motivation = form.value.motivation;
    data.comments = form.value.comments;

    await useProposalStore().update(props.proposalId, data).then(async () => {
        emits('update:updated', !props.updated)
        emits('update:isOpen', false)
    });
}
</script>
