<script setup>

const props = defineProps({
    avaliations: {
        type: [Array, Object],
        required: true,
    },
    isOpen: {
        type: Boolean,
        required: true,
        default: false,
    }
})

let answersInNumbers = null;

props.avaliations?.forEach((avaliation) => {
    if (avaliation?.avaliacao) {
        answersInNumbers = Object.entries(avaliation?.avaliacao)
            .filter((obj) => !isNaN(obj[1]))
            .map((obj) => obj[1])
            .map(Number);
    }

    if (answersInNumbers && !answersInNumbers.some(isNaN)) {
        avaliation.rate = (
            answersInNumbers?.reduce((a, b) => {
                return a + b;
            }) / answersInNumbers?.length
        ).toFixed(1);
    }
})
</script>
<template>
    <tr v-show="isOpen" v-for="(avaliation, index) in avaliations" :key="avaliation">
        <td colspan="1000">
    <tr class="transition-all duration-500 ease-linear hover:bg-white hover:text-purple-800">
        <td colspan="1000">
            <div class="grid grid-cols-3 gap-x-10 ml-2">
                <div>
                    <div class="text-gray-400 text-sm mb-2">Parecerista {{ index + 1 }}</div>
                    <div class="text-md text-neutral-500 font-normal">
                        {{ avaliation.parecerista?.nome_completo }}
                    </div>
                    <div class="text-gray-400 text-sm">
                        {{ avaliation.parecerista?.pareceristaPropostas?.filter(obj =>
                            obj?.status?.includes(
                                'Parecer pendente')).length }}
                        de
                        {{ avaliation.parecerista?.pareceristaPropostas?.length }}
                        propostas em análise
                    </div>
                </div>
                <div>
                    <div class="text-gray-400 text-sm mb-2">
                        Há conflito de interesses para a avaliação deste projeto?
                    </div>
                    <button type="button" class="tag disabled font-bold"
                        :class="avaliation.avaliacao?.has_conflict == 'Sim. Conheço o projeto a ser avaliado.' ? '!red' : ''">
                        {{ avaliation.avaliacao?.has_conflict ? avaliation.avaliacao?.has_conflict : '-' }}
                    </button>
                </div>
                <div class="ml-10">
                    <div class="text-gray-400 text-sm mb-2">Avaliação</div>
                    <p class="text-md text-neutral-500 font-normal">
                        {{ avaliation?.rate ? avaliation?.rate : '-' }} </p>
                </div>
            </div>
        </td>
    </tr>
    <tr>
        <td colspan="1000">
            <div class="grid grid-cols-5 gap-4 ml-2 content-end">
                <div class="h-20">
                    <div class="text-neutral-500 text-md">Nota {{
                        avaliation.avaliacao?.innovation }}</div>
                    <p class="text-sm text-gray-400 font-normal ">
                        Diferencial de Inovação
                    </p>
                </div>
                <div class="h-20">
                    <div class="text-neutral-500 text-md">Nota
                        {{
                            avaliation.avaliacao?.viability }}
                    </div>
                    <p class="text-sm text-gray-400 font-normal ">
                        Viabilidade técnica e qualificação da equipe
                    </p>
                </div>
                <div class="h-20">
                    <div class="text-neutral-500 text-md">Nota
                        {{
                            avaliation.avaliacao?.solution_potential }}
                    </div>
                    <p class="text-sm text-gray-400 font-normal ">
                        Potencial de impacto social da solução
                    </p>
                </div>
                <div class="h-20">
                    <div class="text-neutral-500 text-md">Nota
                        {{
                            avaliation.avaliacao?.scale_potential }}
                    </div>
                    <p class="text-sm text-gray-400 font-normal ">
                        Potencial de escala e sustentabilidade da solução
                    </p>
                </div>
                <div class="h-20">
                    <div class="text-neutral-500 text-md">Nota
                        {{
                            avaliation.avaliacao?.compatibility }}
                    </div>
                    <p class="text-sm text-gray-400 font-normal ">
                        Compatibilidade da solução com a proposta do programa
                    </p>
                </div>
            </div>
        </td>
    </tr>
    <tr class="transition-all duration-500 ease-linear hover:bg-white hover:text-purple-800">
        <td colspan="1000">
            <div class="ml-2">
                <div class="text-gray-400 text-sm mb-2">
                    Justificativa emitida em {{ avaliation.avaliacao ?
                        $moment(avaliation.updated_at).format('DD/MM/YYYY') :
                        '-' }}
                </div>
                <p class="text-md text-neutral-500 font-normal">
                    {{ avaliation.avaliacao?.justify }}
                </p>
            </div>
        </td>
    </tr>
    </td>
    </tr>
</template>


