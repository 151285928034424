import { ref } from "vue";
import * as yup from "yup";
import { ptForm } from "yup-locale-pt";
yup.setLocale(ptForm);

export const currentStep = ref(1);

export const schema = yup.object({
  // STEP 1
  conditions: yup.lazy(() => {
    switch (currentStep.value) {
      case 1:
        return yup
          .array()
          .of(yup.string())
          .min(
            3,
            "É obrigatória a aceitação de todos termos para acessar o formulário de inscrição"
          )
          .required();
      default:
        return yup.mixed();
    }
  }),
  // STEP 2
  formation_experience: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup.string().max(500).required();
      default:
        return yup.mixed();
    }
  }),
  fmusp_connection: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  project_secrecy: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup
          .string()
          .oneOf(
            ["Sim"],
            "Caso não concorde com o sigilo das informações trocadas durante todo o projeto, não poderá seguir adiante de acordo com a política de sigilo das informações trocadas devido a LGPD."
          )
          .max(255)
          .required();
      default:
        return yup.mixed();
    }
  }),
  team_members: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup
          .array()
          .of(
            yup.object({
              name: yup.string().max(255).required(),
              formation: yup.string().max(500).required(),
            })
          )
          .min(1)
          .max(4)
          .required();
      default:
        return yup.mixed();
    }
  }),
  team_composition: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup.array().of(yup.string()).min(1).required();
      default:
        return yup.mixed();
    }
  }),
  team_members_formation: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup.array().of(yup.string()).min(1).required();
      default:
        return yup.mixed();
    }
  }),
  // STEP 3
  team_motivation: yup.lazy(() => {
    switch (currentStep.value) {
      case 3:
        return yup.string().max(1500).required();
      default:
        return yup.mixed();
    }
  }),
  support_types: yup.lazy(() => {
    switch (currentStep.value) {
      case 3:
        return yup.array().of(yup.string().max(255)).min(1).required();
      default:
        return yup.mixed();
    }
  }),
  heard_about: yup.lazy(() => {
    switch (currentStep.value) {
      case 3:
        return yup.array().of(yup.string().max(255)).min(1).required();
      default:
        return yup.mixed();
    }
  }),
  // STEP 4
  project_inpi: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  regulation_support: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(1000).required();
      default:
        return yup.mixed();
    }
  }),
  sensitive_data: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  sensitive_data_support: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(1000).required();
      default:
        return yup.mixed();
    }
  }),
  received_promotion: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  // STEP 5
  project_name: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.string().max(280).required();
      default:
        return yup.mixed();
    }
  }),
  specialty_areas: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.array().of(yup.string().max(255)).min(1).required();
      default:
        return yup.mixed();
    }
  }),
  project_feature: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.array().of(yup.string().max(255)).min(1).required();
      default:
        return yup.mixed();
    }
  }),
  project_description: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.string().max(1500).required();
      default:
        return yup.mixed();
    }
  }),
  project_problems: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.string().max(1500).required();
      default:
        return yup.mixed();
    }
  }),
  people_affected: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.string().max(1500).required();
      default:
        return yup.mixed();
    }
  }),
  project_solutions: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.string().max(1500).required();
      default:
        return yup.mixed();
    }
  }),
  project_offer: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.string().max(1500).required();
      default:
        return yup.mixed();
    }
  }),
  project_validation: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.string().max(1500).required();
      default:
        return yup.mixed();
    }
  }),
  project_scale: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.string().max(1000).required();
      default:
        return yup.mixed();
    }
  }),
  project_files: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup
          .array()
          .of(
            yup.object({
              link: yup
                .string()
                .matches(
                  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                  { message: "Insira uma URL válida!", excludeEmptyString: true }
                )
                .max(255),
              description: yup.string().max(255).required(),
            })
          )
          .min(1)
          .max(10)
          .required();
      default:
        return yup.mixed();
    }
  }),
  project_institution: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
});
