<script setup>
import { ref } from 'vue'
import IconsComp from '@/components/IconsComp'
import CompCheckbox from '@/components/CompCheckbox'
import CompProgress from '@/components/CompProgress'
import CompModal from '@/components/CompModal'
import CompTextArea from '@/components/CompTextArea'
import { Field } from 'vee-validate'

const props = defineProps({
    values: {},
})

const support_types = ref([
    "Articulação com governo",
    "Articulação com saúde suplementar",
    "Desenho de testes em diferentes contextos",
    "Financiamento de testes de escala",
    "Auxílio no processo de regulação Anvisa",
    "Auxílio no processo de Incorporação via Conitec",
    "Mentorias em Implementação Tecnológica",
    "Outro",
])

const heard_about = ref([
    "Instagram",
    "Linkedin",
    "E-mail institucional (Webmaster, HC Online, etc)",
    "Comunicação interna (Newsletter)",
    "Cartaz ou totem no complexo HCFMUSP",
    "Equipe Inova HC",
    "Indicação de amigos/colegas de trabalho",
    "Imprensa",
    "Site de buscas (Google, por exemplo)",
    "Outro",
])


const formStep3 = ref({
    team_motivation: props.values.team_motivation,
    support_types: props.values.support_types,
    heard_about: props.values.heard_about,
})

</script>

<template>
    <div class="mb-16">
        <div class="text-3xl font-poppins font-bold text-purple-800 mb-2">
            Motivações e necessidades de projeto
        </div>
        <p class="mb-8">Para podermos alocar melhor os recursos e esforços do InovaHC, precisamos entender um pouco das
            motivações e necessidades de projeto da equipe. Esta sessão nos ajudará a contribuir da melhor forma com seu
            projeto.</p>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Qual a motivação da equipe para participar do In.pacte?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompTextArea name="team_motivation" v-model="formStep3.team_motivation" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-6">
                Quais os tipos de apoio que você precisa?
                <span class="ml-auto asterisk"></span>
            </div>

            <CompCheckbox name="support_types" :checkboxes="support_types" v-model="formStep3.support_types"
                :hasOther="true" />
        </div>

        <div class="card">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                Como ficou sabendo do processo seletivo In.pacte?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompCheckbox name="heard_about" :checkboxes="heard_about" v-model="formStep3.heard_about" :hasOther="true" />
        </div>
    </div>
</template>