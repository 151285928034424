<script setup>
import CompBreadCrumbs from "@/components/CompBreadCrumbs";
import IconsComp from "@/components/IconsComp";
import CompProposalData from "@/components/CompProposalData";
import CompDropdown from "@/components/CompDropdown";
import CompModal from "@/components/CompModal";
import CompRadio from "@/components/CompRadio";
import CompTextArea from "@/components/CompTextArea";
import CompStatus from "@/components/CompStatus";
import { ref, onMounted } from "vue";
import { useProposalStore, useSpecialtyStore } from "@/stores";
import { useRoute, useRouter } from "vue-router";
import { Form, useForm } from "vee-validate";
import * as yup from "yup";
import { ptForm } from "yup-locale-pt";

yup.setLocale(ptForm);

const route = useRoute();
const router = useRouter();
const proposal_id = route.params.id;
const proposal = ref(null);
const rejectModalIsOpen = ref(false);
const confirmRejectModalIsOpen = ref(false);
const addModalIsOpen = ref(false);
const proposalSelectedModal = ref(false);
const classifiedProposalModal = ref(false)
const form = ref({
    motivation: "",
    comments:
        "",
});

onMounted(async () => {
    proposal.value = await useProposalStore().find(proposal_id).then(response => response?.data);

    if (!proposal.value) {
        router.back();
        return;
    } else if (
        proposal.value.status?.includes("Selecionar parecerista") ||
        proposal.value.status?.includes("Aguardando parecer") ||
        proposal.value.status?.includes('Especialidade rejeitada')
    ) {
        router.push({
            name: "painel-inovahc-proposta-parecerista",
            params: { id: proposal_id },
        });
        return;
    }
});

async function updateProposal(status) {
    const data = {};
    data.status = status;

    if (form.value.motivation.length > 0 && form.value.comments) {
        data.motivation = form.value.motivation;
        data.comments = form.value.comments;
    }

    await useProposalStore().update(proposal_id, data);
    status == 'Rejeitada' ? router.go(0) : ''
}

async function proposalSelected() {
    await useProposalStore().update(proposal_id, { status: 'Selecionado' }).then(async () => {
        router.push({ name: "painel-inovahc-projetos" })
    });
}

async function classifiedProposal() {
    await useProposalStore().update(proposal_id, { status: 'Classificada' }).then(async () => {
        router.go(0)
    });
}
</script>

<template>
    <section>
        <div class="container mx-auto px-6 pt-12 pb-10">
            <div class="flex justify-between flex-col md:flex-row items-center mb-8">
                <div>
                    <CompBreadCrumbs>
                        <template v-slot:default>
                            <li>
                                <router-link :to="{ name: 'painel-inovahc-programas' }">
                                    <IconsComp icon="painel" />
                                    Meu Painel
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'painel-inovahc-programas' }">Propostas
                                </router-link>
                            </li>
                        </template>
                    </CompBreadCrumbs>
                </div>
                <div class="ml-auto flex">
                    <a class="cursor-pointer" v-print="'#project-details'">
                        <IconsComp icon="printer" class="fill-green-500 w-6 h-6" />
                    </a>
                    <div class="ml-4">
                        <CompDropdown alignment="right">
                            <template #toggle>
                                <IconsComp icon="more" class="w-6 h-6 fill-green-500 rotate-90" />
                            </template>
                            <a v-if="proposal?.status.includes('Triagem pendente')" @click.prevent="
                                addModalIsOpen = true;
                            updateProposal('Selecionar parecerista');
                            " class="cursor-pointer">
                                <li class="text-purple-800">
                                    Aprovar proposta
                                </li>
                            </a>
                            <a v-if="proposal?.status.includes('Aguardando classificação')"
                                @click.prevent="classifiedProposalModal = !classifiedProposalModal"
                                class="cursor-pointer no-underline">
                                <li class="text-purple-800">
                                    
                                    Classificar proposta
                                </li>
                            </a>
                            <a v-if="proposal?.status.includes('Classificada')"
                                @click.prevent="proposalSelectedModal = !proposalSelectedModal" class="cursor-pointer">
                                <li class="text-purple-800">
                                    Selecionar como projeto
                                </li>
                            </a>
                            <a v-if="!proposal?.status.includes('Rejeitada')" @click.prevent="rejectModalIsOpen = true"
                                class="cursor-pointer">
                                <li class="text-purple-800">
                                    Rejeitar proposta
                                </li>
                            </a>
                        </CompDropdown>
                    </div>
                </div>
            </div>
            <div class="w-full">
                <div class="text-5xl font-bold font-poppins text-purple-800 mb-4">
                    {{ proposal?.nome_proposta }}
                </div>
                <div class="flex items-center">
                    <span class="text-sm mr-1">Status:</span>
                    <CompStatus :status="proposal?.status" />
                </div>
            </div>
        </div>
    </section>
    <!-- Detalhes do Projeto -->
    <section id="project-details" class="bg-purple-50 bg-opacity-80">
        <div class="container mx-auto px-6 py-10">
            <div class="flex flex-col md:flex-row gap-6">
                <div class="w-full md:w-8/12 flex flex-col justify-start">
                    <div class="text-3xl font-bold font-poppins text-purple-800 mb-4">
                        Informações sobre o projeto
                    </div>
                    <div class="flex gap-8 mb-8">
                        <div>
                            <div class="text-sm text-gray-500">
                                Programa / data de inscrição
                            </div>
                            <div class="font-bold text-purple-800">
                                {{ proposal?.edital ? proposal.edital.nome : "Fluxo contínuo" }}
                            </div>
                            <div class="font-sm text-purple-500">
                                Enviada em
                                {{ $moment(proposal?.created_at).format("DD/MM/YYYY") }}
                            </div>
                        </div>
                        <div>
                            <div class="text-sm text-gray-500">Área de especialidade</div>
                            <div class="font-bold text-purple-800">
                                {{
                                    proposal?.especialidades?.map((obj) => obj.nome).join(", ")
                                }}
                            </div>
                            <div v-if="proposal?.perguntas_e_respostas?.secondary_specialty_areas">
                                <div class="text-sm text-gray-500 mt-1">
                                    Áreas de especialidade secundárias
                                </div>
                                <div class="text-purple-500 text-sm">
                                    {{
                                        proposal?.secondary_specialty_areas?.map((obj) =>
                                            specialties
                                                .map((specialty) => {
                                                    if (obj.valor.includes(specialty.id)) {
                                                        return specialty.nome;
                                                    }
                                                })
                                                .join(", ")
                                        )
                                            .join(", ")
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- COLLAPSE -->
                    <CompProposalData :proposal="proposal" :collapse="[1, 2, 3]" />
                </div>

                <div v-if="proposal?.status == 'Triagem pendente'" class="w-full md:w-4/12 flex flex-col">
                    <div class="ml-auto">
                        <button @click.prevent="
                            addModalIsOpen = true;
                        updateProposal('Selecionar parecerista');
                        " class="btn primary large flex items-center">
                            Aprovar proposta
                            <IconsComp icon="success-line" class="w-6 h-6 fill-white ml-2" />
                        </button>
                    </div>
                    <div class="mt-4 ml-auto">
                        <button @click.prevent="rejectModalIsOpen = true" class="btn secondary large flex items-center">
                            Rejeitar proposta
                            <IconsComp icon="close-line" class="w-6 h-6 fill-green-500 ml-2" />
                        </button>
                    </div>
                </div>
            </div>

            <Form @submit="updateProposal('Rejeitada')">
                <CompModal :isOpen="rejectModalIsOpen" @close="rejectModalIsOpen = !rejectModalIsOpen"
                    customClass="triagem">
                    <button @click="rejectModalIsOpen = !rejectModalIsOpen" class="absolute right-5 top-5">
                        <IconsComp icon="close" class="w-4 h-4" />
                    </button>

                    <div class="text-3xl text-purple-800 font-bold font-poppins mb-8">
                        Rejeitar proposta
                    </div>

                    <div class="mb-8">
                        <div class="text-xl text-purple-800 font-bold font-poppins mb-2">
                            Qual o motivo da rejeição da proposta?
                        </div>

                        <CompRadio v-model="form.motivation" name="motivation" :radios="[
                            'A proposta enviada é uma proposta de venda',
                            'Formulário preenchido com baixa qualidade ou em desacordo com o edital',
                        ]" :schema="yup.string().max(255).required()" />
                    </div>
                    <div class="mb-6">
                        <div class="text-xl text-purple-800 font-bold font-poppins mb-2 relative flex">
                            Comentários sobre a avaliação
                        </div>

                        <CompTextArea v-model="form.comments" id="comments" name="comments" placeholder="Comentários..."
                            :schema="yup.string().max(1500).required()" />
                    </div>

                    <div class="flex justify-end gap-4">
                        <button @click.prevent="rejectModalIsOpen = !rejectModalIsOpen" type="button"
                            class="btn secondary w-full">
                            Cancelar
                        </button>
                        <button @click.prevent="$refs.rejectSubmitButton.click()" type="button" class="btn primary w-full">
                            Rejeitar proposta
                        </button>
                    </div>
                </CompModal>
                <button ref="rejectSubmitButton" type="submit" class="hidden"></button>
            </Form>

            <CompModal v-model="confirmRejectModalIsOpen" :isOpen="confirmRejectModalIsOpen"
                @close="confirmRejectModalIsOpen = !confirmRejectModalIsOpen" customClass="validacao">
                <div class="text-xl font-poppins font-bold mb-2">
                    Você acaba de rejeitar uma proposta
                </div>
                <div class="mb-8">
                    <p>
                        Texto de suporte se necessários. Aenean eu ultricies lorem. Aliquam
                        ac efficitur ante. Etiam nec tempus ante, sed bibendum magna. Donec
                        sed tortor sed sem malesuada.
                    </p>
                </div>
                <div class="flex justify-end">
                    <button @click.prevent="
                        confirmRejectModalIsOpen = !confirmRejectModalIsOpen
                        " type="button" class="btn secondary">
                        Cancelar
                    </button>
                    <button @click.prevent="
                        confirmRejectModalIsOpen = !confirmRejectModalIsOpen;
                    updateProposal('Rejeitada');
                    " type="button" class="btn primary">
                        Confirmar
                    </button>
                </div>
            </CompModal>

            <CompModal v-model="addModalIsOpen" :isOpen="addModalIsOpen" @close="addModalIsOpen = !addModalIsOpen"
                customClass="validacao">
                <div class="text-xl font-poppins font-bold mb-2">
                    Proposta aprovada com sucesso!
                </div>
                <div class="mb-8">
                    Texto de suporte se necessários. Aenean eu ultricies lorem. Aliquam ac
                    efficitur ante. Etiam nec tempus ante, sed bibendum magna. Donec sed
                    tortor sed sem malesuada.
                </div>
                <div class="flex justify-end">
                    <button @click.prevent="addModalIsOpen = !addModalIsOpen" type="button" class="btn secondary">
                        Cancelar
                    </button>
                    <router-link :to="{
                        name: 'painel-inovahc-proposta-parecerista',
                        params: { id: proposal_id },
                    }" type="button" class="btn primary no-underline">
                        Selecionar pareceristas
                    </router-link>
                </div>
            </CompModal>

            <CompModal v-model="proposalSelectedModal" :isOpen="proposalSelectedModal"
                @close="proposalSelectedModal = !proposalSelectedModal" customClass="validacao">
                <div class="text-xl font-poppins font-bold mb-2">
                    Selecionar a proposta como projeto?
                </div>
                <div class="mb-8">
                    <p>
                        Texto de suporte se necessários. Aenean eu ultricies lorem. Aliquam
                        ac efficitur ante. Etiam nec tempus ante, sed bibendum magna. Donec
                        sed tortor sed sem malesuada.
                    </p>
                </div>
                <div class="flex justify-end">
                    <button @click.prevent="proposalSelectedModal = !proposalSelectedModal" type="button"
                        class="btn secondary">
                        Cancelar
                    </button>
                    <button @click.prevent="proposalSelectedModal = !proposalSelectedModal; proposalSelected()"
                        type="button" class="btn primary">
                        Confirmar
                    </button>
                </div>
            </CompModal>

            <CompModal v-model="classifiedProposalModal" :isOpen="classifiedProposalModal"
                @close="classifiedProposalModal = !classifiedProposalModal" customClass="validacao">
                <div class="text-xl font-poppins font-bold mb-2">
                    Classificar a proposta: <b>{{ proposal?.nome_proposta }}</b>?
                </div>
                <div class="mb-8">
                    <p>
                        Texto de suporte se necessários. Aenean eu ultricies
                        lorem. Aliquam ac efficitur ante. Etiam nec tempus ante,
                        sed bibendum magna. Donec sed tortor sed sem malesuada.
                    </p>
                </div>
                <div class="flex justify-end">
                    <button @click.prevent="classifiedProposalModal = !classifiedProposalModal" type="button"
                        class="btn secondary">
                        Cancelar
                    </button>
                    <button @click.prevent=" classifiedProposalModal = !classifiedProposalModal; classifiedProposal()"
                        type="button" class="btn primary">
                        Confirmar
                    </button>
                </div>
            </CompModal>
        </div>
    </section>
</template>
