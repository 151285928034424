<script setup>
const baseUrl = `${process.env.VUE_APP_API_URL}`;

/* Biblioteca de icones */
import IconsComp from "@/components/IconsComp.vue";

import CompBreadCrumbs from "@/components/CompBreadCrumbs.vue";
/* Paginação */
import CompPagination from "@/components/CompPagination.vue";

import CompCollapse from "@/components/CompCollapse.vue";
import CompProgress from "@/components/CompProgress.vue";

/* Modal */
import CompModal from "@/components/CompModal.vue";
/* Dropdown */
import CompDropdown from "@/components/CompDropdown.vue";

/* Forms */
import CompMultiSelect from "@/components/CompMultiSelect.vue";
import CompToogle from "@/components/CompToogle.vue";
import CompSearch from "@/components/CompSearch.vue";
import { ref } from 'vue'

const multiselect = ref([])
const multiselectOptions = ref([{ id: 7, nome: 'Ginecologia' }])

</script>

<template>
    <!-- ESTILOS -->
    <section class="bg-gray-800">
        <div class="container mx-auto px-6 py-10 text-4xl text-white">
            Estilos / Cadastros / Guide
        </div>
    </section>
    <section class="bg-purple-50">
        <div class="container mx-auto p-6">
            <!-- Model Buttons -->
            <div class="grid gap-6 md:grid-cols-3 mb-10">
                <div class="card">
                    <div>Button Primary Enable</div>
                    <div>
                        <button type="button" class="btn large primary">Large</button>
                        <button type="button" class="btn medium primary">Medium</button>
                        <button type="button" class="btn small primary">Small</button>
                    </div>
                    <div>Button Secondary Enable</div>
                    <div>
                        <button type="button" class="btn large secondary">Large</button>
                        <button type="button" class="btn medium secondary">Medium</button>
                        <button type="button" class="btn small secondary">Small</button>
                    </div>
                    <div>Button Tertiary Enable</div>
                    <div>
                        <button type="button" class="btn tertiary">Unique Size</button>
                    </div>
                    <div>Button Primary Disabled</div>
                    <div>
                        <button type="button" class="btn large primary" disabled>
                            Large
                        </button>
                        <button type="button" class="btn medium primary" disabled>
                            Medium
                        </button>
                        <button type="button" class="btn small primary" disabled>
                            Small
                        </button>
                    </div>
                    <div>Button Secondary Disabled</div>
                    <div>
                        <button type="button" class="btn large secondary" disabled>
                            Large
                        </button>
                        <button type="button" class="btn medium secondary" disabled>
                            Medium
                        </button>
                        <button type="button" class="btn small secondary" disabled>
                            Small
                        </button>
                    </div>
                    <div>Button Tertiary Disabled</div>
                    <div>
                        <button type="button" class="btn tertiary" disabled>
                            Unique Size
                        </button>
                    </div>
                </div>
                <div class="card">
                    <div>Tags with close</div>
                    <div class="flex flex-wrap">
                        <button type="button" class="tag">
                            basic
                            <span>
                                <IconsComp icon="close" />
                            </span>
                        </button>
                        <button type="button" class="tag disabled">
                            desativada
                            <span>
                                <IconsComp icon="close" />
                            </span>
                        </button>
                        <button type="button" class="tag red">
                            alerta 1
                            <span>
                                <IconsComp icon="close" />
                            </span>
                        </button>
                        <button type="button" class="tag yellow">
                            alerta 2
                            <span>
                                <IconsComp icon="close" />
                            </span>
                        </button>
                        <button type="button" class="tag blue">
                            normal
                            <span>
                                <IconsComp icon="close" />
                            </span>
                        </button>
                        <button type="button" class="tag green">
                            selecionado
                            <span>
                                <IconsComp icon="close" />
                            </span>
                        </button>
                    </div>
                    <div>Tags simple</div>
                    <div class="flex flex-wrap">
                        <button type="button" class="tag">basic</button>
                        <button type="button" class="tag disabled">desativada</button>
                        <button type="button" class="tag red">alerta 1</button>
                        <button type="button" class="tag yellow">alerta 2</button>
                        <button type="button" class="tag blue">normal</button>
                        <button type="button" class="tag green">selecionado</button>
                    </div>
                    <div>Icons</div>
                    <div class="flex flex-wrap gap-2 mb-2">
                        <IconsComp icon="save" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="download" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="upload" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="trash" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="printer" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="search" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="calendar" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="eye" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="eye-off" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="out" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="logout" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="arrow-full-right" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="arrow-full-left" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="arrow-right" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="arrow-left" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="arrow-top" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="arrow-down" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="close-square" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="tick-square" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="add-square" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="minus-square" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="tick-circle" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="add-circle" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="more" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="dropdown-arrow-down" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="dropdown-arrow-up" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="check-step" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="certificado" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="call" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="email" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="painel" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="document" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="user" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="user-2" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="user-edit" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="close" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="plus" class="fill-green-500 stroke-green-500 w-6 h-6" />
                        <IconsComp icon="institutes" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="gear" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="cover" class="fill-green-500 w-6 h-6" />
                        <IconsComp icon="clipboard" class="fill-green-500 w-6 h-6" />
                    </div>
                    <div>Icons Especiais</div>
                    <div class="flex flex-wrap gap-2 mb-2">
                        <IconsComp icon="proposta-line" class="w-6 h-6 fill-green-500" />
                        <IconsComp icon="proposta-filled" class="w-6 h-6 fill-green-500" />
                        <IconsComp icon="projetos-line" class="w-6 h-6 fill-green-500" />
                        <IconsComp icon="projetos-filled" class="w-6 h-6 fill-green-500" />
                        <IconsComp icon="reject-line" class="w-6 h-6 fill-green-500" />
                        <IconsComp icon="reject-filled" class="w-6 h-6 fill-green-500" />
                        <IconsComp icon="editais-line" class="w-6 h-6 fill-green-500" />
                        <IconsComp icon="editais-filled" class="w-6 h-6 fill-green-500" />
                        <IconsComp icon="timer-line" class="w-6 h-6 fill-green-500" />
                        <IconsComp icon="timer-filled" class="w-6 h-6 fill-green-500" />
                        <IconsComp icon="clipboard-line" class="w-6 h-6 fill-green-500" />
                        <IconsComp icon="clipboard-filled" class="w-6 h-6 fill-green-500" />
                        <IconsComp icon="close-line" class="w-6 h-6 fill-green-500" />
                        <IconsComp icon="close-filled" class="w-6 h-6 fill-green-500" />
                        <IconsComp icon="calendar-line" class="w-6 h-6 fill-green-500" />
                        <IconsComp icon="calendar-filled" class="w-6 h-6 fill-green-500" />
                        <IconsComp icon="success-line" class="w-6 h-6 fill-green-500" />
                        <IconsComp icon="success-filled" class="w-6 h-6 fill-green-500" />
                        <IconsComp icon="clock-line" class="w-6 h-6 fill-green-500" />
                        <IconsComp icon="clock-filled" class="w-6 h-6 fill-green-500" />
                        <IconsComp icon="user-line" class="w-6 h-6" />
                    </div>
                </div>
                <!-- Model Links -->
                <div class="card">
                    <div class="mb-2">
                        <a href="http://www.globo.com"> Hyperlik </a>
                    </div>

                    <p>
                        O programa In.pulse é uma aceleração para empreendedores e/ou
                        startups que
                        <a href="http://www.globo.com">
                            hyperlink within text with semibold</a>
                        possuem tecnologias com maturidade entre 5 e 7 do índice do TRL.
                        Nosso foco é trabalhar no co-desenvolvimento de produto e modelo de
                        negócios com a possibilidade de testar a solução através de POCs
                        e/ou pilotos financiados.
                    </p>
                    <p>
                        Por isso, já disponibilizamos no link abaixo um modelo em branco
                        para que você preencha antes de iniciar a submissão deste
                        formulário.
                        <a href="http://www.globo.com">
                            hyperlink within text with semibold
                        </a>
                        Se você já tiver um documento semelhante já preenchido, basta anexar
                        o arquivo PDF no campo correspondente.
                    </p>
                </div>
            </div>

            <!-- Model Componentes -->
            <div class="grid gap-6 md:grid-cols-4 mb-10">
                <div class="card">
                    <form>
                        <div class="text-xl font-bold font-poppins text-purple-800 mb-2 relative flex">
                            &#123;&#123; Título questão &#125;&#125;
                            <span class="asterisk"></span>
                        </div>
                        <p class="mb-4">
                            Descrição do campo ulputate eu magna magna dignissim malesuada non
                            bibendum felis.
                        </p>
                        <label>
                            <input type="email" placeholder="Sua resposta..." class="peer" />
                            <p class="mt-0 ml-2 hidden peer-invalid:flex text-red-500 text-sm">
                                Mensagem de erro
                            </p>
                        </label>
                    </form>
                </div>
                <div class="card disabled">
                    <form>
                        <div class="text-xl font-bold font-poppins text-purple-800 mb-2 relative flex">
                            &#123;&#123; Título questão &#125;&#125;
                            <span class="asterisk"></span>
                        </div>
                        <p class="mb-4">
                            Descrição do campo ulputate eu magna magna dignissim malesuada non
                            bibendum felis.
                        </p>
                        <label>
                            <input type="email" placeholder="Sua resposta..." class="peer" />
                            <p class="mt-0 ml-2 hidden peer-invalid:flex text-red-500 text-sm">
                                Mensagem de erro
                            </p>
                        </label>
                    </form>
                </div>
                <div class="card">
                    <form>
                        <div class="text-xl font-bold font-poppins text-purple-800 mb-2 relative flex">
                            &#123;&#123; Título questão &#125;&#125;
                            <span class="asterisk"></span>
                        </div>
                        <p class="mb-4">
                            Descrição do campo ulputate eu magna magna dignissim malesuada non
                            bibendum felis.
                        </p>
                        <label>
                            <input type="email" placeholder="Sua resposta..." class="peer" />
                            <p class="mt-0 ml-2 hidden peer-invalid:flex text-red-500 text-sm">
                                Mensagem de erro
                            </p>
                        </label>
                    </form>
                </div>
                <div class="card">
                    <form>
                        <div class="text-xl font-bold font-poppins text-purple-800 mb-2 relative flex">
                            &#123;&#123; Título questão &#125;&#125;
                            <span class="asterisk"></span>
                        </div>
                        <p class="mb-4">
                            Descrição do campo ulputate eu magna magna dignissim malesuada non
                            bibendum felis.
                        </p>
                        <label>
                            <input type="email" placeholder="Sua resposta..." class="peer" />
                            <p class="mt-0 ml-2 hidden peer-invalid:flex text-red-500 text-sm">
                                Mensagem de erro
                            </p>
                        </label>
                    </form>
                </div>
            </div>
            <!-- Model Forms -->
            <div class="grid gap-6 md:grid-cols-3 mb-10">
                <div class="card">
                    <form class="mb-2">
                        <div>Basic Form</div>
                        <label class="block">
                            <span>Email</span>
                            <input type="email" placeholder="email@example.com" />
                        </label>

                        <label class="block">
                            <span>Password</span>
                            <input type="password" placeholder="Your password" />
                        </label>
                        <div class="flex justify-end">
                            <button class="btn primary">Send</button>
                        </div>
                    </form>
                    <div class="mb-2">Componente Dropdown</div>
                    <div class="flex">
                        <div class="tag w-56">Dropdown a esquerda</div>
                        <CompDropdown>
                            <template #toggle>
                                <IconsComp icon="more" class="w-6 h-6 fill-green-500" />
                            </template>
                            <a href="#">
                                <li>
                                    <IconsComp icon="download" /> Avaliar proposta
                                </li>
                            </a>
                            <a href="#">
                                <li>
                                    <IconsComp icon="printer" />Imprimir proposta
                                </li>
                            </a>
                            <a href="#">
                                <li>
                                    <IconsComp icon="close-line" />Recusar avaliação
                                </li>
                            </a>
                        </CompDropdown>
                    </div>
                    <div class="flex">
                        <div class="tag w-56">Dropdown a direita</div>
                        <CompDropdown alignment="right">
                            <template #toggle>
                                <IconsComp icon="more" class="w-6 h-6 fill-green-500" />
                            </template>
                            <a href="#">
                                <li>
                                    <IconsComp icon="download" /> Avaliar proposta
                                </li>
                            </a>
                            <a href="#">
                                <li>
                                    <IconsComp icon="printer" />Imprimir proposta
                                </li>
                            </a>
                            <a href="#">
                                <li>
                                    <IconsComp icon="close-line" />Recusar avaliação
                                </li>
                            </a>
                        </CompDropdown>
                    </div>
                    <div class="flex">
                        <div class="tag w-56">Dropdown ao centro</div>
                        <CompDropdown alignment="center">
                            <template #toggle>
                                <IconsComp icon="more" class="w-6 h-6 fill-green-500" />
                            </template>
                            <a href="#">
                                <li>
                                    <IconsComp icon="download" /> Avaliar proposta
                                </li>
                            </a>
                            <a href="#">
                                <li>
                                    <IconsComp icon="printer" />Imprimir proposta
                                </li>
                            </a>
                            <a href="#">
                                <li>
                                    <IconsComp icon="close-line" />Recusar avaliação
                                </li>
                            </a>
                        </CompDropdown>
                    </div>
                </div>
                <div class="card">
                    <form class="mb-4">
                        <div class="mb-1">Checkbox Model</div>
                        <div class="flex items-center mb-1">
                            <input id="default-checkbox" type="checkbox" value="" />
                            <label for="default-checkbox" class="checkbox-label">Default checkbox</label>
                        </div>
                        <div class="flex items-center mb-1">
                            <input id="checked-checkbox2" type="checkbox" value="" />
                            <label for="checked-checkbox2" class="checkbox-label">Checked state</label>
                        </div>
                        <div class="flex items-center mb-1">
                            <input id="checked-checkbox3" type="checkbox" value="" />
                            <label for="checked-checkbox3" class="checkbox-label">Checked state</label>
                        </div>
                    </form>
                    <form>
                        <div class="mb-1">Radio Model</div>
                        <div class="flex items-center mb-1">
                            <input id="default-radio-1" type="radio" value="" name="default-radio" />
                            <label for="default-radio-1">Default radio</label>
                        </div>
                        <div class="flex items-center mb-1">
                            <input checked id="default-radio-2" type="radio" value="" name="default-radio" />
                            <label for="default-radio-2">Checked state</label>
                        </div>
                        <div class="flex items-center mb-1">
                            <input checked id="default-radio-3" type="radio" value="" name="default-radio" />
                            <label for="default-radio-3">Checked state</label>
                        </div>
                    </form>
                </div>
                <div class="card">
                    <form>
                        <div>Disabled Input Model</div>
                        <input disabled type="password" placeholder="Placeholder Disabled" />
                        <div>Text Area Model</div>
                        <textarea id="textareamodel" name="" rows="4" cols="50" placeholder="Default" />
                        <div>Input Range</div>
                        <input type="range" class="custom-range" min="0" max="100" step="1" />

                        <div class="relative">
                            <select>
                                <option value="opcao1">Opção 1</option>
                                <option value="opcao2" selected>Opção 2</option>
                                <option value="opcao3">Opção 3</option>
                            </select>
                            <label></label>
                        </div>
                    </form>
                </div>
            </div>

            <!-- Model Texto Body -->
            <div class="grid gap-6 md:grid-cols-2 mb-10">
                <div class="card">
                    <div class="mb-2">Body Normal - mulish</div>
                    <div class="text-xs">Weight 400 - text-xs</div>
                    <div class="text-sm">Weight 400 - text-sm</div>
                    <div class="text-base">Weight 400 - text-base</div>
                    <div class="text-lg">Weight 400 - text-lg</div>
                    <div class="text-xl">Weight 400 - text-xl</div>
                </div>

                <div class="card">
                    <div class="mb-2">Body Bold - mulish</div>
                    <div class="text-xs"><b> Weight 700 - text-xs</b></div>
                    <div class="text-sm"><b> Weight 700 - text-sm</b></div>
                    <div class="text-base"><b> Weight 700 - text-base</b></div>
                    <div class="text-lg"><b> Weight 700 - text-lg</b></div>
                    <div class="text-xl"><b> Weight 700 - text-xl</b></div>
                </div>
            </div>
            <!-- Model Texto Title -->
            <div class="grid gap-6 md:grid-cols-2 mb-10">
                <div class="card">
                    <div class="mb-2">Title Light - Poppins</div>
                    <div class="title text-xl">Weight 300 - text-xl</div>
                    <div class="title text-2xl">Weight 300 - text-2xl</div>
                    <div class="title text-3xl">Weight 300 - text-3xl</div>
                    <div class="title text-4xl">Weight 300 - text-4xl</div>
                    <div class="title text-5xl">Weight 300 - text-5xl</div>
                </div>

                <div class="card">
                    <div class="mb-2">Title Bold - Poppins</div>
                    <div class="title text-xl">
                        <b> Weight 700 - text-xl</b>
                    </div>
                    <div class="title text-2xl">
                        <b> Weight 700 - text-2xl</b>
                    </div>
                    <div class="title text-3xl">
                        <b> Weight 700 - text-3xl</b>
                    </div>
                    <div class="title text-4xl">
                        <b> Weight 700 - text-4xl</b>
                    </div>
                    <div class="title text-5xl">
                        <b> Weight 700 - text-5xl</b>
                    </div>
                </div>
            </div>
            <!-- Model Colors -->
            <div class="grid gap-6 md:grid-cols-3 mb-10">
                <!-- Model Color Gray -->
                <div class="card">
                    <div class="text-xl mb-2 text-gray-500">gray</div>
                    <ul class="flex gap-2 mb-2 flex-wrap">
                        <li class="bg-gray-50">#F9FAFB</li>
                        <li class="bg-gray-100">#F3F4F6</li>
                        <li class="bg-gray-200">#E5E7EB</li>
                        <li class="bg-gray-300">#D2D5DA</li>
                        <li class="bg-gray-400">#9CA3AF</li>
                        <li class="bg-gray-500">#6D7280</li>
                        <li class="bg-gray-600">#4B5563</li>
                        <li class="bg-gray-700">#374151</li>
                        <li class="bg-gray-800">#1F2937</li>
                        <li class="bg-gray-900">#111827</li>
                    </ul>
                </div>
                <!-- Model Color Red -->
                <div class="card">
                    <div class="text-xl mb-2 text-red-500">red</div>
                    <ul class="flex flex-wrap gap-2 mb-2">
                        <li class="bg-red-300">#F38B75</li>
                        <li class="bg-red-500">#F64943</li>
                        <li class="bg-red-900">#5E213E</li>
                    </ul>
                </div>
                <!-- Model Color Yellow -->
                <div class="card">
                    <div class="text-xl mb-2 text-yellow-400">yellow</div>
                    <ul class="flex flex-wrap gap-2 mb-2">
                        <li class="bg-yellow-50">#FEFCE8</li>
                        <li class="bg-yellow-100">#FEF9C3</li>
                        <li class="bg-yellow-200">#FEF08A</li>
                        <li class="bg-yellow-300">#FDE047</li>
                        <li class="bg-yellow-400">#FACC15</li>
                        <li class="bg-yellow-500">#EAB308</li>
                        <li class="bg-yellow-600">#CA8A04</li>
                        <li class="bg-yellow-700">#A16207</li>
                        <li class="bg-yellow-800">#854D0E</li>
                        <li class="bg-yellow-900">#713F12</li>
                    </ul>
                </div>
                <!-- Model Color Green -->
                <div class="card">
                    <div class="text-xl mb-2 text-green-600">green</div>
                    <ul class="flex flex-wrap gap-2 mb-2">
                        <li class="bg-green-50">#f0fdf4</li>
                        <li class="bg-green-100">#BEEFD9</li>
                        <li class="bg-green-300">#83EEA7</li>
                        <li class="bg-green-500">#41CC96</li>
                        <li class="bg-green-600">#049F84</li>
                        <li class="bg-green-800">#047474</li>
                        <li class="bg-green-900">#035656</li>
                    </ul>
                </div>
                <!-- Model Color Blue -->
                <div class="card">
                    <div class="text-xl mb-2 text-blue-600">blue</div>
                    <ul class="flex flex-wrap gap-2 mb-2">
                        <li class="bg-blue-50">#F0F5F8</li>
                        <li class="bg-blue-100">#D3E2EB</li>
                        <li class="bg-blue-300">#83A5D2</li>
                        <li class="bg-blue-500">#5289D7</li>
                        <li class="bg-blue-600">#2966BE</li>
                        <li class="bg-blue-800">#23549A</li>
                        <li class="bg-blue-900">#1A3F74</li>
                    </ul>
                </div>
                <!-- Model Color Purple -->
                <div class="card">
                    <div class="text-xl mb-2 text-purple-600">purple</div>
                    <ul class="flex flex-wrap gap-2 mb-2">
                        <li class="bg-purple-50">#F2EEFB</li>
                        <li class="bg-purple-100">#CCBCF1</li>
                        <li class="bg-purple-300">#AD92E7</li>
                        <li class="bg-purple-500">#896BC8</li>
                        <li class="bg-purple-600">#7254B1</li>
                        <li class="bg-purple-800">#604B8C</li>
                        <li class="bg-purple-900">#48386A</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <!-- MODAIS   -->
    <section class="bg-gray-800">
        <div class="container mx-auto px-6 py-10 text-4xl text-white">Modais</div>
    </section>
    <section class="bg-purple-50 bg-opacity-80">
        <div class="container mx-auto px-6 py-10">
            <button @click="openModal" class="btn primary">Modal Validação</button>
            <!-- Conteúdo do modal de validação -->
            <CompModal :isOpen="isOpen" @close="isOpen = false" customClass="validacao">
                <div class="text-xl font-poppins font-bold mb-2">
                    Publicar novo edital
                </div>
                <div class="mb-8">
                    Você tem certeza que deseja publicar o edital NOME ?
                </div>
                <div class="flex justify-end">
                    <button class="btn secondary">Cancelar</button>
                    <button class="btn primary">Publicar Edital</button>
                </div>
            </CompModal>
            <!-- Conteúdo do modal de Triagem -->
            <button @click="openModal1" class="btn primary">Modal Triagem</button>
            <CompModal :isOpen="isOpen1" @close="closeModal1" customClass="triagem">
                <button @click="closeModal1" class="absolute right-5 top-5">
                    <IconsComp icon="close-line" class="w-8 h-8 fill-green-500" />
                </button>

                <div class="text-3xl text-purple-800 font-bold font-poppins mb-8">
                    Rejeitar proposta
                </div>

                <div class="mb-8">
                    <div class="text-xl text-purple-800 font-bold font-poppins mb-8">
                        Qual o motivo da rejeição da proposta?
                    </div>

                    <form>
                        <div class="flex items-center mb-4">
                            <input id="default-radio-1" type="radio" value="" name="default-radio" />
                            <label for="default-radio-1">Especialidade em saúde não condiz com a proposta (enviar de
                                volta para InovaHC)
                            </label>
                        </div>
                        <div class="flex items-center mb-4">
                            <input checked id="default-radio-2" type="radio" value="" name="default-radio" />
                            <label for="default-radio-2">A proposta enviada é uma proposta de venda</label>
                        </div>
                        <div class="flex items-center mb-4">
                            <input checked id="default-radio-3" type="radio" value="" name="default-radio" />
                            <label for="default-radio-3">Formulário preenchido com baixa qualidade ou em desacordo com o
                                edital</label>
                        </div>
                        <div class="flex items-center">
                            <input checked id="default-radio-4" type="radio" value="" name="default-radio" />
                            <label for="default-radio-4">Outros</label>
                        </div>
                    </form>
                </div>
                <div class="mb-6">
                    <div class="text-xl text-purple-800 font-bold font-poppins mb-8 relative flex">
                        Comentários sobre a avaliação <span class="asterisk"></span>
                    </div>
                    <textarea id="textareamodel" name="" rows="10" cols="50" placeholder="Comentários..." />
                </div>

                <div class="flex justify-end gap-4">
                    <button class="btn secondary w-full">Cancelar</button>
                    <button class="btn primary w-full">Rejeitar Proposta</button>
                </div>
            </CompModal>
        </div>
    </section>

    <!-- PAGE Home  -->
    <section class="bg-gray-800">
        <div class="container mx-auto px-6 py-10 text-4xl text-white">
            Page Home
        </div>
    </section>

    <section id="page-home">
        <div class="container mx-auto p-6 flex flex-col md:flex-row justify-between items-center">
            <div class="text-white w-12/12 md:w-5/12 max-w-[456px] shadow-sm">
                <div class="text-4xl font-bold font-poppins mb-6">
                    Bem-vindo à plataforma de projetos de inovação do InovaHC!
                </div>
                <div class="mb-4 text-lg">
                    A plataforma de projetos do InovaHC é o canal unificado de recebimento
                    de projetos de inovação do complexo HCFMUSP. Através dele você pode se
                    inscrever nos nossos editais abertos dos programas de apoio a inovação
                    ou submeter seu projeto para busca de apoio.
                </div>
                <div>
                    <a href="#" class="font-bold no-underline font-poppins">Conheça nossos programas</a>
                </div>
            </div>

            <div class="w-12/12 md:w-5/12 py-16">
                <div class="text-white md:text-purple-800 font-bold font-poppins text-2xl mb-2">
                    Responsável do projeto
                </div>
                <p>
                    Crie seu cadastro para acessar o formulário de inscrição do programa
                </p>

                <form class="form-simples">
                    <label class="font-bold text-white md:text-purple-800">Email</label>
                    <input type="email" placeholder="email@example.com" />

                    <label class="font-bold text-white md:text-purple-800">Nome Completo
                    </label>
                    <input type="text" placeholder="Digite seu nome" />

                    <label class="font-bold text-white md:text-purple-800">Telefone para contato</label>
                    <input type="text" placeholder="Digite seu nome" />

                    <div class="grid gap-6 grid-cols-1 md:grid-cols-2">
                        <div>
                            <label class="font-bold text-white md:text-purple-800">Senha</label>
                            <input type="password" placeholder="Your password" />
                        </div>
                        <div>
                            <label class="font-bold text-white md:text-purple-800">Confirme a senha</label>
                            <input type="password" placeholder="Your password" />
                        </div>
                    </div>
                    <div class="text-sm mb-4">
                        Sua senha deve conter ao menos n caracteres
                    </div>
                    <div class="flex items-center mb-1">
                        <input id="default-checkbox0" type="checkbox" value="" />
                        <label for="default-checkbox0" class="checkbox-label"><span
                                class="text-white md:text-gray-600 mr-1">Li e concordo com a</span>
                            <a href="#">política de privacidade de dados.</a></label>
                    </div>
                    <button class="btn primary w-full my-8">Cadastrar</button>

                    <div></div>
                </form>

                <div class="pt-8 border-t border-purple-100">
                    Já é cadastrado? <a href="#">Acesse sua conta</a>
                </div>
            </div>
        </div>
    </section>

    <!-- PAGE Home2  -->
    <section class="bg-gray-800">
        <div class="container mx-auto px-6 py-10 text-4xl text-white">
            Page Home2
        </div>
    </section>

    <section id="page-home">
        <div class="container mx-auto p-6 flex flex-col md:flex-row justify-between items-center">
            <div class="text-white w-12/12 md:w-5/12 max-w-[456px] shadow-sm">
                <div class="text-4xl font-bold font-poppins mb-6">
                    Bem-vindo de volta à plataforma de projetos de inovação do InovaHC!
                </div>
            </div>

            <div class="w-12/12 md:w-5/12 py-16">
                <form class="form-simples">
                    <label class="font-bold text-white md:text-purple-800">Email</label>
                    <input type="email" placeholder="email@example.com" class="mb-8" />

                    <label class="font-bold text-white md:text-purple-800">Senha</label>
                    <input type="password" placeholder="Your password" />

                    <div class="">
                        <a href="" class="no-underline text-label-base font-semibold mb-4 font-poppins">
                            Esqueceu sua senha?
                        </a>
                    </div>

                    <button class="btn primary w-full my-8">Cadastrar</button>

                    <div></div>
                </form>

                <div class="pt-8 border-t border-purple-100">
                    Ainda não é cadastrado? <a href="#">Conheça nossos programas</a>
                </div>
            </div>
        </div>
    </section>
    <!-- PAGE painel inovahc / programas  -->
    <section class="bg-gray-800">
        <div class="container mx-auto px-6 py-10 text-4xl text-white">
            painel inovahc / programas
        </div>
    </section>
    <section>
        <div class="container mx-auto px-6 pt-14">
            <div class="text-5xl font-bold font-poppins mb-8 text-purple-800">
                Programas
            </div>
            <div>
                <ul class="menu-tabs">
                    <li class="active">
                        <a href="#">
                            <span>
                                <IconsComp icon="document" class="w-6 h-6" />
                            </span>
                            Propostas
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <span>
                                <IconsComp icon="document" class="w-6 h-6" />
                            </span>
                            Projetos
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <span>
                                <IconsComp icon="document" class="w-6 h-6" />
                            </span>
                            Editais
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <!-- Component Lista Status Card -->
    <section class="bg-purple-50">
        <div class="container mx-auto px-6 py-10">
            <!-- Cards -->
            <div class="grid gap-6 grid-cols-1 md:grid-cols-3">
                <div class="card">
                    <div>
                        <div class="font-poppins font-bold text-purple-800 text-2xl">
                            in.cube 2023/1
                        </div>
                        <div class="text-sm text-gray-400">Inscrições</div>
                        <div class="text-sm text-gray-600">02/02/2023 a 30/04/2023</div>
                        <div class="text-sm text-gray-600">106 propostas recebidas</div>
                    </div>

                    <div class="flex flex-col gap-2 py-4">
                        <div class="status green">
                            <div class="head">
                                <IconsComp icon="success-filled" />
                                <span>01</span>
                            </div>
                            <span>Selecionadas </span>
                        </div>

                        <div class="status blue">
                            <div class="head">
                                <IconsComp icon="proposta-filled" />
                                <span>06</span>
                            </div>
                            <span>Classificadas </span>
                        </div>

                        <div class="status yellow">
                            <div class="head">
                                <IconsComp icon="timer-filled" />
                                <span>01</span>
                            </div>
                            <span>Aguardando parecer </span>
                        </div>

                        <div class="status red">
                            <div class="head">
                                <IconsComp icon="editais-filled" />
                                <span>05</span>
                            </div>
                            <span>Triagem </span>
                        </div>

                        <div class="status">
                            <div class="head">
                                <IconsComp icon="reject-filled" />
                                <span>12</span>
                            </div>
                            <span>Rejeitados </span>
                        </div>
                    </div>

                    <div class="flex justify-center">
                        <button class="btn primary">Ver detalhes</button>
                    </div>
                </div>

                <div class="card">
                    <div>
                        <div class="font-poppins font-bold text-purple-800 text-2xl">
                            in.cube 2023/1
                        </div>
                        <div class="text-sm text-gray-400">Inscrições</div>
                        <div class="text-sm text-gray-600">02/02/2023 a 30/04/2023</div>
                        <div class="text-sm text-gray-600">106 propostas recebidas</div>
                    </div>

                    <div class="flex flex-col gap-2 py-4">
                        <div class="status green">
                            <div class="head">
                                <IconsComp icon="success-filled" />
                                <span>01</span>
                            </div>
                            <span>Selecionadas </span>
                        </div>

                        <div class="status blue">
                            <div class="head">
                                <IconsComp icon="proposta-filled" />
                                <span>06</span>
                            </div>
                            <span>Classificadas </span>
                        </div>

                        <div class="status yellow">
                            <div class="head">
                                <IconsComp icon="timer-filled" />
                                <span>01</span>
                            </div>
                            <span>Aguardando parecer </span>
                        </div>

                        <div class="status red">
                            <div class="head">
                                <IconsComp icon="editais-filled" />
                                <span>05</span>
                            </div>
                            <span>Triagem </span>
                        </div>

                        <div class="status">
                            <div class="head">
                                <IconsComp icon="reject-filled" />
                                <span>12</span>
                            </div>
                            <span>Rejeitados </span>
                        </div>
                    </div>

                    <div class="flex justify-center">
                        <button class="btn primary">Ver detalhes</button>
                    </div>
                </div>

                <div class="card">
                    <div>
                        <div class="font-poppins font-bold text-purple-800 text-2xl">
                            in.cube 2023/1
                        </div>
                        <div class="text-sm text-gray-400">Inscrições</div>
                        <div class="text-sm text-gray-600">02/02/2023 a 30/04/2023</div>
                        <div class="text-sm text-gray-600">106 propostas recebidas</div>
                    </div>

                    <div class="flex flex-col gap-2 py-4">
                        <div class="status green">
                            <div class="head">
                                <IconsComp icon="success-filled" />
                                <span>01</span>
                            </div>
                            <span>Selecionadas </span>
                        </div>

                        <div class="status blue">
                            <div class="head">
                                <IconsComp icon="proposta-filled" />
                                <span>06</span>
                            </div>
                            <span>Classificadas </span>
                        </div>

                        <div class="status yellow">
                            <div class="head">
                                <IconsComp icon="timer-filled" />
                                <span>01</span>
                            </div>
                            <span>Aguardando parecer </span>
                        </div>

                        <div class="status red">
                            <div class="head">
                                <IconsComp icon="editais-filled" />
                                <span>05</span>
                            </div>
                            <span>Triagem </span>
                        </div>

                        <div class="status">
                            <div class="head">
                                <IconsComp icon="reject-filled" />
                                <span>12</span>
                            </div>
                            <span>Rejeitados </span>
                        </div>
                    </div>

                    <div class="flex justify-center">
                        <button class="btn primary">Ver detalhes</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Filtro -->
    <section class="bg-purple-50">
        <div class="container mx-auto px-6 pb-4">
            <div class="text-sm text-gray-400">Filtrar propostas</div>
            <ul class="filtro-categoria flex">
                <li class="ativo">Todas</li>
                <li>in.cube</li>
                <li>in.pulse</li>
                <li>in.pacte</li>
            </ul>

            <div class="flex justify-between">
                <div class="flex items-center">
                    <div class="relative mr-2">
                        <select>
                            <option value="opcao1" selected>Todas especialidades</option>
                            <option value="opcao2">Opção 2</option>
                            <option value="opcao3">Opção 3</option>
                        </select>
                        <label></label>
                    </div>
                    <div class="relative">
                        <select>
                            <option value="opcao1" selected>Todos os status</option>
                            <option value="opcao2">Opção 2</option>
                            <option value="opcao3">Opção 3</option>
                        </select>
                        <label></label>
                    </div>
                </div>
                <div class="w-96">
                    <CompSearch />
                </div>
            </div>
        </div>
    </section>
    <!-- Tabela -->
    <section class="bg-purple-50">
        <div class="container mx-auto px-6 py-6">
            <div class="overflow-hidden rounded-lg border border-white shadow-md">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div>
                                    Proposta
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Especialidade
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Inativo
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <span>Status</span>
                            </th>
                            <th scope="col">
                                <div>
                                    Recebida
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="w-[440px] truncate">
                                    Nome da proposta bem grande com um título que precisa de duas
                                    linhas loremipsum dolor sit amet, consectetur adipiscing elit
                                </div>
                            </td>
                            <td>
                                <div class="w-48 truncate">
                                    Cardiologia, Especialidade lorem
                                </div>
                            </td>
                            <td>4 dias</td>
                            <td>
                                <button type="button" class="tag red">Triagem pendente</button>
                            </td>
                            <td>17/02/23</td>
                            <td>
                                <div class="flex">
                                    <button>
                                        <IconsComp icon="more" />
                                    </button>
                                    <button>
                                        <IconsComp icon="arrow-down" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="w-[440px] truncate">E.Visionary</div>
                            </td>
                            <td>
                                <div class="w-48 truncate">Farmacologia</div>
                            </td>
                            <td>7 dias</td>
                            <td>
                                <button type="button" class="tag red">Triagem pendente</button>
                            </td>
                            <td>17/02/23</td>
                            <td>
                                <div class="flex">
                                    <button>
                                        <IconsComp icon="more" />
                                    </button>
                                    <button>
                                        <IconsComp icon="arrow-down" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="w-[440px] truncate">
                                    Projeto de atendimento e discussão online para referência de
                                    casos de neoplasia periampular ao ICESP - Centro de Referência
                                    Online de neoplasias do Pâncreas.
                                </div>
                            </td>
                            <td>
                                <div class="w-48 truncate">Ortopedia e Traumatologia</div>
                            </td>
                            <td>4 dias</td>
                            <td>
                                <button type="button" class="tag yellow">
                                    Aguardando parecer
                                </button>
                            </td>
                            <td>17/02/23</td>
                            <td>
                                <div class="flex">
                                    <button>
                                        <IconsComp icon="more" />
                                    </button>
                                    <button>
                                        <IconsComp icon="arrow-down" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="w-[440px] truncate">
                                    VivaVida: Programas inovativos, baseadas em evidência
                                    científica, para a identificação e tratamento da depressão.
                                </div>
                            </td>
                            <td>
                                <div class="w-48 truncate">Medicina Nuclear</div>
                            </td>
                            <td>4 dias</td>
                            <td>
                                <button type="button" class="tag red">
                                    Selecionar parecerista
                                </button>
                            </td>
                            <td>17/02/23</td>
                            <td>
                                <div class="flex">
                                    <button>
                                        <IconsComp icon="more" />
                                    </button>
                                    <button>
                                        <IconsComp icon="arrow-down" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="w-[440px] truncate">
                                    Ver e Aprender: ações para promoção da inclusão escolar do
                                    aluno com deficiência visual
                                </div>
                            </td>
                            <td>
                                <div class="w-48 truncate">Reabilitação</div>
                            </td>
                            <td>4 dias</td>
                            <td>
                                <button type="button" class="tag blue">Classificada</button>
                            </td>
                            <td>17/02/23</td>
                            <td>
                                <div class="flex">
                                    <button>
                                        <IconsComp icon="more" />
                                    </button>
                                    <button>
                                        <IconsComp icon="arrow-down" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="w-[440px] truncate">
                                    Higienizador Clean Hands com Sensores Inteligentes
                                </div>
                            </td>
                            <td>
                                <div class="w-48 truncate">Atenção primária</div>
                            </td>
                            <td>12 dias</td>
                            <td>
                                <button type="button" class="tag disabled">Rejeitada</button>
                            </td>
                            <td>17/02/23</td>
                            <td>
                                <div class="flex">
                                    <button>
                                        <IconsComp icon="more" />
                                    </button>
                                    <button>
                                        <IconsComp icon="arrow-down" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
    <!--  Paginação -->
    <section class="bg-purple-50">
        <div class="container mx-auto px-6">
            <CompPagination />
        </div>
    </section>
    <!-- PAGE painel instituto / Propostas-fluxo continuo  -->
    <section class="bg-gray-800">
        <div class="container mx-auto px-6 py-10 text-4xl text-white">
            painel instituto / Propostas-fluxo continuo
        </div>
    </section>
    <section>
        <div class="container mx-auto px-6 pt-14">
            <div class="flex flex-col md:flex-row gap-6 mb-6">
                <div class="w-full md:w-4/12">
                    <div class="text-5xl font-bold font-poppins text-purple-800">
                        Meu painel
                    </div>
                    <div class="text-2xl font-light font-poppins">
                        Instituto da Criança e do adolescente
                    </div>
                </div>
                <div class="w-full md:w-6/12">
                    <div class="card">
                        <div class="text-2xl font-bold font-poppins text-purple-800 mb-2">
                            Propostas
                        </div>
                        <!-- COMPONENTE Propostas -->
                        <div class="grid gap-6 md:grid-cols-3 propostas">
                            <div class="big red">
                                <div class="title">
                                    <IconsComp icon="proposta-filled" />
                                    <span> 6 </span>
                                </div>
                                <div class="description">Em processo de triagem</div>
                            </div>

                            <div class="big red">
                                <div class="title">
                                    <IconsComp icon="clock-filled" />
                                    <span> 2 </span>
                                </div>
                                <div class="description">Aguardando classificação</div>
                            </div>

                            <div class="big blue">
                                <div class="title">
                                    <IconsComp icon="proposta-filled" />
                                    <span> 2 </span>
                                </div>
                                <div class="description">Adicionadas ao banco de propostas</div>
                            </div>

                            <div class="small yellow">
                                <div class="title">
                                    <IconsComp icon="timer-filled" />
                                    <span> 2 </span>
                                </div>
                                <div class="description">Aguardando parecer</div>
                            </div>

                            <div class="small">
                                <div class="title">
                                    <IconsComp icon="reject-filled" />
                                    <span> 1 </span>
                                </div>
                                <div class="description">Propostas rejeitadas</div>
                            </div>

                            <div class="small green">
                                <div class="title">
                                    <IconsComp icon="success-filled" />
                                    <span> 1 </span>
                                </div>
                                <div class="description">Propostas aprovadas</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full md:w-2/12">
                    <div class="card">
                        <div class="text-2xl font-bold font-poppins text-purple-800 mb-2">
                            Propostas
                        </div>
                        <!-- COMPONENTE Propostas -->
                        <div class="grid gap-6 md:grid-cols-1 propostas">
                            <div class="big green">
                                <div class="title">
                                    <IconsComp icon="success-filled" />
                                    <span> 2 </span>
                                </div>
                                <div class="description">Andamento normal</div>
                            </div>

                            <div class="small blue">
                                <div class="title">
                                    <IconsComp icon="timer-filled" />
                                    <span> 2 </span>
                                </div>
                                <div class="description">Aguardando parecer</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- TABS -->
            <div>
                <ul class="menu-tabs">
                    <li class="active">
                        <a href="#">
                            <span>
                                <IconsComp icon="document" class="w-6 h-6" />
                            </span>
                            Propostas
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <span>
                                <IconsComp icon="document" class="w-6 h-6" />
                            </span>
                            Projetos
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <span>
                                <IconsComp icon="document" class="w-6 h-6" />
                            </span>
                            Editais
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <!-- Filtro -->
    <section class="bg-purple-50 pt-6">
        <div class="container mx-auto px-6">
            <div class="text-sm text-gray-400">Filtrar propostas</div>
            <ul class="filtro-categoria flex">
                <li class="ativo">Todas</li>
                <li>in.cube</li>
                <li>in.pulse</li>
                <li>in.pacte</li>
            </ul>

            <div class="flex justify-between">
                <div class="flex items-center">
                    <div class="relative mr-2">
                        <select>
                            <option value="opcao1" selected>Todas especialidades</option>
                            <option value="opcao2">Opção 2</option>
                            <option value="opcao3">Opção 3</option>
                        </select>
                        <label></label>
                    </div>
                    <div class="relative">
                        <select>
                            <option value="opcao1" selected>Todos os status</option>
                            <option value="opcao2">Opção 2</option>
                            <option value="opcao3">Opção 3</option>
                        </select>
                        <label></label>
                    </div>
                </div>
                <div class="w-96">
                    <CompSearch />
                </div>
            </div>
        </div>
    </section>
    <!-- Tabela -->
    <section class="bg-purple-50">
        <div class="container mx-auto px-6 py-6">
            <div class="overflow-hidden rounded-lg border border-white shadow-md">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div>
                                    Proposta
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Especialidade
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Inativo
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <span>Status</span>
                            </th>
                            <th scope="col">
                                <div>
                                    Recebida
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="w-[440px] truncate">
                                    Nome da proposta bem grande com um título que precisa de duas
                                    linhas loremipsum dolor sit amet, consectetur adipiscing elit
                                </div>
                            </td>
                            <td>
                                <div class="w-48 truncate">
                                    Cardiologia, Especialidade lorem
                                </div>
                            </td>
                            <td>4 dias</td>
                            <td>
                                <button type="button" class="tag red">Triagem pendente</button>
                            </td>
                            <td>17/02/23</td>
                            <td>
                                <div class="flex">
                                    <button>
                                        <IconsComp icon="more" />
                                    </button>
                                    <button>
                                        <IconsComp icon="arrow-down" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="w-[440px] truncate">E.Visionary</div>
                            </td>
                            <td>
                                <div class="w-48 truncate">Farmacologia</div>
                            </td>
                            <td>7 dias</td>
                            <td>
                                <button type="button" class="tag red">Triagem pendente</button>
                            </td>
                            <td>17/02/23</td>
                            <td>
                                <div class="flex">
                                    <button>
                                        <IconsComp icon="more" />
                                    </button>
                                    <button>
                                        <IconsComp icon="arrow-down" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="w-[440px] truncate">
                                    Projeto de atendimento e discussão online para referência de
                                    casos de neoplasia periampular ao ICESP - Centro de Referência
                                    Online de neoplasias do Pâncreas.
                                </div>
                            </td>
                            <td>
                                <div class="w-48 truncate">Ortopedia e Traumatologia</div>
                            </td>
                            <td>4 dias</td>
                            <td>
                                <button type="button" class="tag yellow">
                                    Aguardando parecer
                                </button>
                            </td>
                            <td>17/02/23</td>
                            <td>
                                <div class="flex">
                                    <button>
                                        <IconsComp icon="more" />
                                    </button>
                                    <button>
                                        <IconsComp icon="arrow-down" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="w-[440px] truncate">
                                    VivaVida: Programas inovativos, baseadas em evidência
                                    científica, para a identificação e tratamento da depressão.
                                </div>
                            </td>
                            <td>
                                <div class="w-48 truncate">Medicina Nuclear</div>
                            </td>
                            <td>4 dias</td>
                            <td>
                                <button type="button" class="tag red">
                                    Selecionar parecerista
                                </button>
                            </td>
                            <td>17/02/23</td>
                            <td>
                                <div class="flex">
                                    <button>
                                        <IconsComp icon="more" />
                                    </button>
                                    <button>
                                        <IconsComp icon="arrow-down" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="w-[440px] truncate">
                                    Ver e Aprender: ações para promoção da inclusão escolar do
                                    aluno com deficiência visual
                                </div>
                            </td>
                            <td>
                                <div class="w-48 truncate">Reabilitação</div>
                            </td>
                            <td>4 dias</td>
                            <td>
                                <button type="button" class="tag blue">Classificada</button>
                            </td>
                            <td>17/02/23</td>
                            <td>
                                <div class="flex">
                                    <button>
                                        <IconsComp icon="more" />
                                    </button>
                                    <button>
                                        <IconsComp icon="arrow-down" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="w-[440px] truncate">
                                    Higienizador Clean Hands com Sensores Inteligentes
                                </div>
                            </td>
                            <td>
                                <div class="w-48 truncate">Atenção primária</div>
                            </td>
                            <td>12 dias</td>
                            <td>
                                <button type="button" class="tag disabled">Rejeitada</button>
                            </td>
                            <td>17/02/23</td>
                            <td>
                                <div class="flex">
                                    <button>
                                        <IconsComp icon="more" />
                                    </button>
                                    <button>
                                        <IconsComp icon="arrow-down" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
    <!--  Paginação -->
    <section class="bg-purple-50">
        <div class="container mx-auto px-6">
            <CompPagination />
        </div>
    </section>

    <section class="bg-gray-800">
        <div class="container mx-auto px-6 py-10 text-4xl text-white">
            Página da proposta/rejeição do parecererista
        </div>
    </section>
    <section>
        <div class="container mx-auto px-6 pt-12 pb-10">
            <div class="flex justify-between flex-col md:flex-row items-center mb-8">
                <div>
                    <CompBreadCrumbs>
                        <template v-slot:default>
                            <li>
                                <a href="#">
                                    <IconsComp icon="painel" />
                                    Meu Painel
                                </a>
                            </li>
                            <li><a href="#">Nivel 1 </a></li>
                            <li><a href="#">Nivel 2 </a></li>
                            <li><a href="#">Propostas </a></li>
                        </template>
                    </CompBreadCrumbs>
                </div>
                <button class="btn primary flex">
                    Imprimir proposta
                    <IconsComp icon="printer" class="fill-white w-6 h-6 ml-2" />
                </button>
            </div>
            <div class="w-full">
                <div class="text-5xl font-bold font-poppins text-purple-800 mb-4">
                    Jornada de Alta
                </div>
                <div class="flex items-center">
                    <span class="text-sm mr-1">Status:</span>
                    <button class="tag red">Selecionar parecerista</button>
                    <button class="tag blue">Selecionar parecerista</button>
                </div>
            </div>
        </div>
    </section>
    <!-- Painel e Tabela -->
    <section class="bg-purple-50">
        <div class="container mx-auto px-6 py-16">
            <div class="text-3xl font-poppins font-bold text-purple-800 mb-8">
                Lista de pareceristas
            </div>
            <div class="flex flex-col md:flex-row gap-6 items-end mb-6">
                <div class="w-full md:w-6/12">
                    <div class="text-sm text-gray-500">Filtrar especialidades</div>
                    <CompMultiSelect v-model="multiselect" :options="multiselectOptions" />
                </div>
                <div class="w-full md:w-6/12">
                    <CompSearch />
                </div>
            </div>

            <div class="flex flex-col-reverse md:flex-row md:gap-6">
                <!-- Tabela 8 Colunas -->
                <div class="w-full md:w-8/12">
                    <div class="overflow-hidden rounded-lg border border-white shadow-md">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Parecerista</th>
                                    <th scope="col">Especialidades</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>

                                    <td>
                                        <div class="profile">
                                            <div class="checkbox">
                                                <input id="default-checkbox" type="checkbox" value="" />
                                            </div>
                                            <div class="profile-img small">
                                                <img src="@/assets/img/template/profile2.png" alt="" />
                                            </div>
                                            <div class="dados">
                                                <div class="nome">Raphael Martus</div>
                                                <div class="data">r.marcon@hc.fm.usp.br</div>
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        <div class="flex justify-between items-center">
                                            <div class="w-48 truncate">Hematologia</div>
                                            <button type="button" class="tag red">
                                                Triagem pendente
                                            </button>
                                        </div>
                                    </td>

                                    <td>
                                        <div class="flex justify-end">
                                            <button>
                                                <IconsComp icon="eye" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>

                                <tr>

                                    <td>
                                        <div class="profile">
                                            <div class="checkbox">
                                                <input id="default-checkbox" type="checkbox" value="" />
                                            </div>
                                            <div class="profile-img small">
                                                <img src="@/assets/img/template/profile2.png" alt="" />
                                            </div>
                                            <div class="dados">
                                                <div class="nome">Raphael Martus</div>
                                                <div class="data">r.marcon@hc.fm.usp.br</div>
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        <div class="flex justify-between items-center">
                                            <div class="w-48 truncate">Hematologia</div>
                                            <button type="button" class="tag red">
                                                Triagem pendente
                                            </button>
                                        </div>
                                    </td>

                                    <td>
                                        <div class="flex justify-end">
                                            <button>
                                                <IconsComp icon="eye" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>

                                <tr>

                                    <td>
                                        <div class="profile">
                                            <div class="checkbox">
                                                <input id="default-checkbox" type="checkbox" value="" />
                                            </div>
                                            <div class="profile-img small">
                                                <img src="@/assets/img/template/profile2.png" alt="" />
                                            </div>
                                            <div class="dados">
                                                <div class="nome">Raphael Martus</div>
                                                <div class="data">r.marcon@hc.fm.usp.br</div>
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        <div class="flex justify-between items-center">
                                            <div class="w-48 truncate">Hematologia</div>
                                            <button type="button" class="tag red">
                                                Triagem pendente
                                            </button>
                                        </div>
                                    </td>

                                    <td>
                                        <div class="flex justify-end">
                                            <button>
                                                <IconsComp icon="eye" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>

                                <tr>

                                    <td>
                                        <div class="profile">
                                            <div class="checkbox">
                                                <input id="default-checkbox" type="checkbox" value="" />
                                            </div>
                                            <div class="profile-img small">
                                                <img src="@/assets/img/template/profile2.png" alt="" />
                                            </div>
                                            <div class="dados">
                                                <div class="nome">Raphael Martus</div>
                                                <div class="data">r.marcon@hc.fm.usp.br</div>
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        <div class="flex justify-between items-center">
                                            <div class="w-48 truncate">Hematologia</div>
                                            <button type="button" class="tag red">
                                                Triagem pendente
                                            </button>
                                        </div>
                                    </td>

                                    <td>
                                        <div class="flex justify-end">
                                            <button>
                                                <IconsComp icon="eye" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <div class="profile">
                                            <div class="checkbox">
                                                <input id="default-checkbox" type="checkbox" value="" />
                                            </div>
                                            <div class="profile-img small">
                                                <img src="@/assets/img/template/profile2.png" alt="" />
                                            </div>
                                            <div class="dados">
                                                <div class="nome">Raphael Martus</div>
                                                <div class="data">r.marcon@hc.fm.usp.br</div>
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        <div class="flex justify-between items-center">
                                            <div class="w-48 truncate">Hematologia</div>
                                            <button type="button" class="tag red">
                                                Triagem pendente
                                            </button>
                                        </div>
                                    </td>

                                    <td>
                                        <div class="flex justify-end">
                                            <button>
                                                <IconsComp icon="eye" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- sidebar -->
                <sidebar class="w-full md:w-4/12 mb-6">
                    <div class="text-xl text-purple-800 mb-6">Selecionados</div>
                    <div class="painel">
                        <button class="card default">
                            <div class="msg">Selecione parecerista</div>
                        </button>

                        <div class="card">
                            <div class="infos">
                                <div class="profile">
                                    <div class="profile-img small">
                                        <img src="@/assets/img/template/profile2.png" alt="" />
                                    </div>
                                    <div class="dados">
                                        <div class="nome">Gabriel Stroligo</div>
                                        <div class="email">r.marcon@hc.fm.usp.br</div>
                                    </div>
                                </div>
                                <button class="options">
                                    <IconsComp icon="trash" />
                                </button>
                            </div>
                            <div class="status">
                                <button type="button" class="tag yellow">Aguardando</button>
                            </div>
                        </div>

                        <div class="card red">
                            <div class="infos">
                                <div class="profile">
                                    <div class="profile-img small">G</div>
                                    <div class="dados">
                                        <div class="nome">Gabriel Stroligo</div>
                                        <div class="email">r.marcon@hc.fm.usp.br</div>
                                    </div>
                                </div>
                                <button class="options">
                                    <IconsComp icon="trash" />
                                </button>
                            </div>
                            <div class="msg">
                                Parecerista recusou a avaliação. Por favor, selecione outro
                                parecerista.
                            </div>
                        </div>
                        <button class="btn primary" disabled>
                            Enviar convite para pareceristas
                        </button>
                    </div>
                </sidebar>
            </div>
        </div>
    </section>
    <!-- Detalhes do Projeto -->
    <section class="bg-purple-50 bg-opacity-80">
        <div class="container mx-auto px-6 py-10">
            <div class="flex flex-col md:flex-row gap-6">
                <div class="w-full md:w-8/12 flex flex-col justify-start">
                    <div class="text-3xl font-bold font-poppins text-purple-800 mb-4">
                        Informações sobre o projeto
                    </div>
                    <div class="flex gap-8 mb-8">
                        <div>
                            <div class="text-sm text-gray-500">
                                Programa / data de inscrição
                            </div>
                            <div class="font-bold text-purple-800">Enviada em 18/01/2023</div>
                            <div class="font-sm text-purple-800">fluxo contínuo</div>
                        </div>
                        <div>
                            <div class="text-sm text-gray-500">Área de especialidade</div>
                            <div class="font-bold text-purple-800">
                                Especialidades separadas por vírgulas
                            </div>
                        </div>
                    </div>
                    <!-- COLLAPSE -->
                    <CompCollapse titulo="Título do Collpase">
                        <template v-slot:default>
                            <div class="highlight1">Representante da Equipe</div>
                            <div class="profile mb-4">
                                <div class="profile-img">
                                    <img src="@/assets/img/template/profile2.png" alt="" />
                                </div>
                                <div class="dados">
                                    <div class="nome">Raphael Martus Marcon</div>
                                    <div class="flex gap-4">
                                        <div class="data">
                                            <IconsComp icon="email" />r.marcon@hc.fm.usp.br
                                        </div>
                                        <div class="data">
                                            <IconsComp icon="call" />21 99999 9999
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="highlight1">Outros integrantes</div>
                            <div class="highlight2">Nome do Integrante 1 da Equipe</div>
                            <p>
                                Porttitor pellentesque eros fringilla faucibus. Vitae aliquet
                                sed quam mauris quis non at sed. Quam duis sit viverra vivamus.
                                Scelerisque nulla iaculis nulla hac dictum. Nulla sed
                                pellentesque ornare a imperdiet id cras venenatis semper.
                                Consequat scelerisque etiam condimentum scelerisque tristique.
                                Duis mi tellus enim sit egestas arcu nulla quam eget. Fermentum
                                lacus semper tincidunt elementum. Urna sagittis fusce
                                scelerisque tellus montes dui. Diam feugiat massa nisl augue
                                urna in vel lobortis.
                            </p>
                            <p>
                                Suspendisse ultrices purus id ex viverra laoreet. Integer
                                volutpat, ante nec egestas volutpat, velit elit vulputate diam,
                                et auctor nulla nibh eu augue. Aliquam at nulla magna.
                                Vestibulum quis sapien vitae odio fringilla fermentum sit amet
                                eget tellus. Sed scelerisque eget libero in lacinia.
                                Pellentesque in orci faucibus, volutpat felis nec, mattis augue.
                                Duis in semper tortor.
                            </p>
                            <div class="highlight2">Nome do Integrante 2 da Equipe</div>
                            <p>
                                Suspendisse ultrices purus id ex viverra laoreet. Integer
                                volutpat, ante nec egestas volutpat, velit elit vulputate diam,
                                et auctor nulla nibh eu augue. Aliquam at nulla magna.
                                Vestibulum quis sapien vitae odio fringilla fermentum sit amet
                                eget tellus. Sed scelerisque eget libero in lacinia.
                                Pellentesque in orci faucibus, volutpat felis nec, mattis augue.
                                Duis in semper tortor. Porttitor pellentesque eros fringilla
                                faucibus. Vitae aliquet sed quam mauris quis non at sed. Quam
                                duis sit viverra vivamus. Scelerisque nulla iaculis nulla hac
                                dictum. Nulla sed pellentesque ornare a imperdiet id cras
                                venenatis semper. Consequat scelerisque etiam condimentum
                                scelerisque tristique. Duis mi tellus enim sit egestas arcu
                                nulla quam eget. Fermentum lacus semper tincidunt elementum.
                                Urna sagittis fusce scelerisque tellus montes dui. Diam feugiat
                                massa nisl augue urna in vel lobortis.
                            </p>
                            <div class="highlight1">Qual sua formação e experiência?</div>
                            <p>
                                Porttitor pellentesque eros fringilla faucibus. Vitae aliquet
                                sed quam mauris quis non at sed. Quam duis sit viverra vivamus.
                                Scelerisque nulla iaculis nulla hac dictum. Nulla sed
                                pellentesque ornare a imperdiet id cras venenatis semper.
                                Consequat scelerisque etiam condimentum scelerisque tristique.
                                Duis mi tellus enim sit egestas arcu nulla quam eget. Fermentum
                                lacus semper tincidunt elementum. Urna sagittis fusce
                                scelerisque tellus montes dui. Diam feugiat massa nisl augue
                                urna in vel lobortis.
                            </p>
                            <p>
                                Suspendisse ultrices purus id ex viverra laoreet. Integer
                                volutpat, ante nec egestas volutpat, velit elit vulputate diam,
                                et auctor nulla nibh eu augue. Aliquam at nulla magna.
                                Vestibulum quis sapien vitae odio fringilla fermentum sit amet
                                eget tellus. Sed scelerisque eget libero in lacinia.
                                Pellentesque in orci faucibus, volutpat felis nec, mattis augue.
                                Duis in semper tortor.
                            </p>

                            <div class="highlight2">Outros integrantes</div>
                            <p>
                                Suspendisse ultrices purus id ex viverra laoreet. Integer
                                volutpat, ante nec egestas volutpat, velit elit vulputate diam,
                                et auctor nulla nibh eu augue. Aliquam at nulla magna.
                                Vestibulum quis sapien vitae odio fringilla fermentum sit amet
                                eget tellus. Sed scelerisque eget libero in lacinia.
                                Pellentesque in orci faucibus, volutpat felis nec, mattis augue.
                                Duis in semper tortor.
                            </p>
                        </template>
                    </CompCollapse>

                    <!-- COLLAPSE -->
                    <CompCollapse titulo="Motivações e necessidades de projeto">
                        <template v-slot:default>
                            <div class="highlight1">Representante da Equipe</div>

                            <div class="highlight1">Outros integrantes</div>
                            <div class="highlight2">Nome do Integrante 1 da Equipe</div>
                            <p>
                                Porttitor pellentesque eros fringilla faucibus. Vitae aliquet
                                sed quam mauris quis non at sed. Quam duis sit viverra vivamus.
                                Scelerisque nulla iaculis nulla hac dictum. Nulla sed
                                pellentesque ornare a imperdiet id cras venenatis semper.
                                Consequat scelerisque etiam condimentum scelerisque tristique.
                                Duis mi tellus enim sit egestas arcu nulla quam eget. Fermentum
                                lacus semper tincidunt elementum. Urna sagittis fusce
                                scelerisque tellus montes dui. Diam feugiat massa nisl augue
                                urna in vel lobortis.
                            </p>
                            <p>
                                Suspendisse ultrices purus id ex viverra laoreet. Integer
                                volutpat, ante nec egestas volutpat, velit elit vulputate diam,
                                et auctor nulla nibh eu augue. Aliquam at nulla magna.
                                Vestibulum quis sapien vitae odio fringilla fermentum sit amet
                                eget tellus. Sed scelerisque eget libero in lacinia.
                                Pellentesque in orci faucibus, volutpat felis nec, mattis augue.
                                Duis in semper tortor.
                            </p>
                            <div class="highlight2">Nome do Integrante 2 da Equipe</div>
                            <p>
                                Suspendisse ultrices purus id ex viverra laoreet. Integer
                                volutpat, ante nec egestas volutpat, velit elit vulputate diam,
                                et auctor nulla nibh eu augue. Aliquam at nulla magna.
                                Vestibulum quis sapien vitae odio fringilla fermentum sit amet
                                eget tellus. Sed scelerisque eget libero in lacinia.
                                Pellentesque in orci faucibus, volutpat felis nec, mattis augue.
                                Duis in semper tortor. Porttitor pellentesque eros fringilla
                                faucibus. Vitae aliquet sed quam mauris quis non at sed. Quam
                                duis sit viverra vivamus. Scelerisque nulla iaculis nulla hac
                                dictum. Nulla sed pellentesque ornare a imperdiet id cras
                                venenatis semper. Consequat scelerisque etiam condimentum
                                scelerisque tristique. Duis mi tellus enim sit egestas arcu
                                nulla quam eget. Fermentum lacus semper tincidunt elementum.
                                Urna sagittis fusce scelerisque tellus montes dui. Diam feugiat
                                massa nisl augue urna in vel lobortis.
                            </p>
                            <div class="highlight1">Qual sua formação e experiência?</div>
                            <p>
                                Porttitor pellentesque eros fringilla faucibus. Vitae aliquet
                                sed quam mauris quis non at sed. Quam duis sit viverra vivamus.
                                Scelerisque nulla iaculis nulla hac dictum. Nulla sed
                                pellentesque ornare a imperdiet id cras venenatis semper.
                                Consequat scelerisque etiam condimentum scelerisque tristique.
                                Duis mi tellus enim sit egestas arcu nulla quam eget. Fermentum
                                lacus semper tincidunt elementum. Urna sagittis fusce
                                scelerisque tellus montes dui. Diam feugiat massa nisl augue
                                urna in vel lobortis.
                            </p>
                            <p>
                                Suspendisse ultrices purus id ex viverra laoreet. Integer
                                volutpat, ante nec egestas volutpat, velit elit vulputate diam,
                                et auctor nulla nibh eu augue. Aliquam at nulla magna.
                                Vestibulum quis sapien vitae odio fringilla fermentum sit amet
                                eget tellus. Sed scelerisque eget libero in lacinia.
                                Pellentesque in orci faucibus, volutpat felis nec, mattis augue.
                                Duis in semper tortor.
                            </p>

                            <div class="highlight2">Outros integrantes</div>
                            <p>
                                Suspendisse ultrices purus id ex viverra laoreet. Integer
                                volutpat, ante nec egestas volutpat, velit elit vulputate diam,
                                et auctor nulla nibh eu augue. Aliquam at nulla magna.
                                Vestibulum quis sapien vitae odio fringilla fermentum sit amet
                                eget tellus. Sed scelerisque eget libero in lacinia.
                                Pellentesque in orci faucibus, volutpat felis nec, mattis augue.
                                Duis in semper tortor.
                            </p>
                        </template>
                    </CompCollapse>
                </div>

                <div class="w-full md:w-4/12 flex flex-col">
                    <div class="card">
                        <div class="text-2xl font-poppins font-bold text-purple-800 mb-6">
                            Documentos do projeto
                        </div>
                        <div>
                            O monitor do projeto ainda não autorizou a submissão de
                            documentos.
                        </div>
                        <hr class="my-6" />
                        <div class="font-poppins text-xl font-light text-purple-800 mb-2">
                            Downloads úteis
                        </div>
                        <ul class="lista-dowloads">
                            <a href="#">
                                <li>
                                    Modelo plano de trabalho
                                    <span>
                                        <IconsComp icon="download" />
                                    </span>
                                </li>
                            </a>
                            <a href="#">
                                <li>
                                    Modelo relatório final<span>
                                        <IconsComp icon="download" />
                                    </span></li>
                            </a>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Edição de perfil -->
    <section class="bg-gray-800">
        <div class="container mx-auto px-6 py-10 text-4xl text-white">
            Edição de perfil
        </div>
    </section>
    <section>
        <div class="container mx-auto px-6 pt-12 pb-10">
            <div class="mb-8">
                <CompBreadCrumbs>
                    <template v-slot:default>
                        <li>
                            <a href="#">
                                <IconsComp icon="painel" />
                                Meu Painel
                            </a>
                        </li>
                        <li><a href="#">Propostas </a></li>
                        <li><a href="#">Navegação </a></li>
                    </template>
                </CompBreadCrumbs>
            </div>

            <div class="text-5xl font-bold font-poppins text-purple-800 mb-4">
                Jornada de Alta
            </div>
        </div>
    </section>
    <section class="bg-purple-50 bg-opacity-80">
        <div class="container mx-auto px-6 py-10">
            <div class="w-full md:w-8/12">
                <div class="profile mb-10">
                    <div class="profile-img big">
                        <img src="@/assets/img/template/profile2.png" alt="" />
                    </div>
                    <button class="btn primary">Substituir imagem</button>
                    <button class="btn secondary">Deletar imagem</button>
                </div>
                <div class="mb-12">
                    <div class="text-xl font-bold font-poppins text-purple-800 mb-6">
                        Notificações
                    </div>
                    <div class="mb-6">
                        <CompToogle texto="Disponível para receber convite de parecer" />
                    </div>
                    <div class="mb-6">
                        <CompToogle texto="Receber notificações por email" />
                    </div>
                </div>
                <div class="mb-12">
                    <div class="text-xl font-bold font-poppins text-purple-800 mb-6">
                        Dados pessoais
                    </div>
                    <div class="flex gap-6 mb-6">
                        <div class="w-1/2">
                            <label class="font-bold text-purple-800">Email de cadastro</label>
                            <input disabled type="email" placeholder="adilson.junior@hc.fm.usp.br" />
                        </div>
                        <div class="w-1/2">
                            <label class="font-bold text-purple-800">Primeiro Nome</label>
                            <input type="text" placeholder="Adiel" />
                        </div>
                    </div>
                    <div class="flex gap-6 mb-6">
                        <div class="w-1/3">
                            <label class="font-bold text-purple-800">Tratamento</label>
                            <input type="text" placeholder="" />
                        </div>
                        <div class="w-2/3">
                            <label class="font-bold text-purple-800">Nome completo</label>
                            <input type="text" placeholder="Adiel Pereira da Silva" />
                        </div>
                    </div>
                    <div class="flex gap-6 mb-6">
                        <div class="w-full">
                            <label class="font-bold text-purple-800">Área de especialidade</label>
                            <input disabled type="text" placeholder="Cirurgia" />
                        </div>
                    </div>
                </div>
                <div class="mb-12">
                    <div class="text-xl font-bold font-poppins text-purple-800 mb-6">
                        Segurança e privacidade
                    </div>
                    <div class="flex justify-between gap-6 mb-6 items-center">
                        <div class="">
                            <div class="font-bold text-purple-800 text-lg">Alterar senha</div>
                            <div>Definir nova senha de acesso à sua conta</div>
                        </div>
                        <div class="flex justify-end">
                            <button type="button" class="btn secondary">Alterar senha</button>
                        </div>
                    </div>
                    <div class="flex justify-between gap-6 mb-6 items-center">
                        <div class="">
                            <div class="font-bold text-purple-800 text-lg">Excluir conta</div>
                            <div>Solicite a exclusão definitiva de seus dados cadastrais</div>
                        </div>
                        <div class="flex justify-end">
                            <button type="button" class="btn secondary">Solicitar</button>
                        </div>
                    </div>
                </div>
                <div>
                    <button class="btn primary flex" disable>
                        Salvar alterações
                        <IconsComp icon="save" class="fill-white w-6 h-6 ml-2" />
                    </button>
                </div>
            </div>
        </div>
    </section>

    <!-- Pagina modelo de form 01 In.cube form-->
    <section class="bg-gray-800">
        <div class="container mx-auto px-6 py-10 text-4xl text-white">
            01 In.cube form
        </div>
    </section>
    <section>
        <div class="container mx-auto px-6 pt-12 pb-10 md:w-8/12">
            <div class="mb-8">
                <CompProgress :currentStep="4" :steps="[
                    'Introdução',
                    'Equipe',
                    'Motivações e necessidades',
                    'Projeto',
                    'Enviar',
                    'Motivações e necessidades',
                    'Projeto',
                    'Enviar',
                ]" />
            </div>

            <div class="text-5xl font-bold font-poppins text-purple-800 text-center">
                Inscrições Programa In.cube
            </div>
        </div>
    </section>
    <section class="bg-purple-50 bg-opacity-80">
        <div class="container mx-auto px-6 py-10 md:w-8/12">
            <div class="mb-16">
                <div class="text-3xl font-poppins font-bold text-purple-800 mb-2">
                    O que é o In.cube?
                </div>
                <p>
                    O programa In.cube é uma capacitação empreendedora que atende
                    tecnologias com
                    <a href="#">maturidade entre 1 e 3 do índice do TRL</a> e tem como
                    foco capacitar pesquisadores e colaboradores do HCFMUSP e da FMUSP no
                    desenvolvimento de projetos de produtos, serviços, processos e
                    negócios inovadores em saúde.
                </p>
                <p>
                    Para mais informações, acesse o nosso edital e o conteúdo do
                    formulário de inscrição:
                </p>
                <button class="btn primary">Baixar o edital</button>
            </div>

            <div class="mb-16">
                <div class="text-2xl font-poppins font-light text-purple-800 mb-2">
                    Condições para participação
                </div>
                <p class="mb-4">
                    Assinale em cada itens logo abaixo para demonstrar que sua equipe está
                    de acordo com cada termo de participação. Os termos são todos
                    obrigatório para a liberação do cadastro do representante do projeto.
                </p>
                <div class="flex items-center mb-6">
                    <input id="default-checkbox" type="checkbox" value="" />
                    <label for="default-checkbox" class="checkbox-label">
                        <b>Li o edital para seleção de projetos para o programa In.cube.</b></label>
                </div>
                <div class="flex items-center">
                    <input id="default-checkbox" type="checkbox" value="" />
                    <label for="default-checkbox" class="checkbox-label">
                        <b>Minha equipe tem no mínimo 2 e máximo de 5 integrantes.</b>
                    </label>
                </div>
                <div class="ml-7 mb-6 text-sm">
                    Caso contrário é possível se inscrever no banco de talentos, inscrição
                    no fim dessa mesma página.
                </div>
                <div class="flex items-center">
                    <input id="default-checkbox" type="checkbox" value="" />
                    <label for="default-checkbox" class="checkbox-label">
                        <b>
                            A equipe se compromete em dedicar, pelo menos, 4 horas semanais
                            para o programa.</b></label>
                </div>
                <div class="ml-7 mb-6 text-sm">
                    Contamos com a participação de todos os integrantes da equipe, porém é
                    essencial que o representante se comprometa a dedicar no mínimo 4
                    horas semanais sendo 1h com acompanhamento de um consultor do InovaHC
                    e 3h de dedicação prevista no desenvolvimento do projeto entre os
                    membros da equipe.
                </div>
            </div>
        </div>
    </section>

    <!-- PRECISA DE JS -->
    <section class="bg-red-800">
        <div class="container mx-auto px-6 py-4 text-2xl text-white">
            Components que precisam ser ajustados no JS
        </div>
    </section>

    <section>
        <div class="container mx-auto px-6 py-16">
            <div class="grid gap-6 md:grid-cols-2">
                <div class="card">
                    <label for="multi-select" class="block mb-2 font-medium text-gray-700">
                        Multi Select: Precisa de ajustes
                    </label>
                    <CompMultiSelect v-model="multiselect" :options="multiselectOptions" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    components: {
        CompModal,
    },
    data() {
        return {
            isOpen: false,
            isOpen1: false,
        };
    },
    watch: {
        isOpen(value) {
            if (value) {
                document.body.classList.add("overflow-hidden");
            } else {
                document.body.classList.remove("overflow-hidden");
            }
        },
        isOpen1(value) {
            if (value) {
                document.body.classList.add("overflow-hidden");
            } else {
                document.body.classList.remove("overflow-hidden");
            }
        },
    },
    methods: {
        openModal() {
            this.isOpen = true;
        },
        closeModal() {
            this.isOpen = false;
        },
        openModal1() {
            this.isOpen1 = true;
        },
        closeModal1() {
            this.isOpen1 = false;
        },
    },
};
</script>
